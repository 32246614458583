import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../components/Admin/TitlePage";
import { MdDeleteForever } from "react-icons/md";
import ButtonFile from "../../../../../components/Admin/Inputs/ButtonFile";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import ModalConfirm from "../../../../../components/Admin/ModalConfirm";
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";
import { apiSaveBlockScreenText, apiSaveFileBlockScreenText, apiDeleteFileBlockScreenText } from "../../../../../services/Admin/screen";


export default function Video(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    const [loadingSave, setLoadingSave] = useState(false)
    const [openModalFile, setOpenModalFile] = React.useState(false)
    const [fileToDelete, setFileToDelete] = useState(null)

    
    const handleChangeMedia = (event) => {
        state[event.target.name] = event.target.value
        setState({ ...state })
    }

    const handleOpenModalFile = (fileType) => {
        setFileToDelete(fileType)
        setOpenModalFile(true)
    }

    const handleDeleteFile = () => {
        apiDeleteFileBlockScreenText({
            blockScreenId: state.blockScreenText.blockScreenId,
            langCode: state.langSelect,
            fileType: fileToDelete
        }).then(resp => {
            setFileToDelete(null)
            setMsgSuccess(resp.data?.msg)
            window.location.reload();
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setFileToDelete(null)
        })
    }

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)
        apiSaveBlockScreenText(state).then(resp => {
            let fileVideoBinary = state?.fileVideo?.length > 0 ? state?.fileVideo[0].binary : null
            let fileImgBinary = state?.fileImg?.length > 0 ? state?.fileImg[0].binary : null
            handleSubmitFile(state.blockScreenText.blockScreenId, fileVideoBinary, fileImgBinary)
            setLoadingSave(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
        })

    }


    const handleSubmitFile = (idBlockScreen, fileVideo, fileImg) => {
        let f = new FormData()
        f.append(`fileVideo[]`, fileVideo)
        f.append(`langCode`, state.langSelect)
        f.append(`isActiveVideo`, state.blockScreenText.isActiveVideo)
        f.append(`fileImg[]`, fileImg)
        f.append(`isActiveImg`, state.blockScreenText.isActiveImg)

        apiSaveFileBlockScreenText(f, idBlockScreen).then(resp => {
            setLoadingSave(false)
            setMsgSuccess(resp.data?.msg)
            window.location.reload();
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingSave(false)
        })
    }

    return (
        <>
            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title={state.blockScreen.displayBlockName} borderTop={false} borderBottom={false} />
            </Grid>

            <Grid item xs={3}>
                    <InputCheckbox
                        label="Plantilla Activada"
                        className="mb-12"
                        checked={state?.blockScreenText.isActiveTexto}
                        onChange={(e) => {
                            state.blockScreenText.isActiveTexto = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingSave}
                    />
                </Grid>

                <Grid item md={3}>
                    <InputCheckbox
                        labelLeft="Media Esquerra: imatge"
                        label=" video"
                        checked={state?.blockScreenText.isActiveVideo}
                        onChange={(e) => {
                            state.blockScreenText.isActiveVideo = e.target.checked
                            state.fileVideo = []
                            state.blockScreenText.urlFileVideo = ""
                            setState({ ...state })
                        }}
                        disabled={loadingSave}
                    />
                </Grid>

                <Grid item md={3}>
                    <InputCheckbox
                        labelLeft="Media Dreta: imatge"
                        label=" video"
                        checked={state?.blockScreenText.isActiveImg}
                        onChange={(e) => {
                            state.blockScreenText.isActiveImg = e.target.checked
                            state.fileImg = []
                            state.blockScreenText.urlFileImg = ""
                            setState({ ...state })
                        }}
                        disabled={loadingSave}
                    />
                </Grid>

                <Grid item xs={2}>
                    <InputCheckbox
                        label="QR Activat"
                        className="mb-12"
                        checked={state?.blockScreenText.qrTexto}
                        onChange={(e) => {
                            state.blockScreenText.qrTexto = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingSave}
                    />
                </Grid>

                <Grid item md={12}></Grid>

                <Grid item md={6}>
                    {((state?.blockScreenText.isActiveVideo && (state?.fileVideo?.length > 0 || state?.blockScreenText.urlFileVideo?.length > 0)) &&
                        <div style={{ textAlign: 'center', width: 400, marginLeft: 'auto', marginRight: 'auto' }}>
                            <video controls style={{ width: '100%', objectFit: 'cover' }}>
                                <source src={
                                     state?.fileVideo?.length > 0 ? state.fileVideo?.[0]?.base64 :
                                     state.blockScreenText.urlFileVideo
                                } ></source>
                            </video>
                        </div>
                    )}
                    {((!state?.blockScreenText.isActiveVideo && (state?.fileVideo?.length > 0 || state?.blockScreenText.urlFileVideo?.length > 0)) &&
                        <div style={{ textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto' }}>
                            <img alt="" src={
                                 state?.fileVideo?.length > 0 ? state.fileVideo?.[0]?.base64 :
                                 state.blockScreenText.urlFileVideo
                            } style={{ width: '100%', objectFit: 'cover' }} />

                        </div>
                    )}
                </Grid>

                <Grid item md={6}>
                {((state?.blockScreenText.isActiveImg && (state?.fileImg?.length > 0 || state?.blockScreenText.urlFileImg?.length > 0)) &&
                        <div style={{ textAlign: 'center', width: 400, marginLeft: 'auto', marginRight: 'auto' }}>
                            <video controls style={{ width: '100%', objectFit: 'cover' }}>
                                <source src={
                                     state?.fileImg?.length > 0 ? state.fileImg?.[0]?.base64 :
                                     state.blockScreenText.urlFileImg
                                } ></source>
                            </video>
                        </div>
                    )}
                    {((!state?.blockScreenText.isActiveImg && (state?.fileImg?.length > 0 || state?.blockScreenText.urlFileImg?.length > 0)) &&
                        <div style={{ textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto' }}>
                            <img alt="" src={
                                 state?.fileImg?.length > 0 ? state.fileImg?.[0]?.base64 :
                                 state.blockScreenText.urlFileImg
                            } style={{ width: '100%', objectFit: 'cover' }} />

                        </div>
                    )}
                </Grid>

                <Grid item md={6}>
                    {state?.blockScreenText.isActiveVideo && (
                        <ButtonFile
                            label="+ Video"
                            required
                            name="fileVideo"
                            typeButton="secondary"
                            multiple={false}
                            onChange={handleChangeMedia}
                            accept="video/mp4,video/mkv,video/x-m4v,video/*"
                        />
                    )}
                    {!state?.blockScreenText.isActiveVideo && (
                        <ButtonFile
                            label="+ Imatge"
                            required
                            name="fileVideo"
                            typeButton="secondary"
                            multiple={false}
                            onChange={handleChangeMedia}
                            accept="image/png, image/jpg, image/jpeg, image/gif, image/*"
                        />
                    )}
                </Grid>

                <Grid item md={6}>
                    {state?.blockScreenText.isActiveImg && (
                        <ButtonFile
                            label="+ Video"
                            required
                            name="fileImg"
                            typeButton="secondary"
                            multiple={false}
                            onChange={handleChangeMedia}
                            accept="video/mp4,video/mkv,video/x-m4v,video/*"
                        />
                    )}
                    {!state?.blockScreenText.isActiveImg && (
                        <ButtonFile
                            label="+ Imatge"
                            required
                            name="fileImg"
                            typeButton="secondary"
                            multiple={false}
                            onChange={handleChangeMedia}
                            accept="image/png, image/jpg, image/jpeg, image/gif, image/*"
                        />
                    )}
                </Grid>

                <Grid item md={2} />
                <Grid item md={2} style={{textAlign:"center"}}>
                    {(state?.fileVideo?.length > 0 || state?.blockScreenText.urlFileVideo?.length > 0) &&
                        <InputButtonLoading
                            onClick={() => handleOpenModalFile('fileVideo')}
                            loading={false}
                            label={(
                                <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar media</>
                            )}
                            position="leftz"
                        />
                    }
                </Grid>
                <Grid item md={2} />

                <Grid item md={2} />
                <Grid item md={2} style={{textAlign:"center"}}>
                    {(state?.fileImg?.length > 0 || state?.blockScreenText.urlFileImg?.length > 0) &&
                        <InputButtonLoading
                            onClick={() => handleOpenModalFile('fileImg')}
                            loading={false}
                            label={(
                                <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar media</>
                            )}
                            position="leftz"
                        />
                    }
                </Grid>
                <Grid item md={2} />


            <Grid item md={12}>
                <InputButtonLoading
                    onClick={handleSubmit}
                    loading={loadingSave}
                    label="Modificar"
                    position="left"
                    style={{ float: 'right' }}
                />
            </Grid>

            <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile} text="Eliminar el fitxer?" />

        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        fileVideo: [],
        urlFileVideo: props?.blockScreenText.urlFileVideo || "",
        fileImg: [],
        urlFileImg: props?.blockScreenText.urlFileImg || "",
        isActiveVideo: props?.blockScreenText.isActiveVideo === undefined ? false : props.blockScreenText.isActiveVideo,
        isActiveImg: props?.blockScreenText.isActiveImg === undefined ? false : props.blockScreenText.isActiveImg,
        isActiveTexto: props?.blockScreenText.isActiveTexto === undefined ? true : props.blockScreenText.isActiveTexto,
        qrTexto: props?.blockScreenText.qrTexto === undefined ? false : props.blockScreenText.qrTexto,

    }
}

