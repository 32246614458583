import React, {useEffect, useState} from "react";
import {Chip, Grid} from "@mui/material";
import Header from "./Header";
import {useParams} from "react-router-dom";
import {apiAddImage, apiList, apiRemoveImage, apiSaveList, apiSavePoster} from "../../../../../services/Admin/obrasScanner";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";
import ButtonFile from "../../../../../components/Admin/Inputs/ButtonFile";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import moment from "moment";
import { TiWarning } from "react-icons/ti";
import {Alert} from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";
import InputCheckboxDos from "../../../../../components/Admin/Inputs/InputCheckboxDos";

export default function TabScanner(props) {
    const {id} = useParams()

    const [state, setState] = useState(initialValues)
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])

    const [loading, setLoading] = useState(false)
    const [loadingAdd, setLoadingAdd] = useState(false)
    const [loadingSaveList, setLoadingSaveList] = useState(false)

    const [rows, setRows] = useState([])
    const vertical = 'bottom'
    const horizontal = 'center'

    useEffect(() => {
        getImageScanner()
    }, [])

    const getImageScanner = () => {
        setLoading(true)
        apiList({
            id
        }).then(resp => {
            const temp = resp.data.map(doc => {
                return {
                    id: doc.id,
                    isPoster: doc.isPoster,
                    fileName: doc.fileName,
                    label: JSON.parse(doc.label),
                    text: JSON.parse(doc.text),
                    color: JSON.parse(doc.color),
                    white_list_label: JSON.parse(doc?.whiteListLabel),
                    black_list_label: JSON.parse(doc?.blackListLabel),
                    white_list_text: JSON.parse(doc?.whiteListText),
                    black_list_text: JSON.parse(doc?.blackListText),
                    scannerTotal: doc.scannerTotal,
                    scannerLastDate: moment.unix(doc.scannerLastDate).format('DD/MM/YYYY HH:mm'),
                }
            })
            setRows(temp)
            setLoading(false)
        }).catch(err => {
            console.error(err)
            setLoading(false)
        })
    }

    const addImage = () => {
        setLoadingAdd(true)
        let f = new FormData()
        for (let value of state.newImage) {
            f.append("file[]", value.binary)
        }

        apiAddImage(f,  id).then(resp => {
            setState(initialValues)
            setLoadingAdd(false)
            getImageScanner()
        }).catch(err => {
            console.error(err)
            setLoadingAdd(false)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})

        addImage()
    }

    const handleRemove = (id) => {
        apiRemoveImage(id).then(resp => {
            getImageScanner()
        }).catch(err => {
            console.error(err)
            setLoadingAdd(false)
        })
    }

    const handleSelect = (type, name, id) => {
        if (!name) {
            return false
        }
        if (type === 'label') {
            // Comprobar si existe en la lista blanca

            // Añadimos a la lista blanca
            const whiteList = rows.find(row => row.id === id)?.white_list_label
            if (whiteList) {
                const find = whiteList.find(item => item === name)
                if (!find) {
                    whiteList.push(name)
                } else {
                    // Eliminar de la lista blanca
                    const temp = whiteList.filter(item => item !== name)
                    whiteList.splice(0, whiteList.length)
                    whiteList.push(...temp)
                }
            }

            updateList("label", whiteList, id)

            // Actualizar la row
            const temp = rows.map(row => {
                if (row.id === id) {
                    row.white_list_label = whiteList
                }
                return row
            })
            setRows(temp)
        } else if (type === 'text') {
            // Comprobar si existe en la lista blanca

            // Añadimos a la lista blanca
            const whiteList = rows.find(row => row.id === id)?.white_list_text
            if (whiteList) {
                const find = whiteList.find(item => item === name)
                if (!find) {
                    whiteList.push(name)
                } else {
                    // Eliminar de la lista blanca
                    const temp = whiteList.filter(item => item !== name)
                    whiteList.splice(0, whiteList.length)
                    whiteList.push(...temp)
                }
            }

            updateList("text", whiteList, id)

            // Actualizar la row
            const temp = rows.map(row => {
                if (row.id === id) {
                    row.white_list_text = whiteList
                }
                return row
            })
            setRows(temp)
        }
    }

    const updateList = (type, list, scannerId) => {
        setLoadingSaveList(true)
        const timeOutId = setTimeout(() => {
            apiSaveList({
                type,
                list,
                scannerId
            }).then(resp => {
                setLoadingSaveList(false)
            }).catch(err => {
                console.error(err)
                setLoadingSaveList(false)
            })
        }, 3000)
        return () => clearTimeout(timeOutId)
    }

    const colorList = (type, name, id) => {
        if (type === 'label') {
            // Buscamos si la palabra existe en la lista blanca solo si el id coinicide
            const whiteList = rows.find(row => row.id === id)?.white_list_label
            if (whiteList) {
                const find = whiteList.find(item => item === name)
                if (find) {
                    return true
                }
            }
        } else if (type === 'text') {
            // Buscamos si la palabra existe en la lista blanca solo si el id coinicide
            const whiteList = rows.find(row => row.id === id)?.white_list_text
            if (whiteList) {
                const find = whiteList.find(item => item === name)
                if (find) {
                    return true
                }
            }
        }
    }

    const handleChangePoster = (id, checked) => {
        // Modificar el valor de isPoster
        const temp = rows.map(row => {
            if (row.id === id) {
                row.isPoster = checked
            }
            return row
        })
        setRows(temp)

        setLoadingSaveList(true)
        apiSavePoster({
            id,
            checked
        }).then(resp => {
            setLoadingSaveList(false)
        }).catch(err => {
            console.error(err)
            setLoadingSaveList(false)
        })
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical, horizontal}}
                open={loadingSaveList}
                variant="filled"
                key={vertical + horizontal}
            >
                <Alert severity="warning" style={{fontSize: 17, width: '100%'}} icon={false}>
                    Guardant...
                </Alert>
            </Snackbar>

            <Header
                state={state}
                setState={setState}
                id={id}
                tabType={9}
                msgError={msgError}
                msgSuccess={msgSuccess}
            >
                <div style={{margin: "5px 10px 0px 0px", width: 200}}>
                    <ButtonFile
                        label="+ Add images"
                        loading={loadingAdd}
                        name="newImage"
                        typeButton="primary"
                        onChange={handleChange}
                        multiple={true}
                    />
                </div>
            </Header>

            <LoadingSpinner show={loading}/>

            <Grid container spacing={3} className="admin-form" style={{display: loading ? 'none' : ''}}>
                {/* Listado con todas las imágenes */}
                {rows.map((row, i) => (
                    <div key={i} style={{background: '#fafafa', padding: '20px 10px', width: '100%', margin: 10}}>
                        <Grid container spacing={3}>
                            <Grid item md={12} style={{paddingTop: 10, paddingBottom: 0}}><span style={{fontSize: 11}}>ID - {row.id}</span></Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <div>
                                    <img src={row.fileName} alt={row.fileName} style={{width: '100%', maxWidth: 300}}/>
                                    <InputCheckboxDos
                                        label="Cartela"
                                        name="isPoster"
                                        checked={row?.isPoster}
                                        onChange={(e) => {
                                            handleChangePoster(row.id, e.target.checked)
                                        }}
                                    />
                                    {row.scannerTotal > 0 && (
                                        <p style={{textAlign: 'center', marginBottom: 0}}>
                                            {row.scannerTotal} - {row.scannerLastDate}
                                        </p>
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12} md={3} lg={3}>
                                LABEL
                                {/* ALERTA */}
                                {row.white_list_label?.length > 3 && (
                                    <p style={{textAlign: 'left', marginBottom: 0}}>
                                        <Chip
                                            icon={<TiWarning style={{
                                                fontSize: 20,
                                            }}/>}
                                            label={row.white_list_label.length}
                                            size="small"
                                            style={{
                                                background: '#efa94a',
                                                width: 100
                                            }}
                                        />
                                    </p>
                                )}
                                <br/>
                                {/* NOMBRES */}
                                {row.label && (
                                    <>
                                        {row.label.length > 0 && (
                                            <>
                                                {row.label.map((label, j) => (
                                                    <Chip
                                                        key={j}
                                                        label={`${label?.Name} ${Math.floor(label?.Confidence)}%`}
                                                        style={{margin: 3, background: colorList('label',`${label?.Name}`, row.id) ? '#77dd77' : '#e7e7e7'}}
                                                        size="small"
                                                        onClick={() => handleSelect('label', `${label?.Name}`, row.id)}
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>

                            <Grid item xs={12} md={3} lg={3}>
                                TEXT
                                {/* ALERTA */}
                                {row.white_list_text?.length > 3 && (
                                    <p style={{textAlign: 'left', marginBottom: 0}}>
                                        <Chip
                                            icon={<TiWarning style={{
                                                fontSize: 20,
                                            }}/>}
                                            label={row.white_list_text.length}
                                            size="small"
                                            style={{
                                                background: '#efa94a',
                                                width: 100
                                            }}
                                        />
                                    </p>
                                )}
                                <br />
                                {/* NOMBRES */}
                                {row.text && (
                                    <>
                                        {row.text.length > 0 && (
                                            <>
                                                {row.text.map((label, j) => (
                                                    <Chip
                                                        key={j}
                                                        label={`${label?.DetectedText} ${Math.floor(label?.Confidence)}%`}
                                                        style={{margin: 3, background: colorList('text', `${label?.DetectedText}`, row.id) ? '#77dd77' : '#e7e7e7'}}
                                                        size="small"
                                                        onClick={() => handleSelect('text', `${label?.DetectedText}`, row.id)}
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                COLOR
                                <br />
                                <br />
                                {row.color && (
                                    <>
                                        {Object.keys(row.color).length > 0 && (
                                            <>
                                                {Object.keys(row.color).map((key) => {
                                                    const label = row.color[key];
                                                    return (
                                                        <Chip
                                                            key={key}
                                                            label={`${key} ${Math.floor(label)}%`}
                                                            style={{ margin: 3, background: `${key}`, color: key === 'white' ? 'black' : 'white' }}
                                                            size="small"
                                                        />
                                                    );
                                                })}
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{padding: 0, margin: 0}}>
                                <InputButton
                                    text="Eliminar"
                                    onClick={() => handleRemove(row.id)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                ))}

            </Grid>
        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        id: props?.id || "",
        newImage: "",
    }
}
