import PageManagement from "../../pages/Admin/Web/Page/Management"
import MenuManagement from "../../pages/Admin/Web/Menu/Management"
import FieldManagement from "../../pages/Admin/Web/Menu/Fields"
import FieldSubmenuManagement from "../../pages/Admin/Web/Menu/Fields/SubmenuFields"
import PageFields from "../../pages/Admin/Web/Page/Fields"
import BlockFields from "../../pages/Admin/Web/Page/Block/Fields"
import BlockFooterFields from "../../pages/Admin/Web/Page/Block/Fields/BlockFooterFields"
import Activity from "../../pages/Admin/Web/Page/Block/Fields/SectionsForm/Activity/EditActivity"

const baseTitle = "Offing - "
const urlBase = "/dash"

export const RouteWebBack = {
    WEB_BACK_MANAGEMENT: {
        path: `${urlBase}/webback/management`,
        menu: "web",
        menuChild: "page",
        page: PageManagement,
        title: `${baseTitle}WebBack Management`,
        roles: ["ROLE_ADMIN"]
    },
    WEB_BACK_EDIT: {
        path: `${urlBase}/webback/edit`,
        menu: "web",
        menuChild: "page",
        params: "/:id",
        page: PageFields,
        title: `${baseTitle}WebBack Edit`,
        roles: ["ROLE_ADMIN"]
    },
    WEB_BACK_CREATE: {
        path: `${urlBase}/webback/create`,
        menu: "web",
        menuChild: "page",
        page: PageFields,
        title: `${baseTitle}WebBack Create`,
        roles: ["ROLE_ADMIN"]
    },
    WEB_BLOCK_EDIT: {
        path: `${urlBase}/webback/block/edit`,
        menu: "web",
        menuChild: "page",
        params: "/:idPage/:idPageBlock",
        page: BlockFields,
        title: `${baseTitle}WebBack Block Edit`,
        roles: ["ROLE_ADMIN"]
    },
    WEB_BLOCK_FOOTEREDIT: {
        path: `${urlBase}/webback/block/footer/edit`,
        menu: "web",
        menuChild: "page",
        params: "/:idPage",
        page: BlockFooterFields,
        title: `${baseTitle}WebBack Block Footer Edit`,
        roles: ["ROLE_ADMIN"]
    },
    WEB_BACK_MENU: {
        path: `${urlBase}/webback/menu/management`,
        menu: "web",
        menuChild: "menu",
        page: MenuManagement,
        title: `${baseTitle}WebBack Menu`,
        roles: ["ROLE_ADMIN"]
    },
    WEB_BACK_MENU_EDIT: {
        path: `${urlBase}/webback/menu/fields`,
        menu: "web",
        menuChild: "menu",
        params: "/:id",
        page: FieldManagement,
        title: `${baseTitle}WebBack Edit`,
        roles: ["ROLE_ADMIN"]
    },
    WEB_BACK_MENU_CREATE: {
        path: `${urlBase}/webback/menu/fields`,
        menu: "web",
        menuChild: "menu",
        page: FieldManagement,
        title: `${baseTitle}WebBack Create`,
        roles: ["ROLE_ADMIN"]
    },
    WEB_BACK_SUBMENU_EDIT: {
        path: `${urlBase}/webback/menu/fields-submenu`,
        menu: "web",
        menuChild: "menu",
        params: "/:submenuId/:menuId",
        page: FieldSubmenuManagement,
        title: `${baseTitle}WebBack Submenu Edit`,
        roles: ["ROLE_ADMIN"]
    },
    WEB_BACK_SUBMENU_CREATE: {
        path: `${urlBase}/webback/menu/fields-submenu`,
        menu: "web",
        menuChild: "menu",
        params: "/:menuId",
        page: FieldSubmenuManagement,
        title: `${baseTitle}WebBack Submenu Create`,
        roles: ["ROLE_ADMIN"]
    },
    WEB_ACTIVITY_CREATE: {
        path: `${urlBase}/webback/activity/create`,
        menu: "web",
        menuChild: "page",
        params: "/:idPage/:idPageBlock/:idBlock",
        page: Activity,
        title: `${baseTitle}WebBack Activity Create`,
        roles: ["ROLE_ADMIN"]
    },
    WEB_ACTIVITY_EDIT: {
        path: `${urlBase}/webback/activity/edit`,
        menu: "web",
        menuChild: "page",
        params: "/:idPage/:idPageBlock/:idBlock/:idActivity",
        page: Activity,
        title: `${baseTitle}WebBack Activity Edit`,
        roles: ["ROLE_ADMIN"]
    },
}
