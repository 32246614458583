import React, {useEffect, useRef, useState} from "react";
import {Grid} from "@mui/material";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";
import InputSelect from '../../../../../components/Admin/Inputs/InputSelect'
import Header from "./Header";
import {useNavigate, useParams} from "react-router-dom";
import {apiSave, apiGet} from "../../../../../services/Admin/obra";
import {RouteAdminObras} from "../../../../../routes/RouteAdminObras";
import {apiList as apiListArtist} from "../../../../../services/Admin/artist";
import {apiList as apiListSeries} from "../../../../../services/Admin/serie";
import {apiList as apiListRooms} from "../../../../../services/Admin/room";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import EditorText from "../../../../../components/EditorText";

export default function TabGeneral() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(false)
    const [artists, setArtists] = useState([])
    const [series, setSeries] = useState([])
    const [rooms, setRooms] = useState([])
    const [collectives, setCollectives] = useState([])
    const myRef = useRef(null)

    useEffect(() => {
        setLoadingGetInfo(true)
        setMsgError([])
        setMsgSuccess([])

        getArtist()
        if (id) {
            getItem()
        } else {
            setLoadingGetInfo(false)
        }
    }, [state.langSelect])

    useEffect(() => {
        getSerie()
        getRoom()
    }, [state.langSelect])

    useEffect(() => {
        if (state.collective.length > 0) {
            const collectivesString = state.collective.map(item => item.trans[state.langSelect]?.name).filter(name => name).join(', ');
            setCollectives(collectivesString);
        }
      }, [state.collective, state.langSelect]);

    const getArtist = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let params = {
            field: "name",
            sort: "ASC",
        }

        apiListArtist(params).then(resp => {
            setArtists(resp.data.data)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    const getSerie = () => {
        setMsgError([])
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let params = {
            lang: state.langSelect,
        }

        apiListSeries(params).then(resp => {
            setSeries(resp.data.data)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    const getRoom = () => {
        setMsgError([])
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let params = {
            lang: state.langSelect,
        }

        apiListRooms(params).then(resp => {
            // Asignar las salas a rooms después de ordenarlas manualmente
            const desiredOrder = [
                20,
                21,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                15,
                16,
                17,
                19
            ];
    
            const compareByDesiredOrder = (a, b) => {
                return desiredOrder.indexOf(a.id) - desiredOrder.indexOf(b.id);
            };

            setRooms(resp.data.data.sort(compareByDesiredOrder))
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    const getItem = () => {
        setLoadingGetInfo(true)
        
        apiGet(id, state?.langSelect).then(resp => {
            setState(resp.data)
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
        })
    }

    const handleSubmit = async () => {
        setMsgError([])
        setMsgSuccess([])
        setLoading(true)
        
        let error = validationSchema(state)
        
        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        await apiSave(state).then(resp => {
            setLoading(false)
            setState({...state, id: resp.data.id})
            if (!id) {
                setMsgSuccess(resp.data.msg)
                navigate(`${RouteAdminObras.OBRA_EDIT_GENERAL.path}/${resp.data.id}`)
            } else {
                setMsgSuccess(resp.data.msg)
            }
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
        })
    }

    const handleChange = (e) => {
        state[e.target.name] = e.target.value
        setState({...state})
    }

    const optionsSelect = [{id: 1, name: "Sala 1"}, {id: 2, name: "Sala 2"}, {id: 3, name: "Sala 3"}]

    return (
        <>
            <Header
                state={state}
                setState={setState}
                id={id}
                tabType={0}
                msgError={msgError}
                msgSuccess={msgSuccess}
            />

            <LoadingSpinner show={loadingGetInfo}/>


            <Grid container spacing={2} style={{display: loadingGetInfo ? 'none' : ''}}>

                <Grid item xs={12} md={12} lg={6}>
                    <InputText
                        label="Codi"
                        name="code"
                        value={state?.code}
                        disabled={loadingGetInfo}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <InputSelect
                        label="Sèrie"
                        name="serie"
                        options={series}
                        selectText="Selecciona una sèrie"
                        value={state.serie}
                        onChange={handleChange}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <p style={{fontSize: '11px', fontFamily: 'AdminFontMedium', color: '#778', marginTop: '0px', paddingLeft: '15px'}}>Títol original</p>
                    <EditorText
                        style={{height: '60px'}}
                        optionsInline={['italic']}
                        content={state?.originalTitle}
                        setContent={(newContent) => {
                            setState((prevState) => ({
                                ...prevState,
                                originalTitle: newContent
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <p style={{fontSize: '11px', fontFamily: 'AdminFontMedium', color: '#778', marginTop: '0px', paddingLeft: '15px'}}>Títol traduït</p>
                    <EditorText
                        style={{height: '60px'}}
                        optionsInline={['italic']}
                        content={state?.title}
                        setContent={(newContent) => {
                            setState((prevState) => ({
                                ...prevState,
                                title: newContent
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <p style={{fontSize: '11px', fontFamily: 'AdminFontMedium', color: '#778', marginTop: '0px', paddingLeft: '15px'}}>Títol original sèrie</p>
                    <EditorText
                        style={{height: '60px'}}
                        optionsInline={['italic']}
                        content={state?.originalTitleSerie}
                        setContent={(newContent) => {
                            setState((prevState) => ({
                                ...prevState,
                                originalTitleSerie: newContent
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <InputSelect
                        label="Artistes"
                        name="artist"
                        options={artists}
                        selectText="Selecciona artistes"
                        multiple={true}
                        value={state.artist}
                        onChange={handleChange}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <InputText
                        label="Col·lectiu"
                        name="collective"
                        value={collectives}
                        disabled={true}
                        className="input-disable-black"
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <InputText
                        label="Any"
                        name="dateArtwork"
                        value={state?.dateArtwork}
                        onChange={handleChange}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <p style={{fontSize: '11px', fontFamily: 'AdminFontMedium', color: '#778', marginTop: '0px', paddingLeft: '15px'}}>Descripció tècnica</p>
                    <EditorText
                        style={{height: '150px'}}
                        content={state?.description}
                        setContent={(newContent) => {
                            setState((prevState) => ({
                                ...prevState,
                                description: newContent
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <InputSelect
                        label="Sala"
                        name="room"
                        options={rooms}
                        selectText="Selecciona una sala"
                        disabled={loadingGetInfo}
                        value={state.room}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} md={12} lg={6}>
                    <InputCheckbox
                        label="Activada"
                        checked={state?.isActive}
                        onChange={(e) => {
                            state.isActive = e.target.checked
                            setState({...state})
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputButtonLoading
                        onClick={handleSubmit}
                        loading={loading}
                        label={id ? 'Modificar' : 'Crear'}
                        disabled={loading}
                    />
                </Grid>
            </Grid>
        </>
    )
}

function initialValues(props) {

    let langSelect = localStorage.getItem('langSelect')

    return {
        id: null,
        langSelect: langSelect ? langSelect : 'ca',
        code: "",
        dateArtwork: "",
        artist: [],
        serie: "",
        isActive: true,
        originalTitle: "",
        title: "",
        description: "",
        collective: {},
        room: null
    }
}
function validationSchema(schema) {

    let error = []

    if (!schema?.artist || schema?.artist.length == 0) {
        error.push("L'artista és obligatori")
    }

    return error
}
