import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}/game`;

export function apiSave(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save`, props, config)
}

export function apiGameScreen(langSelect, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/screen/${langSelect}`, config)
}

export function apiGameScreenSave(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-screen`, props, config)
}
