import CloseIcon from "../../../assets/images/front/Icons/CloseIcon.svg";
import React from "react";
import {useNavigate} from "react-router-dom";
import {RouteUser} from "../../../routes/RouteUser";

export default function InputButtonClose(props) {
    const navigate = useNavigate()
    const {goToGameIntro} = props

    return (
        <button
            className="close-button"
            onClick={goToGameIntro ? () => navigate(RouteUser.GAMEINTRO.path) : () => navigate(-1)}
            style={{
                backgroundColor: '#ffffff',
                borderRadius: '100%',
                display: 'flex',
                boxShadow: '0px 2px 6px rgba(0, 33, 68, 0.1)',
                padding: '.125vw',
                width: 'max-content',
                border: 'none',
            }}
        >
            <img
                src={CloseIcon}
                alt=""
                style={{
                    width: 16,
                    height: 16,
                    padding: 11
                }}
            />
        </button>
    )
}
