import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

export default function PhotoBlock(props) {

    const {
        pluralTitle,
        hideTitle,
        content,
        className
    } = props;
    const {t} = useTranslation()

    const [title, setTitle] = useState("")
    const hideHeaderIn = hideTitle === false ? false : true

    useEffect(() => {
        if(pluralTitle){
            setTitle(t('story.plural.photo'))
        }else{
            setTitle(t('story.photo'))
        }
    }, [title]);

    return (
        <>
            {content.photo &&
                <div className={className} style={{marginTop: hideHeaderIn ? '0px' : '-4px'}}>
                    <div style={{borderTop: hideHeaderIn ? '1px solid #FE7A84' : 'none', paddingTop: '8px', paddingBottom: '4px'}}>
                        {hideHeaderIn && (<h2 style={{fontFamily: 'SohneBreitHalbfett', fontSize: '15px', lineHeight: '18px', color: '#E30613', margin: '0px', marginBottom: '16px'}}>{title}</h2>)}
                        <div style={{marginBottom: '10px'}}>
                            <div style={{width: '100%', height: 'auto'}}>
                                <img src={content.photo} style={{width: '100%', height: 'auto', objectFit: 'cover'}} />
                            </div>
                            <div style={{fontSize: '12px', lineHeight: '16px', color: '#141414', fontFamily: 'SohneBuch', marginTop: '8px', marginBottom: '24px', paddingBottom: '0px'}} dangerouslySetInnerHTML={{ __html: content.footer }} className="text-block-html" />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
