import * as React from 'react';
import {useTranslation} from "react-i18next";
import MobileMessage from '../../../../components/User/MobileMessage';
import logo from '../../../../assets/images/Logo_MuseuArtProhibit_White.png';

export default function SessionExpired() {

    const {t} = useTranslation()

    return (
        <>
            <MobileMessage />
            <div className="session-expired">
                <div className="texts-container">
                    <h2>{t('session_expired.title')}</h2>
                    <p className='session-expired-h1'>{t('session_expired.subtitle')}</p>
                    <p className='session-expired-p'>{t('session_expired.text')}</p>
                </div>
                <div className="image-container">
                    <img src={logo} alt='logo' />
                </div>
            </div>
        </>
    )
}
