import React, {useEffect, useState} from "react";
import {apiList} from "../../../../../services/Admin/user";
import moment from "moment";
import {Button, Checkbox, FormControl, Grid, IconButton, MenuItem, Paper, Select} from "@mui/material";
import MsgError from "../../../../../components/MsgError";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import Table from "../../../../../components/Table/Table";
import {RouteAdmin} from "../../../../../routes/RouteAdmin";
import {DASH_MAX_ROW} from "../../../../../utils/constants";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import InputSelect from "../../../../../components/Admin/Inputs/InputSelect";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import LinkButtonPrimary from "../../../../../components/Buttons/LinkButtonPrimary";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";

export default function Management() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [selection, setSelection] = useState(["92"])

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field
        if (state.sort.field === "roleName") { field = "role" }
        if (state.sort.field === "active") { field = "isActive" }
        if (state.sort.field === "access") { field = "lastActivity" }

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.email) { params.email = state.email }
        if (state.role) { params.role = state.role }

        apiList(params).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc,
                    id: doc.id,
                    roleName: doc?.role?.niceName,
                    access: doc.isActive ? "Sí" : "No",
                    active: doc.lastActivity ? moment.unix(doc.lastActivity).format("D/M/Y") : null,
                }
            })

            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    const handleChangeSelection = (e) => {
        if (selection.includes(e.target.value)) {
            setSelection(selection.filter(item => item !== e.target.value))
        } else {
            setSelection([...selection, e.target.value])
        }
    }

    // const LinkButtonPrimary = styled(Link)(({ theme }) => ({
    //     padding: '8px 22px  !important',
    //     backgroundColor: '#ffffff',
    //     color: '#E30613',
    //     border: '1px solid #E30613',
    //     fontFamily: 'AdminFontBold',
    //     borderRadius: '6px !important',
    //     textDecoration: 'none',
    //     cursor: 'pointer',
    //     transition: 'all 0.3s',
    //     '&:hover': {
    //         backgroundColor: '#E30613',
    //         color: '#ffffff !important',
    //         transition: 'all 0.3s'
    //     },
    // }));

    return (
        <>
            <HeaderPage title="Usuaris">
                <InputButton
                    style={{float: "right", margin: "0px 0px 10px 0px"}}
                    href={RouteAdmin.USER_CREATE.path}
                    text=" + Crear usuari"
                />
            </HeaderPage>

            <MsgError msg={msgError}/>

            <Grid container spacing={2}>

                <Filters
                    state={state}
                    setState={setState}
                />

                <Grid item xs={12}>
                    <Table
                        columns={tableColumns()}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        email: "",
        role: ""
    }
}

function tableColumns(handleChangeSelection, selection) {
    return [
        // {
        //     field: "checkbox",
        //     headerName: "",
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (<Checkbox onChange={handleChangeSelection} value={params.row.id} checked={selection.includes(params.row.id)}/>)
        //     },
        // },
        {
            field: "id",
            headerName: "ID",
            sortable: true,
            flex: 1
        },
        {
            field: "active",
            headerName: "ACTIU",
            sortable: true,
            flex: 1
        },
        {
            field: "role",
            headerName: "TIPUS D'USUARI (ROL)",
            sortable: true,
            flex: 1
        },
        {
            field: "name",
            headerName: "NOM",
            sortable: true,
            flex: 1
        },
        {
            field: "email",
            headerName: "EMAIL",
            sortable: true,
            flex: 1
        },
        {
            field: "access",
            headerName: "ACCÉS A LA PLATAFORMA",
            sortable: true,
            flex: 1
        },
        {
            field: "actions",
            headerName: "ACCIONS",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`${RouteAdmin.USER_EDIT.path}/${params.id}`}>
                            <IconButton size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                    </>
                )
            },
        },
    ]
}

function Filters(props) {

    const {state, setState} = props
    const {t} = useTranslation()

    const optionsRole = [{id: "ROLE_USER", name: t('user.roles.user')}, {id: "ROLE_ADMIN", name: t('user.roles.admin')}]

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <>
            <Grid item xs={6}>
                <InputText
                    label="Email"
                    name="email"
                    value={state.email}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={6}>
                <InputSelect
                    label={t('user.role')}
                    name="role"
                    onChange={handleChange}
                    value={state?.role}
                    options={optionsRole}
                />
            </Grid>
        </>
    )
}
