import React, {useState, useEffect} from "react";
import image from "../../../../assets/images/no_image.png";
import downArrow from "../../../../assets/images/front/web/icons/downArrow.svg";

export default function Header({item}) {

    const [state, setState] = useState(initialValues(item))
    const [headerBlockStyles, setHeaderBlockStyles] = useState({})

    useEffect(() => {
        handleResize()

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    const handleResize = () => {
        if (window.innerWidth >= 768) {
            setHeaderBlockStyles(prevStyles => ({ ...prevStyles, height: 'calc(100vh - 20px)' }));
        } else {
            const height = state?.imgPortada ? 'calc(100vh - 20px)' : 'calc(62vh - 20px)';
            setHeaderBlockStyles(prevStyles => ({ ...prevStyles, height }));
        }
    };

    return (
        <div className="blocks-full-width">
            <div
                className={`header-block ${state?.btnRedName ? "" : "without-button"}`}
                style={{
                    ...(state?.fileBackgroundImage
                      ? { backgroundImage: `url(${state?.fileBackgroundImage})` }
                      : {}),
                    ...headerBlockStyles
                }}
                title={state.itemOne}
                alt={state.itemTwo}
            >
                <div className="text-container">
                    <p style={{color: state?.titleColor ? '#000' : '#fff'}}>
                        {state?.text}
                    </p>
                </div>
                <div className="button-bottom">
                    {state?.btnRedName &&
                        <div className="entradas-container">
                            <a href={state.btnRedLink} id={state?.itemThree} className="block-link">{state.btnRedName}</a>
                        </div>
                    }
                    <div className="scroll-container">
                        <img alt="" src={downArrow}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function initialValues(props) {

    return {
        "fileBackgroundImage": props?.fileBackgroundImage,
        "text": props?.text,
        "btnRedName": props?.btnRedName,
        "btnRedLink": props?.btnRedLink,
        "titleColor": props?.titleColor ?? false,
        "imgPortada": props?.imgPortada ?? false,
        "itemOne": props?.itemOne,
        "itemTwo": props?.itemTwo,
        "itemThree": props?.itemThree,
    }
}
