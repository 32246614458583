import React, {useState} from "react";
import {apiRegister} from "../../../services/Auth/login";
import {Link, useNavigate} from "react-router-dom";
import {CssBaseline, TextField} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {ThemeProvider} from "@emotion/react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {createTheme} from "@mui/material/styles";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MsgError from "../../../components/MsgError";
import MsgSuccess from "../../../components/MsgSuccess";
import {LoadingButton} from "@mui/lab";
import {RouteAuth} from "../../../routes/RouteAuth";
import {useTranslation} from "react-i18next";
const theme = createTheme();

export default function RegisterForm() {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [state, setState] = useState(initialValues())
    const [msgError, setMsgError] = useState([])
    const [showForm, setShowForm] = useState(true)
    const {i18n} = useTranslation()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = (event) => {
        setLoading(true)
        event.preventDefault()

        setMsgError([])
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            return
        }

        apiRegister(state, i18n.resolvedLanguage).then(resp => {
            navigate(RouteAuth.LOGIN.path + `/?msg=${resp.data?.msg}`)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
        })
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <MsgError msg={msgError} />
                            </Grid>
                            {showForm && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="name"
                                            required
                                            fullWidth
                                            id="name"
                                            label="First Name"
                                            autoFocus
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="surnames"
                                            label="Last Name"
                                            name="surnames"
                                            autoComplete="family-name"
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <LoadingButton
                                            type="submit"
                                            loading={loading}
                                            loadingPosition="start"
                                            variant="contained"
                                            fullWidth
                                            sx={{mt: 1, mb: 2}}
                                        >
                                            Sign Up
                                        </LoadingButton>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link to={RouteAuth.LOGIN.path}>
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    )
}

function initialValues() {
    return {
        name: "",
        surnames: "",
        email: "",
        password: ""
    }
}

function validationSchema(schema) {
    let error = []

    if (!schema.name) {
        error.push("- El nombre es obligatorio.")
    }
    if (!schema.email) {
        error.push("- El email es obligatorio.")
    }
    if (!schema.password) {
        error.push("- La contraseña es obligatoria.")
    }

    return error
}
