import React, {useState} from "react";
import {Grid} from "@mui/material";
import Header from "./Header";
import {useParams} from "react-router-dom";

export default function TabXarxes(props) {
    const {id} = useParams()

    const [state, setState] = useState(initialValues)
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])

    return (
        <>
            <Header
                state={state}
                setState={setState}
                id={id}
                tabType={5}
                msgError={msgError}
                msgSuccess={msgSuccess}
            />

            <Grid container spacing={3} className="admin-form">

                {/* FIELDS */}

            </Grid>
        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        id: props?.id || "",
    }
}
