import WebFront from "../../pages/User/Web"

const baseTitle = "Offing - "
const urlBase = "/web"

export const RouteWebFront = {

    WEB_FRONT_: {
        path: `/web`,
        page: WebFront,
        title: `${baseTitle}Page`,
        roles: ["ROLE_PUBLIC"]
    },

    WEB_FRONT_2: {
        path: `/web`,
        params: "/:langSelect",
        page: WebFront,
        title: `${baseTitle}Page`,
        roles: ["ROLE_PUBLIC"]
    },

    WEB_FRONT_1: {
        path: `${urlBase}`,
        params: "/:langSelect/:slugOne",
        page: WebFront,
        title: `${baseTitle}Page`,
        roles: ["ROLE_PUBLIC"]
    },
   
    WEB_FRONT: {
        path: `${urlBase}`,
        params: "/:langSelect/:slugOne/:slugTwo",
        page: WebFront,
        title: `${baseTitle}Page`,
        roles: ["ROLE_PUBLIC"]
    },
   
}
