import React, { useRef, useState, useEffect } from "react";
import {convertFromRaw} from 'draft-js';
import {EditorState} from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export default function FullTextToHtml({text}) {

    const [state, setState] = useState(initialValues(text))

    useEffect(() => {
        setState(initialValues(text))
    },[text])

    return(
        <div className="text-container dynamic-html-text" dangerouslySetInnerHTML={{__html: state?.text}} />
    )
}

function initialValues(text) {

    let a;

    if(text)
    {
        a=EditorState.createWithContent(convertFromRaw(JSON.parse(text))).getCurrentContent()
    }
    else
    {
        a=EditorState.createEmpty().getCurrentContent()
    }

    const rawContentState = convertToRaw(a);

    let markup = draftToHtml(rawContentState);

    markup=markup.replace(/<p><\/p>/gi, "<br/>");


    return {
        "text": markup,
    }
}
