import {BASE_PATH, BASE_PATH_EXTENSION} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION}/user/image-scanner`;

export function apiScanner(props, lang = 'ca') {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/`, props, config)
}
