import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

/**
 * Muestra un reproductor de audio
 *
 * ## PROPS
 * - **url** *string*
 */
export default function CloseIcon(props) {

    const {color, width, height, className, style} = props;

    return (
        <div className={className} style={{width: width, height: height, ...style}}>
            <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 26 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 7.5C17.8978 7.5 18.2794 7.65804 18.5607 7.93934C18.842 8.22064 19 8.60218 19 9V27C19 27.3978 18.842 27.7794 18.5607 28.0607C18.2794 28.342 17.8978 28.5 17.5 28.5H8.5C8.10218 28.5 7.72064 28.342 7.43934 28.0607C7.15804 27.7794 7 27.3978 7 27V9C7 8.60218 7.15804 8.22064 7.43934 7.93934C7.72064 7.65804 8.10218 7.5 8.5 7.5H17.5ZM8.5 6C7.70435 6 6.94129 6.31607 6.37868 6.87868C5.81607 7.44129 5.5 8.20435 5.5 9V27C5.5 27.7956 5.81607 28.5587 6.37868 29.1213C6.94129 29.6839 7.70435 30 8.5 30H17.5C18.2956 30 19.0587 29.6839 19.6213 29.1213C20.1839 28.5587 20.5 27.7956 20.5 27V9C20.5 8.20435 20.1839 7.44129 19.6213 6.87868C19.0587 6.31607 18.2956 6 17.5 6H8.5Z" fill={color} />
                <path d="M13 27C13.3978 27 13.7794 26.842 14.0607 26.5607C14.342 26.2794 14.5 25.8978 14.5 25.5C14.5 25.1022 14.342 24.7206 14.0607 24.4393C13.7794 24.158 13.3978 24 13 24C12.6022 24 12.2206 24.158 11.9393 24.4393C11.658 24.7206 11.5 25.1022 11.5 25.5C11.5 25.8978 11.658 26.2794 11.9393 26.5607C12.2206 26.842 12.6022 27 13 27Z" fill={color}/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8633 5.08827H25.4404C25.4888 5.08829 25.5361 5.10209 25.5769 5.12805C25.6177 5.15401 25.6503 5.19106 25.6708 5.23486C25.6913 5.27866 25.6989 5.3274 25.6927 5.37536C25.6865 5.42333 25.6668 5.46854 25.6359 5.5057L23.6342 7.90848C23.6103 7.93713 23.5805 7.96017 23.5467 7.97599C23.5129 7.9918 23.476 8 23.4388 8C23.4015 8 23.3646 7.9918 23.3308 7.97599C23.2971 7.96017 23.2672 7.93713 23.2433 7.90848L21.2416 5.5057C21.2107 5.46854 21.191 5.42333 21.1848 5.37536C21.1786 5.3274 21.1862 5.27866 21.2067 5.23486C21.2272 5.19106 21.2598 5.15401 21.3006 5.12805C21.3414 5.10209 21.3888 5.08829 21.4371 5.08827H22.8279C22.6351 4.14694 22.18 3.27934 21.515 2.5857C20.8501 1.89204 20.0024 1.40065 19.07 1.16832C18.1376 0.935995 17.1585 0.972201 16.2458 1.27276C15.3331 1.57332 14.5241 2.12597 13.9122 2.86685C13.8705 2.92089 13.8183 2.96599 13.7589 2.99947C13.6994 3.03296 13.6338 3.05414 13.566 3.06177C13.4982 3.06939 13.4295 3.0633 13.3641 3.04386C13.2987 3.02441 13.2378 2.99201 13.1852 2.94859C13.1325 2.90516 13.0891 2.85159 13.0576 2.79106C13.0261 2.73053 13.0071 2.66428 13.0016 2.59624C12.9962 2.52821 13.0046 2.45978 13.0261 2.39503C13.0477 2.33028 13.0821 2.27053 13.1272 2.21932C13.8726 1.31678 14.8617 0.647346 15.9766 0.29064C17.0915 -0.0660658 18.2854 -0.0950336 19.4163 0.207187C20.5472 0.509406 21.5675 1.13009 22.3559 1.99542C23.1442 2.86072 23.6674 3.93423 23.8633 5.08827Z" fill={color}/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.83152 32.9117H0.254436C0.206072 32.9117 0.158713 32.8979 0.117907 32.872C0.0771001 32.846 0.0445345 32.8089 0.0240233 32.7651C0.00351216 32.7213 -0.00409549 32.6726 0.00209116 32.6246C0.00827782 32.5767 0.0280027 32.5315 0.0589561 32.4943L2.06059 30.0915C2.08448 30.0629 2.11437 30.0398 2.14815 30.024C2.18193 30.0082 2.21877 30 2.25607 30C2.29337 30 2.33021 30.0082 2.36399 30.024C2.39777 30.0398 2.42767 30.0629 2.45155 30.0915L4.45319 32.4943C4.48414 32.5315 4.50387 32.5767 4.51005 32.6246C4.51624 32.6726 4.50863 32.7213 4.48812 32.7651C4.46761 32.8089 4.43505 32.846 4.39424 32.872C4.35343 32.8979 4.30607 32.9117 4.25771 32.9117H2.86696C3.05972 33.8531 3.51485 34.7207 4.17979 35.4143C4.84475 36.108 5.6924 36.5993 6.6248 36.8317C7.55721 37.064 8.53631 37.0278 9.44901 36.7272C10.3617 36.4267 11.1708 35.874 11.7827 35.1331C11.8244 35.0791 11.8765 35.034 11.936 35.0005C11.9954 34.967 12.061 34.9459 12.1288 34.9382C12.1967 34.9306 12.2653 34.9367 12.3307 34.9561C12.3962 34.9756 12.457 35.008 12.5097 35.0514C12.5623 35.0948 12.6057 35.1484 12.6372 35.2089C12.6687 35.2695 12.6878 35.3357 12.6932 35.4038C12.6986 35.4718 12.6903 35.5402 12.6687 35.605C12.6471 35.6697 12.6128 35.7295 12.5676 35.7807C11.8222 36.6832 10.8332 37.3527 9.71825 37.7094C8.60333 38.0661 7.40941 38.095 6.2785 37.7928C5.14759 37.4906 4.12728 36.8699 3.33893 36.0046C2.55061 35.1393 2.0274 34.0658 1.83152 32.9117Z" fill={color}/>
            </svg>
        </div>
    )
}
