import React, {useEffect, useState, useRef} from "react"
import {useTranslation} from "react-i18next";
import {getGameScreen} from "../../../../services/User/game";
import LoadingSpinnerBig from "../../../../components/LoadingSpinnerBig";
import MsgError from "../../../../components/MsgError";
import Extintor from '../../../../components/Icons/Extintor';
import Flames from '../../../../components/Icons/Flames';

export default function Info(props) {

    const {t, i18n} = useTranslation()
    const { 
        gameMessageBurn,
        gameMessageSave,
        updateVote
    } = props
    const [msgError, setMsgError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(initialValues());
    const [resultCode, setResultCode] = useState(null);
    const [resultImage, setResultImage] = useState(null);

    const prevResultCodeRef = useRef(null); // Evitar una consulta doble

    // Mensaje dependiendo de la acción repetida 3 veces
    useEffect(() => {
        if (gameMessageBurn) {
            setResultCode("ALERT_SCREEN_SAVE");
            setResultImage(<Extintor className='icon' />);
        } else if(gameMessageSave) {
            setResultCode("ALERT_SCREEN_BURN");
            setResultImage(<Flames className='icon' />);
        }
    }, []);

    // Buscar el mensaje evitando una consulta doble
    useEffect(() => {
        if (prevResultCodeRef.current !== resultCode) {
            getScreen();
        }
        prevResultCodeRef.current = resultCode;
    }, [resultCode]);

    const getScreen = () => {
        setLoading(true);
        getGameScreen({ code: resultCode }, i18n.resolvedLanguage, i18n.resolvedLanguage)
        .then(resp => {
            setState(prevState => ({
                ...prevState,
                ...resp.data[0],
                // image: resp.data[0].id === 5 ? extintor : resp.data[0].id === 6 ? flames : null
            }));
            setLoading(false);
        })
        .catch(err => {
            setMsgError(err.response?.data?.msg);
            setLoading(false);
        });
    };

    return (
        <>
            <LoadingSpinnerBig show={loading}/>
            <MsgError msg={msgError}/>
            {!loading && (
                <div className={`game-info ${gameMessageBurn ? 'extintor-info' : 'flames-info'}`}>
                    <div className='info-card'>
                        <div className='img-container'>
                            {resultImage && resultImage}
                        </div>
                        <div className='text-container'>
                            <div dangerouslySetInnerHTML={{ __html: state.text }} />
                        </div>
                    </div>
                    <button className='button-block' onClick={() => updateVote()}>{t('game.keep_on')}</button>
                </div>
            )}
        </>
    )
}

function initialValues(props) {
    return {
        id: null,
        code: "",
        title: "",
        subtitle: "",
        text: ""
    }
}
