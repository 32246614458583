import React, {useEffect, useState} from "react";
import {apiList} from "../../../../../services/Admin/obra";
import {apiSave as apiSaveGame} from "../../../../../services/Admin/game";
import Dnd from "./Dnd";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import {apiGeneralUpdate, apiGetGeneral} from "../../../../../services/Admin/general";
import {Grid} from "@mui/material";
import {lang} from "../../../../../utils/lang";
import InputSelect from "../../../../../components/Admin/Inputs/InputSelect";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import MsgSuccess from "../../../../../components/MsgSuccess";
import MsgError from "../../../../../components/MsgError";

export default function Management() {
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)

    const [list1, setList1] = useState([])
    const [list2, setList2] = useState([])
    const [totalArtworkRandoms, setTotalArtworkRandoms] = useState(0)
    const [totalArtworkRandomsLoading, setTotalArtworkRandomsLoading] = useState(false)

    const [initEnd, setInitEnd] = useState(false)

    const [loadingList1, setLoadingList1] = useState(false)
    const [loadingList2, setLoadingList2] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)

    useEffect(() => {
        getObras()
        getGeneral()
    }, [])

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            setTotalRandom()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [totalArtworkRandoms])

    useEffect(() => {
        getObras()
    }, [])

    const setTotalRandom = () => {
        if (totalArtworkRandomsLoading) {
            apiGeneralUpdate({
                code: 'GAME_TOTAL_ARTWORK',
                text: totalArtworkRandoms
            }).then(resp => {
            }).catch(err => {
                setMsgError(err.response?.data?.msg)
            })
        }
    }

    const getGeneral = () => {
        apiGetGeneral('GAME_TOTAL_ARTWORK').then(resp => {
            setTotalArtworkRandoms(resp.data.text)
            setTotalArtworkRandomsLoading(true)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setTotalArtworkRandomsLoading(true)
        })
    }


    const saveList2 = () => {
        setLoadingSave(true)
        setMsgError([])
        setMsgSuccess([])
        // Recorremos list2 y generamos un array solo con los ID
        let list2Id = list2.map(item => item.id)

        apiSaveGame({
            artworkId: list2Id
        }).then(resp => {
            setLoadingSave(false)
            setMsgSuccess(['Dades guardades amb èxit'])
        }).catch(err => {
            setLoadingSave(false)
            setMsgError(err.response?.data?.msg)
        })

    }

    const getObras = async () => {
        setLoadingList1(true)

        setMsgError([])
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: 3000,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.title) {
            params.title = state.title
        }
        params.addPhoto = true

        apiList(params).then(resp => {

            let temp = resp.data.data?.map(doc => {
                return {
                    id: `${doc.id}`,
                    content: (
                        <img alt={doc.title} src={doc.photo} style={{width: "100%"}}/>
                    ),
                    inGame: doc.inGame
                }
            }) ?? []

            let temp1 = []
            let temp2 = []
            // Añadir a temp1 solo los que tengas inGame = false
            temp.forEach(item => {
                if (!item?.inGame) {
                    temp1.push(item)
                } else {
                    temp2.push(item)
                }
            })


            setList1(temp1)
            setList2(temp2)
            setLoadingList1(false)
            setInitEnd(true)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoadingList1(false)
            setInitEnd(true)
            setList1([])
            setList2([])
        })
    }

    return (
        <>
            <Grid container spacing={1}>

                <Grid item md={6}>
                    <MsgSuccess msg={msgSuccess} />
                    <MsgError msg={msgError} />
                </Grid>

                <Grid item md={3}>
                    <InputText
                        label="Total de Obres que sortiran aleatoriament"
                        name="totalArtworkRandoms"
                        value={totalArtworkRandoms}
                        disabled={!totalArtworkRandomsLoading}
                        onChange={(e) => {
                            setTotalArtworkRandoms(e.target.value)
                        }}
                    />
                </Grid>

                <Grid item md={3}>
                    <InputButtonLoading
                        onClick={saveList2}
                        loading={loadingSave}
                        label="Guardar"
                        position="right"
                    />
                </Grid>

                <Grid item md={12}>
                    <Dnd
                        loadingList1={loadingList1}
                        loadingList2={loadingList2}
                        loadingTitleList2={false}
                        list1={list1}
                        setList1={setList1}
                        list2={list2}
                        setList2={setList2}
                    />
                </Grid>
            </Grid>
        </>
    )
}

function initialValues(props) {
    return {
        offset: 0,
        sort: {field: "id", sort: "desc"},
    }
}
