import React, {useEffect, useState} from 'react';
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import {stateToHTML} from "draft-js-export-html";
import {stateFromHTML} from "draft-js-import-html";
import {Editor} from 'react-draft-wysiwyg';
import {TEXT_FONTS} from "../utils/constants";
import {TEXT_SIZES} from "../utils/constants";
import {COLOR_PICKER} from "../utils/constants";

export default function EditorTextWeb(props) {

    const {content, setContent, style, options, optionsInline, fonts, sizes, colors} = props

    const [text, setText] = useState(EditorState.createEmpty())

    const fontsIn = TEXT_FONTS
    const sizesIn = TEXT_SIZES
    const colorPickerIn = COLOR_PICKER

    useEffect(() => {
        let temp = null;

        try {
            temp = content ? convertFromRaw(JSON.parse(content)) : null;
        } catch (error) {
            console.error("Error parsing content:", error);
            temp = null;
        }

        if (temp) {
            const rawTemp = convertToRaw(temp);
            const rawText = convertToRaw(text.getCurrentContent());

            if (JSON.stringify(rawTemp) !== JSON.stringify(rawText)) {
                setText(EditorState.createWithContent(temp));
            }
        } else {
            setText(EditorState.createEmpty());
        }
    }, [content]);
    const onEditorStateChange = (newText) => {
        const blocks = stateToHTML(newText.getCurrentContent())
        setContent(JSON.stringify(convertToRaw(newText.getCurrentContent())))

        setText(newText)
    }

    return (
        <div style={{background: '#fff', padding: 0}}>
            <Editor
                editorState={text}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onEditorStateChange}
                editorStyle={style}
                toolbar={{
                    options: options ? options : ['inline', 'history'],
                    inline: { inDropdown: false, options: optionsInline ? optionsInline : ['bold', 'italic', 'underline']},
                    list: { inDropdown: false },
                    link: { inDropdown: false, options: ['link']},
                    image: { inDropdown: false },
                    history: { inDropdown: false },
                    fontSize: {
                        options: sizes ? sizes: sizesIn
                    },
                    fontFamily: {
                        options: fonts ? fonts : fontsIn,
                    },
                    colorPicker: {
                        colors: colors ? colors : colorPickerIn
                    }
                }}
            />
        </div>
    )
}
