import React from "react";
import ContainersManagement from "../../../../../components/Admin/Media/Management"

export default function Management(props) {
    return (
        <ContainersManagement
            canBeSelected={false}
            selectedMultiple={false}
        />
    )
}
