import React from "react";
import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';

export default function ButtonPrimary(props) {
    const {label, onClick, style, className, disabled} = props

    const disabledIn = disabled ? disabled : false

    const ButtonPrimary = styled(Button)(({ theme }) => ({
        padding: '8px 22px',
        backgroundColor: '#ffffff',
        color: '#E30613',
        border: '1px solid #E30613',
        fontFamily: 'AdminFontBold',
        borderRadius: '6px',
        textDecoration: 'none',
        cursor: 'pointer',
        transition: 'all 0.3s',
        textTransform: 'inherit',
        '&:hover': {
            backgroundColor: '#E30613',
            color: '#ffffff',
            transition: 'all 0.3s'
        },
    }));

    return(
        <>
            <ButtonPrimary
                onClick={onClick}
                style={style}
                className={className}
                disabled={disabledIn}
            >
                {label}
            </ButtonPrimary>
        </>
    );
}
