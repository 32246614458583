import React, { useRef, useState, useEffect } from "react";
import {useTranslation} from "react-i18next";

export default function DocumentationBlock(props) {

    const {hideTitle, content} = props
    const {t} = useTranslation()

    const hideHeaderIn = hideTitle === false ? false : true

    return(
        <>
            {content.headline && 
                <div className="documentation-block" style={{maxWidth: '100%', marginTop: hideHeaderIn ? '0px' : '-4px'}}>
                    <div style={{paddingBottom: '0px', paddingTop: '4px', borderTop: hideHeaderIn ? '1px solid #FE7A84' : 'none'}}>
                        {hideHeaderIn && (<h2 style={{fontFamily: 'SohneBreitHalbfett', fontSize: '15px', lineHeight: '18px', color: '#E30613', margin: '0px', marginBottom: '12px'}}>{t('story.documentation')}</h2>)}
                        <div style={{marginBottom: '24px'}}>
                            <h3 style={{fontFamily: 'QueensCondensedBoldIt', fontSize: '20px', lineHeight: '22px', color: '#141414', margin: '0px', marginBottom: '8px'}}>{content.headline}</h3>
                            <p style={{fontFamily: 'SohneBuch', fontSize: '12px', lineHeight: '14px', color: '#141414', margin: '0px'}}>{content.source}</p>
                            <p style={{fontFamily: 'SohneBuch', fontSize: '12px', lineHeight: '14px', color: '#141414', margin: '0px'}}>{content.date}</p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
