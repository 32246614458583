import React, {useEffect, useRef, useState} from "react";
import {Grid} from "@mui/material";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import Header from "./Header";
import {useNavigate, useParams} from "react-router-dom";
import clone from "../../../../../utils/clone";
import {apiSaveQuote, apiGetQuote} from "../../../../../services/Admin/obra";
import {apiDeleteQuote} from "../../../../../services/Admin/obraDelete";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";
import ModalConfirm from "../../../../../components/Admin/ModalConfirm";
import TitlePage from "../../../../../components/Admin/TitlePage";

export default function TabCites() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(false)
    const myRef = useRef(null)
    const [openModal, setOpenModal] = React.useState(false)
    const [pendingIndexRemove, setPendingIndexRemove] = React.useState(null)

    const handleOpenModal = (key) => {
        setOpenModal(true)
        setPendingIndexRemove(key)
    }

    useEffect(() => {
        getData()
    }, [state.langSelect])

    const getData = () => {
        setLoadingGetInfo(true)

        apiGetQuote({id}, state?.langSelect).then(resp => {
            let quotesNew = resp.data.quotes?.map(doc => {
                return {
                    ...doc
                }
            })

            setState({...state, quotes: quotesNew})
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data.msg)
        })
    }

    // Si no existe el idioma iniciamos el modelo. Es decir, montamos estructura
    useEffect(() => {
        let temp = state.quotes?.map(doc => {
            return {
                ...doc
            }
        })

        setState({...state, quotes: temp})
    }, [state.langSelect])
    
    const handleChange = key => (e) => {
        state.quotes[key][e.target.name] = e.target.value
        setState({...state})
    }
    
    const handleChangeTrans = key => (event) => {
        state.quotes[key][event.target.name] = event.target.value
        setState({...state})
    }
    
    const handleSubmit = index => async () => {
        stateUpdateSimple(index, true, [], [])

        let error = validationSchema(state)

        if (error.length !== 0) {
            stateUpdateSimple(index, false, error, [])
            return
        }

        let quotes = clone(state.quotes[index])
        quotes.langSelect = state.langSelect

        await apiSaveQuote(quotes).then(resp => {
            state.quotes[index].id = resp.data?.id
            setState({...state})
            stateUpdateSimple(index, false, [], resp.data?.msg)
        }).catch(err => {
            stateUpdateSimple(index, false, err.response?.data?.msg, [])
        })
    }

    const stateUpdateSimple = (index, loading, msgError, msgSuccess) => {
        state.quotes[index].loading = loading
        state.quotes[index].msgError = msgError
        state.quotes[index].msgSuccess = msgSuccess

        setState({...state})
    }

    const stateDeleteSimple = (index, loadingDelete, msgError, msgSuccess) => {
        state.quotes[index].loadingDelete = loadingDelete
        state.quotes[index].msgError = msgError
        state.quotes[index].msgSuccess = msgSuccess

        setState({...state})
    }

    const addQuote = () => {
        let model = state.model
        model.artworkId = id
        setState({...state, quotes: [...state.quotes, clone(model)]})
        myRef.current.scrollIntoView()
    }

    const handleRemove = () => {
        stateDeleteSimple(pendingIndexRemove, true, [], [])
        apiDeleteQuote({
            id: state.quotes[pendingIndexRemove].id
        }).then(resp => {
            window.location.reload();
            stateDeleteSimple(pendingIndexRemove, false, [], resp.data?.msg)
            setPendingIndexRemove(null)
        }).catch(err => {
            stateDeleteSimple(pendingIndexRemove, false, err.response?.data?.msg, [])
            setPendingIndexRemove(null)
        })
    }

    return (
        <>
            <Header
                state={state}
                setState={setState}
                id={id}
                tabType={7}
                msgError={msgError}
                msgSuccess={msgSuccess}
            >
                <InputButton
                    style={{float: "right", margin: "5px 10px 10px 0px"}}
                    onClick={addQuote}
                    text=" + Afegir cita"
                />
            </Header>

            <LoadingSpinner show={loadingGetInfo}/>

            <div style={{display: loadingGetInfo ? 'none' : ''}}>
                {state.quotes.map((element, key) => (
                    <Grid container spacing={3} style={{padding: '0px'}} key={key}>
                        {key === 0 ? (
                            <Grid item xs={12} md={12} lg={12} style={{paddingTop: 0}}>
                                <TitlePage title={`Cita ${key + 1}`} />
                            </Grid>
                        ) : (
                            <Grid item xs={12} md={12} lg={12}>
                                <TitlePage title={`Cita ${key + 1}`} borderTop={true} />
                            </Grid>
                        )}

                        <Grid item xs={12} md={12} lg={12}>
                            <InputText
                                label={"Text"}
                                rows={4}
                                name="text"
                                value={state.quotes[key]?.text}
                                onChange={handleChangeTrans(key)}
                                disabled={loadingGetInfo}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={4}>
                            <div style={{display: 'block'}}>
                                <MsgSuccess msg={state.quotes[key].msgSuccess} />
                                <MsgError msg={state.quotes[key].msgError} />
                            </div>

                            <InputButtonLoading
                                onClick={handleSubmit(key)}
                                loading={state.quotes[key].loading}
                                label={state.quotes[key].id ? 'Modificar' : 'Crear'}
                                position="left"
                                style={{marginRight: '15px'}}
                            />
                            {state.quotes[key].id && (
                                <InputButtonLoading
                                    onClick={() => handleOpenModal(key)}
                                    loading={state.quotes[key].loadingDelete}
                                    label="Eliminar"
                                    position="left"
                                    variant="outline"
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <div style={{height: 5}}></div>
                        </Grid>
                    </Grid>
                ))}
                <ModalConfirm openModal={openModal} setOpenModal={setOpenModal} handleAction={handleRemove} text="Eliminar el bloc de cites?" />
            </div>
            <div ref={myRef}></div>
        </>
    );
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        quotes:[],
        model: {
            artworkId: null,
            id: null,
            text: "",
            loading: false,
            loadingDelete: false,
            msgSuccess: [],
            msgError: []
        },
    }
}
function validationSchema(schema) {

    let error = []

    return error
}
