import * as React from 'react';

export default function NotFoundPage() {

    return (
        <div className='not-found-page'>
            <h2>ERROR</h2>
            <h1>404</h1>
        </div>
    )
}
