import React, {useEffect, useState} from "react";
import {apiList, apiIsActive} from "../../../../../services/Admin/serie";
import {Grid, IconButton} from "@mui/material";
import MsgError from "../../../../../components/MsgError";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import Table from "../../../../../components/Table/Table";
import {RouteAdmin} from "../../../../../routes/RouteAdmin";
import {DASH_MAX_ROW} from "../../../../../utils/constants";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import LinkButtonPrimary from "../../../../../components/Buttons/LinkButtonPrimary";
import {Visibility} from "@mui/icons-material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputButton from "../../../../../components/Admin/Inputs/InputButton";

export default function Management() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(false)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [selection, setSelection] = useState([])

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.name) { params.name = state.name }
        if (state.artist) { params.artist = state.artist }
        if (state.langSelect) { params.lang = state.langSelect }

        apiList(params).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {
                return { ...doc }
            })
            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    const handleChangeSelection = (e) => {
        if (selection.includes(e.target.value)) {
            setSelection(selection.filter(item => item !== e.target.value))
        } else {
            setSelection([...selection, e.target.value])
        }
    }

    const handleIsActive = (id) => {
        apiIsActive(id).then(resp => {
            getItemsData()
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    return (
        <>
            <HeaderPage>
                <InputButton
                    style={{float: "right", margin: "0px 0px 10px 0px"}}
                    href={RouteAdmin.SERIE_CREATE.path}
                    text="+ Crear sèrie"
                />
            </HeaderPage>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MsgError msg={msgError}/>
                </Grid>

                <Grid item xs={12}>

                    <Filters
                        state={state}
                        setState={setState}
                    />

                    <Table
                        columns={tableColumns(handleIsActive)}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        offset: 0,
        sort: { field: "id", sort: "desc" },
        artist: "",
        name: "",
    }
}

function tableColumns(handleIsActive) {
    return [
        {
            field: "name",
            headerName: "SÈRIE",
            sortable: true,
            flex: 1
        },
        {
            field: "actions",
            headerName: "ACCIONS",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`${RouteAdmin.SERIE_EDIT.path}/${params.id}`}>
                            <IconButton size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                        <button type="button" onClick={(e) => handleIsActive(params.id)} style={{background: "transparent", border: "transparent"}}>
                            <IconButton size="small">
                                {params.isActive == true ? <Visibility fontSize="inherit" /> : <VisibilityOffIcon fontSize="inherit" />}
                            </IconButton>
                        </button>
                    </>
                )
            },
        },
    ]
}

function Filters(props) {

    const {state, setState} = props
    const {t} = useTranslation()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>
            <Grid item xs={12}>
                <InputText
                    label="Sèrie"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                />
            </Grid>

            {/*<Grid item xs={3}>*/}
            {/*    <InputText*/}
            {/*        label="Artista"*/}
            {/*        name="name"*/}
            {/*        value={state.name}*/}
            {/*        onChange={handleChange}*/}
            {/*    />*/}
            {/*</Grid>*/}

            {/* <Grid item xs={3}>
                <InputSelect
                    label="Idioma"
                    options={lang}
                    name="langSelect"
                    value={state.langSelect}
                    selectText="Selecciona un idioma"
                    onChange={handleChange}
                />
            </Grid> */}
        </Grid>
    )
}
