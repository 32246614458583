import React, {useEffect, useState} from "react"

// Secuencia de imágenes
import frame0 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00000.png';
import frame1 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00001.png';
import frame2 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00002.png';
import frame3 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00003.png';
import frame4 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00004.png';
import frame5 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00005.png';
import frame6 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00006.png';
import frame7 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00007.png';
import frame8 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00008.png';
import frame9 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00009.png';
import frame10 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00010.png';
import frame11 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00011.png';
import frame12 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00012.png';
import frame13 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00013.png';
import frame14 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00014.png';
import frame15 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00015.png';
import frame16 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00016.png';
import frame17 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00017.png';
import frame18 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00018.png';
import frame19 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00019.png';
import frame20 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00020.png';
import frame21 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00021.png';
import frame22 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00022.png';
import frame23 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00023.png';
import frame24 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00024.png';
import frame25 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00025.png';
import frame26 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00026.png';
import frame27 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00027.png';
import frame28 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00028.png';
import frame29 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00029.png';
import frame30 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00030.png';
import frame31 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00031.png';
import frame32 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00032.png';
import frame33 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00033.png';
import frame34 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00034.png';
import frame35 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00035.png';
import frame36 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00036.png';
import frame37 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00037.png';
import frame38 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00038.png';
import frame39 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00039.png';
import frame40 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00040.png';
import frame41 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00041.png';
import frame42 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00042.png';
import frame43 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00043.png';
import frame44 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00044.png';
import frame45 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00045.png';
import frame46 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00046.png';
import frame47 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00047.png';
import frame48 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00048.png';
import frame49 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00049.png';
import frame50 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00050.png';
import frame51 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00051.png';
import frame52 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00052.png';
import frame53 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00053.png';
import frame54 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00054.png';
import frame55 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00055.png';
import frame56 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00056.png';
import frame57 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00057.png';
import frame58 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00058.png';
import frame59 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00059.png';
import frame60 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00060.png';
import frame61 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00061.png';
import frame62 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00062.png';
import frame63 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00063.png';
import frame64 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00064.png';
import frame65 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00065.png';
import frame66 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00066.png';
import frame67 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00067.png';
import frame68 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00068.png';
import frame69 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00069.png';
import frame70 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00070.png';
import frame71 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00071.png';
import frame72 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00072.png';
import frame73 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00073.png';
import frame74 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00074.png';
import frame75 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00075.png';
import frame76 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00076.png';
import frame77 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00077.png';
import frame78 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00078.png';
import frame79 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00079.png';
import frame80 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00080.png';
import frame81 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00081.png';
import frame82 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00082.png';
import frame83 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00083.png';
import frame84 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00084.png';
import frame85 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00085.png';
import frame86 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00086.png';
import frame87 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00087.png';
import frame88 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00088.png';
import frame89 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00089.png';
import frame90 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00090.png';
import frame91 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00091.png';
import frame92 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00092.png';
import frame93 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00093.png';
import frame94 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00094.png';
import frame95 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00095.png';
import frame96 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00096.png';
import frame97 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00097.png';
import frame98 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00098.png';
import frame99 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00099.png';
import frame100 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00100.png';
import frame101 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00101.png';
import frame102 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00102.png';
import frame103 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00103.png';
import frame104 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00104.png';
import frame105 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00105.png';
import frame106 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00106.png';
import frame107 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00107.png';
import frame108 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00108.png';
import frame109 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00109.png';
import frame110 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00110.png';
import frame111 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00111.png';
import frame112 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00112.png';
import frame113 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00113.png';
import frame114 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00114.png';
import frame115 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00115.png';
import frame116 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00116.png';
import frame117 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00117.png';
import frame118 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00118.png';
import frame119 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00119.png';
import frame120 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00120.png';
import frame121 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00121.png';
import frame122 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00122.png';
import frame123 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00123.png';
import frame124 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00124.png';
import frame125 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00125.png';
import frame126 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00126.png';
import frame127 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00127.png';
import frame128 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00128.png';
import frame129 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00129.png';
import frame130 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00130.png';
import frame131 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00131.png';
import frame132 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00132.png';
import frame133 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00133.png';
import frame134 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00134.png';
import frame135 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00135.png';
import frame136 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00136.png';
import frame137 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00137.png';
import frame138 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00138.png';
import frame139 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00139.png';
import frame140 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00140.png';
import frame141 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00141.png';
import frame142 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00142.png';
import frame143 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00143.png';
import frame144 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00144.png';
import frame145 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00145.png';
import frame146 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00146.png';
import frame147 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00147.png';
import frame148 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00148.png';
import frame149 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00149.png';
import frame150 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00150.png';
import frame151 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00151.png';
import frame152 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00152.png';
import frame153 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00153.png';
import frame154 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00154.png';
import frame155 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00155.png';
import frame156 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00156.png';
import frame157 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00157.png';
import frame158 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00158.png';
import frame159 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00159.png';
import frame160 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00160.png';
import frame161 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00161.png';
import frame162 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00162.png';
import frame163 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00163.png';
import frame164 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00164.png';
import frame165 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00165.png';
import frame166 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00166.png';
import frame167 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00167.png';
import frame168 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00168.png';
import frame169 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00169.png';
import frame170 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00170.png';
import frame171 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00171.png';
import frame172 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00172.png';
import frame173 from '../../../../assets/images/front/animaciones/stories/secuencia/horizontal/Desliza-lado 2_00173.png';

export default function InstructionsAnimationHorizontal() {

    const frames = [
        frame0,
        frame1,
        frame2,
        frame3,
        frame4,
        frame5,
        frame6,
        frame7,
        frame8,
        frame9,
        frame10,
        frame11,
        frame12,
        frame13,
        frame14,
        frame15,
        frame16,
        frame17,
        frame18,
        frame19,
        frame20,
        frame21,
        frame22,
        frame23,
        frame24,
        frame25,
        frame26,
        frame27,
        frame28,
        frame29,
        frame30,
        frame31,
        frame32,
        frame33,
        frame34,
        frame35,
        frame36,
        frame37,
        frame38,
        frame39,
        frame40,
        frame41,
        frame42,
        frame43,
        frame44,
        frame45,
        frame46,
        frame47,
        frame48,
        frame49,
        frame50,
        frame51,
        frame52,
        frame53,
        frame54,
        frame55,
        frame56,
        frame57,
        frame58,
        frame59,
        frame60,
        frame61,
        frame62,
        frame63,
        frame64,
        frame65,
        frame66,
        frame67,
        frame68,
        frame69,
        frame70,
        frame71,
        frame72,
        frame73,
        frame74,
        frame75,
        frame76,
        frame77,
        frame78,
        frame79,
        frame80,
        frame81,
        frame82,
        frame83,
        frame84,
        frame85,
        frame86,
        frame87,
        frame88,
        frame89,
        frame90,
        frame91,
        frame92,
        frame93,
        frame94,
        frame95,
        frame96,
        frame97,
        frame98,
        frame99,
        frame100,
        frame101,
        frame102,
        frame103,
        frame104,
        frame105,
        frame106,
        frame107,
        frame108,
        frame109,
        frame110,
        frame111,
        frame112,
        frame113,
        frame114,
        frame115,
        frame116,
        frame117,
        frame118,
        frame119,
        frame120,
        frame121,
        frame122,
        frame123,
        frame124,
        frame125,
        frame126,
        frame127,
        frame128,
        frame129,
        frame130,
        frame131,
        frame132,
        frame133,
        frame134,
        frame135,
        frame136,
        frame137,
        frame138,
        frame139,
        frame140,
        frame141,
        frame142,
        frame143,
        frame144,
        frame145,
        frame146,
        frame147,
        frame148,
        frame149,
        frame150,
        frame151,
        frame153,
        frame152,
        frame154,
        frame155,
        frame156,
        frame157,
        frame158,
        frame159,
        frame160,
        frame161,
        frame162,
        frame163,
        frame164,
        frame165,
        frame166,
        frame167,
        frame168,
        frame169,
        frame170,
        frame171,
        frame172,
        frame173
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
            const interval = setInterval(() => {
                setCurrentIndex(prevIndex => (prevIndex + 1) % frames.length);
            }, 25);
    
            return () => {
                clearInterval(interval);
            };
    }, [frames]);

    return (
        <div className="animation-onboarding">
            <img src={frames[currentIndex]} />
        </div>
    )
}
