import React, {useEffect, useState} from "react";
import {apiList} from "../../../../services/Admin/document";

import {Grid, IconButton} from "@mui/material";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import Table from "../../../../components/Table/Table";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import InputText from "../../../../components/Admin/Inputs/InputText";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import InputButton from "../../../../components/Admin/Inputs/InputButton";
import InputSelect from "../../../../components/Admin/Inputs/InputSelect";
import { lang } from "../../../../utils/lang";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';

export default function Management() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [selection, setSelection] = useState(["92"])

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field
    
        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.title) { params.title = state.title }
        if (state.langSelect) { params.lang = state.langSelect }
       
        apiList(params).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc,
                    id: doc.id
                }
            })

            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    const handleChangeSelection = (e) => {
        if (selection.includes(e.target.value)) {
            setSelection(selection.filter(item => item !== e.target.value))
        } else {
            setSelection([...selection, e.target.value])
        }
    }


    return (
        <>
            <HeaderPage title="Documents">
                <InputButton
                    style={{float: "right", margin: "0px 0px 10px 0px"}}
                    href={RouteAdmin.DOCUMENT_CREATE.path}
                    text=" + Crear document"
                />
            </HeaderPage>

            <MsgError msg={msgError}/>

            <Grid container spacing={2}>

                <Filters
                    state={state}
                    setState={setState}
                />

                <Grid item xs={12}>
                    <Table
                        columns={tableColumns()}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        offset: 0,
        sort: { field: "id", sort: "desc" },
        title: "",
    }
}

function tableColumns() {
   
    return [
        // {
        //     field: "checkbox",
        //     headerName: "",
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (<Checkbox onChange={handleChangeSelection} value={params.row.id} checked={selection.includes(params.row.id)}/>)
        //     },
        // },
        {
            field: "id",
            headerName: "ID",
            sortable: true,
            flex: 1
        },
        {
            field: "title",
            headerName: "TÍTOL",
            sortable: true,
            flex: 1
        },

        {
            field: "langCode",
            headerName: "IDIOMA",
            sortable: true,
            flex: 1
        },

        {
            field: "name",
            headerName: "NOM ARXIU",
            sortable: true,
            flex: 1
        },

        {
            field: "url",
            headerName: "URL",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
               
                return (
                    <>  
                        <div style={{display:"flex", alignItems:"center"}}>
                        {params?.url?.length>0 && (
                            <Tooltip id={`tool-${params.id}`} onClick={(e)=>{

                                let item=`url-` + e.currentTarget.id.slice(5)
                                let copyText = document.getElementById(item);
                                navigator.clipboard.writeText(copyText.innerHTML);
                                //document.getElementById("tool-"+e.currentTarget.id.slice(5)).title="Text copiat"
                                const tooltip = e.currentTarget;   
                                tooltip.setAttribute('title','Text copiat al portapapers');

                             }} // onMouseOut={(e) => {
                            //     //const tooltip = e.currentTarget;   
                            //     //tooltip.setAttribute('title','Copiar al portapapers');
                                    // }}
                            >
                                <IconButton size="small" style={{cursor:"pointer"}} id={`urlx-${params.id}`}>
                                    <ContentCopyIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        )}
                            <span id={`url-${params.id}`}>
                            {params.urlFile}
                            </span>
                        </div>
                    </>   
                )
            },
        },


      
        {
            field: "actions",
            headerName: "ACCIONS",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <> 
                        <Link to={`${RouteAdmin.DOCUMENT_EDIT.path}/${params.id}`} style={{paddingRight:"5px"}}>
                            <IconButton size="small">
                                <EditIcon fontSize="inherit"/>
                            </IconButton>
                        </Link>
                        {params?.url?.length>0 && (
                        <a href={`${params.urlFile}`} target="_blank">
                            <IconButton size="small">
                                <VisibilityIcon fontSize="inherit" />
                            </IconButton>
                        </a>
                        )}
                    </>
                )
            },
        },
    ]
}

function Filters(props) {

    const {state, setState} = props
    const {t} = useTranslation()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const handleChangeLang = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
        localStorage.setItem('langSelect', event.target.value)
    }

    return (
        <>
            <Grid item xs={9}>
                <InputText
                    label="Títol original"
                    name="title"
                    value={state.title}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={3}>
                <InputSelect
                    label="Idioma"
                    options={lang}
                    name="langSelect"
                    value={state.langSelect}
                    selectText="Selecciona un idioma"
                    onChange={handleChangeLang}
                />
            </Grid>
        </>
    )
}
