import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getPageInfo} from "../../../services/User/page";
import {apiGetFooter} from "../../../services/User/page"
import {useTranslation} from "react-i18next";
import {RouteUser} from "../../../routes/RouteUser";
import Header from "./Block/Header";
import FullText from "./Block/FullText";
import Visit from "./Block/Visit";
import Search from "./Block/Search";
import Carousel from "./Block/Carousel";
import ImgText from "./Block/ImgText";
import Video from "./Block/Video";
import Map from "./Block/Map";
import ContactFormBlock from "./Block/ContactFormBlock";
import ContactFormModal from "./Block/ContactFormModal";
import Faqs from "./Block/Faqs";
import Footer from "./Block/Footer";
import ScheduleAndRate from "./Block/ScheduleAndRate";
import Transport from "./Block/Transport";
import Text2col from "./Block/Text2col";
import Faqs2col from "./Block/Faqs2col";
import {Helmet} from "react-helmet";


export default function WebFront() {

    const {slugOne, slugTwo} = useParams()
    const navigate = useNavigate()

    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [loadingGetFooter, setLoadingGetFooter] = useState(true)

    const [state, setState] = useState(initialValues())

    const {i18n} = useTranslation()

    const [footer, setFooter] = React.useState({})
    const [seo, setSeo] = React.useState({
        'title': null,
        'desc': null,
    })

    useEffect(() => {
        setLoadingGetInfo(true)
        setLoadingGetFooter(true)
        getPage()
        getFooter()

        handleResize()
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    const getPage = () => {
        setLoadingGetInfo(true)
        getPageInfo({
            slugOne: slugOne,
            slugTwo: slugTwo,
        }, i18n.resolvedLanguage).then(resp => {
            if (resp.data?.blocks?.length > 0) { 
                state.baseUrl=resp.data?.baseUrl
                setState({...state, blocks: resp.data?.blocks})
                setLoadingGetInfo(false)
                setSeo({
                    'title': resp.data?.page[0]?.titleSeo,
                    'desc': resp.data?.page[0]?.descSeo,
                })
            } else {
                // window.location.href=window.location.origin+"/"+i18n.resolvedLanguage // HOME
                navigate(RouteUser.NOTFOUNDPAGE.path) // 404
            }
        }).catch(err => {
            setLoadingGetInfo(false)
        })

    }

    const getFooter = () => {
        setLoadingGetFooter(true)
        apiGetFooter(i18n.resolvedLanguage, i18n.resolvedLanguage).then(resp => {
            setFooter(resp.data.blockText[0])
            setLoadingGetFooter(false)
        }).catch(err => {
            setLoadingGetFooter(false)
        })
    }

    const handleResize = () => {
        var elementoNavbar = document.querySelector('.navbar-web');
        var elementoPage = document.querySelector('.web-page');
        elementoPage.style.paddingTop = `${elementoNavbar.offsetHeight}px`;
    }

    return (
        <>
            <Helmet>
                <title>{seo?.title}</title>
                <meta name="description" content={seo?.desc} />
        
        {/* SCRIPT WEBSITE: solo en la home */}
                {(!slugOne && !slugTwo) && (
                    <script type="application/ld+json">
                        {`
                            "@context" : "https://schema.org",
                            "@type" : "WebSite",
                            "name" : "Museo de l'Art Prohibit",
                            "alternateName" : "Museu de la censura, Museu Art Prohibit",
                            "url" : "https://www.museuartprohibit.org/"
                        `}
                    </script>
                )}
        {/* END SCRIPT WEBSITE */}
        {/* SCRIPT Organitation: en toda la web, en 3 idiomas */}
                {i18n.resolvedLanguage == 'ca' && (

                    <script type="application/ld+json">
                        {`
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "name": "Museu de l'Art Prohibit",
                            "Email": "info@museuartprohibit.org",
                            "legalName": "Museu de l'Art Prohibit",
                            "logo": "https://www.museuartprohibit.org/static/media/Logo_MuseuArtProhibit_Red2.9240d9a7722074d31242.png",
                            "image": ["https://media-museu.s3.eu-west-3.amazonaws.com/page/FOTOCABECERAHOME4.jpg", "https://media-museu.s3.eu-west-3.amazonaws.com/page/FOTO%20P.%20VISITA%20HOME.jpg2", "https://media-museu.s3.eu-west-3.amazonaws.com/page/polisrecortadaokk.jpg3"],
                            "description": "Coneix el Museu de l´Art Prohibit de Barcelona, el primer museu d´art censurat amb més de 200 obres prohibides o denunciades. Pàgina web oficial.",
                            "alternateName": "Museu de la censura, Museu Art Prohibit",
                            "sameAs": ["https://www.instagram.com/museudelartprohibit/", "https://www.facebook.com/museudelartprohibit"],
                            "url": "https://www.museuartprohibit.org/ca",
                            "address": {
                                "@type": "PostalAddress",
                            "addressLocality": "Barcelona",
                            "postalCode": "08007",
                            "streetAddress": "C/ de la Diputació, 250",
                            "telephone": "+34 931 206 374",
                            "addressCountry": "ES"
                        `}
                    </script>

                )}

            {i18n.resolvedLanguage == 'es' && (
                <script type="application/ld+json">
                    {`
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "name": "Museu de l'Art Prohibit",
                            "Email": "info@museuartprohibit.org",
                            "legalName": "Museu de l'Art Prohibit",
                            "logo": "https://www.museuartprohibit.org/static/media/Logo_MuseuArtProhibit_Red2.9240d9a7722074d31242.png",
                            "image": ["https://media-museu.s3.eu-west-3.amazonaws.com/page/FOTOCABECERAHOME4.jpg", "https://media-museu.s3.eu-west-3.amazonaws.com/page/FOTO%20P.%20VISITA%20HOME.jpg2", "https://media-museu.s3.eu-west-3.amazonaws.com/page/polisrecortadaokk.jpg3"],
                            "description": "Conoce el Museu de l'Art Prohibit de Barcelona, el primer museo de arte censurado con más de 200 obras prohibidas o denunciadas. Página web oficial.",
                            "alternateName": "Museu de la censura, Museu Art Prohibit",
                            "sameAs": ["https://www.instagram.com/museudelartprohibit/", "https://www.facebook.com/museudelartprohibit"],
                            "url": "https://museuartprohibit.org/es",
                            "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Barcelona",
                            "postalCode": "08007",
                            "streetAddress": "C/ de la Diputació, 250",
                            "telephone": "+34 931 206 374",
                            "addressCountry": "ES"
                        `}

                </script>
            )}

            {i18n.resolvedLanguage == 'en' && (
                <script type="application/ld+json">
                    {`
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Museu de l'Art Prohibit",
                        "Email": "info@museuartprohibit.org",
                        "legalName": "Museu de l'Art Prohibit",
                        "logo": "https://www.museuartprohibit.org/static/media/Logo_MuseuArtProhibit_Red2.9240d9a7722074d31242.png",
                        "image": ["https://media-museu.s3.eu-west-3.amazonaws.com/page/FOTOCABECERAHOME4.jpg", "https://media-museu.s3.eu-west-3.amazonaws.com/page/FOTO%20P.%20VISITA%20HOME.jpg2", "https://media-museu.s3.eu-west-3.amazonaws.com/page/polisrecortadaokk.jpg3"],
                        "description": "Get to know the Museu de l'Art Prohibit in Barcelona, the first censored art museum with more than 200 prohibited or reported works. Official website.",
                        "alternateName": "Museu de la censura, Museu Art Prohibit",
                        "sameAs": ["https://www.instagram.com/museudelartprohibit/", "https://www.facebook.com/museudelartprohibit"],
                        "url": "https://www.museuartprohibit.org/en",
                        "address": {
                        "@type": "PostalAddress",
                        "addressLocality": "Barcelona",
                        "postalCode": "08007",
                        "streetAddress": "C/ de la Diputació, 250",
                        "telephone": "+34 931 206 374",
                        "addressCountry": "ES"
                    `}
                </script>
            
            )}
        {/* END SCRIPT Organitation */}
        
        {/* SCRIPT Organitation: en toda la web, en 3 idiomas */}
            {i18n.resolvedLanguage == 'ca' && (
                <script type="application/ld+json" class="schemantra">
                    {`
                        "@context": "https://schema.org",
                        "@type": "Museum",
                        "@id": "https://museuartprohibit.org/ca",
                        "name": "Museu de l'Art Prohibit",          
                        "alternateName": "Museu de la censura, Museu Art Prohibit",
                        "description": "Coneix el Museu de l´Art Prohibit de Barcelona, el primer museu d´art censurat amb més de 200 obres prohibides o denunciades. Pàgina web oficial.",
                        "image": ["https://media-museu.s3.eu-west-3.amazonaws.com/page/FOTOCABECERAHOME4.jpg", "https://media-museu.s3.eu-west-3.amazonaws.com/page/FOTO%20P.%20VISITA%20HOME.jpg2", "https://media-museu.s3.eu-west-3.amazonaws.com/page/polisrecortadaokk.jpg3"],
                        "sameAs": ["https://www.instagram.com/museudelartprohibit/", "https://www.facebook.com/museudelartprohibit"],
                        "telephone": "+34 931 206 374",
                        "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "C/ de la Diputació, 250",
                                "postalCode": "08007",
                                "addressLocality": "Barcelona",
                                "addressRegion": "Barcelona",
                                "addressCountry": "Spain"
                                },
                        "email":"info@museuartprohibit.org"
                    `}
                </script>
            )}

            {i18n.resolvedLanguage == 'es' && (
                 <script type="application/ld+json" class="schemantra">
                    {`
                        "@context": "https://schema.org",
                        "@type": "Museum",
                        "@id": "https://museuartprohibit.org/es",
                        "name": "Museu de l'Art Prohibit",          
                        "alternateName": "Museu de la censura, Museu Art Prohibit",
                        "description": "Conoce el Museu de l'Art Prohibit de Barcelona, el primer museo de arte censurado con más de 200 obras prohibidas o denunciadas. Página web oficial.",
                        "image": ["https://media-museu.s3.eu-west-3.amazonaws.com/page/FOTOCABECERAHOME4.jpg", "https://media-museu.s3.eu-west-3.amazonaws.com/page/FOTO%20P.%20VISITA%20HOME.jpg2", "https://media-museu.s3.eu-west-3.amazonaws.com/page/polisrecortadaokk.jpg3"],
                        "sameAs": ["https://www.instagram.com/museudelartprohibit/", "https://www.facebook.com/museudelartprohibit"],
                        "telephone": "+34 931 206 374",
                        "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "C/ de la Diputació, 250",
                                "postalCode": "08007",
                                "addressLocality": "Barcelona",
                                "addressRegion": "Barcelona",
                                "addressCountry": "Spain"
                                },
                        "email":"info@museuartprohibit.org"
                    `}
                </script>
            )}

            {i18n.resolvedLanguage == 'en' && (
                <script type="application/ld+json" class="schemantra">
                    {`
                        "@context": "https://schema.org",
                        "@type": "Museum",
                        "@id": "https://museuartprohibit.org/en",
                        "name": "Museu de l'Art Prohibit",          
                        "alternateName": "Museu de la censura, Museu Art Prohibit",
                        "description": "Get to know the Museu de l'Art Prohibit in Barcelona, the first censored art museum with more than 200 prohibited or reported works. Official website.",
                        "image": ["https://media-museu.s3.eu-west-3.amazonaws.com/page/FOTOCABECERAHOME4.jpg", "https://media-museu.s3.eu-west-3.amazonaws.com/page/FOTO%20P.%20VISITA%20HOME.jpg2", "https://media-museu.s3.eu-west-3.amazonaws.com/page/polisrecortadaokk.jpg3"],
                        "sameAs": ["https://www.instagram.com/museudelartprohibit/", "https://www.facebook.com/museudelartprohibit"],
                        "telephone": "+34 931 206 374",
                        address": {
                                "@type": "PostalAddress",
                                "streetAddress": "C/ de la Diputació, 250",
                                "postalCode": "08007",
                                "addressLocality": "Barcelona",
                                "addressRegion": "Barcelona",
                                "addressCountry": "Spain"
                                },
                        "email":"info@museuartprohibit.org"
                    `}
                  </script>
                
            )}
        {/* END SCRIPT Museum */}

        {/* NO HOME */}
            {/* {(slugOne || (slugOne && slugTwo)) && (
            )} */}

        </Helmet >
            <div className="web-page">
                {state?.blocks.map((doc, key) => (
                    // <div key={key}>
                    <>
                        {doc?.webBlockCode === "TYPE_FULLTEXT" && (
                            <FullText
                                item={doc}
                            />
                        )}

                        {doc?.webBlockCode === "TYPE_HEADER" && (
                            <Header
                                item={doc}
                            />
                        )}

                        {doc?.webBlockCode === "TYPE_VISIT" && (
                            <Visit
                                item={doc}
                            />
                        )}

                        {doc?.webBlockCode === "TYPE_SEARCH" && (
                            <Search/>
                        )}

                        {doc?.webBlockCode === "TYPE_CAROUSEL" && (
                            <Carousel
                                item={doc}
                                baseUrl={state.baseUrl}
                            />
                        )}

                        {doc?.webBlockCode === "TYPE_IMGTEXT" && (
                            <ImgText
                                item={doc}
                            />
                        )}

                        {doc?.webBlockCode === "TYPE_VIDEO" && (
                            <Video
                                item={doc}
                            />
                        )}

                        {doc?.webBlockCode === "TYPE_MAP" && (
                            <Map
                                item={doc}
                            />
                        )}

                        {doc?.webBlockCode === "TYPE_CONTACFORM" && (
                            <ContactFormBlock/>
                        )}

                        {doc?.webBlockCode === "TYPE_FAQS" && (
                            <Faqs
                                item={doc}
                            />
                        )}

                        {doc?.webBlockCode === "TYPE_SCHEDULEANDRATE" && (
                            <ScheduleAndRate
                                item={doc}
                            />
                        )}

                        {doc?.webBlockCode === "TYPE_TRANSPORT" && (
                            <Transport
                                item={doc}
                            />
                        )}

                        {doc?.webBlockCode === "TYPE_TEXT2COL" && (
                            <Text2col
                                item={doc}
                            />
                        )}

                        {doc?.webBlockCode === "TYPE_FAQS2COL" && (
                            <Faqs2col
                                item={doc}
                            />
                        )}
                    </>
                    // </div>
                ))}
            </div>
            {(!loadingGetInfo && !loadingGetFooter) && (
                <>
                    <div className="footer">
                        <Footer
                            item={footer}
                        />
                    </div>
                </>
            )}
        </>
    )

}


function initialValues(props) {
    return {
        page: props?.page || {},
        blocks: props?.blocks || [],
        baseUrl: props?.baseUrl || "",
    }
}
