import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {RouteUser} from "../../../../routes/RouteUser";
import {useNavigate} from "react-router-dom";
import {getGameScreen} from "../../../../services/User/game";
import LoadingSpinnerBig from "../../../../components/LoadingSpinnerBig";
import MsgError from "../../../../components/MsgError";
import MobileMessage from "../../../../components/User/MobileMessage";
import FlamesAnimation from "./flamesAnimation";
import CloseIcon from "../../../../components/Icons/CloseIcon";
import CascoLlama from '../../../../components/Icons/Casco_Llama';
import Extintor2 from '../../../../components/Icons/Extintor';
import Flames2 from '../../../../components/Icons/Flames';


// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../../scss/User/swiper-stories.scss";
// import required modules
import SwiperCore, {Navigation, Pagination, FreeMode, Scrollbar, Mousewheel} from "swiper";
import TopBar from "../../../../components/Layouts/User/Header/TopBar";
import ReactGA from "react-ga4";

SwiperCore.use([Mousewheel])

export default function GameIntro() {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        ReactGA.event({
            category: "Game",
            action: `Game Inicio`,
        });

        ReactGA.event({
            category: "Game",
            action: `Game`,
        });
    }, [])

    const handleGoToGame = () => {
        navigate(RouteUser.GAME.path)
    }

    useEffect(() => {
        getScreen()
    }, [])

    const getScreen = () => {
        setLoading(true);
        getGameScreen({ code: ['INI_SCREEN_1', 'INI_SCREEN_2', 'INI_SCREEN_3', 'INI_SCREEN_4'] }, i18n.resolvedLanguage, i18n.resolvedLanguage)
            .then(resp => {
                const updatedState = resp.data.map(item => {
                if (item.id === 2) {
                    return {
                    ...item,
                    image: Extintor2,
                    newClass: 'extintor-icon'
                    };
                } else if (item.id === 3) {
                    return {
                    ...item,
                    image: Flames2,
                    newClass: ''
                    };
                } else {
                    return {
                    ...item,
                    image: CascoLlama,
                    newClass: ''
                    };
                }
                });
                setState(updatedState);
                setLoading(false);
            }).catch(err => {
                setMsgError(err.response?.data?.msg);
                setLoading(false);
        });
    }

    // Corregir el bug del 100vh en móvil
    useEffect(() => {
        var elementoMapa = document.querySelector('.game-intro-element');
        elementoMapa.style.height = window.innerHeight + 'px';
    }, []);
    
    return (
        <>
            <MobileMessage />
            <div className='game-intro' style={{background: 'black',
                width: '100vw',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                position: 'relative',
                zIndex: '3',
                overflow: 'hidden'}}>
                <div className='game-intro-element' style={{
                    background: '#FFFFFF',
                    maxWidth: '980px',
                    width: '100vw',
                    margin: 'auto',
                    position: 'relative',
                    overflow: 'hidden',
                    height: '100vh',
                    minHeight: '-webkit-fill-available',
                    minHeight: '-moz-available',
                    minHeight: 'fill-available'
                }}>
                    <div className="game-navbar">
                        <div>
                            <h1>{t('game.title')}</h1>
                        </div>
                        <div>
                            <TopBar />
                        </div>
                    </div>
                    <div className='game-intro-container'>
                        <LoadingSpinnerBig show={loading}/>
                        <MsgError msg={msgError}/>
                        {!loading && (
                            <>
                                <div className="slider-container">
                                    <Swiper pagination={true} modules={[Pagination]} spaceBetween={'9.375%'} className="mySwiper">
                                        {state.map((item, key) => (
                                            item.id > 1 ? (
                                                <SwiperSlide key={key}>
                                                    <item.image className={`icon ${item.newClass}`} />
                                                    <span className="h4">{item.title}</span>
                                                    <div dangerouslySetInnerHTML={{ __html: item.text }} />
                                                </SwiperSlide>
                                            ) : (
                                                <SwiperSlide key={key}>
                                                    <h2>{item.title}</h2>
                                                    <span className="h3">{item.subtitle}</span>
                                                    <div dangerouslySetInnerHTML={{ __html: item.text }} />
                                                </SwiperSlide>
                                            )
                                        ))}
                                    </Swiper>
                                </div>
                                <button className='button-block' onClick={() => handleGoToGame()}>{t('game.start_the_game')}</button>
                            </>
                        )}
                    </div>
                    {!loading && (
                        <FlamesAnimation currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
                    )}
                </div>
            </div>
        </>
    )
}

function initialValues(props) {
    return {
        id: null,
        code: "",
        title: "",
        subtitle: "",
        text: ""
    }
}
