import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../components/Admin/TitlePage";
import { MdDeleteForever } from "react-icons/md";
import ButtonFile from "../../../../../components/Admin/Inputs/ButtonFile";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import ModalConfirm from "../../../../../components/Admin/ModalConfirm";
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";
import { apiSaveBlockScreenText, apiSaveFileBlockScreenText, apiDeleteFileBlockScreenText } from "../../../../../services/Admin/screen";


export default function Video(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    const [loadingSave, setLoadingSave] = useState(false)
    const [openModalFile, setOpenModalFile] = React.useState(false)

    const handleChangePhotoFile = (event) => {
        state[event.target.name] = event.target.value
        setState({ ...state })
    }
    
    const handleChangeVideoFile = (event) => {
        state[event.target.name] = event.target.value
        setState({ ...state })
    }

    const handleOpenModalFile = () => {
        setOpenModalFile(true)
    }

    const handleDeleteFile = () => {
        apiDeleteFileBlockScreenText({
            blockScreenId: state.blockScreenText.blockScreenId,
            langCode: state.langSelect,
        }).then(resp => {
            window.location.reload();
            setMsgSuccess(resp.data?.msg)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
        })
    }

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)
        apiSaveBlockScreenText(state).then(resp => {
            let fileVideoBinary = state?.fileVideo?.length > 0 ? state?.fileVideo[0].binary : null
            handleSubmitFile(state.blockScreenText.blockScreenId, fileVideoBinary)
            setLoadingSave(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
        })

    }


    const handleSubmitFile = (idBlockScreen, fileVideo) => {
        let f = new FormData()
        f.append(`fileVideo[]`, fileVideo)
        f.append(`langCode`, state.langSelect)

        apiSaveFileBlockScreenText(f, idBlockScreen).then(resp => {
            setLoadingSave(false)
            setMsgSuccess(resp.data?.msg)
            window.location.reload();
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingSave(false)
        })
    }


    const handleChangeBlockText = (e) => {
        state.blockScreenText[e.target.name] = e.target.value
        setState({ ...state })
    }



    return (
        <>
            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title={state.blockScreen.displayBlockName} borderTop={false} borderBottom={false} />
            </Grid>

            <Grid item md={6}>
                    {((state?.fileVideo?.length > 0 || state?.blockScreenText.urlFileVideo?.length > 0) &&
                        <div style={{ textAlign: 'center', width: 400, marginLeft: 'auto', marginRight: 'auto' }}>
                            <video controls style={{ width: '100%', objectFit: 'cover' }}>
                                <source src={
                                    state?.fileVideo?.length > 0 ? state?.fileVideo[0]?.base64 : state.blockScreenText.urlFileVideo
                                } ></source>
                            </video>
                        </div>
                    )}
                </Grid>

                <Grid item md={6} />

                <Grid item md={6}>
                    <ButtonFile
                        label="+ Video"
                        required
                        name="fileVideo"
                        typeButton="secondary"
                        multiple={false}
                        onChange={handleChangeVideoFile}
                        accept="video/mp4,video/mkv,video/x-m4v,video/*"
                    />
                </Grid>


                {(state?.fileVideo?.length > 0 || state?.blockScreenText.urlFileVideo?.length > 0) &&
                    <Grid item md={2}>
                        <InputButtonLoading
                            onClick={() => handleOpenModalFile()}
                            loading={false}
                            label={(
                                <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar video</>
                            )}
                            position="left"
                        />
                    </Grid>
                }


                <Grid item xs={2}>
                    <InputCheckbox
                        label="Video Activat"
                        className="mb-12"
                        checked={state?.blockScreenText.isActiveVideo}
                        onChange={(e) => {
                            state.blockScreenText.isActiveVideo = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingSave}
                    />
                </Grid>

                <Grid item xs={2}>
                    <InputCheckbox
                        label="QR Activat"
                        className="mb-12"
                        checked={state?.blockScreenText.qrVideo}
                        onChange={(e) => {
                            state.blockScreenText.qrVideo = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingSave}
                    />
                </Grid>


            <Grid item md={12}>
                <InputButtonLoading
                    onClick={handleSubmit}
                    loading={loadingSave}
                    label="Modificar"
                    position="left"
                    style={{ float: 'right' }}
                />
            </Grid>

            <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile} text="Eliminar el fitxer?" />

        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        fileVideo: [],
        urlFileVideo: props?.blockScreenText.urlFileVideo || "",
        isActiveVideo: props?.blockScreenText.isActiveVideo === undefined ? true : props.blockScreenText.isActiveVideo,
        qrVideo: props?.blockScreenText.qrVideo === undefined ? false : props.blockScreenText.qrVideo,

    }
}

