import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}/image-scanner`;

export function apiList(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list/${props.id}`, config)
}

export function apiAddImage(props, id, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/add-image/${id}`, props, config)
}

export function apiRemoveImage(id, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.delete(`${BASE_PATH}/${lang}/${baseUrl}/remove-image/${id}`, config)
}

export function apiSaveList(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/scanner-save-list`, props, config)
}

export function apiSavePoster(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/scanner-save-poster`, props, config)
}
