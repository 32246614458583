import React, {useEffect, useState} from "react";
import {apiList, apiSavePositionMenu} from "../../../../../services/Admin/menu";
import {Chip, Grid} from "@mui/material";
import MsgError from "../../../../../components/MsgError";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import {RouteWebBack} from "../../../../../routes/Web/RouteWebBack";
import InputSelect from "../../../../../components/Admin/Inputs/InputSelect";
import {useNavigate} from "react-router-dom";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";
import {lang} from "../../../../../utils/lang";
import DndElements from "../../../../../components/DndElements";
import LoadingSpinner from "../../../../../components/LoadingSpinner";

export default function Management() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const navigate = useNavigate()

    const [loadingPosition, setLoadingPosition] = useState(true)

    useEffect(() => {
        setLoading(true)
        setLoadingPosition(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    useEffect(() => {
        if (rows.length > 0) {
            setLoadingPosition(true)

            const timeOutId = setTimeout(() => {
                updateElementPosition()
            }, 2000)

            return () => clearTimeout(timeOutId)
        }
    }, [rows])

    const updateElementPosition = () => {
        const menuIdArray = rows.map((item) => item.id);

        apiSavePositionMenu({
            id: menuIdArray
        }).then(resp => {
            setLoadingPosition(false)
        }).catch(err => {
            setMsgError([err.response.data.msg])
            setLoadingPosition(false)
        })
    }

    const getItemsData = () => {
        setMsgError([])
        setRowCount(0)
        setRows([])
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let params = {}

        if (state.langSelect) {
            params.langSelect = state.langSelect
        }


        apiList(params).then(resp => {
            setRowCount(resp.data?.length)

            let temp = resp.data?.map(doc => {
                return {
                    id: `${doc.id}`,
                    content: <ViewMenu id={doc.id} name={doc.name} submenu_count={doc.submenu_count} handleNavigate={handleNavigate}/>
                }
            })

            setRows(temp)
            setLoading(false)
            setLoadingPosition(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setLoadingPosition(false)
            setRows([])
        })
    }

    const handleNavigate = (id) => {
        navigate(`${RouteWebBack.WEB_BACK_MENU_EDIT.path}/${id}`)
    }

    return (
        <>
            <HeaderPage title="Menu">
                <InputButton
                    style={{float: "right", margin: "0px 0px 10px 0px"}}
                    href={RouteWebBack.WEB_BACK_MENU_CREATE.path}
                    text=" + Crear menu"
                />
            </HeaderPage>

            <MsgError msg={msgError}/>

            <Grid container spacing={2}>

                <Filters
                    state={state}
                    setState={setState}
                />

                <Grid item xs={12}>
                    {(!loadingPosition) ? (
                        <DndElements
                            elementDnd={rows}
                            setElementDnd={setRows}
                            title="Posició"
                        />
                    ) : (
                        <LoadingSpinner show={true}/>
                    )}
                </Grid>

            </Grid>
        </>
    );
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        offset: 0,
        sort: {field: "id", sort: "desc"},
        title: ""
    }
}

function Filters(props) {

    const {state, setState} = props

    return (
        <>
            <Grid item xs={12}>
                <InputSelect
                    label="Idioma"
                    options={lang}
                    name="langSelect"
                    value={state.langSelect}
                    selectText="Selecciona un idioma"
                    onChange={(e) => {
                        setState({...state, langSelect: e.target.value, offset: 0})
                        localStorage.setItem('langSelect', e.target.value)
                    }}
                />
            </Grid>
        </>
    )
}

function ViewMenu(props) {
    const {id, name, submenu_count, handleNavigate} = props

    // Separar submenus en array
    const submenus = submenu_count ? submenu_count.split(':') : []

    return (
        <Grid container spacing={0} style={{
            padding: 0,
            margin: 0
        }}>
            <Grid item md={12}>
                <p>Nom: {name}</p>
                {submenus.length > 0 && (
                    <p>Submenu: {submenus.map((item, key) => {
                        return (
                            <Chip
                                key={key}
                                label={item}
                                style={{margin: 3, background: '#f5f5f5', minWidth: 120}}
                                size="small"
                            />
                        )
                    })}</p>
                )}
            </Grid>
            <Grid item md={12}>
                <InputButton
                    style={{float: "left", margin: "0px 10px 0px 0px"}}
                    onClick={() => handleNavigate(id)}
                    variant="outlined"
                    text="Editar"
                />
            </Grid>
        </Grid>
    )
}
