import React, { useRef, useState, useEffect } from "react";
import {useTranslation} from "react-i18next";
import MobileMessage from "../../../../components/User/MobileMessage";
import HeadphonesIcon from '../../../../components/Icons/HeadphonesIcon';
import ButtonScrollTop from '../../../../components/ButtonScrollTop'
import AudioPlayer from '../../../../components/PlayerAudio';
import Edifici from '../../../../assets/images/front/uploads/Edifici.png';
import InputButtonClose from "../../../../components/Layouts/User/InputButtonClose";

export default function Mapa() {

  const {t} = useTranslation()
  const [showAudioIcon, setShowAudioIcon] = useState(false);
  const [audio, setAudio] = useState(null);

  const [height, setHeight] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
      setHeight(ref.current.clientHeight)
      
      setShowAudioIcon(true);
    })
    
    const [openAudio, setOpenAudio] = React.useState(false);

    const handleOpenAudio = () => {
      setOpenAudio(true);
    };

    const handleCloseAudio = () => {
      setOpenAudio(false);
    };

    const ShowAudio = () => (    
      <AudioPlayer url={audio} handleButtonClickStopComponent={handleButtonClickStopComponent} closePlayer={handleCloseAudio} style={{position: 'absolute', bottom: '0px', left: '0px', right: '0', margin: 'auto', width: '100%'}} />
    )

    const handleButtonClickStopComponent = (event) => {
      event.stopPropagation(); // Evita que el evento se propague al div padre
      // Código para manejar el clic en el botón derecho
    };
    function handleOpenAudioAndStopComponent(event) {
      handleOpenAudio();
      handleButtonClickStopComponent(event);
    }

    const paragraphs = t('building_file.description').split('\n').map((paragraph, index) => (
      <p key={index} style={{fontFamily: 'SohneBuch', fontSize: '16px', lineHeight: '21px', color: '#141414', margin: '0px'}}>
        {paragraph}
        <br />
      </p>
    ));

  return (
    <>
      <MobileMessage />
      <div
        className="stories"
        style={{
          position: 'fixed',
          background: 'black',
          width: '100vw',
          height: '-webkit-fill-available',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          zIndex: '3',
          overflowY: 'auto'
      }}>
        <div style={{
          background: 'white',
          maxWidth: '980px',
          width: '100vw',
          minHeight: '100vh',
          minHeight: '-webkit-fill-available',
          margin: 'auto'
        }}>
          <div
            className="swiper mySwiper hide-scroll"
            style={{ overflowY: 'hidden'}}
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div id={`swiper-slide-edifici`} style={{height: 'auto', overflowY: 'hidden'}} className="hide-scroll">

                  <div className="outstanding-image-block cover-story-block primer-bloque" style={{width: '100%', height: window.innerHeight, position: 'relative'}}>
                    <div style={{width: '100%', height: `calc(100% - ${height}px)`, position: 'relative'}}>
                      <div style={{width: '100%', height: '100%'}}>
                          <img src={Edifici} />
                      </div>
                      {audio && <>
                        { openAudio ? null : (
                          <div style={{position: 'absolute', bottom: '22px', left: '20px'}}>
                            {showAudioIcon &&
                              <button onClick={handleOpenAudioAndStopComponent} className="audio-button" style={{backgroundColor: '#ffffff', borderRadius: '100%', display: 'flex', border: 'none'}}>
                                <HeadphonesIcon className="headphones-icon icon" color="#E30613" />
                              </button>
                            }
                          </div>
                        )}
                        { openAudio ? <ShowAudio /> : null }
                      </>}
                    </div>
                    <div className="title-container" style={{backgroundColor: '#FFFFFF', maxWidth: '100%', position: 'absolute', bottom: '0', width: 'calc(100% - 48px)'}} ref={ref}>
                      <div className="story-cover-title" style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between'
                      }}>
                        <p style={{fontFamily: 'QueensCondensedBoldIt', color: '#141414', margin: '0px'}}><em>{t('building_file.title')}</em></p>
                      </div>
                      <p style={{fontFamily: 'SohneBuch', color: '#141414', margin: '0px'}}>{t('building_file.year')}</p>
                    </div>
                  </div>

                  <div className="segundo-bloque" style={{padding: '0px 25px 40px'}}>

                      <div className="text-block" style={{maxWidth: '100%'}}>
                        <div style={{paddingBottom: '28px', paddingTop: '8px', borderTop: '1px solid #FE7A84'}}>
                          <h2 style={{fontFamily: 'SohneBreitHalbfett', fontSize: '15px', lineHeight: '18px', color: '#E30613', margin: '0px', marginBottom: '4px'}}>{t('building_file.architect_title')}</h2>
                          <p style={{fontFamily: 'SohneBuch', fontSize: '16px', lineHeight: '21px', color: '#141414', margin: '0px'}}>{t('building_file.architect')}</p>
                        </div>
                        <div style={{paddingBottom: '28px', paddingTop: '8px', borderTop: '1px solid #FE7A84'}}>
                          <h2 style={{fontFamily: 'SohneBreitHalbfett', fontSize: '15px', lineHeight: '18px', color: '#E30613', margin: '0px', marginBottom: '4px'}}>{t('building_file.description_title')}</h2>
                          <>{paragraphs}</>
                        </div>
                      </div>

                    <ButtonScrollTop scrollId={`swiper-slide-edifici`} />
                  </div>
                </div>
              </div>
            </div>
            <div style={{
                position: 'absolute',
                top: '50px',
                right: '24px',
                width: 'calc(100% - 48px)',
                left: '24px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
                justifyContent: 'space-between',
                zIndex: '1'
            }} className="name-closeicon-container">
              <div>
                <h2 style={{fontFamily: 'SohneBreitHalbfettKursiv', color: '#FDFDFD', margin: '0px', marginRight: '5px'}}>{t('building_file.white_title')}</h2>
              </div>
              <div>
                  <InputButtonClose />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
