import React, { useEffect, useState } from "react";
import QrCode from "../../../Auth/LoginQr/view";
import image from '../../../../assets/images/display/Frame3.svg';
import {getScreen, apiIsUpdateScreens} from "../../../../services/User/display"
import logo from '../../../../assets/images/Logo_MuseuArtProhibit_White.png';
import tarifas from '../../../../assets/images/display/CA_VW1_Tarifas.png';
import { VscUnmute } from "react-icons/vsc"

export default function One() {

    // Pantalla pequeña bloques
    const [showTextOne, setShowTextOne] = useState(false)
    const [showVideoOne, setShowVideoOne] = useState(true)
    const [textOneLoaded, setTextOneLoaded] = useState(false)
    const [showDoubleColumn1, setShowDoubleColumn1] = useState(false)
    const [showDoubleColumn2, setShowDoubleColumn2] = useState(false)
    const [showOneColumn, setShowOneColumn] = useState(false)
    const [showDoubleColumn3, setShowDoubleColumn3] = useState(false)
    const [showRates, setShowRates] = useState(false)
    const [getScreensLoaded, setGetScreensLoaded] = useState(false)
    const [withoutContentOne, setWithoutContentOne] = useState(false)
    const [endedOne, setEndedOne] = useState(false)

    // Pantalla grande bloques
    const [showTextTwo, setShowTextTwo] = useState(false)
    const [showVideoTwo, setShowVideoTwo] = useState(true)
    const [textOneLoadedTwo, setTextOneLoadedTwo] = useState(false)
    const [showSecondOneColumn, setShowSecondOneColumn] = useState(false)
    const [fullWidthImage, setFullWidthImage] = useState(false)
    const [fullWidthText, setFullWidthText] = useState(false)
    const [showSecondDoubleColumn1, setShowSecondDoubleColumn1] = useState(false)
    const [showSecondDoubleColumn2, setShowSecondDoubleColumn2] = useState(false)
    const [showSecondDoubleColumn3, setShowSecondDoubleColumn3] = useState(false)
    const [showQr2, setShowQr2] = useState(false)
    const [getScreensLoaded2, setGetScreensLoaded2] = useState(false)
    const [withoutContentTwo, setWithoutContentTwo] = useState(false)
    const [endedTwo, setEndedTwo] = useState(false)

    // Idiomas para la rotación
    const languages = ["ca", "es", "en"];

    // Pantalla pequeña idioma
    const [currentLanguage, setCurrentLanguage] = useState(languages[0]);
    const [screens, setScreens] = useState({});
    const [state, setState] = useState(initialValues())

    // Pantalla grande idioma
    const [currentLanguage2, setCurrentLanguage2] = useState(languages[0]);
    const [screens2, setScreens2] = useState({});
    const [state2, setState2] = useState(initialValues())

    const [reloadScreen, setReloadScreen] = useState(0)

    useEffect(() => {
        getDisplayScreenOne()
    }, [])

    const getDisplayScreenOne = () => {
        getScreen({screenId:1}, 'ca').then(resp => {
            state.baseUrl = resp.data['baseUrl']
            setScreens(resp.data);
            setState(initialValues(resp.data[currentLanguage][0]));
            setGetScreensLoaded(true)
        }).catch(err => {
            setState(initialValues())
        })

        getScreen({screenId:2}, 'ca').then(resp => {
            state2.baseUrl = resp.data['baseUrl']
            setScreens2(resp.data);
            setState2(initialValues(resp.data[currentLanguage2][0]));
            setGetScreensLoaded2(true)
        }).catch(err => {
            setState2(initialValues())
        })
    }

    useEffect(() => {
        if (screens[currentLanguage] && screens[currentLanguage].length > 0) {
            setState(initialValues(screens[currentLanguage][0]));
        } else {
            console.error(`No se encontró contenido para el idioma ${currentLanguage}`);
        }
    }, [currentLanguage]);

    useEffect(() => {
        if (screens2[currentLanguage2] && screens2[currentLanguage2].length > 0) {
            setState2(initialValues(screens2[currentLanguage2][0]));
        } else {
            console.error(`No se encontró contenido para el idioma ${currentLanguage2}`);
        }
    }, [currentLanguage2]);
    
    // Pantalla pequeña
    const fin = () => {
        setShowTextOne(true)
        setShowVideoOne(false)
    }

    const fin2 = () => {
        setShowTextTwo(true)
        setShowVideoTwo(false)
    }

    // Pantalla pequeña
    useEffect(() => {
        if(showTextOne && !textOneLoaded){
            // Añade un pequeño delay antes de mostrar el bloque para evitar los casos en que la transición no se aprecia
            const timeout = setTimeout(() => {
                if(state.isActiveMediaOne){
                    document.querySelector('.led-1-left-block-1')?.classList.add("visible")
                    document.querySelector('.led-1-right-block-1')?.classList.add("visible")
                    setShowDoubleColumn1(true)
                    setTextOneLoaded(true)
                }else if(state.isActiveMediaTwo){
                    document.querySelector('.led-1-left-block-2')?.classList.add("visible")
                    document.querySelector('.led-1-right-block-2')?.classList.add("visible")
                    setShowDoubleColumn2(true)
                    setTextOneLoaded(true)
                }else if(state.isActiveTextoCentrado){
                    document.querySelector('.led-1-full-width-block')?.classList.add("visible")
                    setShowOneColumn(true)
                    setTextOneLoaded(true)
                }else if(state.isActiveMediaDouble){
                    document.querySelector('.led-1-left-block-3')?.classList.add("visible")
                    document.querySelector('.led-1-right-block-3')?.classList.add("visible")
                    setShowDoubleColumn3(true)
                    setTextOneLoaded(true)
                }else if(state.isActivePrecios){
                    document.querySelector('.rates-container')?.classList.add("visible")
                    setShowRates(true)
                    setTextOneLoaded(true)
                }else{
                    setWithoutContentOne(true)
                    setEndedOne(true)
                    setTextOneLoaded(false)
                }
            }, 500);
            return () => clearTimeout(timeout);
        }
    }, [showTextOne, textOneLoaded, state])

    useEffect(() => {
        if(showDoubleColumn1){
            const timer = setTimeout(() => {
                document.querySelector('.led-1-left-block-1')?.classList.remove("visible")
                document.querySelector('.led-1-right-block-1')?.classList.remove("visible")
                document.querySelector('.led-1-left-block-1')?.classList.add("out")
                document.querySelector('.led-1-right-block-1')?.classList.add("out")
                const timerIn = setTimeout(() => {
                    if(state.isActiveMediaTwo){
                        document.querySelector('.led-1-left-block-2')?.classList.add("visible")
                        document.querySelector('.led-1-right-block-2')?.classList.add("visible")
                        setShowDoubleColumn1(false)
                        setShowDoubleColumn2(true)
                    }else if(state.isActiveTextoCentrado){
                        document.querySelector('.led-1-full-width-block')?.classList.add("visible")
                        setShowDoubleColumn1(false)
                        setShowOneColumn(true)
                    }else if(state.isActiveMediaDouble){
                        document.querySelector('.led-1-left-block-3')?.classList.add("visible")
                        document.querySelector('.led-1-right-block-3')?.classList.add("visible")
                        setShowDoubleColumn1(false)
                        setShowDoubleColumn3(true)
                    }else if(state.isActivePrecios){
                        document.querySelector('.rates-container')?.classList.add("visible")
                        setShowDoubleColumn1(false)
                        setShowRates(true)
                    }else{
                        document.querySelector('.led-1-left-block-1')?.classList.remove("out")
                        document.querySelector('.led-1-right-block-1')?.classList.remove("out")
                        document.querySelector('.led-1-left-block-2')?.classList.remove("out")
                        document.querySelector('.led-1-right-block-2')?.classList.remove("out")
                        document.querySelector('.led-1-full-width-block')?.classList.remove("out")
                        document.querySelector('.led-1-left-block-3')?.classList.remove("out")
                        document.querySelector('.led-1-right-block-3')?.classList.remove("out")
                        document.querySelector('.rates-container')?.classList.remove("out")
                        setShowDoubleColumn1(false)
                        setShowTextOne(false)
                        setTextOneLoaded(false)
                        setEndedOne(true)
                    }
                }, 1700)
                return () => clearTimeout(timerIn)
            }, 5000)
            return () => clearTimeout(timer)
        }else if(showDoubleColumn2){
            const timer = setTimeout(() => {
                document.querySelector('.led-1-left-block-2')?.classList.remove("visible")
                document.querySelector('.led-1-right-block-2')?.classList.remove("visible")
                document.querySelector('.led-1-left-block-2')?.classList.add("out")
                document.querySelector('.led-1-right-block-2')?.classList.add("out")
                const timerIn = setTimeout(() => {
                    if(state.isActiveTextoCentrado){
                        document.querySelector('.led-1-full-width-block')?.classList.add("visible")
                        setShowDoubleColumn2(false)
                        setShowOneColumn(true)
                    }else if(state.isActiveMediaDouble){
                        document.querySelector('.led-1-left-block-3')?.classList.add("visible")
                        document.querySelector('.led-1-right-block-3')?.classList.add("visible")
                        setShowDoubleColumn2(false)
                        setShowDoubleColumn3(true)
                    }else if(state.isActivePrecios){
                        document.querySelector('.rates-container')?.classList.add("visible")
                        setShowDoubleColumn2(false)
                        setShowRates(true)
                    }else{
                        document.querySelector('.led-1-left-block-1')?.classList.remove("out")
                        document.querySelector('.led-1-right-block-1')?.classList.remove("out")
                        document.querySelector('.led-1-left-block-2')?.classList.remove("out")
                        document.querySelector('.led-1-right-block-2')?.classList.remove("out")
                        document.querySelector('.led-1-full-width-block')?.classList.remove("out")
                        document.querySelector('.led-1-left-block-3')?.classList.remove("out")
                        document.querySelector('.led-1-right-block-3')?.classList.remove("out")
                        document.querySelector('.rates-container')?.classList.remove("out")
                        setShowDoubleColumn2(false)
                        setShowTextOne(false)
                        setTextOneLoaded(false)
                        setEndedOne(true)
                    }
                }, 1700)
                return () => clearTimeout(timerIn)
            }, 5000)
            return () => clearTimeout(timer)
        }else if(showOneColumn){
            const timer = setTimeout(() => {
                document.querySelector('.led-1-full-width-block')?.classList.remove("visible")
                document.querySelector('.led-1-full-width-block')?.classList.add("out")
                const timerIn = setTimeout(() => {
                    if(state.isActiveMediaDouble){
                        document.querySelector('.led-1-left-block-3')?.classList.add("visible")
                        document.querySelector('.led-1-right-block-3')?.classList.add("visible")
                        setShowOneColumn(false)
                        setShowDoubleColumn3(true)
                    }else if(state.isActivePrecios){
                        document.querySelector('.rates-container')?.classList.add("visible")
                        setShowOneColumn(false)
                        setShowRates(true)
                    }else{
                        document.querySelector('.led-1-left-block-1')?.classList.remove("out")
                        document.querySelector('.led-1-right-block-1')?.classList.remove("out")
                        document.querySelector('.led-1-left-block-2')?.classList.remove("out")
                        document.querySelector('.led-1-right-block-2')?.classList.remove("out")
                        document.querySelector('.led-1-full-width-block')?.classList.remove("out")
                        document.querySelector('.led-1-left-block-3')?.classList.remove("out")
                        document.querySelector('.led-1-right-block-3')?.classList.remove("out")
                        document.querySelector('.rates-container')?.classList.remove("out")
                        setShowOneColumn(false)
                        setShowTextOne(false)
                        setTextOneLoaded(false)
                        setEndedOne(true)
                    }
                }, 1700)
                return () => clearTimeout(timerIn)
            }, 5000)
            return () => clearTimeout(timer)
        }else if(showDoubleColumn3){
            const timer = setTimeout(() => {
                document.querySelector('.led-1-left-block-3')?.classList.remove("visible")
                document.querySelector('.led-1-right-block-3')?.classList.remove("visible")
                document.querySelector('.led-1-left-block-3')?.classList.add("out")
                document.querySelector('.led-1-right-block-3')?.classList.add("out")
                const timerIn = setTimeout(() => {
                    if(state.isActivePrecios){
                        document.querySelector('.rates-container')?.classList.add("visible")
                        setShowDoubleColumn3(false)
                        setShowRates(true)
                    }else{
                        document.querySelector('.led-1-left-block-1')?.classList.remove("out")
                        document.querySelector('.led-1-right-block-1')?.classList.remove("out")
                        document.querySelector('.led-1-left-block-2')?.classList.remove("out")
                        document.querySelector('.led-1-right-block-2')?.classList.remove("out")
                        document.querySelector('.led-1-full-width-block')?.classList.remove("out")
                        document.querySelector('.led-1-left-block-3')?.classList.remove("out")
                        document.querySelector('.led-1-right-block-3')?.classList.remove("out")
                        document.querySelector('.rates-container')?.classList.remove("out")
                        setShowDoubleColumn3(false)
                        setShowTextOne(false)
                        setTextOneLoaded(false)
                        setEndedOne(true)
                    }
                }, 1000)
                return () => clearTimeout(timerIn)
            }, 5000)
            return () => clearTimeout(timer)
        }else if(showRates){
            const timer = setTimeout(() => {
                document.querySelector('.rates-container')?.classList.remove("visible")
                document.querySelector('.rates-container')?.classList.add("out")
                const timerIn = setTimeout(() => {
                    document.querySelector('.led-1-left-block-1')?.classList.remove("out")
                    document.querySelector('.led-1-right-block-1')?.classList.remove("out")
                    document.querySelector('.led-1-left-block-2')?.classList.remove("out")
                    document.querySelector('.led-1-right-block-2')?.classList.remove("out")
                    document.querySelector('.led-1-full-width-block')?.classList.remove("out")
                    document.querySelector('.led-1-left-block-3')?.classList.remove("out")
                    document.querySelector('.led-1-right-block-3')?.classList.remove("out")
                    document.querySelector('.rates-container')?.classList.remove("out")
                    setShowRates(false)
                    setShowTextOne(false)
                    setTextOneLoaded(false)
                    setEndedOne(true)
                }, 1000)
                return () => clearTimeout(timerIn)
            }, 5000)
            return () => clearTimeout(timer)
        }else if(withoutContentOne){
            setTextOneLoaded(false)
            setWithoutContentOne(false)
            setEndedOne(true)
        }
    }, [showOneColumn, showDoubleColumn1, showDoubleColumn2, showDoubleColumn3, showRates, withoutContentOne])

    useEffect(() => {
        if(endedOne){
            if(currentLanguage === languages[0]){
                setCurrentLanguage(languages[1])
            }else if(currentLanguage === languages[1]){
                setCurrentLanguage(languages[2])
            }else if(currentLanguage === languages[2]){
                setCurrentLanguage(languages[0])
            }
        }
    }, [endedOne])

    useEffect(() => {
        setShowVideoOne(true)
        setShowTextOne(false)
        setEndedOne(false)
    }, [currentLanguage])

    // Pantalla grande
    useEffect(() => {
        if(showTextTwo){
            // Añade un pequeño delay antes de mostrar el bloque para evitar los casos en que la transición no se aprecia
            const timeout = setTimeout(() => {
                if(state2.isActiveExpo){
                    document.querySelector('.led-2-full-width-block')?.classList.add("visible")
                    document.querySelector('.led-2-full-width-image')?.classList.add("visible")
                    if(state2.qrExpo){
                        setShowQr2(true)
                    }else{
                        setShowQr2(false)
                    }
                    setShowSecondOneColumn(true)
                    setFullWidthImage(true)
                    setTextOneLoadedTwo(true)
                }else if(state2.isActiveTextoCentrado){
                    document.querySelector('.led-2-full-width-block')?.classList.add("visible")
                    document.querySelector('.led-2-full-width-text')?.classList.add("visible")
                    if(state2.qrExpo){
                        setShowQr2(true)
                    }else{
                        setShowQr2(false)
                    }
                    setShowSecondOneColumn(true)
                    setFullWidthText(true)
                    setTextOneLoadedTwo(true)
                }else if(state2.isActiveMediaOne){
                    document.querySelector('.led-2-left-block-1')?.classList.add("visible")
                    document.querySelector('.led-2-right-block-1')?.classList.add("visible")
                    if(state2.qrMediaOne){
                        setShowQr2(true)
                    }else{
                        setShowQr2(false)
                    }
                    setShowSecondDoubleColumn1(true)
                    setTextOneLoadedTwo(true)
                }else if(state2.isActiveMediaTwo){
                    document.querySelector('.led-2-left-block-2')?.classList.add("visible")
                    document.querySelector('.led-2-right-block-2')?.classList.add("visible")
                    if(state2.qrMediaTwo){
                        setShowQr2(true)
                    }else{
                        setShowQr2(false)
                    }
                    setShowSecondDoubleColumn2(true)
                    setTextOneLoadedTwo(true)
                }else if(state2.isActiveMediaDouble){
                    document.querySelector('.led-2-left-block-3')?.classList.add("visible")
                    document.querySelector('.led-2-right-block-3')?.classList.add("visible")
                    if(state2.qrMediaDouble){
                        setShowQr2(true)
                    }else{
                        setShowQr2(false)
                    }
                    setShowSecondDoubleColumn3(true)
                    setTextOneLoadedTwo(true)
                }else{
                    setWithoutContentOne(true)
                    setEndedTwo(true)
                    setTextOneLoadedTwo(false)
                    setShowQr2(false)
                }
            }, 500);
            return () => clearTimeout(timeout);
        }
    }, [showTextTwo, textOneLoadedTwo, state2])

    useEffect(() => {
        if(showSecondOneColumn){
            if(fullWidthImage){
                const timer = setTimeout(() => {
                    document.querySelector('.led-2-full-width-image')?.classList.remove("visible")
                    document.querySelector('.led-2-full-width-image')?.classList.add("out")
                    const timerIn = setTimeout(() => {
                        setFullWidthImage(false)
                        if(state2.isActiveTextoCentrado){
                            document.querySelector('.led-2-full-width-text')?.classList.add("visible")
                            if(state2.qrExpo){
                                setShowQr2(true)
                            }else{
                                setShowQr2(false)
                            }
                            setFullWidthText(true)
                        }else if(state2.isActiveMediaOne){
                            document.querySelector('.led-2-left-block-1')?.classList.add("visible")
                            document.querySelector('.led-2-right-block-1')?.classList.add("visible")
                            if(state2.qrMediaOne){
                                setShowQr2(true)
                            }else{
                                setShowQr2(false)
                            }
                            setShowSecondDoubleColumn1(true)
                        }else if(state2.isActiveMediaTwo){
                            document.querySelector('.led-2-left-block-2')?.classList.add("visible")
                            document.querySelector('.led-2-right-block-2')?.classList.add("visible")
                            if(state2.qrMediaTwo){
                                setShowQr2(true)
                            }else{
                                setShowQr2(false)
                            }
                            setShowSecondDoubleColumn2(true)
                        }else if(state2.isActiveMediaDouble){
                            document.querySelector('.led-2-left-block-3')?.classList.add("visible")
                            document.querySelector('.led-2-right-block-3')?.classList.add("visible")
                            if(state2.qrMediaDouble){
                                setShowQr2(true)
                            }else{
                                setShowQr2(false)
                            }
                            setShowSecondDoubleColumn3(true)
                        }else{
                            document.querySelector('.led-2-full-width-block')?.classList.remove("out")
                            document.querySelector('.led-2-left-block-1')?.classList.remove("out")
                            document.querySelector('.led-2-right-block-1')?.classList.remove("out")
                            document.querySelector('.led-2-left-block-2')?.classList.remove("out")
                            document.querySelector('.led-2-right-block-2')?.classList.remove("out")
                            document.querySelector('.led-2-left-block-3')?.classList.remove("out")
                            document.querySelector('.led-2-right-block-3')?.classList.remove("out")
                            setShowTextTwo(false)
                            setTextOneLoadedTwo(false)
                            setShowQr2(false)
                            setEndedTwo(true)
                        }
                    }, 2000)
                    return () => clearTimeout(timerIn)
                }, 5000)
                return () => clearTimeout(timer)
            }else if(fullWidthText){
                const timer = setTimeout(() => {
                    document.querySelector('.led-2-full-width-text')?.classList.add("relative")
                    document.querySelector('.led-2-full-width-block')?.classList.remove("visible")
                    document.querySelector('.led-2-full-width-block')?.classList.add("out")
                    const timerIn = setTimeout(() => {
                        document.querySelector('.led-2-full-width-text')?.classList.remove("visible")
                        document.querySelector('.led-2-full-width-text')?.classList.remove("relative")
                        setFullWidthText(false)
                        setShowSecondOneColumn(false)
                        if(state2.isActiveMediaOne){
                            document.querySelector('.led-2-left-block-1')?.classList.add("visible")
                            document.querySelector('.led-2-right-block-1')?.classList.add("visible")
                            if(state2.qrMediaOne){
                                setShowQr2(true)
                            }else{
                                setShowQr2(false)
                            }
                            setShowSecondDoubleColumn1(true)
                        }else if(state2.isActiveMediaTwo){
                            document.querySelector('.led-2-left-block-2')?.classList.add("visible")
                            document.querySelector('.led-2-right-block-2')?.classList.add("visible")
                            if(state2.qrMediaTwo){
                                setShowQr2(true)
                            }else{
                                setShowQr2(false)
                            }
                            setShowSecondDoubleColumn2(true)
                        }else if(state2.isActiveMediaDouble){
                            document.querySelector('.led-2-left-block-3')?.classList.add("visible")
                            document.querySelector('.led-2-right-block-3')?.classList.add("visible")
                            if(state2.qrMediaDouble){
                                setShowQr2(true)
                            }else{
                                setShowQr2(false)
                            }
                            setShowSecondDoubleColumn3(true)
                        }else{
                            document.querySelector('.led-2-full-width-block')?.classList.remove("out")
                            document.querySelector('.led-2-left-block-1')?.classList.remove("out")
                            document.querySelector('.led-2-right-block-1')?.classList.remove("out")
                            document.querySelector('.led-2-left-block-2')?.classList.remove("out")
                            document.querySelector('.led-2-right-block-2')?.classList.remove("out")
                            document.querySelector('.led-2-left-block-3')?.classList.remove("out")
                            document.querySelector('.led-2-right-block-3')?.classList.remove("out")
                            setShowTextTwo(false)
                            setTextOneLoadedTwo(false)
                            setShowQr2(false)
                            setEndedTwo(true)
                        }
                    }, 2000)
                    return () => clearTimeout(timerIn)
                }, 5000)
                return () => clearTimeout(timer)
            }
        }else if(showSecondDoubleColumn1){
            const timer = setTimeout(() => {
                document.querySelector('.led-2-left-block-1')?.classList.remove("visible")
                document.querySelector('.led-2-right-block-1')?.classList.remove("visible")
                document.querySelector('.led-2-left-block-1')?.classList.add("out")
                document.querySelector('.led-2-right-block-1')?.classList.add("out")
                const timerIn = setTimeout(() => {
                    setShowSecondDoubleColumn1(false)
                    if(state2.isActiveMediaTwo){
                        document.querySelector('.led-2-left-block-2')?.classList.add("visible")
                        document.querySelector('.led-2-right-block-2')?.classList.add("visible")
                        if(state2.qrMediaTwo){
                            setShowQr2(true)
                        }else{
                            setShowQr2(false)
                        }
                        setShowSecondDoubleColumn2(true)
                    }else if(state2.isActiveMediaDouble){
                        document.querySelector('.led-2-left-block-3')?.classList.add("visible")
                        document.querySelector('.led-2-right-block-3')?.classList.add("visible")
                        if(state2.qrMediaDouble){
                            setShowQr2(true)
                        }else{
                            setShowQr2(false)
                        }
                        setShowSecondDoubleColumn3(true)
                    }else{
                        document.querySelector('.led-2-full-width-block')?.classList.remove("out")
                        document.querySelector('.led-2-left-block-1')?.classList.remove("out")
                        document.querySelector('.led-2-right-block-1')?.classList.remove("out")
                        document.querySelector('.led-2-left-block-2')?.classList.remove("out")
                        document.querySelector('.led-2-right-block-2')?.classList.remove("out")
                        document.querySelector('.led-2-left-block-3')?.classList.remove("out")
                        document.querySelector('.led-2-right-block-3')?.classList.remove("out")
                        setShowTextTwo(false)
                        setTextOneLoadedTwo(false)
                        setShowQr2(false)
                        setEndedTwo(true)
                    }
                }, 1700)
                return () => clearTimeout(timerIn)
            }, 5000)
            return () => clearTimeout(timer)
        }else if(showSecondDoubleColumn2){
            const timer = setTimeout(() => {
                document.querySelector('.led-2-left-block-2')?.classList.remove("visible")
                document.querySelector('.led-2-right-block-2')?.classList.remove("visible")
                document.querySelector('.led-2-left-block-2')?.classList.add("out")
                document.querySelector('.led-2-right-block-2')?.classList.add("out")
                const timerIn = setTimeout(() => {
                    setShowSecondDoubleColumn2(false)
                    if(state2.isActiveMediaDouble){
                        document.querySelector('.led-2-left-block-3')?.classList.add("visible")
                        document.querySelector('.led-2-right-block-3')?.classList.add("visible")
                        if(state2.qrMediaDouble){
                            setShowQr2(true)
                        }else{
                            setShowQr2(false)
                        }
                        setShowSecondDoubleColumn3(true)
                    }else{
                        document.querySelector('.led-2-full-width-block')?.classList.remove("out")
                        document.querySelector('.led-2-left-block-1')?.classList.remove("out")
                        document.querySelector('.led-2-right-block-1')?.classList.remove("out")
                        document.querySelector('.led-2-left-block-2')?.classList.remove("out")
                        document.querySelector('.led-2-right-block-2')?.classList.remove("out")
                        document.querySelector('.led-2-left-block-3')?.classList.remove("out")
                        document.querySelector('.led-2-right-block-3')?.classList.remove("out")
                        setShowTextTwo(false)
                        setTextOneLoadedTwo(false)
                        setShowQr2(false)
                        setEndedTwo(true)
                    }
                }, 1700)
                return () => clearTimeout(timerIn)
            }, 5000)
            return () => clearTimeout(timer)
        }else if(showSecondDoubleColumn3){
            const timer = setTimeout(() => {
                document.querySelector('.led-2-left-block-3')?.classList.remove("visible")
                document.querySelector('.led-2-right-block-3')?.classList.remove("visible")
                document.querySelector('.led-2-left-block-3')?.classList.add("out")
                document.querySelector('.led-2-right-block-3')?.classList.add("out")
                const timerIn = setTimeout(() => {
                    document.querySelector('.led-2-full-width-block')?.classList.remove("out")
                    document.querySelector('.led-2-left-block-1')?.classList.remove("out")
                    document.querySelector('.led-2-right-block-1')?.classList.remove("out")
                    document.querySelector('.led-2-left-block-2')?.classList.remove("out")
                    document.querySelector('.led-2-right-block-2')?.classList.remove("out")
                    document.querySelector('.led-2-left-block-3')?.classList.remove("out")
                    document.querySelector('.led-2-right-block-3')?.classList.remove("out")
                    setShowSecondDoubleColumn3(false)
                    setShowTextTwo(false)
                    setTextOneLoadedTwo(false)
                    setShowQr2(false)
                    setEndedTwo(true)
                }, 1000)
                return () => clearTimeout(timerIn)
            }, 10000)
            return () => clearTimeout(timer)
        }else if(withoutContentTwo){
            setTextOneLoadedTwo(false)
            setWithoutContentTwo(false)
            setEndedTwo(true)
        }
    }, [showSecondOneColumn, fullWidthImage, fullWidthText, showSecondDoubleColumn1, showSecondDoubleColumn2, showSecondDoubleColumn3, withoutContentTwo])

    useEffect(() => {
        if(endedTwo){
            if(currentLanguage2 === languages[0]){
                setCurrentLanguage2(languages[1])
            }else if(currentLanguage2 === languages[1]){
                setCurrentLanguage2(languages[2])
            }else if(currentLanguage2 === languages[2]){
                setCurrentLanguage2(languages[0])
            }
        }
    }, [endedTwo])

    useEffect(() => {
        setShowVideoTwo(true)
        setShowTextTwo(false)
        setEndedTwo(false)
    }, [currentLanguage2])
    
    const UnMutedVideo = () => {
        var video = document.getElementById("videoTwo")
        var btnUnMuted = document.getElementById("btnUnMuted")
        video.muted=false;
        btnUnMuted.style.display="none";
    }
    
    useEffect(() => {
        const interval = setInterval(() => {
            checkScreensUpdate()
            setReloadScreen(Math.random())
        }, 300000) 
        return () => clearInterval(interval)
    }, [reloadScreen])

    const checkScreensUpdate = () => {
        apiIsUpdateScreens().then(resp => {
            if(resp.data?.updatedOneOrTwo){
                // reload page
                window.location.reload()
            }
        })
    }

    return (
        <>
            <div style={{ boxSizing: "border-box" }}>
                <div style={{ width: "2560px", height: "360px", backgroundColor: '#141414', position: 'relative'}}>
                    {/*<button style={{border: 'none', backgroundColor:"transparent", color: '#fff', fontSize: 30, cursor: "pointer", position:"fixed", zIndex:10}} id="btnUnMuted" onClick={UnMutedVideo}><VscUnmute/></button>*/}
                    {getScreensLoaded2 && (
                        <>
                            {!showTextTwo && showVideoTwo ? (
                                state2.isActiveVideo ? (
                                    <>
                                        <video id="videoTwo" autoPlay muted onEnded={fin2} style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}>
                                            <source src={state2.urlFileVideo}></source>
                                        </video>
                                        <div className={`logo-qr-container ${state2.qrVideo ? 'visible' : ''}`}>
                                            <img src={logo} className="logo-image-display" />
                                            <div className="qr-right-block">
                                                <p className="url-text">www.museuartprohibit.org</p>
                                                <div className="qr-block">
                                                    <QrCode />
                                                </div>
                                                {currentLanguage2 === "ca" ? (
                                                    <p className="scanner-text">Escaneja el codi QR per accedir a la guia digital del Museu de l’Art Prohibit.</p>
                                                ) : currentLanguage2 === "es" ? (
                                                    <p className="scanner-text">Escanea el código QR para acceder a la guía digital del Museu de l’Art Prohibit.</p>
                                                ) : currentLanguage2 === "en" && (
                                                    <p className="scanner-text">Scan the QR code to access the digital guide of the Museu de l’Art Prohibit.</p>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : !state2.isActiveVideo && fin2()
                            ) : (
                                <>
                                    <div className="led-2-text-container">
                                        {(state2.isActiveExpo || state2.isActiveTextoCentrado) && (
                                            <div className="led-2-full-width-block">
                                                {state2.isActiveExpo && (
                                                    <div className="led-2-full-width-image">
                                                        <p>{state2.textExpoLineOne}</p>
                                                        <p>{state2.textExpoLineTwo}</p>
                                                        <p>{state2.textExpoLineThree}</p>
                                                        <p style={{fontFamily: 'QueensCondensedBoldItalic'}}>
                                                            <span>{state2.textExpoLineFour}</span> - <span>{state2.textExpoLineFourTwo}</span>
                                                        </p>
                                                    </div>
                                                )}
                                                {state2.isActiveTextoCentrado && (
                                                    <div className="led-2-full-width-text">
                                                        <p>{state2.textoCentradoLineOne}</p>
                                                        <p>{state2.textoCentradoLineTwo}</p>
                                                        <p>{state2.textoCentradoLineThree}</p>
                                                        <p>{state2.textoCentradoLineFour}</p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {state2.isActiveMediaOne && (
                                            <>
                                                <div className="led-2-left-block-1">
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        flexWrap: 'nowrap',
                                                        alignItems: 'flex-start',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <p>{state2.textMediaOneLineOne}</p>
                                                        <p>{state2.textMediaOneLineTwo}</p>
                                                        <p>{state2.textMediaOneLineThree}</p>
                                                        <p>{state2.textMediaOneLineFour}</p>
                                                    </div>
                                                    
                                                </div>
                                                <div className="led-2-right-block-1">
                                                    <img src={state2.urlFileMediaOne} />
                                                </div>
                                            </>
                                        )}
                                        {state2.isActiveMediaTwo && (
                                            <>
                                                <div className="led-2-left-block-2">
                                                    <img src={state2.urlFileMediaTwo} />
                                                </div>
                                                <div className="led-2-right-block-2">
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        flexWrap: 'nowrap',
                                                        alignItems: 'flex-start',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <p>{state2.textMediaTwoLineOne}</p>
                                                        <p>{state2.textMediaTwoLineTwo}</p>
                                                        <p>{state2.textMediaTwoLineThree}</p>
                                                        <p>{state2.textMediaTwoLineFour}</p>  
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {state2.isActiveMediaDouble && (
                                            <>
                                                <div className="led-2-left-block-3">
                                                    {state2.mediaDoubleOneType ? (
                                                        <video autoPlay muted>
                                                            <source src={state2.urlFileMediaDoubleOne}></source>
                                                        </video>
                                                    ) : (
                                                        <img src={state2.urlFileMediaDoubleOne} />
                                                    )}
                                                </div>
                                                <div className="led-2-right-block-3">
                                                    {state2.mediaDoubleTwoType ? (
                                                        <video autoPlay muted>
                                                            <source src={state2.urlFileMediaDoubleTwo}></source>
                                                        </video>
                                                    ) : (
                                                        <img src={state2.urlFileMediaDoubleTwo} />
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className={`logo-qr-container ${showQr2 ? 'visible' : ''}`} style={{display: 'none'}}>
                                        <img src={logo} className="logo-image-display" />
                                        <div className="qr-right-block">
                                            <p className="url-text">www.museuartprohibit.org</p>
                                            <div className="qr-block">
                                                <QrCode />
                                            </div>
                                            {currentLanguage2 === "ca" ? (
                                                <p className="scanner-text">Escaneja el codi QR per accedir a la guia digital del Museu de l’Art Prohibit.</p>
                                            ) : currentLanguage2 === "es" ? (
                                                <p className="scanner-text">Escanea el código QR para acceder a la guía digital del Museu de l’Art Prohibit.</p>
                                            ) : currentLanguage2 === "en" && (
                                                <p className="scanner-text">Scan the QR code to access the digital guide of the Museu de l’Art Prohibit.</p>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
                <div style={{ width: "1280px", height: "360px", backgroundColor: '#141414'}}>
                    {getScreensLoaded && (
                        <>
                            {!showTextOne && showVideoOne ? (
                                state.isActiveVideo ? (
                                    <video autoPlay muted onEnded={fin} style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}>
                                        <source src={state.urlFileVideo}></source>
                                    </video>
                                ) : !state.isActiveVideo && fin()
                            ) : (
                                <div className="led-1-text-container">
                                    {state.isActiveMediaOne && (
                                        <>
                                            <div className="led-1-left-block-1">
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexWrap: 'nowrap',
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'center'
                                                }}>
                                                    <p>{state.textMediaOneLineOne}</p>
                                                    <p>{state.textMediaOneLineTwo}</p>
                                                    <p>{state.textMediaOneLineThree}</p>
                                                    <p>{state.textMediaOneLineFour}</p>
                                                </div>
                                            </div>
                                            <div className="led-1-right-block-1">
                                                {state.mediaOneType ? (
                                                    <video autoPlay muted loop>
                                                        <source src={state.urlFileMediaOne}></source>
                                                    </video>
                                                ) : (
                                                    <img src={state.urlFileMediaOne} />
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {state.isActiveMediaTwo && (
                                        <>
                                            <div className="led-1-left-block-2">
                                                {state.mediaTwoType ? (
                                                    <video autoPlay muted loop>
                                                        <source src={state.urlFileMediaTwo}></source>
                                                    </video>
                                                ) : (
                                                    <img src={state.urlFileMediaTwo} />
                                                )}
                                            </div>
                                            <div className="led-1-right-block-2">
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    flexWrap: 'nowrap',
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'center'
                                                }}>
                                                    <p>{state.textMediaTwoLineOne}</p>
                                                    <p>{state.textMediaTwoLineTwo}</p>
                                                    <p>{state.textMediaTwoLineThree}</p>
                                                    <p>{state.textMediaTwoLineFour}</p>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {state.isActiveTextoCentrado && (
                                        <div className="led-1-full-width-block">
                                            <p>{state.textoCentradoLineOne}</p>
                                            <p>{state.textoCentradoLineTwo}</p>
                                            <p>{state.textoCentradoLineThree}</p>
                                            <p>{state.textoCentradoLineFour}</p>
                                        </div>
                                    )}
                                    {state.isActiveMediaDouble && (
                                        <>
                                            <div className="led-1-left-block-3">
                                                {state.mediaDoubleOneType ? (
                                                    <video autoPlay muted loop>
                                                        <source src={state.urlFileMediaDoubleOne}></source>
                                                    </video>
                                                ) : (
                                                    <img src={state.urlFileMediaDoubleOne} />
                                                )}
                                            </div>
                                            <div className="led-1-right-block-3">
                                                {state.mediaDoubleTwoType ? (
                                                    <video autoPlay muted loop>
                                                        <source src={state.urlFileMediaDoubleTwo}></source>
                                                    </video>
                                                ) : (
                                                    <img src={state.urlFileMediaDoubleTwo} />
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {state.isActivePrecios && (
                                        <div className="rates-container">
                                            <img src={state.urlFilePrecios} />
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

function initialValues(props) {

    return {
        fileVideo: props?.fileVideo || [],
        urlFileVideo: props?.urlFileVideo || "",
        isActiveVideo: props?.isActiveVideo === undefined ? false : props.isActiveVideo,
        isActiveExpo: props?.isActiveExpo === undefined ? false : props.isActiveExpo,
        qrVideo: props?.qrVideo === undefined ? false : props.qrVideo,
        textoCentradoLineOne: props?.textoCentradoLineOne || "",
        textoCentradoLineTwo: props?.textoCentradoLineTwo || "",
        textoCentradoLineThree: props?.textoCentradoLineThree || "",
        textoCentradoLineFour: props?.textoCentradoLineFour || "",
        isActiveTextoCentrado: props?.isActiveTextoCentrado === undefined ? false : props.isActiveTextoCentrado,
        qrTextoCentrado: props?.qrTextoCentrado === undefined ? false : props.qrTextoCentrado,
        filePrecios: props?.filePrecios || [],
        urlFilePrecios: props?.urlFilePrecios || "",
        isActivePrecios: props?.isActivePrecios === undefined ? false : props.isActivePrecios,
        qrPrecios: props?.qrPrecios === undefined ? false : props.qrPrecios,
        mediaOneType: props?.mediaOneType === undefined ? false : props.mediaOneType,
        fileMediaOne: props?.fileMediaOne || [],
        urlFileMediaOne: props?.urlFileMediaOne || "",
        qrMediaOne: props?.qrMediaOne === undefined ? false : props.qrMediaOne,
        isActiveMediaOne: props?.isActiveMediaOne === undefined ? false : props.isActiveMediaOne,
        textMediaOneLineOne: props?.textMediaOneLineOne || "",
        textMediaOneLineTwo: props?.textMediaOneLineTwo || "",
        textMediaOneLineThree: props?.textMediaOneLineThree || "",
        textMediaOneLineFour: props?.textMediaOneLineFour || "",
        mediaTwoType: props?.mediaTwoType === undefined ? false : props.mediaTwoType,
        fileMediaTwo: props?.fileMediaTwo || [],
        urlFileMediaTwo: props?.urlFileMediaTwo || "",
        qrMediaTwo: props?.qrMediaTwo === undefined ? false : props.qrMediaTwo,
        isActiveMediaTwo: props?.isActiveMediaTwo === undefined ? false : props.isActiveMediaTwo,
        textMediaTwoLineOne: props?.textMediaTwoLineOne || "",
        textMediaTwoLineTwo: props?.textMediaTwoLineTwo || "",
        textMediaTwoLineThree: props?.textMediaTwoLineThree || "",
        textMediaTwoLineFour: props?.textMediaTwoLineFour || "",
        qrMediaDouble: props?.qrMediaDouble === undefined ? false : props.qrMediaDouble,
        isActiveMediaDouble: props?.isActiveMediaDouble === undefined ? false : props.isActiveMediaDouble,
        mediaDoubleOneType: props?.mediaDoubleOneType === undefined ? false : props.mediaDoubleOneType,
        fileMediaDoubleOne: props?.fileMediaDoubleOne || [],
        urlFileMediaDoubleOne: props?.urlFileMediaDoubleOne || "",
        mediaDoubleTwoType: props?.mediaDoubleTwoType === undefined ? false : props.mediaDoubleTwoType,
        fileMediaDoubleTwo: props?.fileMediaDoubleTwo || [],
        urlFileMediaDoubleTwo: props?.urlFileMediaDoubleTwo || "",
        textExpoLineOne: props?.textExpoLineOne || "",
        textExpoLineTwo: props?.textExpoLineTwo || "",
        textExpoLineThree: props?.textExpoLineThree || "",
        textExpoLineFour: props?.textExpoLineFour || "",
        textExpoLineFourTwo: props?.textExpoLineFourTwo || ""
    }
}
