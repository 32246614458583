import DisplayOne from "../pages/Admin/Display/Display/one"
import QrScreen from "../pages/Admin/Display/QR/qrScreen"
import DisplayRates from "../pages/Admin/Display/Display/rates"

import DisplayNewOne from "../pages/Admin/Display/Display/newOne"

const baseTitle = "Offing - "
const urlBase = "/display"

export const RouteAdminDisplay = {
    RATES: {
        path: `${urlBase}/rates`,
        page: DisplayRates,
        title: `${baseTitle}Display Rates`,
        roles: ["ROLE_PUBLIC"]
    },
    DISPLAY_ONE: {
        path: `${urlBase}/one-old`,
        page: DisplayOne,
        title: `${baseTitle}Display One`,
        roles: ["ROLE_PUBLIC"]
    },
    QRSCREEN: {
        path: `${urlBase}/qr`,
        page: QrScreen,
        title: `${baseTitle}QR`,
        roles: ["ROLE_PUBLIC"]
    },
    DISPLAY_NEW_ONE: {
        path: `${urlBase}/one`,
        page: DisplayNewOne,
        title: `${baseTitle}Display New One`,
        roles: ["ROLE_PUBLIC"]
    },
   
}
