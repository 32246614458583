import React, {useEffect} from "react";
import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {RouteAuth} from "../../../routes/RouteAuth";

export default function Logout() {
    const {logout} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        logout()
        navigate(RouteAuth.LOGIN.path)

    }, [])


    return (
        <></>
    )
}
