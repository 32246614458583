import DisplayEdit from "../pages/Admin/Display/Fields/index"
import DisplayBlockEdit from "../pages/Admin/Display/Fields/SectionsForm/index"
import DisplayThreeEditRates from "../pages/Admin/Display/Fields/Rates"

//OLD 
import DisplayOneEditScreenOne from "../pages/Admin/Display/Fields/ScreenOne"
import DisplayOneEditScreenTwo from "../pages/Admin/Display/Fields/ScreenTwo"

const baseTitle = "Offing - "
const urlBase = "/dash"

export const RouteAdminDisplayEdit = {
  
   
    
    
    DISPLAY_EDIT: {
        path: `${urlBase}/display/edit`,
        params: "/:id",
        menu: "display",
        menuChild: "",
        page: DisplayEdit,
        title: `${baseTitle}Edit Display`,
        roles: ["ROLE_ADMIN"]
    },

    DISPLAY_BLOCK_EDIT: {
        path: `${urlBase}/display/block/edit`,
        params: "/:idScreen/:idScreenBlock",
        menu: "display",
        menuChild: "",
        page: DisplayBlockEdit,
        title: `${baseTitle}Edit Block Display`,
        roles: ["ROLE_ADMIN"]
    },


    DISPLAY_RATES_EDIT: {
        path: `${urlBase}/display/edit/rates`,
        menu: "display",
        menuChild: "",
        page: DisplayThreeEditRates,
        title: `${baseTitle}Edit Display Rates`,
        roles: ["ROLE_ADMIN"]
    },

    //OLD

    DISPLAY_ONE_EDIT_BLOCK1: {
        path: `${urlBase}/display/one/edit/screen-one`,
        menu: "display",
        menuChild: "",
        page: DisplayOneEditScreenOne,
        title: `${baseTitle}Edit Display One: Screen One`,
        roles: ["ROLE_ADMIN"]
    },
    DISPLAY_ONE_EDIT_BLOCK2: {
        path: `${urlBase}/display/one/edit/screen-two`,
        menu: "display",
        menuChild: "",
        page: DisplayOneEditScreenTwo,
        title: `${baseTitle}Edit Display One: Screen Two`,
        roles: ["ROLE_ADMIN"]
    },
   
}
