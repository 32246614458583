import React, {useEffect, useState} from "react";
import {apiList} from "../../../../../services/Admin/user";
import moment from "moment";
import {Grid, IconButton} from "@mui/material";
import MsgError from "../../../../../components/MsgError";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import Table from "../../../../../components/Table/Table";
import {RouteAdmin} from "../../../../../routes/RouteAdmin";
import {DASH_MAX_ROW} from "../../../../../utils/constants";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import InputSelect from "../../../../../components/Admin/Inputs/InputSelect";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import LinkButtonPrimary from "../../../../../components/Buttons/LinkButtonPrimary";

export default function Management() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(false)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [selection, setSelection] = useState([])

    useEffect(() => {
        // setLoading(true)
        // const timeOutId = setTimeout(() => {
        //     getItemsData()
        // }, 500)
        // return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        // setMsgError([])
        //
        // // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        // let field = state.sort.field
        // if (state.sort.field === "roleName") { field = "role" }
        // if (state.sort.field === "active") { field = "isActive" }
        // if (state.sort.field === "access") { field = "lastActivity" }
        //
        // let params = {
        //     limit: DASH_MAX_ROW,
        //     offset: state.offset ?? 0,
        //     field: field,
        //     sort: state.sort.sort
        // }
        //
        // if (state.email) { params.email = state.email }
        // if (state.role) { params.role = state.role }
        //
        // apiList(params).then(resp => {
        //     setRowCount(resp.data?.count)
        //
        //     let temp = resp.data.data?.map(doc => {
        //         return {
        //             ...doc,
        //             id: doc.id,
        //             roleName: doc?.role?.niceName,
        //             access: doc.isActive ? "Sí" : "No",
        //             active: moment.unix(doc.lastActivity).format("D/M/Y"),
        //         }
        //     })
        //
        //     setRows(temp)
        //     setLoading(false)
        // }).catch(err => {
        //     setMsgError(err.response?.data?.msg)
        //     setLoading(false)
        //     setRows([])
        // })
    }

    const handleChangeSelection = (e) => {
        if (selection.includes(e.target.value)) {
            setSelection(selection.filter(item => item !== e.target.value))
        } else {
            setSelection([...selection, e.target.value])
        }
    }

    // const LinkButtonPrimary = styled(Link)(({ theme }) => ({
    //     padding: '8px 22px  !important',
    //     backgroundColor: '#ffffff',
    //     color: '#E30613',
    //     border: '1px solid #E30613',
    //     fontFamily: 'AdminFontBold',
    //     borderRadius: '6px !important',
    //     textDecoration: 'none',
    //     cursor: 'pointer',
    //     transition: 'all 0.3s',
    //     '&:hover': {
    //         backgroundColor: '#E30613',
    //         color: '#ffffff !important',
    //         transition: 'all 0.3s'
    //     },
    // }));

    return (
        <>
            <HeaderPage />

            {/* <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MsgError msg={msgError}/>
                </Grid>

                <Grid item xs={12}>

                    <Filters
                        state={state}
                        setState={setState}
                    />

                    <Table
                        columns={tableColumns()}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid> */}
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        email: "",
        role: ""
    }
}

function tableColumns(handleChangeSelection, selection) {
    return [
        {
            field: "name",
            headerName: "NOM ARTISTA",
            sortable: true,
            flex: 1
        },
        {
            field: "name",
            headerName: "NOM SÈRIE",
            sortable: true,
            flex: 1
        },
        {
            field: "actions",
            headerName: "ACCIONS",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`${RouteAdmin.ARTISTA_CREATE.path}`}>
                            <IconButton size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                    </>
                )
            },
        },
    ]
}

function Filters(props) {

    const {state, setState} = props
    const {t} = useTranslation()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>
            <Grid item xs={6}>
                <InputText
                    label="Nom Artista"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={6}>
                <InputText
                    label="Nom Sèrie"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    )
}
