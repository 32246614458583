import * as React from 'react';
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import {RouteAdmin} from "../../../../../routes/RouteAdmin";
import Box from '@mui/material/Box';
import StoryCreate from './StoryCreate';

export default function Edit() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <>
            <HeaderPage title="Tornar" urlBack={RouteAdmin.STORIES_MANAGEMENT.path}/>

            <StoryCreate />
        </>
    );
}
