import React, {useEffect, useState} from "react";
import {apiLogin} from "../../../services/Auth/login";
import useAuth from "../../../hooks/useAuth";
import {Link, useNavigate} from "react-router-dom";
import {TextField} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import MsgError from "../../../components/MsgError";
import {LoadingButton} from "@mui/lab";
import {RouteAuth} from "../../../routes/RouteAuth";
import {RouteAdmin} from "../../../routes/RouteAdmin";
import MsgSuccess from "../../../components/MsgSuccess";
import jwtDecode from "jwt-decode";
import {RouteUser} from "../../../routes/RouteUser";
import logo from '../../../assets/images/Logo_MuseuArtProhibit_Red2.png'

const theme = createTheme();

export default function LoginForm() {

    const {login} = useAuth()
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search)

    const [state, setState] = useState(initialValues())
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loading, setLoading] = useState(false)
    const pjson = require("../../../../package.json")

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = (event) => {
        setLoading(true)
        event.preventDefault()

        setMsgError([])
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            return
        }

        apiLogin(state).then(resp => {
            startLogin(resp.data?.token)
        }).catch(err => {
            if (err.response?.data?.msg) {
                setMsgError(err.response?.data?.msg)
            } else {
                setMsgError(["Parece que algo ha ido mal, inténtalo más tarde o contacta con el administrador."])
            }
            setLoading(false)
        })
    }

    const startLogin = async (token) => {
        if (jwtDecode(token).roles[0] === "ROLE_ADMIN") {
            await login({
                primaryUser: token,
                secondaryUser: token,
                initial: true
            })
            navigate(RouteAdmin.USER_MANAGEMENT.path)
            setLoading(false)

        } else {
            await login({
                primaryUser: token,
                initial: true
            })
            navigate(RouteUser.SPLASH.path)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <div style={{minHeight: '100vh', display: 'flex', alignItems: 'center'}}>
                <Container component="main" maxWidth="xs" style={{padding: '48px 40px 36px', border: '1px solid #dadce0', borderRadius: '8px'}}>
                    <CssBaseline/>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                        <img style={{width: 150, marginBottom: '10px'}} src={logo} />
                        <Typography component="h1" variant="h5">
                            Log in
                        </Typography>
                        <Box component="form" noValidate sx={{mt: 3}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <MsgError msg={msgError}/>
                                    <MsgSuccess msg={msgSuccess}/>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="username"
                                        label="Email Address"
                                        name="username"
                                        autoComplete="email"
                                        onChange={handleChange}
                                        autoFocus
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        onChange={handleChange}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs sx={{mb: 3}} display="flex" justifyContent="flex-end">
                                    <Link to={RouteAuth.RECOVER_PASSWORD.path} style={{
                                        textDecoration: 'none',
                                        fontFamily: 'AdminFontBold',
                                        fontSize: '14px',
                                        color: '#414042'
                                    }}>
                                        Forgot password?
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center">
                                <Grid item xs={6}>
                                    <Link to={RouteAuth.SIGNUP.path} style={{
                                        textDecoration: 'none',
                                        fontFamily: 'AdminFontBold',
                                        fontSize: '14px',
                                        color: '#414042'
                                    }}>
                                        {"Sign Up"}
                                    </Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <LoadingButton
                                        type="button"
                                        loading={loading}
                                        loadingPosition="start"
                                        variant="contained"
                                        fullWidth
                                        onClick={handleSubmit}
                                        sx={{mt: 1, mb: 0}}
                                    >
                                        Log In
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
                <div style={{color: "gray", position: 'absolute', bottom: 20, right: 20, fontSize: 15}}>v.{pjson.version}</div>
            </div>
        </ThemeProvider>
    )
}

function initialValues() {
    return {
        username: "", password: ""
    }
}

function validationSchema(schema) {
    let error = []

    if (!schema.username) {
        error.push("El correo electrónico es obligatorio")
    }
    if (!schema.password) {
        error.push("La contraseña es obligatoria")
    }

    return error
}
