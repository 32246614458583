import React from "react";
import GeneralObraBlock from '../../../../components/GeneralObraBlock'
import IncidentAnyLlocBlock from '../../../../components/IncidentAnyLlocBlock'
import IncidentTextBlock from '../../../../components/IncidentTextBlock'
import InsultBlock from '../../../../components/InsultBlock'
import DocumentationBlock from '../../../../components/DocumentationBlock'
import PlayerVideo from "../../../../components/PlayerVideo";
import PreviewPdf from '../../../../components/PreviewPdf'
import BiografiaBlock from '../../../../components/BiografiaBlock'
import CitaBlock from '../../../../components/CitaBlock'
import CreditsBlock from '../../../../components/CreditsBlock'
import PhotoBlock from '../../../../components/PhotoBlock'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../../../../scss/User/swiper-stories.scss";
export default  function Section(props) {
    const {item, index, sectionItem, setArticleLoaded, storyId, sections} = props

    if (sectionItem.sectionType === 1) {
        return (
            <>
                <BiografiaBlock content={sectionItem.content}/>
            </>
        )
    } else if (sectionItem.sectionType === 2) {
        return (
            <>
                <GeneralObraBlock content={sectionItem.content} storyId={storyId} />
            </>
        )
    } else if (sectionItem.sectionType === 3) {
        return (
            <>
                <IncidentAnyLlocBlock content={sectionItem.content}/>
                <IncidentTextBlock content={sectionItem.content}/>
            </>
        )
    } else if (sectionItem.sectionType === 4) {
        return (
            <>
                <PreviewPdf
                    pluralTitle={sections[index + 1]?.sectionType === 4 ? true : false}
                    hideTitle={sections[index - 1]?.sectionType === 4 ? false : true}
                    content={{
                        documentUrl: sectionItem.content.document,
                        footer: sectionItem.content.footer
                    }}
                    setArticleLoaded={setArticleLoaded}
                />
            </>
        )
    } else if (sectionItem.sectionType === 5) {
        return (
            <>
                <PlayerVideo
                    pluralTitle={sections[index + 1]?.sectionType === 5 ? true : false}
                    hideTitle={sections[index - 1]?.sectionType === 5 ? false : true}
                    className="video-block"
                    content={sectionItem.content}
                />
            </>
        )
    } else if (sectionItem.sectionType === 6) {
        return (
            <>
                <InsultBlock
                    pluralTitle={sections[index + 1]?.sectionType === 6 ? true : false}
                    hideTitle={sections[index - 1]?.sectionType === 6 ? false : true}
                    content={sectionItem.content}
                />
            </>
        )
    } else if (sectionItem.sectionType === 7) {
        return (
            <>
                <CitaBlock 
                    pluralTitle={sections[index + 1]?.sectionType === 7 ? true : false}
                    hideTitle={sections[index - 1]?.sectionType === 7 ? false : true}
                    content={sectionItem.content}
                />
            </>
        )
    } else if (sectionItem.sectionType === 10) {
        return (
            <>
                <CreditsBlock 
                    hideTitle={sections[index - 1]?.sectionType === 7 ? false : true}
                    content={sectionItem.content}
                />
            </>
        )
    } else if (sectionItem.sectionType === 8) {
        return (
            <>
                <DocumentationBlock hideTitle={sections[index - 1]?.sectionType === 8 ? false : true} content={sectionItem.content}/>
            </>
        )
    } else if (sectionItem.sectionType === 9) {
        return (
            <>
                <PhotoBlock
                    pluralTitle={sections[index + 1]?.sectionType === 9 ? true : false}
                    hideTitle={sections[index - 1]?.sectionType === 9 ? false : true}
                    content={sectionItem.content}
                />
            </>
        )
    }
}
