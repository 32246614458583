import {BASE_PATH, BASE_PATH_EXTENSION} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION}/user/story`;

export function getStoryContent(props, langSelect, lang = 'en') {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    let query = "";
    if (props.slideId) {
        query += `?slideId=${props.slideId}`;
    }
    query += `?slideNumber=${props.slideNumber ?? 0}`;

    if (langSelect) {
        query += `&lang=${langSelect}`;
    }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/get-story/${props.storyId}${query}`, config)
}

export function getArtworkByName(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props) { query += `&name=${props.name}`; }
    if (props) { query += `&field=${props.order}`; }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/search-artwork?1=1${query}`, config)
}

export function getArtworkByRoom(salaId, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (salaId) {
        query += `&salaId=${salaId}`;
    }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/map-results/${salaId}?1=1${query}`, config)
}
