import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../../../components/Admin/TitlePage";
import InputText from "../../../../../../../components/Admin/Inputs/InputText";
import InputButtonLoading from "../../../../../../../components/Admin/Inputs/InputButtonLoading";
import InputButton from "../../../../../../../components/Admin/Inputs/InputButton";
import ModalConfirm from "../../../../../../../components/Admin/ModalConfirm";
import { apiSaveBlockText } from "../../../../../../../services/Admin/page";
import { MdDeleteForever } from "react-icons/md";
import EditorTextWeb from "../../../../../../../components/EditorTextWeb";
import ReactDOMServer from "react-dom/server";
import FullTextToHtml from "./FullTextToHtml";
import {clone} from "lodash";

export default function Faqs2cols(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    const [loadingSave, setLoadingSave] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openModalFile, setOpenModalFile] = React.useState(false)
    const [openModalFile2, setOpenModalFile2] = React.useState(false)
    const [itemRemove, setItemRemove] = React.useState(null)
    const [itemRemove2, setItemRemove2] = React.useState(null)
    const myRef = useRef(null)
    const [itemsFaqs, setItemFaqs] = React.useState([])
    const [itemsFaqs2, setItemFaqs2] = React.useState([])



    useEffect(() => {

        if (state.blockText.itemOne && state.blockText.itemOne.length > 0) {
            setItemFaqs(JSON.parse(state.blockText.itemOne))
        }
        else {
            setItemFaqs([])
        }

    }, [state.blockText.itemOne])


    useEffect(() => {

        if (state.blockText.itemTwo && state.blockText.itemTwo.length > 0) {
            setItemFaqs2(JSON.parse(state.blockText.itemTwo))
        }
        else {
            setItemFaqs2([])
        }

    }, [state.blockText.itemTwo])


    const handleOpenModalItem = (id) => {
        setOpenModalFile(true)
        setItemRemove(id)
    }

    const handleOpenModalItem2 = (id) => {
        setOpenModalFile2(true)
        setItemRemove2(id)
    }



    const addFaq = () => {
        setItemFaqs([...itemsFaqs, { id: itemsFaqs.length + 1, p: "", r: "" }])
        myRef.current.scrollIntoView()
    }

    const addFaq2 = () => {
        setItemFaqs2([...itemsFaqs2, { id: itemsFaqs2.length + 1, p: "", r: "" }])
        myRef.current.scrollIntoView()
    }

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)

        let dataFaqs = {
            items: itemsFaqs,
            items2: itemsFaqs2
        }

        state.dataFaqs = dataFaqs
        state.blockText.htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.text} />);

        apiSaveBlockText(state).then(resp => {
            handleRefresh()
            setMsgSuccess(resp.data?.msg)
            setLoadingSave(false)
            window.location.reload();
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
            handleRefresh()
        })

    }


    const handleDelete = () => {

        let temp = []
        let temp2 = []

        itemsFaqs.map((element, key) => {

            if (itemRemove != element.id) {
                temp.push(element)
            }

        })

        temp.map((element, key) => {

            element.id = key + 1;
            temp2.push(element)

        })

        setItemFaqs(temp2)
        setItemRemove(null)


    }

    const handleDelete2 = () => {

        let temp = []
        let temp2 = []

        itemsFaqs2.map((element, key) => {

            if (itemRemove2 != element.id) {
                temp.push(element)
            }

        })

        temp.map((element, key) => {

            element.id = key + 1;
            temp2.push(element)

        })

        setItemFaqs2(temp2)
        setItemRemove2(null)


    }


    const handleChange = key => (e) => {

        itemsFaqs[key][e.target.name] = e.target.value
        setItemFaqs([...itemsFaqs])
    }

    const handleChange2 = key => (e) => {

        itemsFaqs2[key][e.target.name] = e.target.value
        setItemFaqs2([...itemsFaqs2])
    }

    const handleChangeBlockText = (e) => {
        state.blockText[e.target.name] = e.target.value
        setState({ ...state })
    }

    const handleRefresh = () => {
        setReloadPreview(Math.random())
    }

    return (
        <>
            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title={state.block.webBlockName} borderTop={false} borderBottom={false} />
            </Grid>

            <Grid container spacing={2} md={12}>

            <   Grid item md={12}>
                    <InputText
                        label={"Caracter(s) de separació [Nom/Cognom]"}
                        name="itemThree"
                        disabled={loadingSave}
                        value={state?.blockText?.itemThree}
                        onChange={handleChangeBlockText}
                    />
                </Grid>

                <Grid item md={6}>
                    <InputButton
                        style={{ float: "left", margin: "5px 10px 10px 0px" }}
                        onClick={addFaq}
                        text=" + Afegir artista - obres - COL ESQ"
                    />


                    {itemsFaqs.map((element, key) => (
                        <>
                            <Grid container spacing={1} style={{ padding: '0px' }} key={key} >
                                {key === 0 ? (
                                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: 0 }}>
                                        <TitlePage title={`Artista - Obres ${key + 1} - ESQ`} />
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} md={12} lg={12} >
                                        <TitlePage title={`Artista - Obres ${key + 1} - ESQ`} borderTop={true} />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item md={12} style={{ marginBottom: "15px", paddingTop: "0px" }}>
                                <InputText
                                    label={"Artista"}
                                    name="p"
                                    disabled={loadingSave}
                                    value={itemsFaqs[key].p}
                                    onChange={handleChange(key)}
                                    rows={1}
                                />
                            </Grid>

                            <Grid item md={12} style={{ marginBottom: "15px", paddingTop: "0px" }}>
                                <EditorTextWeb
                                    label={"Obras"}
                                    style={{ height: 150 }}
                                    options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'history']}
                                    content={itemsFaqs[key].r}
                                    setContent={(e) => {
                                        itemsFaqs[key].r = e
                                        itemsFaqs[key].htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={e} />)
                                    }}
                                />
                            </Grid>
                            <Grid item md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "15px", paddingTop: "0px" }}>
                                <InputButtonLoading
                                    onClick={() => handleOpenModalItem(itemsFaqs[key].id)}
                                    loading={false}
                                    label={(
                                        <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar </>
                                    )}
                                />
                            </Grid>

                        </>
                    )
                    )}

                    <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDelete} text="Eliminar l'artista - obres - COL IZQ" />

                </Grid>

                <Grid item md={6}>
                    <InputButton
                        style={{ float: "left", margin: "5px 10px 10px 0px" }}
                        onClick={addFaq2}
                        text=" + Afegir artista - obres - COL DRE"
                    />


                    {itemsFaqs2.map((element, key) => (
                        <>
                            <Grid container spacing={1} style={{ padding: '0px' }} key={key} >
                                {key === 0 ? (
                                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: 0 }}>
                                        <TitlePage title={`Artista - Obres ${key + 1} - DRE`} />
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} md={12} lg={12} >
                                        <TitlePage title={`Artista - Obres ${key + 1} - DRE`} borderTop={true} />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item md={12} style={{ marginBottom: "15px", paddingTop: "0px" }}>
                                <InputText
                                    label={"Artista"}
                                    name="p"
                                    disabled={loadingSave}
                                    value={itemsFaqs2[key].p}
                                    onChange={handleChange2(key)}
                                    rows={1}
                                />
                            </Grid>

                            <Grid item md={12} style={{ marginBottom: "15px", paddingTop: "0px" }}>
                                <EditorTextWeb
                                    label={"Obras"}
                                    style={{ height: 150 }}
                                    options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'history']}
                                    content={itemsFaqs2[key].r}
                                    setContent={(e) => {
                                        itemsFaqs2[key].r = e
                                        itemsFaqs2[key].htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={e} />)
                                    }}
                                />
                            </Grid>
                            <Grid item md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "15px", paddingTop: "0px" }}>
                                <InputButtonLoading
                                    onClick={() => handleOpenModalItem2(itemsFaqs2[key].id)}
                                    loading={false}
                                    label={(
                                        <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar </>
                                    )}
                                />
                            </Grid>

                        </>
                    )
                    )}

                    <ModalConfirm openModal={openModalFile2} setOpenModal={setOpenModalFile2} handleAction={handleDelete2} text="Eliminar l'artista - obres - COL DRE" />

                </Grid>
            </Grid>

            <Grid item md={12}>
                <InputButtonLoading
                    onClick={handleSubmit}
                    loading={loadingSave}
                    label="Modificar"
                    position="left"
                    style={{ float: 'right' }}
                />
            </Grid>

            <div ref={myRef}></div>

        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        model: {
            id: null,
            blockId: null,
            langSelect: state.langSelect,
            text: "",
            itemOne: "",
            itemTwo: "",
            itemThree: "",
            loading: false,
            loadingDelete: false,
            loadingDeleteFile: false,
            msgSuccess: [],
            msgError: []
        },
    }
}

function validationSchema(schema) {

    let error = []

    return error
}
