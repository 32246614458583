import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../../../components/Admin/TitlePage";
import InputButtonLoading from "../../../../../../../components/Admin/Inputs/InputButtonLoading";
import { apiSaveBlockText } from "../../../../../../../services/Admin/page";
import EditorTextWeb from "../../../../../../../components/EditorTextWeb";
import InputText from "../../../../../../../components/Admin/Inputs/InputText";
import InputCheckbox from "../../../../../../../components/Admin/Inputs/InputCheckbox";
import FullTextToHtml from "./FullTextToHtml";
import ReactDOMServer from 'react-dom/server';
import {clone} from "lodash";

export default function FullText(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    const [loadingSave, setLoadingSave] = useState(false)
   
    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)
        const htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.text} />);

        const params = clone(state)
        params.blockText.htmlCode = htmlCode

        apiSaveBlockText(params).then(resp => {
            handleRefresh()
            setMsgSuccess(resp.data?.msg)
            setLoadingSave(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
            handleRefresh()
        })
      
    }

    const handleChangeBlockText = (e) => {
        state.blockText[e.target.name] = e.target.value
        setState({ ...state })
    }

    const handleRefresh = () => {
        setReloadPreview(Math.random())
    }

    return (
        <>
            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title={state.block.webBlockName} borderTop={false} borderBottom={false} />
            </Grid>

            <Grid item md={12}>
                
                <EditorTextWeb
                    style={{height: 250}}
                    options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'history']}
                    //fonts = {TEXT_FONTS}
                    content={state.blockText.text}
                    setContent={(e) => {
                        state.blockText.text = e
                        setState({ ...state })
                    }}
                />
            </Grid>

            <Grid item md={12}>
                <InputCheckbox
                    labelLeft="Marges del text: Sí"
                    label="No"
                    name="titleColor"
                    checked={state.blockText?.titleColor? true : false}
                    onChange={(e) => {
                        state.blockText.titleColor = e.target.checked
                        setState({ ...state })
                    }}
                    disabled={loadingSave}
                />
            </Grid>

            <Grid item md={4}>
                <InputText
                    label={"Text Botó Blanc"}
                    name="btnWhiteName"
                    disabled={loadingSave}
                    value={state?.blockText?.btnWhiteName}
                    onChange={handleChangeBlockText}

                />
            </Grid>

            <Grid item md={4}>
                <InputText
                    label={"Enllaç Botó Blanc"}
                    name="btnWhiteLink"
                    disabled={loadingSave}
                    value={state?.blockText?.btnWhiteLink}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={4}>
                <InputText
                    label={"SEO ID Botó Blanc"}
                    name="itemOne"
                    disabled={loadingSave}
                    value={state?.blockText?.itemOne}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={4}>
                <InputText
                    label={"Text Botó Modal"}
                    name="btnRedName"
                    disabled={loadingSave}
                    value={state?.blockText?.btnRedName}
                    onChange={handleChangeBlockText}

                />
            </Grid>

            <Grid item md={4}>
                <InputText
                    label={"Text Modal"}
                    name="btnRedLink"
                    disabled={loadingSave}
                    value={state?.blockText?.btnRedLink}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={4}>
                <InputText
                    label={"SEO ID Botó Modal"}
                    name="itemTwo"
                    disabled={loadingSave}
                    value={state?.blockText?.itemTwo}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={12}>
                <InputButtonLoading
                    onClick={handleSubmit}
                    loading={loadingSave}
                    label="Modificar"
                    position="left"
                    style={{ float: 'right' }}
                />
            </Grid>
        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        fileBackgroundImage: [],

    }
}

