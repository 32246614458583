import React, { useRef, useState, useEffect } from "react";
import {useTranslation} from "react-i18next";

export default function IncidentAnyLloc(props) {

    const {t} = useTranslation()
    const {content} = props

    return(
        <div className="text-block" style={{maxWidth: '100%'}}>
            {content.yearSite && 
                <div style={{paddingBottom: '28px', paddingTop: '8px', borderTop: '1px solid #FE7A84'}}>
                    <h2 style={{fontFamily: 'SohneBreitHalbfett', fontSize: '15px', lineHeight: '18px', color: '#E30613', margin: '0px', marginBottom: '4px'}}>{t('story.incident_year_site')}</h2>
                    <div style={{fontFamily: 'SohneBuch', fontSize: '16px', lineHeight: '21px', color: '#141414', margin: '0px'}} dangerouslySetInnerHTML={{ __html: content.yearSite }} />
                </div>
            }
        </div>
    )
}
