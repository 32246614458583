import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

/**
 * Muestra un reproductor de audio
 *
 * ## PROPS
 * - **url** *string*
 */
export default function CloseIcon(props) {

    const {className} = props;

    return (
        <div className={className}>
            <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 166 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M60.7206 41.2812C69.5302 39.0137 77.6206 38.0962 83.8312 38.3471C82.8013 35.0092 80.1992 27.3876 76.7027 23.6988C72.1644 18.9193 66.3452 13.7298 53.6706 15.4745C53.6706 15.4745 54.5701 16.7686 54.0463 18.5141C53.4155 20.6005 52.5378 24.765 53.3736 26.7274C53.3736 26.7274 56.0365 27.167 58.1256 28.9223C60.2092 30.6776 59.2581 34.321 59.6936 35.6692C60.1223 37.0196 61.0017 38.5971 61.898 39.0585C61.898 39.0585 61.0338 40.1733 60.7206 41.2812Z" fill="#E30613"/>
                <path d="M41.3796 30.7831C40.8519 28.7147 37.6288 25.9391 35.8593 24.6645C34.3836 23.5953 34.3111 22.0216 34.3128 22.021C23.1814 28.3283 21.7088 35.984 21.0018 42.5376C20.4652 47.5943 23.0232 55.2345 24.2304 58.5107C29.0113 54.5401 35.9993 50.3577 44.3788 46.8076C43.4557 46.1178 42.0923 45.7565 42.0923 45.7565C42.525 44.8474 42.2657 43.0583 41.7867 41.7249C41.3144 40.3892 38.3469 38.0711 38.9372 35.4114C39.5319 32.7484 41.3807 30.7809 41.3813 30.7826L41.3796 30.7831Z" fill="#E30613"/>
                <path d="M42.5979 28.8713C44.4874 27.1237 45.6173 25.0703 45.9293 24.4619C46.5449 24.7566 48.691 25.7019 51.2534 25.9437C51.2534 25.9437 51.2591 22.4237 51.9165 19.9844C52.571 17.5478 52.5813 15.832 49.7952 13.9891C47.2814 12.327 43.1893 13.7578 42.4123 14.0636C41.6092 14.2922 37.4887 15.6392 36.5 18.486C35.404 21.6401 36.4537 22.9973 38.4535 24.538C40.4565 26.0775 42.5995 28.8707 42.5979 28.8713Z" fill="#E30613"/>
                <path d="M47.0148 30.8147C48.0252 33.7854 47.2835 35.5111 46.1654 37.1904C45.0444 38.8724 45.5833 41.4549 47.6006 42.412C49.6174 43.373 50.8908 42.7553 50.8925 42.7547C47.3619 40.0925 49.9855 36.8012 49.9944 36.8C50.3293 38.3429 51.4506 38.8834 52.5679 39.429C53.6835 39.9751 53.5096 41.6882 53.5113 41.6877C56.7419 39.6939 56.1995 35.664 53.4011 33.4588C50.6033 31.2553 47.0148 30.8147 47.0153 30.8164L47.0148 30.8147Z" fill="#E30613"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M60.996 68.9943C83.0343 61.4804 100.745 52.7834 99.5592 46.2436C98.4226 39.7882 77.247 38.2245 53.3367 46.3491C29.4019 54.4072 13.5249 68.5033 16.5402 74.3233C19.5677 80.2401 38.9214 76.4008 60.996 68.9943ZM59.5522 64.7269C74.9558 59.4776 87.5374 54.5454 86.9613 51.3886C86.4196 48.2893 72.1688 48.2809 55.8644 53.8236C39.5429 59.316 28.2231 67.9732 29.674 70.765C31.133 73.6251 44.1248 69.9057 59.5522 64.7269Z" fill="#E30613"/>
                <path d="M165.132 30.1875C165.132 30.1875 162.875 34.0389 154.224 35.7409C157.891 30.3662 159.584 22.1255 156.575 16.0346C155.54 21.1407 152.814 23.1107 149.993 24.8127C150.557 21.4087 149.24 8.2415 139.555 1.52344C142.094 8.95809 133.444 15.6762 133.444 15.6762C133.444 15.6762 131.187 14.9595 127.801 3.76279C119.433 10.8388 122.441 24.544 122.441 24.544C122.441 24.544 115.859 23.2006 115.201 15.4073C110.029 21.14 115.577 32.4264 115.577 32.5159C115.577 32.5159 109.559 31.6199 109.276 25.4399C104.011 33.5913 110.687 43.3547 118.021 48.0126C117.927 46.042 118.115 43.8026 119.055 41.2051C121.218 35.0243 128.834 32.5164 129.681 28.7543C130.527 30.7249 131.28 34.487 130.151 35.9204C134.665 33.1438 135.511 30.2771 136.733 25.7089C140.683 29.8297 142.845 35.2036 142.658 40.7571C144.068 38.8757 145.102 36.5471 145.667 34.3077C149.24 41.8318 146.701 51.3268 138.332 56.1638C149.804 58.3138 159.113 47.923 159.113 47.923C159.113 47.923 154.318 48.0124 151.685 48.1023C161.182 42.817 165.132 30.1875 165.132 30.1875Z" fill="#E30613"/>
                <path d="M93.3932 75.7029C92.3578 76.4995 92.2639 78.0044 93.1109 78.8898L94.8048 80.7491C95.6518 81.6346 97.1579 81.8112 98.1933 81.0146L125.337 57.2254L120.536 52.0027L93.3932 75.7029Z" fill="#E30613"/>
                <path d="M139.296 40.8502C136.339 37.6909 130.519 37.9616 126.322 41.3916C123.556 43.648 122.124 46.7166 122.22 49.4244L127.753 55.5615C130.615 56.103 134.05 55.2007 136.816 52.9444C141.108 49.4244 142.253 44.0093 139.296 40.8502Z" fill="#E30613"/>
            </svg>
        </div>
    )
}
