import React, { useEffect, useRef, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import InputSelect from '../../../../../../components/Admin/Inputs/InputSelect'
import HeaderPage from "../../../../../../components/Admin/HeaderPage";
import { RouteWebBack } from "../../../../../../routes/Web/RouteWebBack";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { lang } from "../../../../../../utils/lang";
import MsgError from "../../../../../../components/MsgError";
import MsgSuccess from "../../../../../../components/MsgSuccess";
import LoadingSpinner from "../../../../../../components/LoadingSpinner";
import Preview from "./Preview";
import { AiOutlineReload } from "react-icons/ai";
import { URL_WEB } from "../../../../../../utils/constants";
import { apiGetBlockFooter } from "../../../../../../services/Admin/page";
import Header from "./SectionsForm/Header";
import FullText from "./SectionsForm/FullText";
import Visit from "./SectionsForm/Visit";
import Search from "./SectionsForm/Search";
import Carousel from "./SectionsForm/Carousel";
import ImgText from "./SectionsForm/ImgText";
import Video from "./SectionsForm/Video";
import Map from "./SectionsForm/Map";
import ContactForm from "./SectionsForm/ContactForm";
import Faqs from "./SectionsForm/Faqs";
import Footer from "./SectionsForm/Footer";
import ScheduleAndRate from "./SectionsForm/ScheduleAndRate";

export default function BlockFooterFields() {

    const { idPage } = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [reloadPreview, setReloadPreview] = useState(null)
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(false)
    const myRef = useRef(null)
    const { t, i18n } = useTranslation()

    useEffect(() => {
        setLoadingGetInfo(true)
        if (idPage) {
            getPageBlock()
        } else {
            setLoadingGetInfo(false)
            setState({ ...state, loading: false })
        }
    }, [state.langSelect])

    const getPageBlock = () => {

        setMsgError([])
        setMsgSuccess([])

        apiGetBlockFooter(state?.langSelect, i18n.resolvedLanguage).then(resp => {

            let bl = resp.data.block[0];
            let blt = resp.data.blockText[0];
            let bu = resp.data.baseUrl;

          if(blt==undefined)
            {
                state.blockText =  {
                    id: null,
                    blockId: bl.blockId,
                    langSelect: state.langSelect,
                    text: "",
                    fileBackgroundImage: null,
                    backgroundImage: "",
                    backgroundImageName: "",
                    titleColor: false,
                    imgPortada: false,
                    btnRedName: "",
                    btnRedLink: "",
                    btnWhiteName: "",
                    btnWhiteLink: "",
                    itemOne:"",
                    itemTwo:"",
                    itemThree:"",
                    itemFour:"",
                    itemFive:"",
                    itemOneName:"",
                    itemTwoName:"",
                    itemThreeName:"",
                    itemFourName:"",
                }
            }else
            {
                state.blockText = blt;
            }

            state.block = bl;
            state.baseUrl=bu;
         
            setState({

                ...state,
            })

            setLoadingGetInfo(false)

        }).catch(err => {
            setLoadingGetInfo(false)
            //setMsgError(err.response.data?.msg)
        })
    }

    const handleRefresh = () => {
        setReloadPreview(Math.random())
    }

    return (
        <>
            <div ref={myRef}></div>

            <HeaderPage
                title="Tornar"
                urlBack={`${RouteWebBack.WEB_BACK_EDIT.path}` + "/" + `${idPage}`}
            >
                <div style={{ float: 'right' }}>
                    <InputSelect
                        label="Idioma"
                        options={lang}
                        name="langSelect"
                        value={state.langSelect}
                        selectText="Selecciona un idioma"
                        style={{ width: 100 }}
                        onChange={(e) => {
                            setState({ ...state, langSelect: e.target.value })
                            localStorage.setItem('langSelect', e.target.value)
                        }}
                    />
                </div>
            </HeaderPage>

            <LoadingSpinner show={loadingGetInfo} />

            <MsgError msg={msgError} />
            <MsgSuccess msg={msgSuccess} />

            <Grid container spacing={2} style={{ display: loadingGetInfo ? 'none' : '' }}>

                <Grid item xs={12} md={12} lg={12}>
                    <Grid container spacing={2}>

                        
                        {state.block?.webBlockCode === "TYPE_FOOTER" && (
                            <Footer
                                state={state}
                                setState={setState}
                                setReloadPreview={setReloadPreview}
                                setMsgSuccess={setMsgSuccess}
                                setMsgError={setMsgError}
                            />
                        )}

                    </Grid>
                </Grid>

                {/* <Grid item xs={3} md={3} lg={3}>
                    <div style={{ position: 'fixed', marginLeft: 50 }}>
                        <div style={{ display: 'flex' }}>
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                onClick={handleRefresh}
                                style={{ marginTop: '-30px' }}
                            >
                                <AiOutlineReload color="#F00214" />
                            </IconButton>
                            <p style={{ marginTop: '-10px' }}>{`${URL_WEB}/page/${idPage}/${idPageBlock}/${state.langSelect}`}</p>
                        </div>
                        <Preview
                            width={'33.275vh'}
                            height={'76.63vh'}
                            src={`/page/${idPage}/${idPageBlock}/${state.langSelect}`}
                            reload={reloadPreview}
                        />
                    </div>
                </Grid> */}

            </Grid>
        </>
    );
}

function initialValues(props) {

    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        block: props?.block || {},
        blockText: props?.blockText || {},

    }
}

