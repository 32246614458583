import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../../../../components/Admin/TitlePage";
import InputButtonLoading from "../../../../../../../../components/Admin/Inputs/InputButtonLoading";
import { apiGetActivity, apiSaveActivity, apiSaveFileActivity, apiDeleteFileActivity } from "../../../../../../../../services/Admin/blockActivity";
import EditorTextWeb from "../../../../../../../../components/EditorTextWeb";
import InputText from "../../../../../../../../components/Admin/Inputs/InputText";
import ModalConfirm from "../../../../../../../../components/Admin/ModalConfirm";
import { MdDeleteForever } from "react-icons/md";
import ButtonFile from "../../../../../../../../components/Admin/Inputs/ButtonFile";
import InputCheckbox from "../../../../../../../../components/Admin/Inputs/InputCheckbox";
import ReactDOMServer from "react-dom/server";
import FullTextToHtml from "../FullTextToHtml";
import { clone } from "lodash";
import HeaderPage from "../../../../../../../../components/Admin/HeaderPage";
import { RouteWebBack } from "../../../../../../../../routes/Web/RouteWebBack";
import InputSelect from '../../../../../../../../components/Admin/Inputs/InputSelect'
import { lang } from "../../../../../../../../utils/lang";
import LoadingSpinner from "../../../../../../../../components/LoadingSpinner";
import MsgError from "../../../../../../../../components/MsgError";
import MsgSuccess from "../../../../../../../../components/MsgSuccess";
import { useTranslation } from "react-i18next";
import InputDateTime from "../../../../../../../../components/Admin/Inputs/InputDateTime";

export default function EditActivity() {

    let langSelectStorage = localStorage.getItem('langSelect')
    const navigate = useNavigate()
    const { idPage, idPageBlock, idBlock, idActivity } = useParams()
    const [state, setState] = useState(initialValues())
    const [loadingSave, setLoadingSave] = useState(false)
    const [openModalFile, setOpenModalFile] = React.useState(false)
    const [loadingGetInfo, setLoadingGetInfo] = useState(false)

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])

    const { t, i18n } = useTranslation()
    const [langSelect, setLangSelect] = useState(langSelectStorage ? langSelectStorage : 'ca')

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        if (idActivity) {
            void getActivity()
        } else {
            setLoadingGetInfo(false)
            setState(initialValues())
        }
    }, [langSelect]) // eslint-disable-line react-hooks/exhaustive-deps

    const getActivity = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingGetInfo(true)
        apiGetActivity(idBlock, idActivity, state?.langSelect, i18n.resolvedLanguage).then(resp => {
            setState(initialValues(resp.data[0]))
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
        })
    }

    const handleChangePhotoFile = (event) => {
        state[event.target.name] = event.target.value
        setState({ ...state })
    }
    const handleOpenModalFile = () => {
        setOpenModalFile(true)
    }

    const handleDeleteFile = () => {
        apiDeleteFileActivity({
            blockId: idBlock,
            idActivity:idActivity,
            langCode: state.langSelect,
        }).then(resp => {
            window.location.reload();
            setMsgSuccess(resp.data?.msg)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
        })
    }

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)

        const htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.text} />);
        
        const params = clone(state)
        params.htmlCode = htmlCode
        params.idActivity = idActivity
        params.blockId = idBlock
        params.langSelect = langSelect

        apiSaveActivity(params).then(resp => {
            let fileImageBinary = state?.file?.length > 0 ? state?.file[0].binary : null
            if (typeof idActivity === 'undefined') { handleSubmitFile(idBlock, resp.data.data.activityId, fileImageBinary)}
            else{  handleSubmitFile(idBlock, idActivity, fileImageBinary)}
            setLoadingSave(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
        })

    }

    const handleSubmitFile = (idBlock, idActivity, file) => {
        let f = new FormData()
        f.append(`file[]`, file)
        f.append(`langCode`, state.langSelect)
        f.append('idActivity',idActivity)

        apiSaveFileActivity(f, idBlock).then(resp => {
            setLoadingSave(false)
            setMsgSuccess(resp.data?.msg)
            if (idActivity) {
                navigate(`${RouteWebBack.WEB_ACTIVITY_EDIT.path}/${idPage}/${idPageBlock}/${idBlock}/${idActivity}`)
            }
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingSave(false)
        })
    }


    const handleChange = (e) => {
        state[e.target.name] = e.target.value
        setState({ ...state })
    }

    const handleRefresh = () => {
        setReloadPreview(Math.random())
    }




    return (
        <>
            <HeaderPage
                title="Tornar"
                urlBack={`${RouteWebBack.WEB_BLOCK_EDIT.path}/${idPage}/${idPageBlock}`}
            >
                <div style={{ float: 'right' }}>
                    <InputSelect
                        label="Idioma"
                        options={lang}
                        name="langSelect"
                        value={langSelect}
                        selectText="Selecciona un idioma"
                        style={{ width: 100 }}
                        onChange={(e) => {
                            setLangSelect(e.target.value)
                            localStorage.setItem('langSelect', e.target.value)
                            state.langSelect = e.target.value
                            setState({ ...state })
                        }}
                    />
                </div>
            </HeaderPage>

            <MsgError msg={msgError} />
            <MsgSuccess msg={msgSuccess} />

            <LoadingSpinner show={loadingGetInfo} />

            <Grid container spacing={2} style={{ display: loadingGetInfo ? 'none' : '' }}>

                <Grid item md={12} style={{ paddingTop: '0px' }}>
                    <TitlePage title="Activitat" borderTop={false} borderBottom={false} />
                </Grid>

                <Grid container md={12} spacing={1} rowSpacing={2}>

                    <Grid item md={12}>
                        <InputCheckbox
                            label="Activat"
                            className="mb-12"
                            checked={state.isActive ? true : false || false}
                            onChange={(e) => {
                                state.isActive = e.target.checked
                                setState({ ...state })
                            }}
                            disabled={loadingSave}
                        />
                    </Grid>
                    
                    <Grid item md={4} style={{ marginTop: '5px' }}>
                        <InputDateTime {...{
                            label: "Data de l'esdeveniment",
                            onChange: (e) => { state.eventDate = e.target.value
                                               setState({...state}) },
                            value: state?.eventDate || null,
                            name: "eventDate"
                        }} />
                    </Grid>
                    <Grid item md={8}></Grid>

                    <Grid item md={12}>

                        <EditorTextWeb
                            style={{ height: 250 }}
                            options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'history']}
                            content={state.text}
                            setContent={(e) => {
                                state.text = e
                                setState({ ...state })
                            }}
                        />

                    </Grid>
                    <Grid item md={12}>
                        <Grid item md={4}>
                            <div style={{ textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto' }}>
                                <img alt="" src={
                                    state?.file?.[0]?.base64 ??
                                    state?.urlFile
                                } style={{ width: '100%', objectFit: 'cover' }} />
                            </div>
                        </Grid>
                        <Grid item md={4}>
                            <ButtonFile
                                label="+ Imatge"
                                required
                                name="file"
                                typeButton="secondary"
                                multiple={false}
                                onChange={handleChangePhotoFile}
                                accept="image/png, image/jpg, image/jpeg, image/gif"
                            />
                        </Grid>
                    </Grid>

                    <Grid item md={4} style={{ marginTop: '15px' }}>
                        {(state?.file.length > 0 || state?.urlFile) &&
                            <InputButtonLoading
                                onClick={() => handleOpenModalFile()}
                                loading={false}
                                label={(
                                    <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar imatge</>
                                )}
                                position="left"
                            />
                        }
                    </Grid>

                    <Grid item md={4} style={{ marginTop: '15px' }}>
                        <InputText
                            label={"SEO: Títol de la imatge"}
                            name="imgSeoTitle"
                            disabled={loadingSave}
                            value={state?.imgSeoTitle}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item md={4} style={{ marginTop: '15px' }}>
                        <InputText
                            label={"SEO: Atribut alt de la imatge"}
                            name="imgSeoAttribute"
                            disabled={loadingSave}
                            value={state?.imgSeoAttribute}
                            onChange={handleChange}
                        />
                    </Grid>


                </Grid>

                <Grid item md={12}></Grid>

                <Grid item md={12} />

                <Grid item md={4}>
                    <InputText
                        label={"Text Botó Vermell"}
                        name="btnRedName"
                        disabled={loadingSave}
                        value={state?.btnRedName}
                        onChange={handleChange}

                    />
                </Grid>

                <Grid item md={4}>
                    <InputText
                        label={"Enllaç Botó Vermell"}
                        name="btnRedLink"
                        disabled={loadingSave}
                        value={state?.btnRedLink}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item md={4}>
                    <InputText
                        label={"SEO ID Botó Vermell"}
                        name="btnRedSeoId"
                        disabled={loadingSave}
                        value={state?.btnRedSeoId}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item md={12}>
                    <InputButtonLoading
                        onClick={handleSubmit}
                        loading={loadingSave}
                        label= { idActivity? "Modificar": "Crear"}
                        position="left"
                        style={{ float: 'right' }}
                    />
                </Grid>

            </Grid>
            <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile} text="Eliminar el fitxer?" />

        </>
    )
}

function initialValues(props) {

    let langSelect = localStorage.getItem('langSelect')
    
    return {
        langSelect: langSelect ? langSelect : 'ca',
        isActive: props?.isActive ? true : false || false,
        text: props?.text || "",
        file: [],
        urlFile: props?.imgUrl || null,
        imgSeoTitle: props?.imgSeoTitle || "",
        imgSeoAttribute: props?.imgSeoAttribute || "",
        htmlCode: props?.htmlCode || "",
        btnRedName: props?.btnRedName || "",
        btnRedLink: props?.btnRedLink || "",
        btnRedSeoId: props?.btnRedSeoId || "",
        eventDate: props?.eventDate || ""


    }
}

