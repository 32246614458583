import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}/screen`;

export function apiGetListBlock(screenId, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list-block/${screenId}`, config)    
}

export function apiUpdatePositionBlock(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/position-block`, props, config)
}

export function apiAddBlock(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/add-block`, props, config)
}

export function apiDeleteBlock(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.delete(`${BASE_PATH}/${lang}/${baseUrl}/delete-block/${props.id}`, config)
}

export function apiGetListWebBlocks(lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/blockscreen/get-blocks`, config)
}

export function apiGetScreenBlock(idPage, idBlock, langSelect, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/blockscreen/get-block/${idPage}/${idBlock}/${langSelect}`, config)
}


export function apiSaveBlockScreenText(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/blockscreen/save-block`, props, config)
}

export function apiSaveFileBlockScreenText(props, idBlock, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/blockscreen/save-block-file/${idBlock}`, props, config)
}

export function apiDeleteFileBlockScreenText(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/blockscreen/delete-block-file`, props, config)
}
