import * as React from 'react';
import MobileMessage from "../../../../components/User/MobileMessage";
import ButtonBlock from '../../../../components/ButtonBlock'
import logo from '../../../../assets/images/Logo_MuseuArtProhibit_Red.png'

export default function Disclaimer() {

    return (
        <>
            <MobileMessage />
            <div className='disclaimer' style={{background: 'black',
                width: '100vw',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                position: 'relative',
                zIndex: '3',
                height: '-webkit-fill-available'}}>
                <div style={{
                    background: '#FDFDFD',
                    maxWidth: '980px',
                    width: '100vw',
                    margin: 'auto',
                    position: 'relative',
                    minHeight: '100vh',
                    minHeight: '-webkit-fill-available',
                    height: 'calc(100vh - 66px)'
                }}>
                    <div className='disclaimer-container'>
                        <div>
                            <h1>Aviso</h1>
                            <p>Lorem ipsum dolor sit amet consectetur. Phasellus mattis nunc vulputate nunc viverra turpis venenatis fermentum eros. Tellus commodo lectus gravida ac dictumst molestie rhoncus amet. Viverra tellus volutpat lectus dui felis dolor ultricies. Et massa commodo fermentum est neque nam erat lacinia. Et mi eu varius consectetur urna libero tempor in. Nunc enim in sodales et sem eleifend massa orci quam. Mauris vel etiam ac et ac a viverra id facilisis.</p>
                        </div>
                        <ButtonBlock label="Aceptar" />
                    </div>
                </div>
            </div>
        </>
    )
}
