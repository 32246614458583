import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}/artwork`;

export function apiList(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.limit) { query += `&limit=${props.limit}`; }
    if (props.offset) { query += `&offset=${props.offset}`; }
    if (props.field) { query += `&field=${props.field}`; }
    if (props.sort) { query += `&sort=${props.sort}`; }
    if (props.title) { query += `&title=${props.title}`; }
    if (props.lang) { query += `&lang=${props.lang}`; }
    if (props.addPhoto) { query += `&addPhoto=${props.addPhoto}`; }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list?1=1${query}`, config)
}

export function apiSave(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save`, props, config)
}

export function apiGet(id, langSelect, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/${id}?langSelect=${langSelect}`, config)
}

export function apiSaveIncident(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-incidents`, props, config)
}

export function apiGetIncident(props, langSelect, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/fields-incidents/${props.id}?langSelect=${langSelect}&langControl=${props.langControl ?? false}`, config)
}

export function apiSaveInsult(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-insults`, props, config)
}

export function apiGetInsult(props, langSelect, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/fields-insults/${props.id}?langSelect=${langSelect}&langControl=${props.langControl ?? false}`, config)
}

export function apiSaveQuote(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-quotes`, props, config)
}

export function apiGetQuote(props, langSelect, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/fields-quotes/${props.id}?langSelect=${langSelect}&langControl=${props.langControl ?? false}`, config)
}

export function apiSaveCredits(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-credits`, props, config)
}

export function apiGetCredits(props, langSelect, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/fields-credits/${props.id}?langSelect=${langSelect}&langControl=${props.langControl ?? false}`, config)
}

export function apiSaveDocumentation(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-documentation`, props, config)
}

export function apiGetDocumentation(props, langSelect, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/fields-documentation/${props.id}?langSelect=${langSelect}&langControl=${props.langControl ?? false}`, config)
}

export function apiSaveArticle(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-article`, props, config)
}

export function apiSaveFileArticle(props, id, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-article-file/${id}`, props, config)
}

export function apiGetArticle(props, langSelect, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/fields-article/${props.id}?langSelect=${langSelect}&langControl=${props.langControl ?? false}`, config)
}

export function apiSaveVideo(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-video`, props, config)
}

export function apiSaveFileVideo(props, id, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-video-file/${id}`, props, config)
}

export function apiGetVideo(props, langSelect, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/fields-video/${props.id}?langSelect=${langSelect}&langControl=${props.langControl ?? false}`, config)
}

export function apiSavePhoto(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-photo`, props, config)
}

export function apiSaveFilePhoto(props, id, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-photo-file/${id}`, props, config)
}

export function apiGetPhoto(props, langSelect, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/fields-photo/${props.id}?langSelect=${langSelect}&langControl=${props.langControl ?? false}`, config)
}

export function apiIsActive(id, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/is-active/${id}`, config)
}