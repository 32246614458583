import {TOKEN} from "../../utils/constants"
import SecureLS from "secure-ls";
import jwtDecode from "jwt-decode";
import moment from "moment";

function LocalStorage() {
    if (typeof window !== 'undefined') {
        return new SecureLS({'encodingType': 'aes'});
    } else {
        return null;
    }
}

export function setToken(token) {

    let ls = LocalStorage();
    ls?.set(TOKEN, token);

}

export function getToken() {
    try {
        let ls = LocalStorage()
        return ls?.get(TOKEN)
    } catch (error) {
        removeToken()
        return null
    }

}

export function removeToken() {

    let ls = LocalStorage()
    ls?.remove(TOKEN)

}

export function hasExpiredToken() {
    const auth = getToken()
    const currentDate = new Date().getTime()

    let expireDate = null
    let expired = false
    if (auth?.primaryUser?.token) {
        expireDate = jwtDecode(auth?.primaryUser?.token).exp * 1000
        if (currentDate > expireDate) {
            expired = true
        }
    }

    if (auth?.secondaryUser?.token) {
        expireDate = jwtDecode(auth?.secondaryUser?.token).exp * 1000
        if (currentDate > expireDate) {
            expired = true
        }

    }

    let expirateDateUser = jwtDecode(auth?.primaryUser?.token).expUser

    // Fecha actual
    let currentDate2 = moment().format("DD/MM/YYYY")
    // Fecha de expiración
    let expirateDateUser2 = moment.unix(expirateDateUser).format("DD/MM/YYYY")
    // Si es el mismo dia

    if (auth.primaryUser?.role === "ROLE_USER") {
        if (
            moment(currentDate2).isAfter(expirateDateUser2) ||
            moment(currentDate2).isSame(expirateDateUser2)
        ) {
            expired = true
        }
    }


    return expired;
}
