import React, { useRef, useState, useEffect } from "react";
import MobileMessage from "../../../../components/User/MobileMessage";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useNavigate } from "react-router-dom";
import {RouteUser} from "../../../../routes/RouteUser";
import {getArtworkByRoom} from "../../../../services/User/story";
import CloseIcon from '../../../../components/Icons/CloseIcon';
import KeyboardArrowLeftIcon from '../../../../components/Icons/KeyboardArrowLeftIcon';
import KeyboardArrowRightIcon from '../../../../components/Icons/KeyboardArrowRightIcon';
import LoadingSpinnerBig from "../../../../components/LoadingSpinnerBig";
import MsgError from "../../../../components/MsgError";
import TopBar from "../../../../components/Layouts/User/Header/TopBar";

export default function MapaResults() {

  const {planta} = useParams()
  const {t} = useTranslation()
  const [state, setState] = useState(initialValues)
  const {salaId} = useParams()
  const navigate = useNavigate();
  const [roomPrev, setRoomPrev] = useState(null)
  const [roomNext, setRoomNext] = useState(null)
  const [roomPrevText, setRoomPrevText] = useState(null)
  const [roomNextText, setRoomNextText] = useState(null)
  const [loading, setLoading] = useState(false)
  const [msgError, setMsgError] = useState([])

  useEffect(() => {
    // Corregir el bug del 100vh en móvil
    var elementoMapa = document.querySelector('.mapa-results-element');
    elementoMapa.style.height = window.innerHeight + 'px';

    getArtworks()
  }, []);

  const getArtworks = () => {
    setLoading(true)
    getArtworkByRoom(salaId).then(resp => {
      
      setState({
        ...state,
        artworks: resp.data?.data
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      setMsgError(err.response.data?.msg)
    })
  }

  useEffect(() => {
    setRoomPrevText(null)
    setRoomNextText(null)
    if(state.artworks.room == 20){
      setRoomPrevText(null)
      setRoomNextText(`${t('map.room')} 0`)
      setRoomPrev(0)
      setRoomNext(21)
    }else if(state.artworks.room == 21){
      setRoomPrevText(t('map.entrance'))
      setRoomNextText(`${t('map.room')} 1`)
      setRoomPrev(20)
      setRoomNext(1)
    }else if(state.artworks.room == 17){
      const roomPrevTemp = parseInt(state.artworks.room, 10) - 1;
      const roomNextTemp = parseInt(state.artworks.room, 10) + 1;
      setRoomPrev(roomPrevTemp)
      setRoomNext(19)
      setRoomNextText(t('map.terrace'))
    }else if(state.artworks.room == 19){
      const roomPrevTemp = parseInt(state.artworks.room, 10) - 1;
      const roomNextTemp = parseInt(state.artworks.room, 10) + 1;
      setRoomPrev(17)
      setRoomNext(roomNextTemp)
    }else if(state.artworks.room == 1){
      const roomNextTemp = parseInt(state.artworks.room, 10) + 1;
      setRoomPrev(21)
      setRoomNext(roomNextTemp)
      setRoomPrevText(`${t('map.room')} 0`)
    }else if(state.artworks.room == 13){
      const roomPrevTemp = parseInt(state.artworks.room, 10) - 1;
      const roomNextTemp = parseInt(state.artworks.room, 10) + 2;
      setRoomPrev(roomPrevTemp)
      setRoomNext(roomNextTemp)
    }else if(state.artworks.room == 15){
      const roomPrevTemp = parseInt(state.artworks.room, 10) - 2;
      const roomNextTemp = parseInt(state.artworks.room, 10) + 1;
      setRoomPrev(roomPrevTemp)
      setRoomNext(roomNextTemp)
    }else{
      const roomPrevTemp = parseInt(state.artworks.room, 10) - 1;
      const roomNextTemp = parseInt(state.artworks.room, 10) + 1;
      setRoomPrev(roomPrevTemp)
      setRoomNext(roomNextTemp)
    }

  }, [state]);

  const handleClickArtwork = (storyId) => {
    navigate(`${RouteUser.STORIES.path}/${storyId}`);
  }

  const handleClickSala = (salaIdNew) => {
    setLoading(true)
    navigate(`${RouteUser.MAPARESULTS.path}/${salaIdNew}/0`)
    getArtworkByRoom(salaIdNew).then(resp => {
      setState({
        ...state,
        artworks: resp.data?.data
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      setMsgError(err.response.data?.msg)
    })
  }
  
  return (
    <>
      <MobileMessage />
      <div className='mapa-results' style={{background: 'black',
        width: '100vw',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        position: 'relative',
        overflowY: 'hidden',
        zIndex: '3'}}>
        <div className='mapa-results-element' style={{
          background: '#FDFDFD',
          maxWidth: '980px',
          width: '100vw',
          height: '100%',
          margin: 'auto',
          position: 'relative',
          overflowY: 'auto',
          height: '100vh',
          minHeight: '-webkit-fill-available',
          minHeight: '-moz-available',
          minHeight: 'fill-available'
        }}>
          
          <LoadingSpinnerBig show={loading} />
          <MsgError msg={msgError}/>

          {!loading && (
            <>
              <div>
                <div className="map-navbar">
                  <div>
                      <h1>MAPA</h1>
                  </div>
                  <div>
                    <TopBar />
                  </div>
                </div>
                
                <div class="container">
                  <div class="sala-title">
                  {
                    state.artworks.room == 19 ? 
                      <h3>{t('map.terrace')}</h3>
                     : state.artworks.room == 20 ? 
                      <h3>{t('map.entrance')}</h3>
                     : state.artworks.room == 21 ? 
                      <h3>{t('map.room')} 0</h3>
                     : <h3>{t('map.room')} {state.artworks.room}</h3>
                  }
                    <a href={`${RouteUser.MAPA.path}/${planta ? parseInt(planta) : 0}`}><CloseIcon className="close-icon icon" color="#FDFDFD" /></a>
                  </div>
                  <div className="result-list">
                    {state.artworks?.artworks?.map((element, key) => (
                      <div className="results-element" onClick={() => handleClickArtwork(element.id)}>
                        <div className="results-image">
                          <img src={element.featuredImage} alt={""}/>
                        </div>
                        <div className="results-text">
                            <div className="artwork-title" dangerouslySetInnerHTML={{ __html: element.artworkTitle }} />
                            <div>
                                <p>{element.artistName}</p>
                                <p>{element.dateArtwork}</p>
                                <p>{salaId == 20 ? t('map.entrance') : salaId == 19 ? t('map.terrace') : salaId == 21 ? `${t('map.room')} 0` : `${t('map.room')} ${state.artworks.room}`}</p>
                            </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                
              </div>
              <div class="salas-select">
                {state.artworks.room == 20 ?
                  <a disabled />
                  :
                  <a onClick={() => handleClickSala(roomPrev)}><KeyboardArrowLeftIcon className='keyboard-arrow-left-icon' />{roomPrevText ? roomPrevText : `${t('map.room')} ${roomPrev}`}</a>
                }
                {state.artworks.room == 19 ?
                  <a disabled />
                  :
                  <a onClick={() => handleClickSala(roomNext)}>{roomNextText ? roomNextText : `${t('map.room')} ${roomNext}`}<KeyboardArrowRightIcon className='keyboard-arrow-right-icon' color='#F00214' /></a>
                }
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

function initialValues() {
  return {
    artworks: []
  }
}
