import UserManagement from "../pages/Admin/General/User/Management"
import UserEdit from "../pages/Admin/General/User/Fields"
import UserCreate from "../pages/Admin/General/User/Fields"
import MediaManagement from "../pages/Admin/General/Media/Management"
import Testing from "../pages/Admin/General/Testing"
import LogReport from "../pages/Admin/General/Log/LogReport"
import LogMail from "../pages/Admin/General/Log/LogMail"
import LogCronjob from "../pages/Admin/General/Log/LogCronjob"
import LogScanner from "../pages/Admin/General/Log/LogScanner"
import ArtistaFields from "../pages/Admin/General/Artista/Fields"
import ArtistaManagement from "../pages/Admin/General/Artista/Management"
import SerieManagement from "../pages/Admin/General/Serie/Management"
import SerieFields from "../pages/Admin/General/Serie/Fields"
import DocInstall from "../pages/Admin/General/Doc/DocInstall"
import StoriesManagement from "../pages/Admin/WebApp/Stories/Management"
import StoriesFields from "../pages/Admin/WebApp/Stories/Fields"
import SlideEdit from "../pages/Admin/WebApp/Stories/Fields/SlideEdit"
import OnBoardingManagement from "../pages/Admin/General/OnBoarding/Management"
import JocManagement from "../pages/Admin/WebApp/Joc/Management"
import JocScreen from "../pages/Admin/WebApp/Joc/Screen"
import ColectiuManagement from "../pages/Admin/General/Colectiu/Management"
import ColectiuFields from "../pages/Admin/General/Colectiu/Fields"
import DisplayManagement from "../pages/Admin/Display/Management"
import DocumentManagement from "../pages/Admin/Document/Management"
import DocumentEdit from "../pages/Admin/Document/Fields"

const baseTitle = "Offing - "
const urlBase = "/dash"

export const RouteAdmin = {
    USER_MANAGEMENT: {
        path: `${urlBase}/users/management`,
        menu: "user",
        menuChild: "",
        page: UserManagement,
        title: `${baseTitle}User Management`,
        roles: ["ROLE_ADMIN"]
    },
    USER_EDIT: {
        path: `${urlBase}/users/edit`,
        menu: "user",
        menuChild: "",
        params: "/:id",
        page: UserEdit,
        title: `${baseTitle}User Edit`,
        roles: ["ROLE_ADMIN"]
    },
    USER_CREATE: {
        path: `${urlBase}/users/create`,
        menu: "user",
        menuChild: "",
        page: UserCreate,
        title: `${baseTitle}User Create`,
        roles: ["ROLE_ADMIN"]
    },
    MEDIA_MANAGEMENT: {
        path: `${urlBase}/media/management`,
        menu: "media",
        menuChild: "",
        page: MediaManagement,
        title: `${baseTitle}Media Management`,
        roles: ["ROLE_ADMIN"]
    },
    TESTING: {
        path: `${urlBase}/testing`,
        menu: "test",
        menuChild: "",
        page: Testing,
        title: `${baseTitle}testing`,
        roles: ["ROLE_ADMIN"]
    },
    LOG_REPORT: {
        path: `${urlBase}/logs/report`,
        menu: "log",
        menuChild: "report",
        page: LogReport,
        title: `${baseTitle}Log Report`,
        roles: ["ROLE_ADMIN"]
    },
    LOG_MAIL: {
        path: `${urlBase}/logs/mail`,
        menu: "log",
        menuChild: "mail",
        page: LogMail,
        title: `${baseTitle}Log Mail`,
        roles: ["ROLE_ADMIN"]
    },
    LOG_CRONJOB: {
        path: `${urlBase}/logs/cronjob`,
        menu: "log",
        menuChild: "cronjob",
        page: LogCronjob,
        title: `${baseTitle}Log Cronjob`,
        roles: ["ROLE_ADMIN"]
    },
    LOG_SCANNER: {
        path: `${urlBase}/logs/scanner`,
        menu: "log",
        menuChild: "scanner",
        page: LogScanner,
        title: `${baseTitle}Log Scanner`,
        roles: ["ROLE_ADMIN"]
    },
    ARTISTA_CREATE: {
        path: `${urlBase}/artista/create`,
        menu: "artistes",
        menuChild: "artistes",
        page: ArtistaFields,
        title: `${baseTitle}artista create`,
        roles: ["ROLE_ADMIN"]
    },
    ARTISTA_EDIT: {
        path: `${urlBase}/artista/edit`,
        menu: "artistes",
        params: "/:id",
        menuChild: "artistes",
        page: ArtistaFields,
        title: `${baseTitle}artista create`,
        roles: ["ROLE_ADMIN"]
    },
    ARTISTA_MANAGEMENT: {
        path: `${urlBase}/artista/management`,
        menu: "artistes",
        menuChild: "artistes",
        page: ArtistaManagement,
        title: `${baseTitle}artista management`,
        roles: ["ROLE_ADMIN"]
    },
    SERIE_MANAGEMENT: {
        path: `${urlBase}/series/management`,
        menu: "obres",
        menuChild: "series",
        page: SerieManagement,
        title: `${baseTitle}series management`,
        roles: ["ROLE_ADMIN"]
    },
    SERIE_EDIT: {
        path: `${urlBase}/serie/edit`,
        params: "/:id",
        menu: "obres",
        menuChild: "series",
        page: SerieFields,
        title: `${baseTitle}serie edit`,
        roles: ["ROLE_ADMIN"]
    },
    SERIE_CREATE: {
        path: `${urlBase}/serie/create`,
        menu: "obres",
        menuChild: "series",
        page: SerieFields,
        title: `${baseTitle}serie create`,
        roles: ["ROLE_ADMIN"]
    },
    COLECTIU_MANAGEMENT: {
        path: `${urlBase}/colectiu/management`,
        menu: "artistes",
        menuChild: "colectius",
        page: ColectiuManagement,
        title: `${baseTitle}colectiu management`,
        roles: ["ROLE_ADMIN"]
    },
    COLECTIU_CREATE: {
        path: `${urlBase}/colectiu/create`,
        menu: "artistes",
        menuChild: "colectius",
        page: ColectiuFields,
        title: `${baseTitle}colectiu create`,
        roles: ["ROLE_ADMIN"]
    },
    COLECTIU_EDIT: {
        path: `${urlBase}/colectiu/edit`,
        menu: "artistes",
        params: "/:id",
        menuChild: "colectius",
        page: ColectiuFields,
        title: `${baseTitle}colectiu edit`,
        roles: ["ROLE_ADMIN"]
    },
    DOC_INSTALL: {
        path: `${urlBase}/doc/install`,
        menu: "doc",
        menuChild: "install",
        page: DocInstall,
        title: `${baseTitle}Doc Install`,
        roles: ["ROLE_ADMIN"]
    },
    STORIES_MANAGEMENT: {
        path: `${urlBase}/stories/management`,
        menu: "webapp",
        menuChild: "stories",
        page: StoriesManagement,
        title: `${baseTitle}stories management`,
        roles: ["ROLE_ADMIN"]
    },
    STORIES_CREATE: {
        path: `${urlBase}/stories/create`,
        menu: "webapp",
        menuChild: "stories",
        page: StoriesFields,
        title: `${baseTitle}stories create`,
        roles: ["ROLE_ADMIN"]
    },
    STORIES_EDIT: {
        path: `${urlBase}/stories/edit`,
        menu: "webapp",
        params: "/:id",
        menuChild: "stories",
        page: StoriesFields,
        title: `${baseTitle}stories edit`,
        roles: ["ROLE_ADMIN"]
    },
    SLIDE_EDIT: {
        path: `${urlBase}/stories/edit/slide`,
        menu: "webapp",
        params: "/:idStory/:idSlide",
        menuChild: "stories",
        page: SlideEdit,
        title: `${baseTitle}slide edit`,
        roles: ["ROLE_ADMIN"]
    },
    ONBOARDING_MANAGEMENT: {
        path: `${urlBase}/onboarding/management`,
        menu: "webapp",
        menuChild: "onboarding",
        page: OnBoardingManagement,
        title: `${baseTitle}onboarding management`,
        roles: ["ROLE_ADMIN"]
    },
    JOC_MANAGEMENT: {
        path: `${urlBase}/joc/management`,
        menu: "webapp",
        menuChild: "joc",
        page: JocManagement,
        title: `${baseTitle}joc management`,
        roles: ["ROLE_ADMIN"]
    },
    JOC_SCREEN: {
        path: `${urlBase}/joc-screen`,
        menu: "webapp",
        menuChild: "joc-screen",
        page: JocScreen,
        title: `${baseTitle}joc screen`,
        roles: ["ROLE_ADMIN"]
    },
    DISPLAY_MANAGEMENT: {
        path: `${urlBase}/display/management`,
        menu: "display",
        menuChild: "",
        page: DisplayManagement,
        title: `${baseTitle}Display management`,
        roles: ["ROLE_ADMIN"]
    },
    DOCUMENT_MANAGEMENT: {
        path: `${urlBase}/document/management`,
        menu: "document",
        menuChild: "",
        page: DocumentManagement,
        title: `${baseTitle}Document management`,
        roles: ["ROLE_ADMIN"]
    },
    DOCUMENT_CREATE: {
        path: `${urlBase}/document/create`,
        menu: "document",
        menuChild: "",
        page: DocumentEdit,
        title: `${baseTitle}Document create`,
        roles: ["ROLE_ADMIN"]
    },
    DOCUMENT_EDIT: {
        path: `${urlBase}/document/edit`,
        menu: "document",
        params: "/:id",
        menuChild: "",
        page: DocumentEdit,
        title: `${baseTitle}Document edit`,
        roles: ["ROLE_ADMIN"]
    },
}
