import React, {useEffect} from "react";
import {apiList, apiListSlugs} from "../../../services/User/menu";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {RouteUser} from "../../../routes/RouteUser";
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import Logo from "../../../assets/images/Logo_MuseuArtProhibit_Red2.png"
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function Basic(props) {

    const [menu, setMenu] = React.useState([])
    const [slugs, setSlugs] = React.useState([])
    const {i18n} = useTranslation()
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate()

    // El Footer y el menu deberian ser fijos y solo cargarse cuando cambie el idioma, por eso lo pongo aki
    // getSlugsPage nos devuelve la url actual con los slugs en su idioma, por si cambia de idioma, cargar la pagina equivalente

    useEffect(() => {
        setLoading(true)
        getMenu()
        getSlugsPage()
    }, [])

    const getMenu = () => {
        apiList({}, i18n.resolvedLanguage).then(resp => {
            setMenu(resp.data)
        }).catch(err => {
            setLoading(false)
        })
    }

    const getSlugsPage = () => {
        apiListSlugs({path: window.location.pathname}, i18n.resolvedLanguage).then(resp => {
            setSlugs(resp.data)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    const changeLanguage = (event) => {
        void i18n.changeLanguage(event.target.value)
        if(slugs[event.target.value])
        {
            window.location.href=window.location.origin+"/"+slugs[event.target.value]
        }
        else{ // Deberia ir a 404 pagina no encontrada, por ahora va home
            
            // window.location.href=window.location.origin+"/"+event.target.value // HOME
            navigate(RouteUser.NOTFOUNDPAGE.path) // 404
        }
    }

    const openSubmenu = (id) => {

        closeOpenSubMenus();

        let submenu = document.querySelector('#submenu-' + id)
        let linkDes = document.querySelector("#menuDes-" + id)
        let linkMov = document.querySelector("#menuMov-" + id)
        let closeIcon = document.querySelector('#closeIcon')

        if (linkDes) {
            linkDes.classList.add("link-active")
        }
        if (linkMov) {
            linkMov.classList.add("link-active")
        }
        if (submenu) {
            submenu.classList.add("open")
        }

        if (closeIcon) {
            closeIcon.classList.remove("d-none")
        }


    }

    const closeOpenSubMenus = () => {

        let submenus = document.querySelectorAll('.submenu.open')
        let linkActive = document.querySelectorAll('.link-active')
        let closeIcon = document.querySelector('#closeIcon')

        if (submenus) {
            submenus.forEach(element => {
                element.classList.remove("open")
            });
        }

        if (linkActive) {
            linkActive.forEach(element => {
                element.classList.remove("link-active")
            });
        }

        if (closeIcon) {
            closeIcon.classList.add("d-none")
        }

    }

    return (
        <>
            <Grid container spacing={0.5} md={12} className="navbar-web"
                  style={{fontFamily: 'SohneKraftig', position: 'fixed', backgroundColor: '#fff', marginLeft:'0px', zIndex: 1}}>
                <Grid container md={12}
                      direction="row"
                      wrap="nowrap"
                      justifyContent="space-between" className="menu-container">

                    <Grid item md={5} style={{display: "flex", justifyContent: "start", alignItems: "center"}} className="left-block">
                        <Item style={{width: '100%'}}>
                            <div className="menu linkDesktop">
                                {Object.keys(menu).map((key) => {
                                        const menuItem = menu[key];
                                        const submenuLength = menuItem.submenu ? Object.keys(menuItem.submenu).length : 0;

                                        return (
                                            <>
                                                {submenuLength === 0 ? (
                                                    // SE TIENE QUE PULIR, PARA EVITAR QUE SE RECARGUE LA PAGINA
                                                    <a href={menuItem.link} style={{}} className="menu-web-links">
                                                        {menuItem.name}
                                                    </a>
                                                ) : (
                                                    <span id={`menuDes-${menuItem.id}`}
                                                          style={{textDecoration: 'none'}} className="menu-web-links"
                                                          onClick={() => {
                                                              openSubmenu(menuItem.id)
                                                          }}>
                                                {menuItem.name}
                                            </span>
                                                )}
                                            </>
                                        )
                                    }
                                )}


                            </div>
                            <Select className="langSelectorMovile" style={{border: 'none'}} 
                                    value={i18n.resolvedLanguage}
                                    label="Lang"
                                    onChange={changeLanguage}
                                    sx={{
                                        fontFamily: 'SohneKraftig',
                                        "& fieldset": {
                                            border: "none",
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },

                                    }}
                            >
                                {Object.keys(slugs).map((key, index) => {
                                    return (
                                        <MenuItem value={key} key={key}><span style={{textTransform: 'uppercase'}} className="lang-item">{key}</span></MenuItem>
                                    )
                                })}
                            </Select>

                        </Item>
                    </Grid>
                    <Grid item md={2} style={{display: "flex", justifyContent: "center", alignItems: "center"}} className="logo-container">
                        <a href={window.location.origin + "/" + i18n.resolvedLanguage} className="logo-link">
                            <img src={Logo} alt="Logo Museu de l'Art Phohibit" className="logo-image" />
                        </a>
                    </Grid>
                    <Grid item md={5} style={{display: "flex", justifyContent: "end", alignItems: "center"}} className="right-block">
                        <Item className="langSelectorDesktop">
                            <Select style={{border: 'none'}}
                                    value={i18n.resolvedLanguage}
                                    label="Lang"
                                    onChange={changeLanguage}
                                    sx={{
                                        fontFamily: 'SohneKraftig',
                                        "& fieldset": {
                                            border: "none",
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },

                                    }}
                            >
                                {Object.keys(slugs).map((key, index) => {
                                    return (
                                        <MenuItem value={key} key={key}><span style={{textTransform: 'uppercase'}} className="lang-item">{key}</span></MenuItem>
                                    )
                                })}
                            </Select>

                        </Item>
                        <Item style={{display: "inline-block", marginLeft: "10px"}} className="tickets-container">
                            {i18n.resolvedLanguage == 'ca' && (<>
                                <a href="https://tickets.museuartprohibit.org/ca/" id="tck_header" className="" style={{
                                    fontFamily: "SohneBuch",
                                    backgroundColor: "#F00",
                                    color: 'white',
                                    textDecoration: "none",
                                    textAlign: "center",
                                    borderRadius: "80.5px"
                                }}>ENTRADES</a></>)}
                            {i18n.resolvedLanguage == 'es' && (<>
                                <a href="https://tickets.museuartprohibit.org/es/" id="tck_header" className="" style={{
                                    fontFamily: "SohneBuch",
                                    backgroundColor: "#F00",
                                    color: 'white',
                                    textDecoration: "none",
                                    textAlign: "center",
                                    borderRadius: "80.5px"
                                }}>ENTRADAS</a></>)}
                            {i18n.resolvedLanguage == 'en' && (<>
                                <a href="https://tickets.museuartprohibit.org/en/" id="tck_header" className="" style={{
                                    fontFamily: "SohneBuch",
                                    backgroundColor: "#F00",
                                    color: 'white',
                                    textDecoration: "none",
                                    textAlign: "center",
                                    borderRadius: "80.5px"
                                }}>TICKETS</a></>)}

                        </Item>
                    </Grid>
                </Grid>
                <Grid item md={12} style={{width: '100%', padding: '0px'}}>
                    <div className="menu linkMovile">
                        {Object.keys(menu).map((key) => {
                                const menuItem = menu[key];
                                const submenuLength = menuItem.submenu ? Object.keys(menuItem.submenu).length : 0;

                                return (
                                    <>
                                        {submenuLength === 0 ? (
                                            // SE TIENE QUE PULIR, PARA EVITAR QUE SE RECARGUE LA PAGINA
                                            <a className="menu-web-links" href={menuItem.link} style={{}}>
                                                {menuItem.name}
                                            </a>
                                        ) : (
                                            <span className="menu-web-links" id={`menuMov-${menuItem.id}`}
                                                  style={{textDecoration: 'none'}} onClick={() => {
                                                openSubmenu(menuItem.id)
                                            }}>
                                                {menuItem.name}
                                            </span>
                                        )}
                                    </>
                                )
                            }
                        )}


                    </div>
                </Grid>
                <Grid item md={12} className="cajaSubMenu">
                    {Object.keys(menu).map((key) => {
                            const menuItem = menu[key];
                            const submenuLength = menuItem.submenu ? Object.keys(menuItem.submenu).length : 0;

                            return (
                                <>
                                    {(submenuLength > 0 && menuItem.submenu) && (
                                        <div id={`submenu-${menuItem.id}`} className="submenu">
                                            {Object.keys(menuItem.submenu).map((submenuKey) => {
                                                const submenuItem = menuItem.submenu[submenuKey];
                                                return (
                                                    <span key={submenuKey}>
                                                        <a href={submenuItem.link} style={{
                                                            color: '#000',
                                                            textDecoration: 'none'
                                                        }}>
                                                            {submenuItem.name}
                                                        </a>
                                                    </span>
                                                );
                                            })
                                            }

                                        </div>
                                    )}
                                </>
                            )
                        }
                    )}
                    <div id="closeIcon" className="closeIcon d-none">
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={closeOpenSubMenus}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </Grid>
            </Grid>

            {!loading && (
                <>
                    {props.children}
                </>
            )}
        </>
    );
}
