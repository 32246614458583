import React, {useState, useEffect} from "react";
import {convertFromRaw} from 'draft-js';
import {EditorState} from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export default function ImgText({item}) {

    const [state, setState] = useState(initialValues(item))
    const [plantilla, setPlantilla] = useState("plantilla-uno")
    const [number, setNumber] = useState(1)

    useEffect(() => {
        if(!state.titleColor && state.backgroundImageName){
            setPlantilla("plantilla-uno")
            setNumber(1)
        }else if(!state.titleColor && !state.backgroundImageName){
            setPlantilla("plantilla-dos")
            setNumber(2)
        }else if(state.titleColor && !state.imgPortada){
            setPlantilla("plantilla-tres")
            setNumber(3)
        }else if(state.titleColor && state.imgPortada){
            setPlantilla("plantilla-cuatro")
            setNumber(4)
        }
    }, [])

    return (
        <div className="image-text-block" style={(number === 1 || number === 2) ? { width: 'calc(100vw - 20px)' } : {}}>
            <div className={plantilla}>
                <div className="image-title-container">
                    <div className="image-container">
                        <img src={state?.fileBackgroundImage} title={state.itemOne} alt={state.itemTwo}/>
                    </div>
                    {state?.backgroundImageName && number == 1 && <p className="title">{state?.backgroundImageName}</p>}
                </div>
                {state?.btnWhiteName && number != 1 && <p className="title">{state?.btnWhiteName}</p>}
                {state?.text && number != 4 && <div className="photo-footer dynamic-html-text" dangerouslySetInnerHTML={{__html: state?.text}} />}
                {state?.btnRedLink && <a href={state?.btnRedLink} id={state?.itemThree} className="block-link">{state?.btnRedName}</a>}
            </div>
        </div>
    )
}

function initialValues(props) {

    let text;
    if(props?.text){
        text=EditorState.createWithContent(convertFromRaw(JSON.parse(props?.text))).getCurrentContent()
    }else{
        text=EditorState.createEmpty().getCurrentContent()
    }

    const rawContentState = convertToRaw(text);
    let markup = draftToHtml(rawContentState);

    markup=markup.replace(/<p><\/p>/gi, "<br/>");

    return {
        "fileBackgroundImage": props?.fileBackgroundImage,
        "titleColor": props?.titleColor,
        "backgroundImageName": props?.backgroundImageName,
        "text": markup,
        "btnWhiteName": props?.btnWhiteName,
        "btnRedName": props?.btnRedName,
        "btnRedLink": props?.btnRedLink,
        "imgPortada": props?.imgPortada,
        "itemOne": props?.itemOne,
        "itemTwo": props?.itemTwo,
        "itemThree": props?.itemThree,
    }
}

