import React, { useRef, useState, useEffect } from "react";
import KeyboardArrowDownIcon from './Icons/KeyboardArrowDownIcon';
import HeadphonesIcon from './Icons/HeadphonesIcon';
import AudioPlayer from './PlayerAudio';
import {useTranslation} from "react-i18next";

export default function SlideFirstCover(props) {

    const {t} = useTranslation()
    const [height, setHeight] = useState(0)
    const [showAudioIcon, setShowAudioIcon] = useState(false);
    const ref = useRef(null)
    const {
        featuredImage,
        audio,
        audioIsActive,
        colorText,
        artistName,
        artworkTitle,
        artworkDate,
        handleClick,
        segundoBloqueEmpty,
        handleClickToScroll,
        index,
        handleButtonClickStop,
        colorTextArtistDinamic
    } = props

    useEffect(() => {
        setHeight(ref.current.clientHeight)
        
        if(audio && audioIsActive){
            setShowAudioIcon(true);
        }
    })

    const style = {
        width: '100%', height: window.innerHeight, position: 'relative'
    }
    
    const [openAudio, setOpenAudio] = React.useState(false);

    const handleOpenAudio = () => {
        setOpenAudio(true);
    };

    const handleCloseAudio = () => {
        setOpenAudio(false);
    };

    const ShowAudio = () => (    
        <AudioPlayer url={audio} handleButtonClickStopComponent={handleButtonClickStopComponent} closePlayer={handleCloseAudio} style={{position: 'absolute', bottom: '0px', left: '0px', right: '0', margin: 'auto', width: '100%'}} />
    )

    const handleButtonClickStopComponent = (event) => {
        event.stopPropagation(); // Evita que el evento se propague al div padre
        // Código para manejar el clic en el botón derecho
    };
    function handleOpenAudioAndStopComponent(event) {
        handleOpenAudio();
        handleButtonClickStopComponent(event);
    }

    return(
        <div className="outstanding-image-block cover-story-block primer-bloque" style={style} onClick={handleClick}>
            <div style={{width: '100%', height: `calc(100% - ${height}px)`, position: 'relative'}}>
                <div style={{width: '100%', height: '100%'}}>
                    <img src={featuredImage} />
                </div>
                { openAudio ? null : (
                    <div style={{position: 'absolute', bottom: '22px', left: '20px'}}>
                        {showAudioIcon &&
                            <button onClick={handleOpenAudioAndStopComponent} className="audio-button" style={{backgroundColor: '#ffffff', borderRadius: '100%', display: 'flex', border: 'none'}}>
                                <HeadphonesIcon className="headphones-icon icon" color="#E30613" />
                            </button>
                        }
                    </div>
                )}
                { openAudio ? <ShowAudio /> : null }
            </div>
            <div className="title-container" style={{backgroundColor: '#FFFFFF', maxWidth: '100%', position: 'absolute', bottom: '0', width: 'calc(100% - 48px)'}} ref={ref}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between'
                }}>
                    <div style={{color: '#141414', margin: '0px'}} dangerouslySetInnerHTML={{ __html: artworkTitle }} className="story-cover-title" />
                </div>
                <p style={{fontFamily: 'SohneBuch', color: '#141414', margin: '0px'}}>{artworkDate}</p>
                {!segundoBloqueEmpty && 
                    <div onClick={(event) => {handleClickToScroll(index);handleButtonClickStop(event);}} className="deslizar-text" style={{fontFamily: 'SohneBuch', color: '#AFAFAF', margin: '0px', display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'nowrap'}}><KeyboardArrowDownIcon className="keyboard-arrow-down-icon icon" color="#AFAFAF" /> {t('story.scroll_info')}</div>
                }
            </div>
        </div>
    )

    
}

