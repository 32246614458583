import React, {useEffect, useRef, useState} from "react";
import {Grid, TextField} from "@mui/material";
import Header from "./Header";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import ButtonFile from "../../../../../components/Admin/Inputs/ButtonFile";
import {useNavigate, useParams} from "react-router-dom";
import clone from "../../../../../utils/clone";
import {apiSavePhoto, apiGetPhoto, apiSaveFilePhoto} from "../../../../../services/Admin/obra";
import {apiDeletePhoto, apiDeletePhotoFile} from "../../../../../services/Admin/obraDelete";
import {LoadingButton} from "@mui/lab";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import {MdDeleteForever} from "react-icons/md";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";
import ModalConfirm from "../../../../../components/Admin/ModalConfirm";
import EditorText from "../../../../../components/EditorText";
import TitlePage from "../../../../../components/Admin/TitlePage";

export default function TabFotos(props) {
    const {id} = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(false)
    const myRef = useRef(null)
    const [openModal, setOpenModal] = React.useState(false)
    const [pendingIndexRemove, setPendingIndexRemove] = React.useState(null)
    const [openModalFile, setOpenModalFile] = React.useState(false)
    const [pendingIndexRemoveFile, setPendingIndexRemoveFile] = React.useState(null)

    const handleOpenModal = (key) => {
        setOpenModal(true)
        setPendingIndexRemove(key)
    }

    const handleOpenModalFile = (key) => {
        setOpenModalFile(true)
        setPendingIndexRemoveFile(key)
    }

    useEffect(() => {
        getData()
    }, [state.langSelect])

    const getData = () => {
        setLoadingGetInfo(true)

        apiGetPhoto({id}, state?.langSelect).then(resp => {
            let photosNew = resp.data.photos?.map(doc => {
                return {
                    ...doc
                }
            })

            setState({...state, photos: photosNew})
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data.msg)
        })
    }

    const handleChange = key => (e) => {
        state.photos[key][e.target.name] = e.target.value
        setState({...state})
    }

    const handleChangeFile = key => (event) => {
        state.photos[key][event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = index => async () => {
        stateUpdateSimple(index, true, [], [])

        let error = validationSchema(state)
        if (error.length !== 0) {
            stateUpdateSimple(index, false, error, [])
            return
        }

        let photos = state.photos[index]
        photos.langSelect = state.langSelect

        await apiSavePhoto(photos).then(resp => {
            let idPhotos = resp.data?.id
            // Actualizamos el id en caso de creación
            state.photos[index].id = idPhotos
            setState({...state})
            let successMessage = resp.data?.msg

            // Enviamos para guardar la imagen
            let trans = state.photos[index]
            // Comprobamos si existe file
            let fileBinary = trans?.file.length > 0 ? trans?.file[0].binary : null
            let fileName = trans?.file.length > 0 ? trans?.file[0].name : null

            handleSubmitFile(index, idPhotos, state.langSelect, fileBinary, fileName, successMessage)

            state.photos[index].id = resp.data?.id
            setState({...state})

        }).catch(err => {
            stateUpdateSimple(index, false, err.response?.data?.msg, [])
        })
    }

    const stateUpdateSimple = (index, loading, msgError, msgSuccess) => {
        state.photos[index].loading = loading
        state.photos[index].msgError = msgError
        state.photos[index].msgSuccess = msgSuccess

        setState({...state})
    }

    const stateDeleteSimple = (index, loadingDelete, msgError, msgSuccess) => {
        state.photos[index].loadingDelete = loadingDelete
        state.photos[index].msgError = msgError
        state.photos[index].msgSuccess = msgSuccess

        setState({...state})
    }

    const stateDeleteFileSimple = (index, loadingDeleteFile, msgError, msgSuccess) => {
        state.photos[index].loadingDeleteFile = loadingDeleteFile
        state.photos[index].msgError = msgError
        state.photos[index].msgSuccess = msgSuccess

        setState({...state})
    }

    const handleSubmitFile = (index, idTemp, langCode, fileBinary, fileName, successMessage) => {
        if (idTemp && fileBinary && fileName) {

            let f = new FormData()
            f.append(`file[]`, fileBinary)
            f.append(`originalName`, fileName)
            f.append(`langCode`, langCode)

            apiSaveFilePhoto(f, idTemp).then(resp => {
                state.photos[index].photo = resp.data?.data?.photo
                state.photos[index].photoOriginalName = resp.data?.data?.photoOriginalName
                state.photos[index].file = []
                state.photos[index].photoUrl = resp.data?.data?.photoUrl
                setState({...state})
                stateUpdateSimple(index, false, [], resp.data.msg)
            }).catch(err => {
                stateUpdateSimple(index, false, err.response?.data?.msg, [])
            })
        }else{
            stateUpdateSimple(index, false, [], successMessage)
        }
    }


    const addPhoto = () => {
        let model = state.model
        model.artworkId = id
        setState({...state, photos: [...state.photos, clone(model)]})
        myRef.current.scrollIntoView()
    }

    const handleRemove = () => {
        stateDeleteSimple(pendingIndexRemove, true, [], [])
        apiDeletePhoto({
            id: state.photos[pendingIndexRemove].id
        }).then(resp => {
            window.location.reload();
            stateDeleteSimple(pendingIndexRemove, false, [], resp.data?.msg)
            setPendingIndexRemove(null)
        }).catch(err => {
            stateDeleteSimple(pendingIndexRemove, false, err.response?.data?.msg, [])
            setPendingIndexRemove(null)
        })
    }

    const handleDeleteFile = () => {
        stateDeleteFileSimple(pendingIndexRemoveFile, true, [], [])
        apiDeletePhotoFile({
            id: state.photos[pendingIndexRemoveFile].id,
            fileName: state.photos[pendingIndexRemoveFile]?.photo,
            langCode: state.langSelect
        }).then(resp => {
            window.location.reload();
            stateDeleteFileSimple(pendingIndexRemoveFile, false, [], resp.data?.msg)
            setPendingIndexRemoveFile(null)
        }).catch(err => {
            stateDeleteFileSimple(pendingIndexRemoveFile, false, err.response?.data?.msg, [])
            setPendingIndexRemoveFile(null)
        })
    }

    return (
        <>
            <Header
                state={state}
                setState={setState}
                id={id}
                tabType={3}
                msgError={msgError}
                msgSuccess={msgSuccess}
            >
                <InputButton
                    style={{float: "right", margin: "5px 10px 10px 0px"}}
                    onClick={addPhoto}
                    text=" + Afegir foto"
                />
            </Header>

            <LoadingSpinner show={loadingGetInfo}/>

            <div style={{display: loadingGetInfo ? 'none' : ''}}>
                {state.photos.map((element, key) => (
                    <Grid container spacing={3} style={{padding: '0px'}} key={key}>
                        {key === 0 ? (
                            <Grid item xs={12} md={12} lg={12} style={{paddingTop: 0}}>
                                <TitlePage title={`Foto ${key + 1}`} />
                            </Grid>
                        ) : (
                            <Grid item xs={12} md={12} lg={12}>
                                <TitlePage title={`Foto ${key + 1}`} borderTop={true} />
                            </Grid>
                        )}

                        <Grid item xs={3} md={3} lg={3} style={{textAlign: 'center'}}>
                            <img
                                src={
                                    state.photos[key]?.file[0]?.base64 ??
                                    state.photos[key]?.photoUrl
                                }
                                style={{width: '100%', objectFit: 'cover'}}
                            />
                        </Grid>

                        <Grid item xs={3} md={3} lg={3} style={{textAlign: 'center'}}>
                            <span style={{wordWrap: 'break-word', overflowWrap: 'break-word'}}>{state.photos[key]?.file[0]?.name ?? state.photos[key]?.photoOriginalName}</span>
                        </Grid>

                        <Grid item xs={12} style={{padding: '0px'}}/>

                        <Grid item xs={3}>
                            <ButtonFile
                                label="+ Foto (.jpg, .png)"
                                name="file"
                                onChange={handleChangeFile(key)}
                                multiple={true}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            {state.photos[key]?.photo &&
                                <InputButtonLoading
                                    onClick={() => handleOpenModalFile(key)}
                                    loading={state.photos[key].loadingDeleteFile}
                                    label={(
                                        <><MdDeleteForever fontSize="inherit" style={{width: '20px', height: '20px', marginBottom: '-3px'}} /> Eliminar imatge</>
                                    )}
                                    position="left"
                                />
                            }
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <p style={{fontSize: '11px', fontFamily: 'AdminFontMedium', color: '#778', marginTop: '0px', paddingLeft: '15px'}}>Text peu</p>
                            <EditorText
                                style={{height: '132px'}}
                                content={state.photos[key]?.footer}
                                setContent={(newContent) => {
                                    setState((prevState) => {
                                        const photos = [...prevState.photos];
                                        photos[key] = {
                                            ...photos[key],
                                            footer: newContent
                                        };
                                        return { ...prevState, photos };
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={4}>
                            <div style={{display: 'block'}}>
                                <MsgSuccess msg={state.photos[key].msgSuccess}/>
                                <MsgError msg={state.photos[key].msgError}/>
                            </div>
                            <InputButtonLoading
                                onClick={handleSubmit(key)}
                                loading={state.photos[key].loading}
                                label={state.photos[key].id ? 'Modificar' : 'Crear'}
                                position="left"
                                style={{marginRight: '15px'}}
                            />
                            {state.photos[key].id && (
                                <InputButtonLoading
                                    onClick={() => handleOpenModal(key)}
                                    loading={state.photos[key].loadingDelete}
                                    label="Eliminar"
                                    position="left"
                                    variant="outline"
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div style={{height: 5}}></div>
                        </Grid>
                    </Grid>
                ))}
                <ModalConfirm openModal={openModal} setOpenModal={setOpenModal} handleAction={handleRemove} text="Eliminar el bloc de fotos?" />
                <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile} text="Eliminar el fitxer?" />
            </div>
            <div ref={myRef}></div>
        </>
    );
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        photos: [],
        model: {
            artworkId: null,
            id: null,
            photo: "",
            photoOriginalName: "",
            footer: "",
            file: [],
            loading: false,
            loadingDelete: false,
            loadingDeleteFile: false,
            msgSuccess: [],
            msgError: []
        },
    }
}

function validationSchema(schema) {

    let error = []

    return error
}
