import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { RouteAdmin } from "../../../../routes/RouteAdmin";
import TitlePage from "../../../../components/Admin/TitlePage";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import InputSelect from '../../../../components/Admin/Inputs/InputSelect'
import InputText from "../../../../components/Admin/Inputs/InputText";
import { MdDeleteForever } from "react-icons/md";
import ButtonFile from "../../../../components/Admin/Inputs/ButtonFile";
import InputButtonLoading from "../../../../components/Admin/Inputs/InputButtonLoading";
import { useTranslation } from "react-i18next";
import ModalConfirm from "../../../../components/Admin/ModalConfirm";
import { apiGetDisplayScreen, apiSaveDisplayScreen, apiSaveFileDisplayScreen, apiDeleteFileDisplayScreen } from "../../../../services/Admin/display";
import InputCheckbox from "../../../../components/Admin/Inputs/InputCheckbox";
import MsgError from "../../../../../src/components/MsgError";
import MsgSuccess from "../../../../../src/components/MsgSuccess";
import { lang } from "../../../../utils/lang";
import LoadingSpinner from "../../../../components/LoadingSpinner";


export default function ScreenOne() {

    let langSelectStorage = localStorage.getItem('langSelect')

    const [state, setState] = useState(initialValues())
    const [loadingSave, setLoadingSave] = useState(false)
    const [openModalFile, setOpenModalFile] = React.useState(false)
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(false)
    const [langSelect, setLangSelect] = useState(langSelectStorage ? langSelectStorage : 'ca')
    const [fileToDelete, setFileToDelete] = useState(null)
    const { t, i18n } = useTranslation()

    useEffect(() => {
        setLoadingGetInfo(true)
        getDisplayScreen()
    }, [state.langSelect])

    const getDisplayScreen = () => {

        setMsgError([])
        setMsgSuccess([])

        apiGetDisplayScreen(1, state?.langSelect, i18n.resolvedLanguage).then(resp => {
            state.baseUrl = resp.data['baseUrl']
            setState(initialValues(resp.data[0]))
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
            setState(initialValues())

        })

    }

    const handleChangeVideoFile = (event) => {
        state[event.target.name] = event.target.value
        setState({ ...state })
    }

    const handleChangeMedia = (event) => {

        state[event.target.name] = event.target.value
        setState({ ...state })
    }


    const handleOpenModalFile = (fileType) => {
        setFileToDelete(fileType)
        setOpenModalFile(true)
    }

    const handleDeleteFile = () => {
        apiDeleteFileDisplayScreen({
            screenId: state.idScreen,
            langCode: state.langSelect,
            fileType: fileToDelete
        }).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setFileToDelete(null)
            window.location.reload();
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setFileToDelete(null)
        })
    }

    const handleSubmit = () => {

        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)

        let fileVideoBinary = state?.fileVideo?.length > 0 ? state?.fileVideo[0].binary : null
        let fileMediaOneBynary = state?.fileMediaOne?.length > 0 ? state?.fileMediaOne[0].binary : null
        let fileMediaTwoBynari = state?.fileMediaTwo?.length > 0 ? state?.fileMediaTwo[0].binary : null
        let filePreciosBinary = state?.filePrecios?.length > 0 ? state?.filePrecios[0].binary : null
        let fileMediaDoubleOneBinary = state?.fileMediaDoubleOne?.length > 0 ? state?.fileMediaDoubleOne[0].binary : null
        let fileMediaDoubleTwoBinary = state?.fileMediaDoubleTwo?.length > 0 ? state?.fileMediaDoubleTwo[0].binary : null

        apiSaveDisplayScreen(state).then(resp => {

            handleSubmitFile(state.idScreen, fileVideoBinary, fileMediaOneBynary, fileMediaTwoBynari, filePreciosBinary, fileMediaDoubleOneBinary, fileMediaDoubleTwoBinary)

        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)

        })

    }


    const handleSubmitFile = (idScreen, fileVideo, fileMediaOne, fileMediaTwo, filePrecios, fileMediaDoubleOne, fileMediaDoubleTwo) => {
        let f = new FormData()
        f.append(`fileVideo[]`, fileVideo)
        f.append(`langCode`, state.langSelect)
        f.append(`fileMediaOne[]`, fileMediaOne)
        f.append(`mediaOneType`, state.mediaOneType)
        f.append(`fileMediaTwo[]`, fileMediaTwo)
        f.append(`mediaTwoType`, state.mediaTwoType)
        f.append(`filePrecios[]`, filePrecios)
        f.append(`fileMediaDoubleOne[]`, fileMediaDoubleOne)
        f.append(`mediaDoubleOneType`, state.mediaDoubleOneType)
        f.append(`fileMediaDoubleTwo[]`, fileMediaDoubleTwo)
        f.append(`mediaDoubleTwoType`, state.mediaDoubleTwoType)

        apiSaveFileDisplayScreen(f, idScreen).then(resp => {
            setLoadingSave(false)
            setMsgSuccess(resp.data?.msg)
            window.location.reload();
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingSave(false)
        })
    }


    const handleChange = (e) => {
        state[e.target.name] = e.target.value
        setState({ ...state })
    }



    return (
        <>
            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title="VW1: Edit Pantalla 1 (1280x360)" borderTop={false} borderBottom={false} />
            </Grid>
            <HeaderPage
                title="Tornar"
                urlBack={RouteAdmin.DISPLAY_MANAGEMENT.path}
            >
                <div style={{ float: 'right' }}>
                    <InputSelect
                        label="Idioma"
                        options={lang}
                        name="langSelect"
                        value={langSelect}
                        selectText="Selecciona un idioma"
                        style={{ width: 100 }}
                        onChange={(e) => {
                            setLangSelect(e.target.value)
                            state.langSelect = e.target.value
                            localStorage.setItem('langSelect', e.target.value)
                        }}
                    />
                </div>
            </HeaderPage>

            <LoadingSpinner show={loadingGetInfo} />

            <MsgError msg={msgError} />
            <MsgSuccess msg={msgSuccess} />

            <Grid container spacing={2} style={{ display: loadingGetInfo ? 'none' : '' }}>

                <Grid item md={12}><strong>MODE A:</strong> 2 pantalles juntes</Grid>
                <Grid item md={12} style={{ marginTop: '5px' }}>Video</Grid>

                <Grid item md={6}>
                    {((state?.fileVideo.length > 0 || state?.urlFileVideo.length > 0) &&
                        <div style={{ textAlign: 'center', width: 400, marginLeft: 'auto', marginRight: 'auto' }}>
                            <video controls style={{ width: '100%', objectFit: 'cover' }}>
                                <source src={
                                    state?.fileVideo.length > 0 ? state?.fileVideo[0]?.base64 : state.urlFileVideo
                                } ></source>
                            </video>
                        </div>
                    )}
                </Grid>

                <Grid item md={6} />

                <Grid item md={6}>
                    <ButtonFile
                        label="+ Video"
                        required
                        name="fileVideo"
                        typeButton="secondary"
                        multiple={false}
                        onChange={handleChangeVideoFile}
                        accept="video/mp4,video/mkv,video/x-m4v,video/*"
                    />
                </Grid>


                {(state?.fileVideo.length > 0 || state?.urlFileVideo.length > 0) &&
                    <Grid item md={2}>
                        <InputButtonLoading
                            onClick={() => handleOpenModalFile('fileVideo')}
                            loading={false}
                            label={(
                                <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar video</>
                            )}
                            position="left"
                        />
                    </Grid>
                }


                <Grid item xs={2}>
                    <InputCheckbox
                        label="Video Activat"
                        className="mb-12"
                        checked={state?.isActiveVideo}
                        onChange={(e) => {
                            state.isActiveVideo = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item xs={2}>
                    <InputCheckbox
                        label="QR Activat"
                        className="mb-12"
                        checked={state?.qrVideo}
                        onChange={(e) => {
                            state.qrVideo = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item md={12} style={{ marginTop: '5px' }}>Plantilla de text genèric centrat</Grid>

                <Grid item xs={2}>
                    <InputCheckbox
                        label="Text Centrat Activat"
                        className="mb-12"
                        checked={state?.isActiveTextoCentrado}
                        onChange={(e) => {
                            state.isActiveTextoCentrado = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item md={2}>
                    <InputCheckbox
                        label="QR Activat"
                        className="mb-12"
                        checked={state?.qrTextoCentrado}
                        onChange={(e) => {
                            state.qrTextoCentrado = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item md={8} />

                <Grid item md={12}>
                    <InputText
                        label={"Text Centrat: línia 1"}
                        name="textoCentradoLineOne"
                        disabled={loadingSave}
                        value={state?.textoCentradoLineOne}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item md={12}>
                    <InputText
                        label={"Text Centrat: línia 2"}
                        name="textoCentradoLineTwo"
                        disabled={loadingSave}
                        value={state?.textoCentradoLineTwo}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item md={12}>
                    <InputText
                        label={"Text Centrat: línia 3"}
                        name="textoCentradoLineThree"
                        disabled={loadingSave}
                        value={state?.textoCentradoLineThree}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item md={12}>
                    <InputText
                        label={"Text Centrat: línia 4"}
                        name="textoCentradoLineFour"
                        disabled={loadingSave}
                        value={state?.textoCentradoLineFour}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item md={12} style={{ marginTop: '5px' }}>Preus</Grid>

                <Grid item md={6}>
                    {(((state?.filePrecios.length > 0 || state?.urlFilePrecios.length > 0)) &&
                        <div style={{ textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto' }}>
                            <img alt="" src={
                                state.filePrecios?.[0]?.base64 ??
                                state.urlFilePrecios
                            } style={{ width: '100%', objectFit: 'cover' }} />

                        </div>
                    )}
                </Grid>

                <Grid item md={4} />

                <Grid item md={6}>

                    <ButtonFile
                        label="+ Imatge"
                        required
                        name="filePrecios"
                        typeButton="secondary"
                        multiple={false}
                        onChange={handleChangeMedia}
                        accept="image/png, image/jpg, image/jpeg, image/gif, image/*"
                    />

                </Grid>


                {(state?.filePrecios.length > 0 || state?.urlFilePrecios.length > 0) &&
                    <Grid item md={2}>
                        <InputButtonLoading
                            onClick={() => handleOpenModalFile('filePrecios')}
                            loading={false}
                            label={(
                                <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar imatge</>
                            )}
                            position="left"
                        />
                    </Grid>
                }


                <Grid item xs={2}>
                    <InputCheckbox
                        label="Preus Activat"
                        className="mb-12"
                        checked={state?.isActivePrecios}
                        onChange={(e) => {
                            state.isActivePrecios = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item xs={2}>
                    <InputCheckbox
                        label="QR Activat"
                        className="mb-12"
                        checked={state?.qrPrecios}
                        onChange={(e) => {
                            state.qrPrecios = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item md={12} style={{ marginTop: '10px' }}><strong>MODE B:</strong> 1-1 pantalles</Grid>

                <Grid item md={12}>Plantilla de Text Esquerra + Media Dreta</Grid>

                <Grid item md={12}></Grid>

                <Grid item xs={3}>
                    <InputCheckbox
                        label="Plantilla Activada"
                        className="mb-12"
                        checked={state?.isActiveMediaOne}
                        onChange={(e) => {
                            state.isActiveMediaOne = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item md={3}>
                    <InputCheckbox
                        labelLeft="Media: imatge"
                        label=" video"
                        name="mediaOneType"
                        checked={state?.mediaOneType || false}
                        onChange={(e) => {
                            state.mediaOneType = e.target.checked
                            state.fileMediaOne = []
                            state.urlFileMediaOne = ""
                            setState({ ...state })
                        }}
                        disabled={loadingSave}
                    />
                </Grid>

                <Grid item xs={2}>
                    <InputCheckbox
                        label="QR Activat"
                        className="mb-12"
                        checked={state?.qrMediaOne}
                        onChange={(e) => {
                            state.qrMediaOne = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item md={12}></Grid>

                <Grid md={6}>
                    <Grid item md={12} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                        <InputText
                            label={"Text: línia 1"}
                            name="textMediaOneLineOne"
                            disabled={loadingSave}
                            value={state?.textMediaOneLineOne}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                        <InputText
                            label={"Text: línia 2"}
                            name="textMediaOneLineTwo"
                            disabled={loadingSave}
                            value={state?.textMediaOneLineTwo}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                        <InputText
                            label={"Text: línia 3"}
                            name="textMediaOneLineThree"
                            disabled={loadingSave}
                            value={state?.textMediaOneLineThree}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                        <InputText
                            label={"Text: línia 4"}
                            name="textMediaOneLineFour"
                            disabled={loadingSave}
                            value={state?.textMediaOneLineFour}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>

                <Grid item md={6}>
                    <Grid item md={12} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                        {((state?.mediaOneType && (state?.fileMediaOne.length > 0 || state?.urlFileMediaOne.length > 0)) &&
                            <div style={{ textAlign: 'center', width: 400, marginLeft: 'auto', marginRight: 'auto' }}>
                                <video controls style={{ width: '100%', objectFit: 'cover' }}>
                                    <source src={
                                        state.fileMediaOne?.[0]?.base64 ?? state.urlFileMediaOne
                                    } ></source>
                                </video>
                            </div>
                        )}
                        {((!state?.mediaOneType && (state?.fileMediaOne.length > 0 || state?.urlFileMediaOne.length > 0)) &&
                            <div style={{ textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto' }}>
                                <img alt="" src={
                                    state?.fileMediaOne.length > 0 ? state.fileMediaOne?.[0]?.base64 :
                                        state.urlFileMediaOne
                                } style={{ width: '100%', objectFit: 'cover' }} />

                            </div>
                        )}
                    </Grid>



                    <Grid item md={12} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                        {state?.mediaOneType && (
                            <ButtonFile
                                label="+ Video"
                                required
                                name="fileMediaOne"
                                typeButton="secondary"
                                multiple={false}
                                onChange={handleChangeMedia}
                                accept="video/mp4,video/mkv,video/x-m4v,video/*"
                            />
                        )}
                        {!state?.mediaOneType && (
                            <ButtonFile
                                label="+ Imatge"
                                required
                                name="fileMediaOne"
                                typeButton="secondary"
                                multiple={false}
                                onChange={handleChangeMedia}
                                accept="image/png, image/jpg, image/jpeg, image/gif, image/*"
                            />
                        )}
                    </Grid>
                    <Grid item md={12} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                        <Grid item md={2}></Grid>
                        <Grid item md={4} style={{ marginTop: '10px', marginBottom: '10px' }}>
                            {(state?.fileMediaOne.length > 0 || state?.urlFileMediaOne.length > 0) &&
                                <InputButtonLoading
                                    onClick={() => handleOpenModalFile('fileMediaOne')}
                                    loading={false}
                                    label={(
                                        <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar media</>
                                    )}
                                    position="left"
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>Plantilla de Text Dreta + Media Esquerra</Grid>

                <Grid item md={12}></Grid>

                <Grid item xs={3}>
                    <InputCheckbox
                        label="Plantilla Activada"
                        className="mb-12"
                        checked={state?.isActiveMediaTwo}
                        onChange={(e) => {
                            state.isActiveMediaTwo = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item md={3}>
                    <InputCheckbox
                        labelLeft="Media: imatge"
                        label=" video"
                        name="mediaTwoType"
                        checked={state?.mediaTwoType || false}
                        onChange={(e) => {
                            state.mediaTwoType = e.target.checked
                            state.fileMediaTwo = []
                            state.urlFileMediaTwo = ""
                            setState({ ...state })
                        }}
                        disabled={loadingSave}
                    />
                </Grid>

                <Grid item xs={2}>
                    <InputCheckbox
                        label="QR Activat"
                        className="mb-12"
                        checked={state?.qrMediaTwo}
                        onChange={(e) => {
                            state.qrMediaTwo = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item md={12}></Grid>

                <Grid item md={6} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                    <Grid item md={12}>
                        {((state?.mediaTwoType && (state?.fileMediaTwo.length > 0 || state?.urlFileMediaTwo.length > 0)) &&
                            <div style={{ textAlign: 'center', width: 400, marginLeft: 'auto', marginRight: 'auto' }}>
                                <video controls style={{ width: '100%', objectFit: 'cover' }}>
                                    <source src={
                                        state.fileMediaTwo?.[0]?.base64 ?? state.urlFileMediaTwo
                                    } ></source>
                                </video>
                            </div>
                        )}
                        {((!state?.mediaTwoType && (state?.fileMediaTwo.length > 0 || state?.urlFileMediaTwo.length > 0)) &&
                            <div style={{ textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto' }}>
                                <img alt="" src={
                                    state.fileMediaTwo?.[0]?.base64 ??
                                    state.urlFileMediaTwo
                                } style={{ width: '100%', objectFit: 'cover' }} />

                            </div>
                        )}
                    </Grid>

                    <Grid item md={12} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                        {state?.mediaTwoType && (
                            <ButtonFile
                                label="+ Video"
                                required
                                name="fileMediaTwo"
                                typeButton="secondary"
                                multiple={false}
                                onChange={handleChangeMedia}
                                accept="video/mp4,video/mkv,video/x-m4v,video/*"
                            />
                        )}
                        {!state?.mediaTwoType && (
                            <ButtonFile
                                label="+ Imatge"
                                required
                                name="fileMediaTwo"
                                typeButton="secondary"
                                multiple={false}
                                onChange={handleChangeMedia}
                                accept="image/png, image/jpg, image/jpeg, image/gif, image/*"
                            />
                        )}
                    </Grid>
                    <Grid item md={12} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                        <Grid item md={4}>
                            {(state?.fileMediaTwo.length > 0 || state?.urlFileMediaTwo.length > 0) &&
                                <InputButtonLoading
                                    onClick={() => handleOpenModalFile('fileMediaTwo')}
                                    loading={false}
                                    label={(
                                        <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar media</>
                                    )}
                                    position="left"
                                />
                            }
                        </Grid>
                    </Grid>     
                </Grid>
                <Grid item md={6}>
                    <Grid item md={12} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                        <InputText
                            label={"Text: línia 1"}
                            name="textMediaTwoLineOne"
                            disabled={loadingSave}
                            value={state?.textMediaTwoLineOne}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                        <InputText
                            label={"Text: línia 2"}
                            name="textMediaTwoLineTwo"
                            disabled={loadingSave}
                            value={state?.textMediaTwoLineTwo}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                        <InputText
                            label={"Text: línia 3"}
                            name="textMediaTwoLineThree"
                            disabled={loadingSave}
                            value={state?.textMediaTwoLineThree}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                        <InputText
                            label={"Text: línia 4"}
                            name="textMediaTwoLineFour"
                            disabled={loadingSave}
                            value={state?.textMediaTwoLineFour}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>

                <Grid item md={12}>Plantilla de Media + Media </Grid>

                <Grid item md={12}></Grid>

                <Grid item xs={3}>
                    <InputCheckbox
                        label="Plantilla Activada"
                        className="mb-12"
                        checked={state?.isActiveMediaDouble}
                        onChange={(e) => {
                            state.isActiveMediaDouble = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item md={3}>
                    <InputCheckbox
                        labelLeft="Media Esquerra: imatge"
                        label=" video"
                        name="mediaDoubleOneType"
                        checked={state?.mediaDoubleOneType || false}
                        onChange={(e) => {
                            state.mediaDoubleOneType = e.target.checked
                            state.fileMediaDoubleOne = []
                            state.urlFileMediaDoubleOne = ""
                            setState({ ...state })
                        }}
                        disabled={loadingSave}
                    />
                </Grid>

                <Grid item md={3}>
                    <InputCheckbox
                        labelLeft="Media Dreta: imatge"
                        label=" video"
                        name="mediaDoubleTwoType"
                        checked={state?.mediaDoubleTwoType || false}
                        onChange={(e) => {
                            state.mediaDoubleTwoType = e.target.checked
                            state.fileMediaDoubleTwo = []
                            state.urlFileMediaDoubleTwo = ""
                            setState({ ...state })
                        }}
                        disabled={loadingSave}
                    />
                </Grid>

                <Grid item xs={2}>
                    <InputCheckbox
                        label="QR Activat"
                        className="mb-12"
                        checked={state?.qrMediaDouble}
                        onChange={(e) => {
                            state.qrMediaDouble = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item md={12}></Grid>

                <Grid item md={6}>
                    {((state?.mediaDoubleOneType && (state?.fileMediaDoubleOne.length > 0 || state?.urlFileMediaDoubleOne.length > 0)) &&
                        <div style={{ textAlign: 'center', width: 400, marginLeft: 'auto', marginRight: 'auto' }}>
                            <video controls style={{ width: '100%', objectFit: 'cover' }}>
                                <source src={
                                    state.fileMediaDoubleOne?.[0]?.base64 ?? state.urlFileMediaDoubleOne
                                } ></source>
                            </video>
                        </div>
                    )}
                    {((!state?.mediaDoubleOneType && (state?.fileMediaDoubleOne.length > 0 || state?.urlFileMediaDoubleOne.length > 0)) &&
                        <div style={{ textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto' }}>
                            <img alt="" src={
                                state.fileMediaDoubleOne?.[0]?.base64 ??
                                state.urlFileMediaDoubleOne
                            } style={{ width: '100%', objectFit: 'cover' }} />

                        </div>
                    )}
                </Grid>

                <Grid item md={6}>
                    {((state?.mediaDoubleTwoType && (state?.fileMediaDoubleTwo.length > 0 || state?.urlFileMediaDoubleTwo.length > 0)) &&
                        <div style={{ textAlign: 'center', width: 400, marginLeft: 'auto', marginRight: 'auto' }}>
                            <video controls style={{ width: '100%', objectFit: 'cover' }}>
                                <source src={
                                    state.fileMediaDoubleTwo?.[0]?.base64 ?? state.urlFileMediaDoubleTwo
                                } ></source>
                            </video>
                        </div>
                    )}
                    {((!state?.mediaDoubleTwoType && (state?.fileMediaDoubleTwo.length > 0 || state?.urlFileMediaDoubleTwo.length > 0)) &&
                        <div style={{ textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto' }}>
                            <img alt="" src={
                                state.fileMediaDoubleTwo?.[0]?.base64 ??
                                state.urlFileMediaDoubleTwo
                            } style={{ width: '100%', objectFit: 'cover' }} />

                        </div>
                    )}
                </Grid>

                <Grid item md={6}>
                    {state?.mediaDoubleOneType && (
                        <ButtonFile
                            label="+ Video"
                            required
                            name="fileMediaDoubleOne"
                            typeButton="secondary"
                            multiple={false}
                            onChange={handleChangeMedia}
                            accept="video/mp4,video/mkv,video/x-m4v,video/*"
                        />
                    )}
                    {!state?.mediaDoubleOneType && (
                        <ButtonFile
                            label="+ Imatge"
                            required
                            name="fileMediaDoubleOne"
                            typeButton="secondary"
                            multiple={false}
                            onChange={handleChangeMedia}
                            accept="image/png, image/jpg, image/jpeg, image/gif, image/*"
                        />
                    )}
                </Grid>

                <Grid item md={6}>
                    {state?.mediaDoubleTwoType && (
                        <ButtonFile
                            label="+ Video"
                            required
                            name="fileMediaDoubleTwo"
                            typeButton="secondary"
                            multiple={false}
                            onChange={handleChangeMedia}
                            accept="video/mp4,video/mkv,video/x-m4v,video/*"
                        />
                    )}
                    {!state?.mediaDoubleTwoType && (
                        <ButtonFile
                            label="+ Imatge"
                            required
                            name="fileMediaDoubleTwo"
                            typeButton="secondary"
                            multiple={false}
                            onChange={handleChangeMedia}
                            accept="image/png, image/jpg, image/jpeg, image/gif, image/*"
                        />
                    )}
                </Grid>

                <Grid item md={2} />
                <Grid item md={2}>
                    {(state?.fileMediaDoubleOne.length > 0 || state?.urlFileMediaDoubleOne.length > 0) &&
                        <InputButtonLoading
                            onClick={() => handleOpenModalFile('fileMediaDoubleOne')}
                            loading={false}
                            label={(
                                <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar media</>
                            )}
                            position="left"
                        />
                    }
                </Grid>
                <Grid item md={2} />

                <Grid item md={2} />
                <Grid item md={2}>
                    {(state?.fileMediaDoubleTwo.length > 0 || state?.urlFileMediaDoubleTwo.length > 0) &&
                        <InputButtonLoading
                            onClick={() => handleOpenModalFile('fileMediaDoubleTwo')}
                            loading={false}
                            label={(
                                <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar media</>
                            )}
                            position="left"
                        />
                    }
                </Grid>
                <Grid item md={2} />



                <Grid item md={12}>
                    <InputButtonLoading
                        onClick={handleSubmit}
                        loading={loadingSave}
                        label="Modificar"
                        position="left"
                        style={{ float: 'right' }}
                    />
                </Grid>

                <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile} text="Eliminar el fitxer?" />

            </Grid>

        </>
    )
}

function initialValues(props) {

    let langSelect = localStorage.getItem('langSelect')

    return {
        idDisplay: 1,
        idScreen: 1,
        langSelect: langSelect ? langSelect : 'ca',
        fileVideo: props?.fileVideo || [],
        urlFileVideo: props?.urlFileVideo || "",
        isActiveVideo: props?.isActiveVideo === undefined ? false : props.isActiveVideo,
        qrVideo: props?.qrVideo === undefined ? false : props.qrVideo,
        textoCentradoLineOne: props?.textoCentradoLineOne || "",
        textoCentradoLineTwo: props?.textoCentradoLineTwo || "",
        textoCentradoLineThree: props?.textoCentradoLineThree || "",
        textoCentradoLineFour: props?.textoCentradoLineFour || "",
        isActiveTextoCentrado: props?.isActiveTextoCentrado === undefined ? false : props.isActiveTextoCentrado,
        qrTextoCentrado: props?.qrTextoCentrado === undefined ? false : props.qrTextoCentrado,
        filePrecios: props?.filePrecios || [],
        urlFilePrecios: props?.urlFilePrecios || "",
        isActivePrecios: props?.isActivePrecios === undefined ? false : props.isActivePrecios,
        qrPrecios: props?.qrPrecios === undefined ? false : props.qrPrecios,
        mediaOneType: props?.mediaOneType === undefined ? false : props.mediaOneType,
        fileMediaOne: props?.fileMediaOne || [],
        urlFileMediaOne: props?.urlFileMediaOne || "",
        qrMediaOne: props?.qrMediaOne === undefined ? false : props.qrMediaOne,
        isActiveMediaOne: props?.isActiveMediaOne === undefined ? false : props.isActiveMediaOne,
        textMediaOneLineOne: props?.textMediaOneLineOne || "",
        textMediaOneLineTwo: props?.textMediaOneLineTwo || "",
        textMediaOneLineThree: props?.textMediaOneLineThree || "",
        textMediaOneLineFour: props?.textMediaOneLineFour || "",
        mediaTwoType: props?.mediaTwoType === undefined ? false : props.mediaTwoType,
        fileMediaTwo: props?.fileMediaTwo || [],
        urlFileMediaTwo: props?.urlFileMediaTwo || "",
        qrMediaTwo: props?.qrMediaTwo === undefined ? false : props.qrMediaTwo,
        isActiveMediaTwo: props?.isActiveMediaTwo === undefined ? false : props.isActiveMediaTwo,
        textMediaTwoLineOne: props?.textMediaTwoLineOne || "",
        textMediaTwoLineTwo: props?.textMediaTwoLineTwo || "",
        textMediaTwoLineThree: props?.textMediaTwoLineThree || "",
        textMediaTwoLineFour: props?.textMediaTwoLineFour || "",
        qrMediaDouble: props?.qrMediaDouble === undefined ? false : props.qrMediaDouble,
        isActiveMediaDouble: props?.isActiveMediaDouble === undefined ? false : props.isActiveMediaDouble,
        mediaDoubleOneType: props?.mediaDoubleOneType === undefined ? false : props.mediaDoubleOneType,
        fileMediaDoubleOne: props?.fileMediaDoubleOne || [],
        urlFileMediaDoubleOne: props?.urlFileMediaDoubleOne || "",
        mediaDoubleTwoType: props?.mediaDoubleTwoType === undefined ? false : props.mediaDoubleTwoType,
        fileMediaDoubleTwo: props?.fileMediaDoubleTwo || [],
        urlFileMediaDoubleTwo: props?.urlFileMediaDoubleTwo || "",

    }
}

