import React, {useEffect, useState} from "react"

// Secuencia de imágenes
import frame0 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00000.webp';
import frame1 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00001.webp';
import frame2 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00002.webp';
import frame3 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00003.webp';
import frame4 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00004.webp';
import frame5 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00005.webp';
import frame6 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00006.webp';
import frame7 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00007.webp';
import frame8 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00008.webp';
import frame9 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00009.webp';
import frame10 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00010.webp';
import frame11 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00011.webp';
import frame12 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00012.webp';
import frame13 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00013.webp';
import frame14 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00014.webp';
import frame15 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00015.webp';
import frame16 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00016.webp';
import frame17 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00017.webp';
import frame18 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00018.webp';
import frame19 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00019.webp';
import frame20 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00020.webp';
import frame21 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00021.webp';
import frame22 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00022.webp';
import frame23 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00023.webp';
import frame24 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00024.webp';
import frame25 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00025.webp';
import frame26 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00026.webp';
import frame27 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00027.webp';
import frame28 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00028.webp';
import frame29 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00029.webp';
import frame30 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00030.webp';
import frame31 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00031.webp';
import frame32 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00032.webp';
import frame33 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00033.webp';
import frame34 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00034.webp';
import frame35 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00035.webp';
import frame36 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00036.webp';
import frame37 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00037.webp';
import frame38 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00038.webp';
import frame39 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00039.webp';
import frame40 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00040.webp';
import frame41 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00041.webp';
import frame42 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00042.webp';
import frame43 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00043.webp';
import frame44 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00044.webp';
import frame45 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00045.webp';
import frame46 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00046.webp';
import frame47 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00047.webp';
import frame48 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00048.webp';
import frame49 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00049.webp';
import frame50 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00050.webp';
import frame51 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00051.webp';
import frame52 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00052.webp';
import frame53 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00053.webp';
import frame54 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00054.webp';
import frame55 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00055.webp';
import frame56 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00056.webp';
import frame57 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00057.webp';
import frame58 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00058.webp';
import frame59 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00059.webp';
import frame60 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00060.webp';
import frame61 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00061.webp';
import frame62 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00062.webp';
import frame63 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00063.webp';
import frame64 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00064.webp';
import frame65 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00065.webp';
import frame66 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00066.webp';
import frame67 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00067.webp';
import frame68 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00068.webp';
import frame69 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00069.webp';
import frame70 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00070.webp';
import frame71 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00071.webp';
import frame72 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00072.webp';
import frame73 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00073.webp';
import frame74 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00074.webp';
import frame75 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00075.webp';
import frame76 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00076.webp';
import frame77 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00077.webp';
import frame78 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00078.webp';
import frame79 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00079.webp';
import frame80 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00080.webp';
import frame81 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00081.webp';
import frame82 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00082.webp';
import frame83 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00083.webp';
import frame84 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00084.webp';
import frame85 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00085.webp';
import frame86 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00086.webp';
import frame87 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00087.webp';
import frame88 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00088.webp';
import frame89 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00089.webp';
import frame90 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00090.webp';
import frame91 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00091.webp';
import frame92 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00092.webp';
import frame93 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00093.webp';
import frame94 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00094.webp';
import frame95 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00095.webp';
import frame96 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00096.webp';
import frame97 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00097.webp';
import frame98 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00098.webp';
import frame99 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00099.webp';
import frame100 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00100.webp';
import frame101 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00101.webp';
import frame102 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00102.webp';
import frame103 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00103.webp';
import frame104 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00104.webp';
import frame105 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00105.webp';
import frame106 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00106.webp';
import frame107 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00107.webp';
import frame108 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00108.webp';
import frame109 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00109.webp';
import frame110 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00110.webp';
import frame111 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00111.webp';
import frame112 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00112.webp';
import frame113 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00113.webp';
import frame114 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00114.webp';
import frame115 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00115.webp';
import frame116 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00116.webp';
import frame117 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00117.webp';
import frame118 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00118.webp';
import frame119 from '../../../../assets/images/front/animaciones/game/secuencia/FLAMES_cremant_00119.webp';

// Secuencia de imágenes para quemar o salvar
import frameDos0 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant000.webp';
import frameDos1 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant001.webp';
import frameDos2 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant002.webp';
import frameDos3 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant003.webp';
import frameDos4 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant004.webp';
import frameDos5 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant005.webp';
import frameDos6 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant006.webp';
import frameDos7 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant007.webp';
import frameDos8 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant008.webp';
import frameDos9 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant009.webp';
import frameDos10 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant010.webp';
import frameDos11 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant011.webp';
import frameDos12 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant012.webp';
import frameDos13 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant013.webp';
import frameDos14 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant014.webp';
import frameDos15 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant015.webp';
import frameDos16 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant016.webp';
import frameDos17 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant017.webp';
import frameDos18 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant018.webp';
import frameDos19 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant019.webp';
import frameDos20 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant020.webp';
import frameDos21 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant021.webp';
import frameDos22 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant022.webp';
import frameDos23 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant023.webp';
import frameDos24 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant024.webp';
import frameDos25 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant025.webp';
import frameDos26 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant026.webp';
import frameDos27 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant027.webp';
import frameDos28 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant028.webp';
import frameDos29 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant029.webp';
import frameDos30 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant030.webp';
import frameDos31 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant031.webp';
import frameDos32 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant032.webp';
import frameDos33 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant033.webp';
import frameDos34 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant034.webp';
import frameDos35 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant035.webp';
import frameDos36 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant036.webp';
import frameDos37 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant037.webp';
import frameDos38 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant038.webp';
import frameDos39 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant039.webp';
import frameDos40 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant040.webp';
import frameDos41 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant041.webp';
import frameDos42 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant042.webp';
import frameDos43 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant043.webp';
import frameDos44 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant044.webp';
import frameDos45 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant045.webp';
import frameDos46 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant046.webp';
import frameDos47 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant047.webp';
import frameDos48 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant048.webp';
import frameDos49 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant049.webp';
import frameDos50 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant050.webp';
import frameDos51 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant051.webp';
import frameDos52 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant052.webp';
import frameDos53 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant053.webp';
import frameDos54 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant054.webp';
import frameDos55 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant055.webp';
import frameDos56 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant056.webp';
import frameDos57 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant057.webp';
import frameDos58 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant058.webp';
import frameDos59 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant059.webp';
import frameDos60 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant060.webp';
import frameDos61 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant061.webp';
import frameDos62 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant062.webp';
import frameDos63 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant063.webp';
import frameDos64 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant064.webp';
import frameDos65 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant065.webp';
import frameDos66 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant066.webp';
import frameDos67 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant067.webp';
import frameDos68 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant068.webp';
import frameDos69 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant069.webp';
import frameDos70 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant070.webp';
import frameDos71 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant071.webp';
import frameDos72 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant072.webp';
import frameDos73 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant073.webp';
import frameDos74 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant074.webp';
import frameDos75 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant075.webp';
import frameDos76 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant076.webp';
import frameDos77 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant077.webp';
import frameDos78 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant078.webp';
import frameDos79 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant079.webp';
import frameDos80 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant080.webp';
import frameDos81 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant081.webp';
import frameDos82 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant082.webp';
import frameDos83 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant083.webp';
import frameDos84 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant084.webp';
import frameDos85 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant085.webp';
import frameDos86 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant086.webp';
import frameDos87 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant087.webp';
import frameDos88 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant088.webp';
import frameDos89 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant089.webp';
import frameDos90 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant090.webp';
import frameDos91 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant091.webp';
import frameDos92 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant092.webp';
import frameDos93 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant093.webp';
import frameDos94 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant094.webp';
import frameDos95 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant095.webp';
import frameDos96 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant096.webp';
import frameDos97 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant097.webp';
import frameDos98 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant098.webp';
import frameDos99 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant099.webp';
import frameDos100 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant100.webp';
import frameDos101 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant101.webp';
import frameDos102 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant102.webp';
import frameDos103 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant103.webp';
import frameDos104 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant104.webp';
import frameDos105 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant105.webp';
import frameDos106 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant106.webp';
import frameDos107 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant107.webp';
import frameDos108 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant108.webp';
import frameDos109 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant109.webp';
import frameDos110 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant110.webp';
import frameDos111 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant111.webp';
import frameDos112 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant112.webp';
import frameDos113 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant113.webp';
import frameDos114 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant114.webp';
import frameDos115 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant115.webp';
import frameDos116 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant116.webp';
import frameDos117 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant117.webp';
import frameDos118 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant118.webp';
import frameDos119 from '../../../../assets/images/front/animaciones/game/cremarsalvar/FLAMES_cremant119.webp';

export default function FlamesAnimation(props) {

    const frames = [
        frame0,
        frame1,
        frame2,
        frame3,
        frame4,
        frame5,
        frame6,
        frame7,
        frame8,
        frame9,
        frame10,
        frame11,
        frame12,
        frame13,
        frame14,
        frame15,
        frame16,
        frame17,
        frame18,
        frame19,
        frame20,
        frame21,
        frame22,
        frame23,
        frame24,
        frame25,
        frame26,
        frame27,
        frame28,
        frame29,
        frame30,
        frame31,
        frame32,
        frame33,
        frame34,
        frame35,
        frame36,
        frame37,
        frame38,
        frame39,
        frame40,
        frame41,
        frame42,
        frame43,
        frame44,
        frame45,
        frame46,
        frame47,
        frame48,
        frame49,
        frame50,
        frame51,
        frame52,
        frame53,
        frame54,
        frame55,
        frame56,
        frame57,
        frame58,
        frame59,
        frame60,
        frame61,
        frame62,
        frame63,
        frame64,
        frame65,
        frame66,
        frame67,
        frame68,
        frame69,
        frame70,
        frame71,
        frame72,
        frame73,
        frame74,
        frame75,
        frame76,
        frame77,
        frame78,
        frame79,
        frame80,
        frame81,
        frame82,
        frame83,
        frame84,
        frame85,
        frame86,
        frame87,
        frame88,
        frame89,
        frame90,
        frame91,
        frame92,
        frame93,
        frame94,
        frame95,
        frame96,
        frame97,
        frame98,
        frame99,
        frame100,
        frame101,
        frame102,
        frame103,
        frame104,
        frame105,
        frame106,
        frame107,
        frame108,
        frame109,
        frame110,
        frame111,
        frame112,
        frame113,
        frame114,
        frame115,
        frame116,
        frame117,
        frame118,
        frame119
    ];

    const framesDos = [
        frameDos0,
        frameDos1,
        frameDos2,
        frameDos3,
        frameDos4,
        frameDos5,
        frameDos6,
        frameDos7,
        frameDos8,
        frameDos9,
        frameDos10,
        frameDos11,
        frameDos12,
        frameDos13,
        frameDos14,
        frameDos15,
        frameDos16,
        frameDos17,
        frameDos18,
        frameDos19,
        frameDos20,
        frameDos21,
        frameDos22,
        frameDos23,
        frameDos24,
        frameDos25,
        frameDos26,
        frameDos27,
        frameDos28,
        frameDos29,
        frameDos30,
        frameDos31,
        frameDos32,
        frameDos33,
        frameDos34,
        frameDos35,
        frameDos36,
        frameDos37,
        frameDos38,
        frameDos39,
        frameDos40,
        frameDos41,
        frameDos42,
        frameDos43,
        frameDos44,
        frameDos45,
        frameDos46,
        frameDos47,
        frameDos48,
        frameDos49,
        frameDos50,
        frameDos51,
        frameDos52,
        frameDos53,
        frameDos54,
        frameDos55,
        frameDos56,
        frameDos57,
        frameDos58,
        frameDos59,
        frameDos60,
        frameDos61,
        frameDos62,
        frameDos63,
        frameDos64,
        frameDos65,
        frameDos66,
        frameDos67,
        frameDos68,
        frameDos69,
        frameDos70,
        frameDos71,
        frameDos72,
        frameDos73,
        frameDos74,
        frameDos75,
        frameDos76,
        frameDos77,
        frameDos78,
        frameDos79,
        frameDos80,
        frameDos81,
        frameDos82,
        frameDos83,
        frameDos84,
        frameDos85,
        frameDos86,
        frameDos87,
        frameDos88,
        frameDos89,
        frameDos90,
        frameDos91,
        frameDos92,
        frameDos93,
        frameDos94,
        frameDos95,
        frameDos96,
        frameDos97,
        frameDos98,
        frameDos99,
        frameDos100,
        frameDos101,
        frameDos102,
        frameDos103,
        frameDos104,
        frameDos105,
        frameDos106,
        frameDos107,
        frameDos108,
        frameDos109,
        frameDos110,
        frameDos111,
        frameDos112,
        frameDos113,
        frameDos114,
        frameDos115,
        frameDos116,
        frameDos117,
        frameDos118,
        frameDos119
    ];

    const { 
        resetAnimation,
        setResetAnimation,
        currentIndex,
        setCurrentIndex,
        actionSaveIsVisibleAnimation,
        setActionSaveIsVisibleAnimation,
        actionBurnIsVisibleAnimation,
        setActionBurnIsVisibleAnimation,
        actionSaveIsVisible,
        setActionSaveIsVisible,
        actionBurnIsVisible,
        setActionBurnIsVisible,
        countIsVisible
     } = props

    useEffect(() => {
        if(actionSaveIsVisibleAnimation){
            const interval = setInterval(() => {
                setCurrentIndex(prevIndex => (prevIndex - 1) % framesDos.length);
            }, 20);
    
            if (currentIndex === 0) {
                setActionSaveIsVisibleAnimation(false)
                setActionSaveIsVisible(true);
            }

            return () => {
                clearInterval(interval);
            };
        }else if (actionBurnIsVisibleAnimation){
            let secuenciaTotal;
            if (currentIndex > 70) {
                secuenciaTotal = framesDos.length;
            } else {
                secuenciaTotal = 90;
            }
            const interval = setInterval(() => {
                setCurrentIndex(prevIndex => (prevIndex + 1) % secuenciaTotal);
            }, 20);

            if (currentIndex === 0) {
                setActionBurnIsVisibleAnimation(false)
                setActionBurnIsVisible(true);
            }

            return () => {
                clearInterval(interval);
            };
        }else if(!actionSaveIsVisibleAnimation && !actionBurnIsVisibleAnimation){
            const interval = setInterval(() => {
                setCurrentIndex(prevIndex => (prevIndex + 1) % frames.length);
            }, 70);
    
            // Reiniciar la animación cuando resetAnimation cambie
            if (resetAnimation) {
                setCurrentIndex(0);
                setResetAnimation(false);
            }
    
            return () => {
                clearInterval(interval);
            };
        }
    }, [resetAnimation, frames, actionSaveIsVisibleAnimation, actionBurnIsVisibleAnimation]);

    return (
        <div className="animation-flames">
            {/* {frames[currentIndex] && <img src={frames[currentIndex]} />} */}
            {actionSaveIsVisibleAnimation || actionBurnIsVisibleAnimation ? (
                <img src={framesDos[currentIndex]} />
                ) : (
                <img src={frames[currentIndex]} />
                )
            }
        </div>
    )
}
