import React, {useEffect} from "react";
import {RouteWebBack} from "../../../../../routes/Web/RouteWebBack";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import InputSelect from "../../../../../components/Admin/Inputs/InputSelect";
import {lang} from "../../../../../utils/lang";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import {Grid} from "@mui/material";
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import {apiGetSubmenu, apiSaveSubmenu, apiRemoveSubmenu} from "../../../../../services/Admin/menu";
import {apiList} from "../../../../../services/Admin/page";
import ModalSection from "../../../../../components/Admin/ModalConfirm";
import ModalConfirm from "../../../../../components/Admin/ModalConfirm";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";
import clone from "../../../../../utils/clone";
import SubmenuList from "./SubmenuList";

export default function SubmenuFields() {

    const {submenuId, menuId} = useParams()
    const navigate = useNavigate()
    const [state, setState] = React.useState(initialValues)
    const [msgError, setMsgError] = React.useState([])
    const [msgSuccess, setMsgSuccess] = React.useState([])
    const {t, i18n} = useTranslation()
    const [optionsPage, setOptionsPage] = React.useState([])
    const [openModalRemove, setOpenModalRemove] = React.useState(false)

    const [loading, setLoading] = React.useState(false)
    const [loadingGetInfo, setLoadingGetInfo] = React.useState(false)
    const [loadingSave, setLoadingSave] = React.useState(false)
    const [loadingRemove, setLoadingRemove] = React.useState(false)
    const [pageId, setPageId] = React.useState(null)

    useEffect(() => {
        getOptionsPage()
        if (submenuId) {
            getInfo()
        }
    }, [])

    useEffect(() => {
        if (submenuId) {
            getInfo()
        }
    }, [state.langSelect])

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)

        let params = clone(state)
        params.id = submenuId
        params.menuId = menuId
        params.page = pageId

        apiSaveSubmenu(params)
            .then(resp => {
                setMsgSuccess(resp.data?.msg)
                if (!submenuId) {
                    navigate(`${RouteWebBack.WEB_BACK_MENU_EDIT.path}/${resp.data?.id}`)
                }
                setLoadingSave(false)
            })
            .catch(err => {
                setMsgError(err.response?.data?.msg)
                setLoadingSave(false)
            })
    }

    const getInfo = () => {
        setLoadingGetInfo(true)
        apiGetSubmenu({
            id: submenuId,
            langSelect: state.langSelect
        })
            .then(response => {
                let data = response.data
                state.id = submenuId
                state.name = data.name
                state.link = data.link
                state.isActive = data.isActive
                setState({...state})
                setPageId(data.page)
            })
            .catch(error => {
                setMsgError(['Error al obtener la información'])
            })
            .finally(() => {
                setLoadingGetInfo(false)
            })
    }



    const getOptionsPage = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let params = {
            limit: 30000,
            offset: 0,
            field: 'title',
            sort: 'ASC'
        }


        apiList(params).then(resp => {
            let temp = resp.data.data?.map(doc => {
                return {
                    id: doc.id,
                    name: doc.title
                }
            })

            setOptionsPage(temp)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setOptionsPage([])
        })
    }

    const handleRemove = () => {
        setLoadingRemove(true)
        apiRemoveSubmenu(submenuId)
            .then(resp => {
                setMsgSuccess(resp.data?.msg)
                navigate(`${RouteWebBack.WEB_BACK_MENU_EDIT.path}/${menuId}`)
            })
            .catch(err => {
                setMsgError(err.response?.data?.msg)
            })
            .finally(() => {
                setLoadingRemove(false)
            })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }
    return (
        <>
            <HeaderPage
                title="Tornar"
                urlBack={`${RouteWebBack.WEB_BACK_MENU_EDIT.path}/${menuId}`}
            >
                <div style={{ float: 'right' }}>
                    <InputSelect
                        label="Idioma"
                        options={lang}
                        name="langSelect"
                        value={state.langSelect}
                        selectText="Selecciona un idioma"
                        style={{ width: 100 }}
                        onChange={(e) => {
                            setState({ ...state, langSelect: e.target.value })
                            localStorage.setItem('langSelect', e.target.value)
                        }}
                    />
                </div>
            </HeaderPage>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <LoadingSpinner show={loadingGetInfo}/>

            <Grid container spacing={2} style={{display: loadingGetInfo ? 'none' : ''}}>
                <Grid item xs={12} md={6}>
                    <InputText
                        label="Nom"
                        name="name"
                        value={state?.name}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputSelect
                        label="Page"
                        name="page"
                        onChange={(e) => {
                            setPageId(e.target.value)
                        }}
                        value={pageId}
                        options={optionsPage}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <InputText
                        label="Enllaç"
                        name="link"
                        value={state?.link}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={23}>
                    <InputButtonLoading
                        onClick={handleSubmit}
                        loading={loadingSave}
                        label={submenuId ? t('Modificar') : t('Crear')}
                    />

                    {submenuId && (
                        <InputButtonLoading
                            onClick={() => setOpenModalRemove(true)}
                            loading={loadingRemove}
                            label={'Eliminar'}
                            variant="outlined"
                            style={{marginRight: 10}}
                        />
                    )}
                </Grid>

            </Grid>

            <ModalConfirm
                openModal={openModalRemove}
                setOpenModal={setOpenModalRemove}
                handleAction={handleRemove}
                text="Eliminar el menu?"
            />
        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        isActive: false,
    }
}
