import React, { useRef, useState, useEffect } from "react";
import {convertFromRaw} from 'draft-js';
import {EditorState} from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export default function FullText() {

    const [state, setState] = useState(null)
    const [margin, setMargin] = useState('25px auto')


    useEffect(() => {
        setState(initialValues('{"blocks":[{"key":"ctg8d","text":"TRANSPORTE ","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"color-rgb(0,0,0)"},{"offset":0,"length":10,"style":"bgcolor-rgb(255,255,255)"},{"offset":0,"length":10,"style":"fontsize-50"},{"offset":0,"length":11,"style":"fontfamily-SohneBreitDreiviertelfett"},{"offset":0,"length":10,"style":"ITALIC"},{"offset":10,"length":1,"style":"fontsize-36"}],"entityRanges":[],"data":{"text-align":"center"}}],"entityMap":{}}'))
    }, [])

    useEffect(() => {
        responsiveMargin()
        window.addEventListener("resize", responsiveMargin);

        return () => {
            window.removeEventListener("resize", responsiveMargin);
        }
    }, [])

    const responsiveMargin = () => {
        if(state?.blockMargin){
            setMargin('0px auto')
        } else {
            if (window.innerWidth >= 768) {
                setMargin('50px auto')
            }else{
                setMargin('25px auto')
            }
        }
    };

    return(
        <div className="text-container dynamic-html-text" dangerouslySetInnerHTML={{__html: state?.text}} />
    )
}

function initialValues(text) {

    let a;

    if(text)
    {
        a=EditorState.createWithContent(convertFromRaw(JSON.parse(text))).getCurrentContent()
    }
    else
    {
        a=EditorState.createEmpty().getCurrentContent()
    }

    const rawContentState = convertToRaw(a);

    let markup = draftToHtml(rawContentState);

    markup=markup.replace(/<p><\/p>/gi, "<br/>");


    return {
        "text": markup,
    }
}
