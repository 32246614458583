import React, { useRef, useState, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation, Pagination } from 'swiper';

export default function Carousel({item, baseUrl}) {

    const [state, setState] = useState(initialValues(item))
    const [widthTitle, setWidthTitle] = useState(false)
    //console.log("........")
    //console.log(item)
    const styles = (theme) => {

        return {
            root: {
                // ...
            },
            slide: {
                // ...
            },
            pagination: {
                position: 'static',
                marginTop: theme.spacing.unit * 3,
                '@media (min-width: 701px)': {
                display: "none",
                },
            },
            paginationBullet: {
                background: theme.palette.primary.main,
            },
            paginationBulletActive: {
                background: "purple", 
            },
            // ...
        }
    }

    const swiperOptions = {
        breakpoints: {
            1: { // including the number, so basically its as `<=`
                pagination: {
                    bulletClass: styles.paginationBullet + " swiper-pagination-bullet",
                    bulletActiveClass: styles.paginationBulletActive + " swiper-pagination-bullet-active",
                }
            },
        }
    }

    const [customSlidesPerView, setCustomSlidesPerView] = useState('auto')
    const [customCenteredSlides, setCustomCenteredSlides] = useState(true)

    useEffect(() => {
        if(state?.imageOneTitle || state?.imageTwoTitle || state?.imageThreeTitle ||state?.imageFourTitle || state?.imageFiveTitle){
            setWidthTitle(true)
        }else{
            setWidthTitle(false)
        }

        handleResize()
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    const handleResize = () => {
        if (window.innerWidth >= 768) {
            setCustomSlidesPerView(3)
            setCustomCenteredSlides(false)
        } else {
            setCustomSlidesPerView(1)
            setCustomCenteredSlides(true)
        }
    };

    return (
        <div className="blocks-full-width">
            <div className="carousel-block">
                <div className='title-container'>
                    <p>{state?.title}</p>
                </div>
                <Swiper 
                    navigation={widthTitle ? false : true} 
                    pagination={widthTitle ? false : true} 
                    modules={[Navigation, Pagination]} 
                    swiperOptions={ swiperOptions } 
                    slidesPerView={widthTitle ? customSlidesPerView : 1} 
                    centeredSlides={widthTitle ? customCenteredSlides : true} 
                    spaceBetween={widthTitle ? 10 : 0} 
                    className={`mySwiper ${widthTitle ? 'with-title' : 'without-title'}`}
                >
                    {state?.imageOne   && <SwiperSlide><img title={state?.itemFive} alt={state?.itemFiveName} src={state?.imageOne} /><p>{state?.imageOneTitle}</p></SwiperSlide>}
                    {state?.imageTwo   && <SwiperSlide><img title={state?.itemSix} alt={state?.itemSixName} src={`${baseUrl}${state?.imageTwo}`} /><p>{state?.imageTwoTitle}</p></SwiperSlide>}
                    {state?.imageThree && <SwiperSlide><img title={state?.itemSeven} alt={state?.itemSevenName} src={`${baseUrl}${state?.imageThree}`} /><p>{state?.imageThreeTitle}</p></SwiperSlide>}
                    {state?.imageFour  && <SwiperSlide><img title={state?.itemEight} alt={state?.itemEightName} src={`${baseUrl}${state?.imageFour}`} /><p>{state?.imageFourTitle}</p></SwiperSlide>}
                    {state?.imageFive  && <SwiperSlide><img title={state?.itemNine} alt={state?.itemNineName} src={`${baseUrl}${state?.imageFive}`} /><p>{state?.imageFiveTitle}</p></SwiperSlide>}
                </Swiper>
                {state?.subtitle && (
                <div className='subtitle-container mt-2'>
                    <p>{state?.subtitle}</p>
                </div>
                )}
                {state?.link && (
                <div className='button-container mt-2'>
                    <a href={state?.link} id={state?.itemTen}>{state?.linkName}</a>
                </div>
                )}
            </div>
        </div>
    )
}

function initialValues(props) {

    return {
        "imageOne": props?.fileBackgroundImage,
        "imageOneTitle": props?.backgroundImageName,
        "imageTwo": props?.itemOne,
        "imageTwoTitle": props?.itemOneName,
        "imageThree": props?.itemTwo,
        "imageThreeTitle": props?.itemTwoName,
        "imageFour": props?.itemThree,
        "imageFourTitle": props?.itemThreeName,
        "imageFive": props?.itemFour,
        "imageFiveTitle": props?.itemFourName,
        "title": props?.text,
        "subtitle": props?.btnRedName,
        "linkName": props?.btnWhiteName,
        "link": props?.btnWhiteLink,
        "itemFive": props?.itemFive,
        "itemFiveName": props?.itemFiveName,
        "itemSix": props?.itemSix,
        "itemSixName": props?.itemSixName,
        "itemSeven": props?.itemSeven,
        "itemSevenName": props?.itemSevenName,
        "itemEight": props?.itemEight,
        "itemEightName": props?.itemEightName,
        "itemNine": props?.itemNine,
        "itemNineName": props?.itemNineName,
        "itemTen": props?.itemTen,
    }
}

