import React, {useEffect, useRef, useState} from "react";
import {Grid} from "@mui/material";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import ButtonFile from "../../../../../components/Admin/Inputs/ButtonFile";
import Header from "./Header";
import {useNavigate, useParams} from "react-router-dom";
import clone from "../../../../../utils/clone";
import {apiSaveArticle, apiGetArticle, apiSaveFileArticle} from "../../../../../services/Admin/obra";
import {apiDeleteArticle, apiDeleteArticleFile} from "../../../../../services/Admin/obraDelete";
import {LoadingButton} from "@mui/lab";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import {MdDeleteForever} from "react-icons/md";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";
import ModalConfirm from "../../../../../components/Admin/ModalConfirm";
import EditorText from "../../../../../components/EditorText";
import TitlePage from "../../../../../components/Admin/TitlePage";

export default function TabArticles() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [state, setState] = useState(initialValues())
    const myRef = useRef(null)
    const [openModal, setOpenModal] = React.useState(false)
    const [pendingIndexRemove, setPendingIndexRemove] = React.useState(null)
    const [openModalFile, setOpenModalFile] = React.useState(false)
    const [pendingIndexRemoveFile, setPendingIndexRemoveFile] = React.useState(null)

    const handleOpenModal = (key) => {
        setOpenModal(true)
        setPendingIndexRemove(key)
    }

    const handleOpenModalFile = (key) => {
        setOpenModalFile(true)
        setPendingIndexRemoveFile(key)
    }

    useEffect(() => {
        getData()
    }, [state.langSelect])

    const getData = () => {
        setLoadingGetInfo(true)

        apiGetArticle({id}, state?.langSelect).then(resp => {
            let articlesNew = resp.data.articles?.map(doc => {
                return {
                    ...doc
                }
            })

            setState({...state, articles: articlesNew})
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data.msg)
        })
    }

    const handleChange = key => (e) => {
        state.articles[key][e.target.name] = e.target.value
        setState({...state})
    }

    const handleChangeFile = key => (event) => {
        state.articles[key][event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = index => async () => {
        stateUpdateSimple(index, true, [], [])

        let error = validationSchema(state)
        if (error.length !== 0) {
            stateUpdateSimple(index, false, error, [])
            return
        }

        let articles = state.articles[index]
        articles.langSelect = state.langSelect

        await apiSaveArticle(articles).then(resp => {
            let idArticles = resp.data?.id
            // Actualizamos el id en caso de creación
            state.articles[index].id = idArticles
            setState({...state})
            let successMessage = resp.data?.msg

            // Enviamos para guardar la imagen
            let trans = state.articles[index]
            // Comprobamos si existe file
            let fileBinary = trans?.file.length > 0 ? trans?.file[0].binary : null
            let fileName = trans?.file.length > 0 ? trans?.file[0].name : null
            handleSubmitFile(index, idArticles, state.langSelect, fileBinary, fileName, successMessage)

            state.articles[index].id = resp.data?.id
            setState({...state})

        }).catch(err => {
            stateUpdateSimple(index, false, err.response?.data?.msg, [])
        })

    }

    const stateUpdateSimple = (index, loading, msgError, msgSuccess) => {
        state.articles[index].loading = loading
        state.articles[index].msgError = msgError
        state.articles[index].msgSuccess = msgSuccess

        setState({...state})
    }

    const handleSubmitFile = (index, idTemp, langCode, fileBinary, fileName, successMessage) => {
        if (idTemp && fileBinary && fileName) {
            let f = new FormData()
            f.append(`file[]`, fileBinary)
            f.append(`originalName`, fileName)
            f.append(`langCode`, langCode)

            apiSaveFileArticle(f, idTemp).then(resp => {
                state.articles[index].document = resp.data?.data?.document
                state.articles[index].documentOriginalName = resp.data?.data?.documentOriginalName
                state.articles[index].documentUrl = resp.data?.data?.documentUrl
                state.articles[index].file = []
                setState({...state})
                stateUpdateSimple(index, false, [], resp.data?.msg)
            }).catch(err => {
                stateUpdateSimple(index, false, err.response?.data?.msg, [])
            })
        } else {
            stateUpdateSimple(index, false, [], successMessage)
        }
    }

    const stateDeleteSimple = (index, loadingDelete, msgError, msgSuccess) => {
        state.articles[index].loadingDelete = loadingDelete
        state.articles[index].msgError = msgError
        state.articles[index].msgSuccess = msgSuccess

        setState({...state})
    }

    const stateDeleteFileSimple = (index, loadingDeleteFile, msgError, msgSuccess) => {
        state.articles[index].loadingDeleteFile = loadingDeleteFile
        state.articles[index].msgError = msgError
        state.articles[index].msgSuccess = msgSuccess

        setState({...state})
    }

    const addArticle = () => {
        let model = state.model
        model.artworkId = id
        setState({...state, articles: [...state.articles, clone(model)]})
        myRef.current.scrollIntoView()
    }

    const handleRemove = () => {
        stateDeleteSimple(pendingIndexRemove, true, [], [])
        apiDeleteArticle({
            id: state.articles[pendingIndexRemove].id
        }).then(resp => {
            window.location.reload();
            stateDeleteSimple(pendingIndexRemove, false, [], resp.data?.msg)
            setPendingIndexRemove(null)
        }).catch(err => {
            stateDeleteSimple(pendingIndexRemove, false, err.response?.data?.msg, [])
            setPendingIndexRemove(null)
        })
    }

    const handleDeleteFile = () => {
        stateDeleteFileSimple(pendingIndexRemoveFile, true, [], [])

        apiDeleteArticleFile({
            id: state.articles[pendingIndexRemoveFile].id,
            fileName: state.articles[pendingIndexRemoveFile]?.document,
            langCode: state.langSelect
        }).then(resp => {
            window.location.reload();
            stateDeleteFileSimple(pendingIndexRemoveFile, false, [], resp.data?.msg)
            setPendingIndexRemoveFile(null)
        }).catch(err => {
            stateDeleteFileSimple(pendingIndexRemoveFile, false, err.response?.data?.msg, [])
            setPendingIndexRemoveFile(null)
        })
    }

    return (
        <>
            <Header
                state={state}
                setState={setState}
                id={id}
                tabType={2}
                msgError={msgError}
                msgSuccess={msgSuccess}
            >
                <InputButton
                    style={{float: "right", margin: "5px 10px 10px 0px"}}
                    onClick={addArticle}
                    text=" + Afegir article"
                />
            </Header>

            <LoadingSpinner show={loadingGetInfo}/>

            <div style={{display: loadingGetInfo ? 'none' : ''}}>

                {state.articles.map((element, key) => (
                    <Grid container spacing={3} style={{padding: '0px'}} key={key}>
                        {key === 0 ? (
                            <Grid item xs={12} md={12} lg={12} style={{paddingTop: 0}}>
                                <TitlePage title={`Article ${key + 1}`}/>
                            </Grid>
                        ) : (
                            <Grid item xs={12} md={12} lg={12}>
                                <TitlePage title={`Article ${key + 1}`} borderTop={true}/>
                            </Grid>
                        )}

                        <Grid item xs={3} md={3} lg={3} style={{textAlign: 'center'}}>
                                <span>
                                    {state.articles[key]?.file[0]?.name ?? (
                                        <a href={state.articles[key]?.documentUrl} target="_blank">
                                            {state.articles[key]?.documentOriginalName}
                                        </a>
                                    )}
                                </span>
                        </Grid>

                        <Grid item xs={12} style={{padding: '0px'}}/>

                        <Grid item xs={3} md={3} lg={3}>
                            <ButtonFile
                                label="+ PDF Article"
                                name="file"
                                onChange={handleChangeFile(key)}
                                multiple={true}
                                variant="outlined"
                                accept="application/pdf"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            {state.articles[key]?.document &&
                                <InputButtonLoading
                                    onClick={() => handleOpenModalFile(key)}
                                    loading={state.articles[key].loadingDeleteFile}
                                    label={(
                                        <><MdDeleteForever fontSize="inherit" style={{
                                            width: '20px',
                                            height: '20px',
                                            marginBottom: '-3px'
                                        }}/> Eliminar document</>
                                    )}
                                    position="left"
                                />
                            }
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <p style={{
                                fontSize: '11px',
                                fontFamily: 'AdminFontMedium',
                                color: '#778',
                                marginTop: '0px',
                                paddingLeft: '15px'
                            }}>Text peu</p>
                            <EditorText
                                style={{height: '132px'}}
                                content={state.articles[key]?.footer}
                                setContent={(newContent) => {
                                    setState((prevState) => {
                                        const articles = [...prevState.articles];
                                        articles[key] = {
                                            ...articles[key],
                                            footer: newContent
                                        };
                                        return {...prevState, articles};
                                    });
                                }}
                            />
                        </Grid>


                        <Grid item xs={12} md={12} lg={12}>
                            <div style={{display: 'block'}}>
                                <MsgSuccess msg={state.articles[key].msgSuccess}/>
                                <MsgError msg={state.articles[key].msgError}/>
                            </div>
                            <InputButtonLoading
                                onClick={handleSubmit(key)}
                                loading={state.articles[key].loading}
                                label={state.articles[key].id ? 'Modificar' : 'Crear'}
                                position="left"
                                style={{marginRight: '15px'}}
                            />
                            {state.articles[key].id && (
                                <InputButtonLoading
                                    onClick={() => handleOpenModal(key)}
                                    loading={state.articles[key].loadingDelete}
                                    label="Eliminar"
                                    position="left"
                                    variant="outline"
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div style={{height: 5}}></div>
                        </Grid>
                    </Grid>
                ))}
                <ModalConfirm openModal={openModal} setOpenModal={setOpenModal} handleAction={handleRemove}
                              text="Eliminar el bloc d'articles?"/>
                <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile}
                              text="Eliminar el fitxer?"/>
            </div>
            <div ref={myRef}></div>

        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        articles: [],
        model: {
            id: null,
            document: "",
            documentOriginalName: "",
            footer: "",
            file: [],

            loading: false,
            loadingDelete: false,
            loadingDeleteFile: false,
            msgSuccess: [],
            msgError: []
        },
    }
}

function validationSchema(schema) {

    let error = []

    return error
}
