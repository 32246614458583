import React, { useEffect } from "react";
import {useTranslation} from "react-i18next";
import {RouteUser} from "../../../../routes/RouteUser";
import {useNavigate} from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import CloseIcon from "../../../../assets/images/front/Icons/CloseIcon.svg";
import infoIcon from "../../../../assets/images/front/scaner/info-circle.svg";

/**
 * Previsualiza un PDF
 *
 * ## PROPS
 * - **url** *string*
 * - **showPages** *bool*
 */
export default function ResultStories(props) {
    const {open, setOpen, artworks} = props
    const {t} = useTranslation()
    const navigate = useNavigate()
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleClickArtwork = (storyId) => {
        navigate(`${RouteUser.STORIES.path}/${storyId}`)
    }

    const handleBuscar = () => {
        navigate(RouteUser.SEARCH.path)
    }

    return (
        <>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={'paper'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title" style={{border: 0, padding: '16px 24px 8px'}}>
                        <p
                            style={{
                                fontSize: 15,
                                margin: 0,
                                fontWeight: 300,
                                fontFamily: 'SohneBuch',
                                color: '#E30613'
                            }}
                        >
                            {t('scan.Resultados sugeridos')}
                        </p>
                        <div style={{
                            position: 'fixed',
                            zIndex: 9999999,
                            float: 'right',
                            right: 20,
                            top: 12,
                        }}>
                            <button
                                className="close-button"
                                onClick={handleClose}
                                style={{
                                    backgroundColor: '#ffffff',
                                    borderRadius: '100%',
                                    display: 'flex',
                                    boxShadow: '0px 2px 6px rgba(0, 33, 68, 0.1)',
                                    padding: '.125vw',
                                    width: 'max-content',
                                    border: 'none',
                                }}
                            >
                                <img
                                    src={CloseIcon}
                                    alt=""
                                    style={{
                                        width: 16,
                                        height: 16,
                                        padding: 11
                                    }}
                                />
                            </button>
                        </div>
                    </DialogTitle>
                    <DialogContent dividers={'paper'} style={{borderColor: '#fff'}}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <div className="search">
                                <div className="search-content" style={{padding: 0}}>
                                    <div className="results-container">
                                        <div className="results-list">
                                            {artworks.length > 0 && (
                                                <>
                                                    {artworks?.map((element, key) => (
                                                        <div key={key} className="results-element" onClick={() => handleClickArtwork(element.id)}>
                                                            <div className="results-image">
                                                                <img src={element.featuredImage} alt={""}/>
                                                            </div>
                                                            <div className="results-text">
                                                                <div className="artwork-title" dangerouslySetInnerHTML={{__html: element.artworkTitle}} />
                                                                <div>
                                                                    <p>{element?.artistName}</p>
                                                                    <p>{element?.dateArtwork}</p>
                                                                    <p>{element?.roomName && (
                                                                        <>
                                                                            Sala {element?.roomName}
                                                                        </>
                                                                    )}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{
                        padding: 20, 
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                    }}>
                        <img src={infoIcon} style={{width: '20px', height: '20px', objectFit: 'cover'}} />
                        <p style={{fontFamily: 'SohneBuch', fontSize: '15px', lineHeight: '19px', color: 'rgb(20, 20, 20)', margin: '0px 0px 0px 8px', paddingBottom: 0, paddingTop: 0}}>
                            <span style={{fontFamily: 'SohneKraftig'}}>{t('scan.¿No es ninguna de estas obras?')}</span> {t('scan.¿No es ninguna de estas obras?_2')}
                        </p>
                    </DialogActions>
                </Dialog>

            </div>
            <style>
                {`
                    .MuiDialog-container{
                        width: 100vw;
                    }
                    .MuiPaper-root{
                        margin-left: 0px;
                        margin-right: 0px;
                        width: 85%;
                    }
                `}
            </style>
        </>
    )
}

const style = {
    width: '100%',
    height: '100vh',
    bgcolor: 'background.paper',
};
