import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../../../components/Admin/TitlePage";
import InputButtonLoading from "../../../../../../../components/Admin/Inputs/InputButtonLoading";
import { apiSaveBlockText } from "../../../../../../../services/Admin/page";
import EditorTextWeb from "../../../../../../../components/EditorTextWeb";
import InputText from "../../../../../../../components/Admin/Inputs/InputText";
import ReactDOMServer from "react-dom/server";
import FullTextToHtml from "./FullTextToHtml";
import {clone} from "lodash";


export default function Text2col(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    const [loadingSave, setLoadingSave] = useState(false)
   
    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)

        state.blockText.htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.text} />);
        state.blockText.itemOneHtmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.itemOne} />);

        apiSaveBlockText(state).then(resp => {
            handleRefresh()
            setMsgSuccess(resp.data?.msg)
            setLoadingSave(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
            handleRefresh()
        })
      
    }

    const handleChangeBlockText = (e) => {
        state.blockText[e.target.name] = e.target.value
        setState({ ...state })
    }

    const handleRefresh = () => {
        setReloadPreview(Math.random())
    }

    return (
        <>
            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title={state.block.webBlockName} borderTop={false} borderBottom={false} />
            </Grid>

            <Grid item md={6}>
                <EditorTextWeb
                    options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'history']}
                    content={state.blockText.text}
                    setContent={(e) => {
                        state.blockText.text = e
                        setState({ ...state })
                    }}
                />
            </Grid>
            <Grid item md={6}>
                <EditorTextWeb
                    options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'history']}
                    content={state.blockText.itemOne}
                    setContent={(e) => {
                        state.blockText.itemOne = e
                        setState({ ...state })
                    }}
                />
            </Grid>

            
            <Grid item md={12}>
                <InputButtonLoading
                    onClick={handleSubmit}
                    loading={loadingSave}
                    label="Modificar"
                    position="left"
                    style={{ float: 'right' }}
                />
            </Grid>
        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        fileBackgroundImage: [],

    }
}

