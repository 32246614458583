import * as React from 'react';
import {useTranslation} from "react-i18next";

export default function ObraSalvadaFilter() {

    const {t} = useTranslation()

    return (
        <div className='obra-salvada-filter'>
            <span>{t('game.save')}</span>
        </div>
    )
}
