import React, {useEffect, useRef, useState} from "react";
import {Grid, TextField} from "@mui/material";
import DatePickerSimple from "../../../../../components/Admin/Inputs/DatePickerSimple";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import Header from "./Header";
import {useNavigate, useParams} from "react-router-dom";
import clone from "../../../../../utils/clone";
import {apiSaveInsult, apiGetInsult} from "../../../../../services/Admin/obra";
import {apiDeleteInsult} from "../../../../../services/Admin/obraDelete";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";
import ModalConfirm from "../../../../../components/Admin/ModalConfirm";
import EditorText from "../../../../../components/EditorText";
import TitlePage from "../../../../../components/Admin/TitlePage";

export default function TabInsults() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(false)
    const myRef = useRef(null)
    const [openModal, setOpenModal] = React.useState(false)
    const [pendingIndexRemove, setPendingIndexRemove] = React.useState(null)

    const handleOpenModal = (key) => {
        setOpenModal(true)
        setPendingIndexRemove(key)
    }

    useEffect(() => {
        getData()
    }, [state.langSelect])

    const getData = () => {
        setLoadingGetInfo(true)

        apiGetInsult({id}, state?.langSelect).then(resp => {
            let insultsNew = resp.data.insults?.map(doc => {
                return {
                    ...doc
                }
            })

            setState({...state, insults: insultsNew})
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data.msg)
        })
    }

    const handleChange = key => (e) => {
        state.insults[key][e.target.name] = e.target.value
        setState({...state})
    }

    const handleChangeTrans = key => (event) => {
        state.insults[key][event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = index => async () => {
        stateUpdateSimple(index, true, [], [])

        let error = validationSchema(state)

        if (error.length !== 0) {
            stateUpdateSimple(index, false, error, [])
            return
        }

        let insults = clone(state.insults[index])
        insults.langSelect = state.langSelect

        await apiSaveInsult(insults).then(resp => {
            state.insults[index].id = resp.data?.id
            setState({...state})
            stateUpdateSimple(index, false, [], resp.data?.msg)
        }).catch(err => {
            stateUpdateSimple(index, false, err.response?.data?.msg, [])
        })
    }

    const stateUpdateSimple = (index, loading, msgError, msgSuccess) => {
        state.insults[index].loading = loading
        state.insults[index].msgError = msgError
        state.insults[index].msgSuccess = msgSuccess

        setState({...state})
    }

    const stateDeleteSimple = (index, loadingDelete, msgError, msgSuccess) => {
        state.insults[index].loadingDelete = loadingDelete
        state.insults[index].msgError = msgError
        state.insults[index].msgSuccess = msgSuccess

        setState({...state})
    }

    const addInsult = () => {
        let model = state.model
        model.artworkId = id
        setState({...state, insults: [...state.insults, clone(model)]})
        myRef.current.scrollIntoView()
    }

    const handleRemove = () => {
        stateDeleteSimple(pendingIndexRemove, true, [], [])
        apiDeleteInsult({
            id: state.insults[pendingIndexRemove].id
        }).then(resp => {
            window.location.reload();
            stateDeleteSimple(pendingIndexRemove, false, [], resp.data?.msg)
            setPendingIndexRemove(null)
        }).catch(err => {
            stateDeleteSimple(pendingIndexRemove, false, err.response?.data?.msg, [])
            setPendingIndexRemove(null)
        })
    }

    return (
        <>
            <Header
                state={state}
                setState={setState}
                id={id}
                tabType={6}
                msgError={msgError}
                msgSuccess={msgSuccess}
            >
                <InputButton
                    style={{float: "right", margin: "5px 10px 10px 0px"}}
                    onClick={addInsult}
                    text=" + Afegir insult"
                />
            </Header>

            <LoadingSpinner show={loadingGetInfo}/>

            <div style={{display: loadingGetInfo ? 'none' : ''}}>
                {state.insults.map((element, key) => (
                    <Grid container spacing={3} style={{padding: '0px'}} key={key}>

                        {key === 0 ? (
                            <Grid item xs={12} md={12} lg={12} style={{paddingTop: 0}}>
                                <TitlePage title={`Insult ${key + 1}`}/>
                            </Grid>
                        ) : (
                            <Grid item xs={12} md={12} lg={12}>
                                <TitlePage title={`Insult ${key + 1}`} borderTop={true}/>
                            </Grid>
                        )}

                        <Grid item xs={12} md={12} lg={12}>
                            <p style={{
                                fontSize: '11px',
                                fontFamily: 'AdminFontMedium',
                                color: '#778',
                                marginTop: '0px',
                                paddingLeft: '15px'
                            }}>Text</p>
                            <EditorText
                                style={{height: '132px'}}
                                content={state.insults[key]?.text}
                                setContent={(newContent) => {
                                    setState((prevState) => {
                                        const insults = [...prevState.insults];
                                        insults[key] = {
                                            ...insults[key],
                                            text: newContent
                                        };
                                        return {...prevState, insults};
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <p style={{
                                fontSize: '11px',
                                fontFamily: 'AdminFontMedium',
                                color: '#778',
                                marginTop: '0px',
                                paddingLeft: '15px'
                            }}>Font</p>
                            <EditorText
                                style={{height: '40px'}}
                                content={state.insults[key]?.source}
                                setContent={(newContent) => {
                                    setState((prevState) => {
                                        const insults = [...prevState.insults];
                                        insults[key] = {
                                            ...insults[key],
                                            source: newContent
                                        };
                                        return {...prevState, insults};
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <InputText
                                label="Data"
                                name="date"
                                value={state.insults[key]?.date}
                                onChange={handleChange(key)}
                                disabled={loadingGetInfo}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <InputText
                                label="Autor"
                                name="author"
                                value={state.insults[key]?.author}
                                onChange={handleChange(key)}
                                disabled={loadingGetInfo}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={4}>
                            <div style={{display: 'block'}}>
                                <MsgSuccess msg={state.insults[key].msgSuccess}/>
                                <MsgError msg={state.insults[key].msgError}/>
                            </div>

                            <InputButtonLoading
                                onClick={handleSubmit(key)}
                                loading={state.insults[key].loading}
                                label={state.insults[key].id ? 'Modificar' : 'Crear'}
                                position="left"
                                style={{marginRight: '15px'}}
                            />

                            {state.insults[key].id && (
                                <InputButtonLoading
                                    onClick={() => handleOpenModal(key)}
                                    loading={state.insults[key].loadingDelete}
                                    label="Eliminar"
                                    position="left"
                                    variant="outline"
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div style={{height: 5}}></div>
                        </Grid>
                    </Grid>
                ))}
            </div>
            <ModalConfirm
                openModal={openModal}
                setOpenModal={setOpenModal}
                handleAction={handleRemove}
                text="Eliminar el bloc d'insults?"
            />
            <div ref={myRef}></div>
        </>
    );
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        insults: [],
        model: {
            artworkId: null,
            id: null,
            source: "",
            date: "",
            author: "",
            text: "",
            loading: false,
            loadingDelete: false,
            msgSuccess: [],
            msgError: []
        },
    }
}

function validationSchema(schema) {

    let error = []

    return error
}
