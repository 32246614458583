import React, {useEffect, useState} from "react";
import InputSelect from '../../../../../../components/Admin/Inputs/InputSelect'
import {apiGetQuote} from "../../../../../../services/Admin/obra";
import {Grid} from "@mui/material";
import ButtonSecondary from "../../../../../../components/Buttons/ButtonSecondary";
import ButtonPrimary from "../../../../../../components/Buttons/ButtonPrimary";
import {setStorySlideSectionSave} from "../../../../../../services/Admin/story";
import {LoadingButton} from "@mui/lab";
import InputButton from "../../../../../../components/Admin/Inputs/InputButton";
import InputButtonLoading from "../../../../../../components/Admin/Inputs/InputButtonLoading";
import LoadingSpinner from "../../../../../../components/LoadingSpinner";

export default function Quote(props) {

    const {
        artworkId,
        slideId,
        section,
        setSectionSelected,
        selectionInit,
        setMsgError,
        colseSection
    } = props

    const [loading, setLoading] = useState(false)
    const [loadingPage, setLoadingPage] = useState(true)
    const [optionsQuote, setOptionsQuote] = useState([])
    const [stateForm, setStateForm] = useState(initialValues)

    useEffect(() => {
        if (artworkId) {
            setLoadingPage(true)
            getQuote()
        }
    }, [artworkId])

    const handleChange = (e) => {
        stateForm[e.target.name] = e.target.value
        setStateForm({...stateForm})
    }

    const getQuote = () => {
        setLoadingPage(true)
        apiGetQuote({
            id: artworkId,
            langControl: true
        }).then(resp => {
            let temp = resp.data.quotes.map(element => {
                return {
                    id: element.id,
                    name: element.text
                }
            })
            setOptionsQuote(temp)
            setLoadingPage(false)
        }).catch(err => {
            setMsgError(err.response.data.msg)
            setLoading(false)
            setLoadingPage(false)
        })
    }

    const handleSave = () => {

        // No dejamos guardar si el formulario no esta completo
        if (!stateForm?.artworkQuoteId) {
            setMsgError(['Falten camps per omplir'])
            return
        }

        setLoading(true)
        setStorySlideSectionSave({
            slideId: slideId,
            sectionId: section.id,
            artworkQuoteId: stateForm?.artworkQuoteId
        }).then(resp => {
            colseSection()
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response.data.msg)
            setLoading(false)
        })
    }

    const handleCancel = () => {
        setMsgError([])
        setSectionSelected(selectionInit)
    }

    return (
        <Grid container spacing={1}>
            <Grid item md={12}>
                <h2 style={{marginTop: 0}}>{section.name}</h2>
            </Grid>

            <LoadingSpinner show={loadingPage}/>

            {!loadingPage && (
                <>
                    <Grid item md={12} style={{marginTop: '20px', marginBottom: '20px'}}>
                        <InputSelect
                            label="Apartat"
                            name="artworkQuoteId"
                            options={optionsQuote}
                            selectText="Selecciona el contingut"
                            onChange={handleChange}
                            value={stateForm?.artworkQuoteId}
                        />
                    </Grid>

                    <Grid item md={6}>
                        <InputButton
                            onClick={handleCancel}
                            text="← Cancelar"
                            variant="outlined"
                            style={{width: '100%'}}
                        />
                    </Grid>

                    <Grid item md={6}>
                        <InputButtonLoading
                            onClick={handleSave}
                            loading={loading}
                            label="Guardar"
                            position="left"
                            style={{width: '100%'}}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    )
}

function initialValues() {
    return {
        artworkQuoteId: null
    }
}
