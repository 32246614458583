import React, {useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import ReactPlayer from "react-player";
import PlayIcon from './Icons/PlayIcon';
import {useTranslation} from "react-i18next";

/**
 * Muestra un video con subtítulos
 *
 * ## PROPS
 * - **urlVideo** *string*
 * - **urlSubtitle** *string*
 */
export default function PlayerVideo(props) {

    const {
        pluralTitle,
        hideTitle,
        content,
        urlSubtitle,
        className
    } = props;
    const {t} = useTranslation()

    const [trackLang, setTrackLang] = useState()
    const [playing, setPlaying] = useState(false)
    const [start, setStart] = useState(false)
    const [title, setTitle] = useState("")

    const hideHeaderIn = hideTitle === false ? false : true

    const player = useRef();
    // const [padding, setPadding] = useState(0)

    useEffect(() => {
        const textTracks = player.current.getInternalPlayer()?.textTracks;

        for (var i = 0; textTracks?.length && i < textTracks.length; i++) {
            // For the 'subtitles-off' button, the first condition will never match so all will subtitles be turned off
            if (textTracks[i].language === trackLang) {
                textTracks[i].mode = "showing";
            } else {
                textTracks[i].mode = "hidden";
            }
        }
    }, [trackLang]);

    const styles = {
        button:{
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            margin: "auto",
            border: "none",
            color: playing ? "transparent" : "#ffffff",
            background: playing ? "transparent" : "#0000004d",
            display: start ? "none" : "inline-block"
        }
    };

    useEffect(() => {
        if(pluralTitle){
            setTitle(t('story.plural.video'))
        }else{
            setTitle(t('story.video'))
        }
    }, [title]);

    const setLightClick = () => {
        var node = document.querySelector(".video-block .player-container .react-player__preview");
        var a = ReactDOM.findDOMNode(node);
        // a.click()
    }

    const handleButtonClickStopVideo = (event) => {
        event.stopPropagation();
    };
    
    return (
        <>
            {content.video && 
                <div className={className} style={{marginTop: hideHeaderIn ? '0px' : '-4px'}}>
                    <div style={{borderTop: hideHeaderIn ? '1px solid #FE7A84' : 'none', paddingTop: '8px', paddingBottom: '4px'}}>
                        {hideHeaderIn && (<h2 style={{fontFamily: 'SohneBreitHalbfett', fontSize: '15px', lineHeight: '18px', color: '#E30613', margin: '0px', marginBottom: '16px'}}>{title}</h2>)}
                        <div style={{marginBottom: '10px'}}>
                            <div style={{position: 'relative'}} onClick={handleButtonClickStopVideo} className="player-container">
                                <ReactPlayer
                                    className="react-player"
                                    width="100%"
                                    height="100%"
                                    ref={player}
                                    config={{
                                        file: {
                                            attributes: {
                                                crossOrigin: "anonymous"
                                            },
                                            tracks: [
                                                {
                                                    kind: "subtitles",
                                                    src:
                                                    urlSubtitle,
                                                    srcLang: "en",
                                                    default: false,
                                                    mode: trackLang === "en" ? "showing" : "hidden"
                                                }
                                            ]
                                        }
                                    }}
                                    url={content.video}
                                    playing={playing}
                                    loop
                                    controls={start ? true : false}
                                    light={content.thumbnail}
                                />

                                <button style={styles.button} onClick={() => {
                                    setStart(!start);
                                    setPlaying(!playing);
                                    setTrackLang("en")
                                    setLightClick()
                                }}>
                                    {!start && <PlayIcon className="play-icon" color="#333333" style={{margin: 'auto'}} />}
                                </button>
                            </div>
                            <div style={{fontSize: '12px', lineHeight: '16px', color: '#141414', fontFamily: 'SohneBuch', marginTop: '8px', marginBottom: '24px', paddingBottom: '0px'}} dangerouslySetInnerHTML={{ __html: content.footer }} className="text-block-html" />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
