import React, { useState } from "react";
import { convertFromRaw } from 'draft-js';
import { EditorState } from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {useTranslation} from "react-i18next";

import logoOne from "../../../../assets/images/ona-black.svg";

export default function Footer({ item }) {

    const [state, setState] = useState(initialValues(item))
    const {i18n} = useTranslation()

    return (
        <>
            <Grid container spacing={2} md={12} style={{width: '100%'}} 
              direction="row"
              wrap="wrap"
              justifyContent="space-between"
              className="cajaMovile">
                <Grid item md={6} style={{width:"48%", display: "flex", flexDirection: "column",justifyContent: "space-between", paddingLeft: '0px'}}>
                    <div>
                        <Item style={{paddingLeft:"0px", marginBottom: '20px'}}>
                            <div>
                                <h4 className="title">{state?.backgroundImageName}</h4>
                            </div>
                            <div>
                                <div className="text dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.text }} />
                            </div>
                        </Item>
                        <Item style={{marginBottom: '20px'}}>
                            <div>
                                <h4 className="title">{state?.btnWhiteName}</h4>
                            </div>
                            <div>
                                <div className="text dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.btnWhiteLink }} />
                            </div>
                        </Item>
                    </div>
                    <Item style={{marginBottom: '20px'}}>
                        <div>
                            <div className="text dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.itemFive }} />
                        </div>
                    </Item>
                </Grid>
                <Grid item md={6} style={{width:"48%", paddingLeft:"0px"}}>
                    <Item style={{marginBottom: '20px'}}>
                        <div>
                            <h4 className="title">{state?.backgroundImage}</h4>
                        </div>
                        <div>
                            <div className="text fcol2 dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.btnRedName }} />
                            <div className="text fcol2 dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.btnRedLink }} />
                        </div>
                    </Item>
                    <Item style={{marginBottom: '20px'}}>
                        <div>
                            <h4 className="title">{state?.itemOneName}</h4>
                        </div>
                        <div>
                            <div className="text dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.itemOne }} />
                        </div>
                    </Item>
                </Grid>
                <Grid container spacing={2} md={12}
                 direction="row"
                 wrap="wrap"
                 justifyContent="space-between"
                 className="cajaMovile">
                    <Item md={6} style={{width:"48%", paddingLeft:"0px"}}>
                        <div>
                            <h5 className="title">{state?.itemThreeName}</h5>
                        </div>
                        <div className="cajaIcons">
                            <a href={state?.itemThree} className="ficon"><InstagramIcon /></a>
                            <a href={state?.itemFourName} className="ficon"><TwitterIcon /></a>
                            <a href={state?.itemFour} className="ficon"><YouTubeIcon /></a>
                        </div>
                    </Item>

                    <Item md={6} style={{width:"48%",paddingLeft:"0px"}}>
                        <div>
                            <h4 className="title">{state?.itemTwoName}</h4>
                        </div>
                        <div>
                            <div className="text dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.itemTwo }} />
                        </div>
                    </Item>
                    {/* <Item xs={12}>
                        <div style={{
                            maxWidth: '100%', 
                            marginTop: '20px', 
                            borderTop: '2px solid #000', 
                            paddingTop: '7px', 
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center'
                        }}>
                            <img src={logoOne} style={{maxWidth: '110px', width: '100%', height: '100%', objectFit: 'cover'}} />
                        </div>
                    </Item> */}
                </Grid>
            </Grid>

            <Grid container spacing={2} md={12} style={{width: '100%'}}
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                className="cajaTabDesk"
            >
                <Grid item >
                {/* Cambio estilos */}
                {/* style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'stretch'
                }} */}
                    <Item>
                        <div>
                            <h4 className="title">{state?.backgroundImageName}</h4>
                        </div>
                        <div>
                            <div className="text dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.text }} />
                        </div>
                    </Item>
                    {/* Cambio estilos */}
                    {/* <Item>
                        <div style={{maxWidth: '110px'}}>
                            <img src={logoOne} style={{width: '100%', height: '100%', objectFit: 'cover'}} />
                        </div>
                    </Item> */}
                </Grid>
                <Grid item>
                    <Item>
                        <div>
                            <h4 className="title">{state?.backgroundImage}</h4>
                        </div>
                    </Item>
                    <Grid container md={12} className="info-practica-container">
                        <Grid item className="info-practica">
                            <div>
                                <div className="text fcol2 dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.btnRedName }} />
                            </div>
                        </Grid>
                        <Grid item className="info-practica">
                            <div>
                                <div className="text fcol2 dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.btnRedLink }} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Item>
                        <div>
                            <h4 className="title">{state?.btnWhiteName}</h4>
                        </div>
                        <div>
                            <div className="text dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.btnWhiteLink }} />
                        </div>
                    </Item>
                </Grid>
                <Grid item>
                    <Item>
                        <div>
                            <h4 className="title">{state?.itemOneName}</h4>
                        </div>
                        <div>
                            <div className="text dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.itemOne }} />
                        </div>
                    </Item>
                </Grid>
                <Grid item>
                    <Item>
                        <div>
                            <h4 className="title">{state?.itemTwoName}</h4>
                        </div>
                        <div>
                            <div className="text dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.itemTwo }} />
                        </div>
                        <div>
                            <h5 className="titleRRSS">{state?.itemThreeName}</h5>
                        </div>
                        <div className="cajaIcons">
                            <a href={state?.itemThree} className="ficon"><InstagramIcon /></a>
                            <a href={state?.itemFourName} className="ficon"><TwitterIcon /></a>
                            <a href={state?.itemFour} className="ficon"><YouTubeIcon /></a>
                        </div>
                        <div>
                            <div className="text dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.itemFive }} />
                            <div className="text text-legal">
                                <p style={{fontSize:"13px", marginTop:"-3px"}}>
                                    {i18n.resolvedLanguage == 'ca' && (<>
                                        <a href="#" className="cky-banner-element">POLÍTICA DE GALETES</a></>)}
                                    {i18n.resolvedLanguage == 'es' && (<>
                                        <a href="#" className="cky-banner-element">POLÍTICA DE COOKIES</a></>)}
                                    {i18n.resolvedLanguage == 'en' && (<>
                                        <a href="#" className="cky-banner-element">COOKIES</a></>)}
                                </p>
                            </div>
                        </div>
                    </Item>
                </Grid>
            </Grid>
        </>
    )
}

function initialValues(props) {
  
    let a;

    if (props?.text) {
        a = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.text))).getCurrentContent()
    }
    else {
        a = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentState = convertToRaw(a);

    let markup = draftToHtml(rawContentState);
    markup = markup.replace(/<p><\/p>/gi, "<br/>");


    if (props?.btnRedName) {
        a = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.btnRedName))).getCurrentContent()
    }
    else {
        a = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentStateB = convertToRaw(a);

    let markupB = draftToHtml(rawContentStateB);
    markupB = markupB.replace(/<p><\/p>/gi, "<br/>");

    if (props?.btnRedLink) {
        a = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.btnRedLink))).getCurrentContent()
    }
    else {
        a = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentStateC = convertToRaw(a);

    let markupC = draftToHtml(rawContentStateC);
    markupC = markupC.replace(/<p><\/p>/gi, "<br/>");

    if (props?.btnWhiteLink) {
        a = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.btnWhiteLink))).getCurrentContent()
    }
    else {
        a = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentStateD = convertToRaw(a);

    let markupD = draftToHtml(rawContentStateD);
    markupD = markupD.replace(/<p><\/p>/gi, "<br/>");

    if (props?.itemOne) {
        a = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.itemOne))).getCurrentContent()
    }
    else {
        a = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentStateE = convertToRaw(a);

    let markupE = draftToHtml(rawContentStateE);
    markupE = markupE.replace(/<p><\/p>/gi, "<br/>");

    if (props?.itemTwo) {
        a = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.itemTwo))).getCurrentContent()
    }
    else {
        a = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentStateF = convertToRaw(a);

    let markupF = draftToHtml(rawContentStateF);
    markupF = markupF.replace(/<p><\/p>/gi, "<br/>");

    if (props?.itemFive) {
        a = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.itemFive))).getCurrentContent()
    }
    else {
        a = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentStateG = convertToRaw(a);

    let markupG = draftToHtml(rawContentStateG);
    markupG = markupG.replace(/<p><\/p>/gi, "<br/>");

    return {
        'backgroundImageName': props?.backgroundImageName,
        'text': markup,

        'backgroundImage': props?.backgroundImage,
        'btnRedName': markupB,
        'btnRedLink': markupC,

        'btnWhiteName': props?.btnWhiteName,
        'btnWhiteLink': markupD,

        'itemOneName': props?.itemOneName,
        'itemOne': markupE,

        'itemTwoName': props?.itemTwoName,
        'itemTwo': markupF,

        'itemThreeName': props?.itemThreeName,

        'itemThree': props?.itemThree,
        'itemFourName': props?.itemFourName,
        'itemFour': props?.itemFour,

        'itemFive': markupG,
    }
}

