import React, { useState, useEffect } from 'react';
import {useTranslation} from "react-i18next";
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import './style-camera.css';

export default function ImageScanner({ setImageData, loading, bottomMargin, pressCapturePhoto, setTextCenter, setImageDataBase64 }) {
    const {t} = useTranslation()
    const [cameraPermission, setCameraPermission] = useState(true); // Estat per controlar l'estat dels permisos de la càmera

    useEffect(() => {
        if (pressCapturePhoto) {
            capturePhoto();
        }
    }, [pressCapturePhoto]);

    const onTakePhoto = (file, base64) => {
        setImageData(file)
        setImageDataBase64(base64)

    }

    const onCameraError = (error) => {
        setCameraPermission(false)
        setTextCenter(t('scan.permissions'));
    }

    // const capturePhoto = () => {
    //     const video = document.querySelector('.react-html5-camera-photo video');
    //     if (video) {
    //         const canvas = document.createElement('canvas');
    //         canvas.width = video.videoWidth;
    //         canvas.height = video.videoHeight;
    //         const context = canvas.getContext('2d');
    //         context.drawImage(video, 0, 0, canvas.width, canvas.height);
    //         const dataUri = canvas.toDataURL('image/png');
    //
    //         if (dataUri !== 'data:,') {
    //             onTakePhoto(dataUri);
    //         }
    //     }
    // }

    const capturePhoto = () => {
        const video = document.querySelector('.react-html5-camera-photo video');
        if (video) {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            canvas.toBlob((blob) => {
                if (blob) {
                    const fileName = 'captured_image.png'; // Nombre del archivo
                    const file = new File([blob], fileName, { type: blob.type });

                    //Base64
                    const dataUri = canvas.toDataURL('image/png');
                    let base64 = null
                    if (dataUri !== 'data:,') {
                        base64 = dataUri
                    }
                    //

                    onTakePhoto(file, base64);
                }
            }, 'image/png');
        }
    }

    return (
        <>
            <>
                {cameraPermission && (
                    <div>
                        <Camera
                            idealFacingMode={FACING_MODES.ENVIRONMENT}
                            onTakePhoto={onTakePhoto}
                            isFullscreen={true}
                            className="camera"
                            onCameraError={onCameraError}
                        />
                    </div>
                )}
            </>
        </>
    );
}
