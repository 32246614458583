import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

/**
 * Muestra un reproductor de audio
 *
 * ## PROPS
 * - **url** *string*
 */
export default function CloseIcon(props) {

    const {className} = props;

    return (
        <div className={className}>
            <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 74 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M73.2957 28.6641C73.2957 28.6641 71.0388 32.5154 62.388 34.2175C66.0552 28.8427 67.7481 20.6021 64.7388 14.5112C63.7044 19.6172 60.9779 21.5872 58.1568 23.2893C58.7209 19.8853 57.4044 6.71806 47.7193 0C50.2583 7.43466 41.6076 14.1527 41.6076 14.1527C41.6076 14.1527 39.3507 13.436 35.9654 2.23935C27.5967 9.31539 30.6054 23.0206 30.6054 23.0206C30.6054 23.0206 24.0233 21.6772 23.3648 13.8838C18.1929 19.6166 23.7406 30.9029 23.7406 30.9925C23.7406 30.9925 17.7227 30.0965 17.4406 23.9165C12.175 32.0679 18.8508 41.8313 26.1851 46.4892C26.0913 44.5185 26.2789 42.2792 27.2195 39.6817C29.382 33.5009 36.9984 30.993 37.8451 27.2309C38.6913 29.2015 39.4436 32.9636 38.3154 34.397C42.8287 31.6203 43.6755 28.7536 44.8974 24.1855C48.8467 28.3062 51.0091 33.6802 50.8216 39.2336C52.2318 37.3523 53.2662 35.0237 53.8309 32.7843C57.4042 40.3084 54.8652 49.8034 46.4964 54.6404C57.9682 56.7903 67.2773 46.3996 67.2773 46.3996C67.2773 46.3996 62.4819 46.4889 59.8492 46.5789C69.3462 41.2936 73.2957 28.6641 73.2957 28.6641Z" fill="#FDFDFD"/>
                <path d="M1.55724 74.1795C0.521819 74.976 0.427928 76.4809 1.27491 77.3663L2.96885 79.2256C3.81584 80.1111 5.32201 80.2877 6.3574 79.4912L33.5008 55.702L28.7005 50.4793L1.55724 74.1795Z" fill="#FDFDFD"/>
                <path d="M47.46 39.3267C44.5028 36.1674 38.6834 36.4382 34.4859 39.8682C31.7197 42.1246 30.2883 45.1931 30.3842 47.901L35.9172 54.0381C38.7793 54.5795 42.2136 53.6773 44.9798 51.421C49.2725 47.901 50.4171 42.4859 47.46 39.3267Z" fill="#FDFDFD"/>
            </svg>
        </div>
    )
}
