import {BASE_PATH, BASE_PATH_EXTENSION} from "../../utils/constants";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION}/user/page`;


export function getPageInfo(props, lang = 'ca') {
  
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/`, props, {})
}


export function apiGetFooter(langSelect, lang = "ca", ) {
   
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/get-footer/${langSelect}`)
}