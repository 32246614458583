import React, {useEffect, useState} from "react"

// Secuencia de imágenes
import frame0 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00000.png';
import frame1 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00001.png';
import frame2 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00002.png';
import frame3 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00003.png';
import frame4 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00004.png';
import frame5 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00005.png';
import frame6 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00006.png';
import frame7 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00007.png';
import frame8 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00008.png';
import frame9 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00009.png';
import frame10 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00010.png';
import frame11 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00011.png';
import frame12 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00012.png';
import frame13 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00013.png';
import frame14 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00014.png';
import frame15 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00015.png';
import frame16 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00016.png';
import frame17 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00017.png';
import frame18 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00018.png';
import frame19 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00019.png';
import frame20 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00020.png';
import frame21 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00021.png';
import frame22 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00022.png';
import frame23 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00023.png';
import frame24 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00024.png';
import frame25 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00025.png';
import frame26 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00026.png';
import frame27 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00027.png';
import frame28 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00028.png';
import frame29 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00029.png';
import frame30 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00030.png';
import frame31 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00031.png';
import frame32 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00032.png';
import frame33 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00033.png';
import frame34 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00034.png';
import frame35 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00035.png';
import frame36 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00036.png';
import frame37 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00037.png';
import frame38 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00038.png';
import frame39 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00039.png';
import frame40 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00040.png';
import frame41 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00041.png';
import frame42 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00042.png';
import frame43 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00043.png';
import frame44 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00044.png';
import frame45 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00045.png';
import frame46 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00046.png';
import frame47 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00047.png';
import frame48 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00048.png';
import frame49 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00049.png';
import frame50 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00050.png';
import frame51 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00051.png';
import frame52 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00052.png';
import frame53 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00053.png';
import frame54 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00054.png';
import frame55 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00055.png';
import frame56 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00056.png';
import frame57 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00057.png';
import frame58 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00058.png';
import frame59 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00059.png';
import frame60 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00060.png';
import frame61 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00061.png';
import frame62 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00062.png';
import frame63 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00063.png';
import frame64 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00064.png';
import frame65 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00065.png';
import frame66 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00066.png';
import frame67 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00067.png';
import frame68 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00068.png';
import frame69 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00069.png';
import frame70 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00070.png';
import frame71 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00071.png';
import frame72 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00072.png';
import frame73 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00073.png';
import frame74 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00074.png';
import frame75 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00075.png';
import frame76 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00076.png';
import frame77 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00077.png';
import frame78 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00078.png';
import frame79 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00079.png';
import frame80 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00080.png';
import frame81 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00081.png';
import frame82 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00082.png';
import frame83 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00083.png';
import frame84 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00084.png';
import frame85 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00085.png';
import frame86 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00086.png';
import frame87 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00087.png';
import frame88 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00088.png';
import frame89 from '../../../../assets/images/front/animaciones/stories/secuencia/vertical/Desliza-bajo 2_00089.png';

export default function InstructionsAnimationVertical() {

    const frames = [
        frame0,
        frame1,
        frame2,
        frame3,
        frame4,
        frame5,
        frame6,
        frame7,
        frame8,
        frame9,
        frame10,
        frame11,
        frame12,
        frame13,
        frame14,
        frame15,
        frame16,
        frame17,
        frame18,
        frame19,
        frame20,
        frame21,
        frame22,
        frame23,
        frame24,
        frame25,
        frame26,
        frame27,
        frame28,
        frame29,
        frame30,
        frame31,
        frame32,
        frame33,
        frame34,
        frame35,
        frame36,
        frame37,
        frame38,
        frame39,
        frame40,
        frame41,
        frame42,
        frame43,
        frame44,
        frame45,
        frame46,
        frame47,
        frame48,
        frame49,
        frame50,
        frame51,
        frame52,
        frame53,
        frame54,
        frame55,
        frame56,
        frame57,
        frame58,
        frame59,
        frame60,
        frame61,
        frame62,
        frame63,
        frame64,
        frame65,
        frame66,
        frame67,
        frame68,
        frame69,
        frame70,
        frame71,
        frame72,
        frame73,
        frame74,
        frame75,
        frame76,
        frame77,
        frame78,
        frame79,
        frame80,
        frame81,
        frame82,
        frame83,
        frame84,
        frame85,
        frame86,
        frame87,
        frame88,
        frame89
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
            const interval = setInterval(() => {
                setCurrentIndex(prevIndex => (prevIndex + 1) % frames.length);
            }, 25);
    
            return () => {
                clearInterval(interval);
            };
    }, [frames]);

    return (
        <div className="animation-onboarding">
            <img src={frames[currentIndex]} />
        </div>
    )
}
