import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import MobileMessage from "../../../../components/User/MobileMessage";
import {apiScanner} from "../../../../services/User/imageScanner";
import Camera from "./Camera";
import {RouteUser} from "../../../../routes/RouteUser";
import {useNavigate} from "react-router-dom";
import MensajeCentral from "./MensajeCentral";
import ResultStories from "./ResultStories";
import Info from "./Info";
import ReactGA from "react-ga4";

export default function ImageScanner() {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()

    const [imageData, setImageData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [bottomMargin, setBottomMargin] = useState(0)
    const [pressCapturePhoto, setPressCapturePhoto] = useState(null)
    const [textCenter, setTextCenter] = useState(t('scan.instructions'))
    const [color, setColor] = useState('#fff')
    const [textCenterH1, setTextCenterH1] = useState('')
    const [imageDataBase64, setImageDataBase64] = useState(null)
    const [height, setHeight] = useState(0)
    const [openResults, setOpenResults] = useState(false)
    const [artworks, setArtworks] = useState([])
    const [openInfo, setOpenInfo] = useState(false)

    useEffect(() => {
        ReactGA.event({
            category: "Scanner",
            action: `Scanner`,
        });

        // Comprobamos si se ha mostrado esta pantalla
        const scannerInfo = localStorage.getItem('scanner-info')
        if (scannerInfo !== 'no') {
            setOpenInfo(true)
        }
    }, [])

    useEffect(() => {
        if (imageData) {
            setColor('red')
            setTextCenter(t('scan.scanning'))
            // setBottomMargin('200px')
            setLoading(true)

            recortarImagenBase64(imageDataBase64, 20, 20, function (imagenRecortadaBase64) {

                const binaryData = atob(imagenRecortadaBase64.split(',')[1]);

                // Convert the binary data to a Uint8Array
                const uint8Array = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    uint8Array[i] = binaryData.charCodeAt(i);
                }

                // Create a Blob from the Uint8Array
                const blob = new Blob([uint8Array], { type: 'image/png' }); // Adjust the 'image/png' type as needed

                // Create a FormData object and append the Blob
                const formData = new FormData();
                formData.append('file[]', blob, 'image.png'); // 'file' is the field name, 'image.png' is the desired filename

                apiScanner(formData, i18n.resolvedLanguage).then(resp => {
                    if (resp.data.length === 1) {
                        navigate(RouteUser.STORIES.path + '/' + resp.data[0].id)
                    }
                    setArtworks(resp.data)
                    setOpenResults(true)
                    setColor('#fff')
                    setTextCenterH1(null)
                    setTextCenter(t('scan.instructions'))
                    setImageData(null)
                    setLoading(false)

                    if (resp.data[0].artworkTitle) {
                        ReactGA.event({
                            category: "Scanner",
                            action: `Scanner - ${resp.data[0].artworkTitle}`,
                        });
                    }

                }).catch(err => {
                    setColor('red')
                    setTextCenterH1('Error')
                    setTextCenter(t('scan.error'))
                    setLoading(false)
                    setImageData(null)
                })

            });


        }
    }, [imageData])

    function recortarImagenBase64(base64, porcentajeDerecha, porcentajeInferior, callback) {
        const img = new Image();
        img.src = base64;

        img.onload = function () {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Calcula las nuevas dimensiones
            const nuevaAnchura = img.width - (img.width * (porcentajeDerecha / 100));
            const nuevaAltura = img.height - (img.height * (porcentajeInferior / 100));

            // Establece las dimensiones del lienzo
            canvas.width = nuevaAnchura;
            canvas.height = nuevaAltura;

            // Dibuja la imagen recortada en el lienzo
            ctx.drawImage(img, 0, 0, nuevaAnchura, nuevaAltura, 0, 0, nuevaAnchura, nuevaAltura);

            // Convierte el lienzo a base64
            const imagenRecortadaBase64 = canvas.toDataURL('image/png');

            // Llama al callback con la imagen recortada en base64
            callback(imagenRecortadaBase64);
        };
    }

    return (
        <>
            <Info
                open={openInfo}
                setOpen={setOpenInfo}
            />
            <MobileMessage />
            <ResultStories
                open={openResults}
                setOpen={setOpenResults}
                artworks={artworks}
            />

            <div style={{
                width: '100vw',
                height: '100vh',
                background: '#000',
                overflow: 'hidden',
                marginTop: `-${bottomMargin}`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                justifyContent: 'center',
                alignItems: 'center',
            }}>

                {imageData && (
                    <img alt={""} src={imageDataBase64} style={{height: '100vh', width: 'auto', display: 'block', margin: 'auto'}}/>
                )}

                <MensajeCentral
                    textCenter={textCenter}
                    color={color}
                    textCenterH1={textCenterH1}
                    loading={loading}
                    height={height}
                    setHeight={setHeight}
                />

                <div className='camera-filter' />

                {!imageData && (
                    <Camera
                        setImageData={setImageData}
                        loading={loading}
                        bottomMargin={bottomMargin}
                        pressCapturePhoto={pressCapturePhoto}
                        setTextCenter={setTextCenter}
                        setImageDataBase64={setImageDataBase64}
                    />
                )}

                <div>
                    <div
                        style={{
                            position: 'fixed',
                            zIndex: 1,
                            bottom: bottomMargin === 0 ? '20px' : bottomMargin - '20px',
                            left: 0,
                            right: 0,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width: 64,
                        }}
                    >
                        <div style={{width: '48px',
                            height: '48px',
                            border: '2px solid white',
                            padding: '6px',
                            borderRadius: '50%'}}>
                            <button
                                type="button"
                                onClick={() => {
                                    setPressCapturePhoto(Math.random())
                                    setLoading(true)
                                }}
                                style={{
                                    zIndex: 90,
                                    border: 'none',
                                    background: '#fff',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '50%',
                                    padding: '0px'
                                }}
                                disabled={loading}
                            >
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <style>
                {`
                    .camera-filter{
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        background-color: rgba(0, 0, 0, 0.7);
                        clip-path: polygon(0% 0%, -1% 100%, 101% 100%, 101% 0%, -49% 0%, 0% 9.6%, 92.5% 9.6%, 92.5% 85.5%, 7.5% 85.5%, 7.5% 0%, 0% 0%);
                    }
                `}
            </style>

        </>
    )
}
