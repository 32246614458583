import React, {useRef, useState, useEffect} from "react";
import image from "../../../../assets/images/front/web/Test/Captura de pantalla 2023-06-30 a las 12.47 2.png";
import about from "../../../../assets/images/front/web/icons/About.svg";
import clock from "../../../../assets/images/front/web/icons/Clock.svg";
import group from "../../../../assets/images/front/web/icons/Group.svg";
import language from "../../../../assets/images/front/web/icons/Language.svg";

import {convertFromRaw} from 'draft-js';
import {EditorState} from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';


export default function Visit({item}) {

    const [state, setState] = useState(initialValues(item))
    
    // Controlar el orden de los elementos en responsive
    const [orderValueImage, setOrderValueImage] = useState('1')
    const [orderValueText, setOrderValueText] = useState('2')
    
    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
        
    }, [state]);

    const handleResize = () => {
        if(window.innerWidth >= 768){
            if(state?.titleColor){
                setOrderValueText('1')
                setOrderValueImage('2')
            }else{
                setOrderValueImage('1')
                setOrderValueText('2')
            }
        }else{
            setOrderValueImage('1')
            setOrderValueText('2')
        }
    };
    
    return (
        <div className="blocks-full-width">
            <div className='visit-block'>
                <div className="grid-box" style={{order: orderValueImage}}>
                    <div className="image-container">
                        <img title={state?.itemFive} alt={state?.itemSix} src={state?.fileBackgroundImage}/>
                    </div>
                </div>
                <div className="grid-box" style={{order: orderValueText}}>
                    <div className="text-container dynamic-html-text" dangerouslySetInnerHTML={{__html: state?.text}} />
                    {(state?.itemOne || state?.itemTwo || state?.itemThree || state?.itemFour) && (
                        <div className="icons-container">
                            {state?.itemOne && (
                                <div className="row-container">
                                    <div className="icon-container">
                                        <img alt="" src={group}/>
                                    </div>
                                    <p>{state?.itemOne}</p>
                                </div>
                            )}
                            {state?.itemTwo && (
                                <div className="row-container">
                                    <div className="icon-container">
                                        <img alt="" src={clock}/>
                                    </div>
                                    <p>{state.itemTwo}</p>
                                </div>
                            )}
                            {state?.itemThree && (
                                <div className="row-container">
                                    <div className="icon-container">
                                        <img alt="" src={language}/>
                                    </div>
                                    <p>{state.itemThree}</p>
                                </div>
                            )}
                            {state?.itemFour && (
                                <div className="row-container">
                                    <div className="icon-container">
                                        <img alt="" src={about}/>
                                    </div>
                                    <p>{state.itemFour}</p>
                                </div>
                            )}
                        </div>
                        )
                    }
                    {state?.btnRedName && state?.imgPortada && (
                        <div className="red-link-container">
                            <a href={state.btnRedLink} id={state.itemSeven} className="red-link block-link">{state.btnRedName}</a>
                        </div>
                    )}
                    {state?.btnRedName && !state?.imgPortada && (
                        <button className="red-button block-link" id={state.itemSeven}>{state.btnRedName}</button>
                    )}
                    {state?.btnWhiteName && (
                        <div className="white-link-container">
                            <a href={state.btnWhiteLink} id={state.itemEight} className="white-button block-link">{state.btnWhiteName}</a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

function initialValues(props) {

    let a;
    if(props?.text)
    {
        a=EditorState.createWithContent(convertFromRaw(JSON.parse(props?.text))).getCurrentContent()
    }
    else
    {
        a=EditorState.createEmpty().getCurrentContent()
    }

    const rawContentState = convertToRaw(a);

    let markup = draftToHtml(
    rawContentState
    );

    markup=markup.replace(/<p><\/p>/gi, "<br/>");

    return {
        "fileBackgroundImage": props?.fileBackgroundImage,
        "text":  markup,
        "titleColor": props?.titleColor,
        "itemOne": props?.itemOne,
        "itemTwo": props?.itemTwo,
        "itemThree": props?.itemThree,
        "itemFour": props?.itemFour,
        "itemFive": props?.itemFive,
        "itemSix": props?.itemSix,
        "btnRedName": props?.btnRedName,
        "btnRedLink": props?.btnRedLink,
        "btnWhiteName": props?.btnWhiteName,
        "btnWhiteLink": props?.btnWhiteLink,
        "imgPortada": props?.imgPortada,
        "itemSeven": props?.itemSeven,
        "itemEight": props?.itemEight,
    }
}

