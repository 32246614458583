import React, { useRef, useState, useEffect } from "react";
import {useTranslation} from "react-i18next";

export default function GeneralObraBlock(props) {

    const {content, storyId} = props
    const {t} = useTranslation()

    return(
        <div className="text-block" style={{maxWidth: '100%'}}>
            {content.originalTitleSerie && 
                (<div style={{paddingBottom: '28px', paddingTop: '8px', borderTop: '1px solid #FE7A84'}}>
                    {/* <h2 style={{fontFamily: 'SohneBreitHalbfett', fontSize: '15px', lineHeight: '18px', color: '#E30613', margin: '0px', marginBottom: '4px'}}>{t('story.artwork_name_serie')}</h2> */}
                    <div style={{fontFamily: 'SohneBuch', fontSize: '16px', lineHeight: '21px', color: '#141414', margin: '0px'}} dangerouslySetInnerHTML={{ __html: content.originalTitleSerie }} className="text-block-html" />
                </div>)
            }
            {content.title && 
                (<div style={{paddingBottom: '28px', paddingTop: '8px', borderTop: '1px solid #FE7A84'}}>
                    <h2 style={{fontFamily: 'SohneBreitHalbfett', fontSize: '15px', lineHeight: '18px', color: '#E30613', margin: '0px', marginBottom: '4px'}}>{t('story.translated_title')}</h2>
                    <div style={{fontFamily: 'SohneBuch', fontSize: '16px', lineHeight: '21px', color: '#141414', margin: '0px'}} dangerouslySetInnerHTML={{ __html: content.title }} className="text-block-html" />
                </div>)
            }
            {content.description &&
                <div style={{paddingBottom: '28px', paddingTop: '8px', borderTop: '1px solid #FE7A84'}}>
                    <h2 style={{fontFamily: 'SohneBreitHalbfett', fontSize: '15px', lineHeight: '18px', color: '#E30613', margin: '0px', marginBottom: '4px'}}>{storyId == 66 ? t('story.description_place') : t('story.description')}</h2>
                    <div style={{fontFamily: 'SohneBuch', fontSize: '16px', lineHeight: '21px', color: '#141414', margin: '0px'}} dangerouslySetInnerHTML={{ __html: content.description }} className="text-block-html" />
                </div>
            }
        </div>
    )
}
