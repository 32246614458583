import React, {useEffect, useRef, useState} from "react";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import {RouteAdmin} from "../../../../../routes/RouteAdmin";
import {Grid} from "@mui/material";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import InputSelect from "../../../../../components/Admin/Inputs/InputSelect";
import ButtonFile from "../../../../../components/Admin/Inputs/ButtonFile";
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";
import {apiFields, apiFieldsFile, apiGet, apiDeleteFile} from "../../../../../services/Admin/collective";
import {apiList as apiListArtist} from "../../../../../services/Admin/artist";
import {lang} from "../../../../../utils/lang";
import {LoadingButton} from "@mui/lab";
import {useNavigate, useParams} from "react-router-dom";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import {MdDeleteForever} from "react-icons/md";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import clone from "../../../../../utils/clone";

export default function Fields() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [state, setState] = useState(initialValues())
    const [artist, setArtist] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const myRef = useRef(null)

    useEffect(() => {
        setLoadingGetInfo(true)
        setMsgSuccess([])
        setMsgError([])
        getArtist()

        if (id) {
            getCollective()
        } else {
            setLoadingGetInfo(false)
        }
    }, [state.langSelect])

    const getArtist = () => {
        apiListArtist({}).then(resp => {
            setArtist(resp.data.data)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    const getCollective = () => {
        if (state.langSelect) {
            apiGet(id, state.langSelect).then(resp => {
                let items = resp.data
                items["file"] = []
                setState(items)
                setLoadingGetInfo(false)
            }).catch(err => {
                setLoadingGetInfo(false)
                setMsgError(err.response.data?.msg)
            })
        } else {
            setLoadingGetInfo(false)
        }
    }

    const handleSubmit = async () => {
        setMsgError([])
        setMsgSuccess([])
        setLoading(true)

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        state.id = id
        setState({...state})

        apiFields(state).then(resp => {
            handleSubmitFile(resp.data?.id)
            setState({...state, id: resp.data.id})
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
        })

    }

    const handleSubmitFile = (idTemp) => {
        let f = new FormData()
        f.append(`file[]`, state.file[0]?.binary)
        f.append(`originalName`, state.file[0]?.name)

        apiFieldsFile(f, idTemp).then(resp => {
            setLoading(false)
            setMsgSuccess(resp.data?.msg)
            state.photo = resp.data?.data?.photo
            state.photoOriginalName = resp.data?.data?.photoOriginalName
            state.file = []
            setState({...state})
            if (!id) {
                navigate(`${RouteAdmin.COLECTIU_EDIT.path}/${idTemp}`)
            }
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
        })
    }

    const handleChange = (e) => {
        state[e.target.name] = e.target.value
        setState({...state})
    }

    const handleDeleteFile = (fileName) => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingDelete(true)

        apiDeleteFile({
            id: id,
            fileName: fileName
        }).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoadingDelete(false)

            state.photo = resp.data?.data?.photo
            state.photoOriginalName = resp.data?.data?.photoOriginalName
            setState({...state})
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingDelete(false)
        })
    }

    return (
        <>
            <div ref={myRef}></div>

            <HeaderPage
                title="Tornar"
                urlBack={RouteAdmin.COLECTIU_MANAGEMENT.path}
            >
                <div style={{float: 'right'}}>
                    <InputSelect
                        label="Idioma"
                        options={lang}
                        name="langSelect"
                        value={state.langSelect}
                        selectText="Selecciona un idioma"
                        style={{width: 100}}
                        onChange={(e) => {
                            setState({...state, langSelect: e.target.value})
                            localStorage.setItem('langSelect', e.target.value)
                        }}
                    />
                </div>
            </HeaderPage>

            <LoadingSpinner show={loadingGetInfo}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={2} style={{display: loadingGetInfo ? 'none' : ''}}>

                <Grid item xs={3} style={state.file[0]?.base64 || state.photo ? { paddingTop: '16px', height: 'auto' } : { paddingTop: '0px', height: '0px' }}>
                    <img src={state.file[0]?.base64 ?? state.photo} alt="" style={{width: '100%', maxWidth: 200}}/>
                </Grid>
                <Grid item xs={12} style={{padding: '0px'}}/>
                <Grid item xs={3} style={state.file[0]?.name || state.photoOriginalName ? { paddingTop: '16px', height: 'auto' } : { paddingTop: '0px', height: '0px' }}>
                    <p></p>
                    <span style={{wordWrap: 'break-word', overflowWrap: 'break-word'}}>{state.file[0]?.name ?? state.photoOriginalName}</span>
                </Grid>
                <Grid item xs={12} style={{padding: '0px'}}/>
                <Grid item xs={3}>
                    <ButtonFile
                        label="+ FOTO COL·LECTIU"
                        required
                        name="file"
                        onChange={handleChange}
                        multiple={true}
                    />
                </Grid>
                <Grid item xs={3}>
                    {state.photo && (
                        <InputButtonLoading
                            onClick={() => handleDeleteFile(state.photo)}
                            loading={loadingDelete}
                            label={(
                                <><MdDeleteForever fontSize="inherit" style={{width: '20px', height: '20px', marginBottom: '-3px'}} /> Eliminar foto</>
                            )}
                            position="left"
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <InputText
                        label={"Nom"}
                        name="name"
                        required
                        disabled={loadingGetInfo}
                        value={state?.name}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputSelect
                        label="Artistes"
                        name="artist"
                        options={artist}
                        selectText="Selecciona artistes"
                        multiple={true}
                        value={state.artist}
                        onChange={handleChange}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item xs={6}>
                    <InputCheckbox
                        label="Activat"
                        className="mb-12"
                        checked={state?.isActive}
                        onChange={(e) => {
                            state.isActive = e.target.checked
                            setState({...state})
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputButtonLoading
                        onClick={handleSubmit}
                        loading={loading}
                        label={id ? 'Modificar' : 'Crear'}
                        disabled={loading}
                    />
                </Grid>

            </Grid>

        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        photo: props?.photo || "",
        file: [],
        photoOriginalName: "",
        artist: props?.artist || [],
        isActive: props?.isActive || true
    }
}

function validationSchema(schema) {
    let error = []

    if (!schema?.name) {
        error.push("El nom és obligatori")
    }

    return error
}
