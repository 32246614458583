import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Box} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import {RouteAdminObras} from "../../../../../routes/RouteAdminObras";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import {lang} from "../../../../../utils/lang";
import InputSelect from "../../../../../components/Admin/Inputs/InputSelect";

export default function Header(props) {

    const {state, setState, id, msgError, msgSuccess, tabType} = props;

    const handleChangeTab = (event, newValue) => {
        state["type"] = newValue
        setState({...state})
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    return (
        <>
            <HeaderPage
                border={false}
                title="Tornar"
                urlBack={RouteAdminObras.OBRA_MANAGEMENT.path}
            >
                <div style={{float: 'right'}}>
                    <div style={{display: 'flex'}}>
                        {props.children}

                        <div style={{width: 100, height: 20}}>
                            <InputSelect
                                label="Idioma"
                                options={lang}
                                name="langSelect"
                                value={state.langSelect}
                                selectText="Selecciona un idioma"
                                onChange={(e) => {
                                    setState({...state, langSelect: e.target.value})
                                    localStorage.setItem('langSelect', e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </HeaderPage>

            <Box style={{width: '100%', marginBottom: 20}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={tabType} onChange={handleChangeTab} className="admin-tabs">
                        {!id && (
                            <Tab
                                component={Link}
                                label="General"
                                to={`${RouteAdminObras.OBRA_CREATE.path}`}
                                {...a11yProps(0)}
                            />
                        )}
                        {id && (
                            <Tab
                                component={Link}
                                label="General"
                                to={`${RouteAdminObras.OBRA_EDIT_GENERAL.path}/${id}`}
                                {...a11yProps(0)}
                            />
                        )}
                        <Tab
                            component={Link}
                            disabled={!id}
                            label="Incident"
                            to={`${RouteAdminObras.OBRA_EDIT_INCIDENTS.path}/${id}`}
                            {...a11yProps(1)}
                        />
                        <Tab
                            component={Link}
                            disabled={!id}
                            label="Articles"
                            to={`${RouteAdminObras.OBRA_EDIT_ARTICLES.path}/${id}`}
                            {...a11yProps(2)}
                        />
                        <Tab
                            component={Link}
                            disabled={!id}
                            label="Fotos"
                            to={`${RouteAdminObras.OBRA_EDIT_FOTO.path}/${id}`}
                            {...a11yProps(3)}
                        />
                        <Tab
                            component={Link}
                            disabled={!id}
                            label="Vídeos"
                            to={`${RouteAdminObras.OBRA_EDIT_VIDEO.path}/${id}`}
                            {...a11yProps(4)}
                        />
                        <Tab
                            component={Link}
                            disabled={!id}
                            label="Xarxes"
                            to={`${RouteAdminObras.OBRA_EDIT_XARXES.path}/${id}`}
                            {...a11yProps(5)}
                        />
                        <Tab
                            component={Link}
                            disabled={!id}
                            label="Insults"
                            to={`${RouteAdminObras.OBRA_EDIT_INSULTS.path}/${id}`}
                            {...a11yProps(6)}
                        />
                        <Tab
                            component={Link}
                            disabled={!id}
                            label="Cites"
                            to={`${RouteAdminObras.OBRA_EDIT_CITES.path}/${id}`}
                            {...a11yProps(7)}
                        />
                        <Tab
                            component={Link}
                            disabled={!id}
                            label="Crèdits"
                            to={`${RouteAdminObras.OBRA_EDIT_CREDITS.path}/${id}`}
                            {...a11yProps(8)}
                        />
                        {/* <Tab
                            component={Link}
                            disabled={!id}
                            label="Doc. addicional"
                            to={`${RouteAdminObras.OBRA_EDIT_DOC.path}/${id}`}
                            {...a11yProps(9)}
                        /> */}
                        <Tab
                            component={Link}
                            disabled={!id}
                            label="Scanner"
                            to={`${RouteAdminObras.OBRA_EDIT_SCANNER.path}/${id}`}
                            {...a11yProps(9)} // {...a11yProps(10)}
                        />
                    </Tabs>
                </Box>

                {(msgError.length > 0 || msgSuccess.length > 0) && (<br/>)}

                <MsgError msg={msgError}/>
                <MsgSuccess msg={msgSuccess}/>

            </Box>
        </>
    )
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
