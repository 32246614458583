import React, { useState, useEffect } from "react";
import {useTranslation} from "react-i18next";
import {RouteUser} from "../../../../routes/RouteUser";
import {Link} from "react-router-dom";
import MobileMessage from "../../../../components/User/MobileMessage";
import joc from "../../../../assets/images/front/animaciones/onboarding/gifs/JOC.gif";
import scaner from "../../../../assets/images/front/animaciones/onboarding/gifs/Scaner-V3.gif";
import stories from "../../../../assets/images/front/animaciones/onboarding/gifs/Stories-V3.gif";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "../../../../scss/User/swiper-onboarding.scss";

// import required modules
import {Pagination, EffectCards} from "swiper";
// import { EffectCards } from 'swiper/modules';

export default function Onboarding() {
    const {t} = useTranslation()
    const [slide, setSlide] = useState([0,1,2]);

    // Valor del transform por defecto para la carga de página
    const [slideNextStyle, setSlideNextStyle] = useState(86.25);
    const [slidePrevStyle, setSlidePrevStyle] = useState(0);
    const [swiperLoaded, setSwiperLoaded] = useState(false);
    const [skipText, setSkipText] = useState(t('onboarding.skip'));

    // Calcular el valor del width para el slide next y el prev cada vez que se cambia de slide
    const handleSlideChange = (swiper) => {
        var slideWidth = 86.25;

        if(swiper.activeIndex < 4){
            var slideNextWidth = slideWidth * swiper.activeIndex + slideWidth
            setSlideNextStyle(slideNextWidth)
        }
        if(swiper.activeIndex > 0){
            var slidePrevWidth = slideWidth * swiper.activeIndex - slideWidth
            setSlidePrevStyle(slidePrevWidth)
        }
        if(swiper.activeIndex + 1 == swiper.slides.length){
            setSkipText(t('onboarding.continue'))
        }else{
            setSkipText(t('onboarding.skip'))
        }
    };

    useEffect(() => {
        handleResize()

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    // Corregir el bug del 100vh en móvil
    const handleResize = () => {
        var elementoOnboarding = document.querySelector('.onboarding-element');
        elementoOnboarding.style.height = `${window.innerHeight}px`;
    };

    useEffect(() => {
        const swiperSlideElements = document.querySelectorAll(".swiper-slide");

        const handleSlidesLoad = (swiperSlideElement) => {
            setSwiperLoaded(true)
        };
    
        swiperSlideElements.forEach((swiperSlideElement) => {
            swiperSlideElement.addEventListener("load", handleSlidesLoad(swiperSlideElement));
        });
    
        return () => {
            swiperSlideElements.forEach((swiperSlideElement) => {
                swiperSlideElement.removeEventListener("load", handleSlidesLoad(swiperSlideElement));
            });
        };
    }, []); 

    return (
        <>
            <MobileMessage />
            <div className='onboarding' style={{position: 'fixed',
                background: 'black',
                width: '100vw',
                height: '100vh',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                zIndex: '3'}}>
                <div className='onboarding-element' style={{
                    background: '#ffffff',
                    maxWidth: '980px',
                    width: '100vw',
                    height: '100vh',
                    margin: 'auto',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap'
                }}>
                    <div className="skip">
                        <Link to={RouteUser.MENU.path}>{skipText}</Link>
                    </div>
                    <div className="slider-container">
                        <Swiper
                            onSlideChange={(swiper) => handleSlideChange(swiper)}
                            spaceBetween={0}
                            slidesPerView={1}
                            pagination={{
                                clickable: true
                            }}
                            effect={'cards'}
                            grabCursor={true}
                            modules={[Pagination, EffectCards]}
                            className="mySwiper"
                        >
                            {slide.map((item, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        {item === 0 &&
                                            <div className="card-container">
                                                <div className="image-container">
                                                    <img src={scaner} />
                                                </div>
                                                <div className="text-container">
                                                    <p className="title">{t('onboarding.first_title')}</p>
                                                    <p className="text">{t('onboarding.first_text')}</p>
                                                </div>
                                            </div>
                                        }
                                        {item === 1 &&
                                            <div className="card-container">
                                                <div className="image-container">
                                                    <img src={stories} />
                                                </div>
                                                <div className="text-container">
                                                    <p className="title">{t('onboarding.second_title')}</p>
                                                    <p className="text">{t('onboarding.second_text')}</p>
                                                </div>
                                            </div>
                                        }
                                        {item === 2 &&
                                            <div className="card-container">
                                                <div className="image-container">
                                                    <img src={joc} />
                                                </div>
                                                <div className="text-container">
                                                    <p className="title">{t('onboarding.third_title')}</p>
                                                    <p className="text">{t('onboarding.third_text')}</p>
                                                </div>
                                            </div>
                                        }
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
            <style>{`
                .swiper-slide-next{
                    transform: translate3d(calc(-${slideNextStyle}vw + 7.25%), 0px, -100px) rotateZ(0deg) scale(1) !important;
                }
            
                .swiper-slide-prev{
                    transform: translate3d(calc(-${slidePrevStyle}vw + -7.25%), 0px, -100px) rotateZ(0deg) scale(1) !important;
                }

                ${swiperLoaded ? '.swiper-slide { transition: transform 0.5s, background-color 1s !important; }' : ''}
            `}</style>
        </>
    )
}
