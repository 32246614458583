import * as React from 'react';
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import {RouteAdmin} from "../../../../../routes/RouteAdmin";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import InputSelect from "../../../../../components/Admin/Inputs/InputSelect";
import {Grid} from "@mui/material";
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {apiFields, apiGet} from "../../../../../services/Admin/serie";
import {lang} from "../../../../../utils/lang";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import clone from "../../../../../utils/clone";

const optionsLanguage = [{id: "1", name: 'CA'}, {id: "2", name: 'ES'}, {id: "3", name: 'EN'}]

export default function Edit() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(false)
    const myRef = useRef(null)

    useEffect(() => {
        setLoadingGetInfo(true)
        setMsgError([])
        setMsgSuccess([])

        if (id) {
            getItem()
        } else {
            setLoadingGetInfo(false)
        }
    }, [state?.langSelect])

    const getItem = () => {
        apiGet(id, state?.langSelect).then(resp => {
            setState(resp.data)
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
        })
    }

    const handleSubmit = async () => {
        setMsgError([])
        setMsgSuccess([])
        setLoading(true)

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        state.id = id
        setState({...state})

        await apiFields(state).then(resp => {
            setLoading(false)
            setState({...state, id: resp.data.id})
            if (!id) {
                navigate(`${RouteAdmin.SERIE_EDIT.path}/${resp.data.id}`)
            } else {
                setMsgSuccess(resp.data.msg)
            }
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
        })
    }

    const handleChange = (e) => {
        state[e.target.name] = e.target.value
        setState({...state})
    }

    return (
        <>
            <HeaderPage title="Tornar" urlBack={RouteAdmin.SERIE_MANAGEMENT.path}>
                <div style={{float: 'right'}}>
                    <InputSelect
                        label="Idioma"
                        options={lang}
                        name="langSelect"
                        value={state.langSelect}
                        selectText="Selecciona un idioma"
                        style={{width: 100}}
                        onChange={(e) => {
                            setState({...state, langSelect: e.target.value})
                            localStorage.setItem('langSelect', e.target.value)
                        }}
                    />
                </div>
            </HeaderPage>

            <Grid container spacing={3}>
                <LoadingSpinner show={loadingGetInfo}/>

                {!loadingGetInfo && (
                    <>
                        <MsgError msg={msgError}/>
                        <MsgSuccess msg={msgSuccess}/>

                        <Grid item xs={12} md={12} lg={12}>
                            <InputText
                                label={"Nom sèrie"}
                                required
                                name="name"
                                value={state?.name}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InputCheckbox
                                label="Activada"
                                checked={state?.isActive}
                                onChange={(e) => {
                                    state.isActive = e.target.checked
                                    setState({...state})
                                }}
                                disabled={loadingGetInfo}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InputButtonLoading
                                onClick={handleSubmit}
                                loading={loading}
                                label={id ? 'Modificar' : 'Crear'}
                                position="left"
                                style={{float: 'right'}}
                                disabled={loading}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        id: null,
        langSelect: langSelect ? langSelect : 'ca',
        isActive: true,
        name: "",
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema?.name) {
        error.push("El nom és obligatori")
    }

    return error
}
