import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../../../components/Admin/TitlePage";
import InputText from "../../../../../../../components/Admin/Inputs/InputText";
import InputButtonLoading from "../../../../../../../components/Admin/Inputs/InputButtonLoading";
import InputButton from "../../../../../../../components/Admin/Inputs/InputButton";
import ModalConfirm from "../../../../../../../components/Admin/ModalConfirm";
import { apiSaveBlockText } from "../../../../../../../services/Admin/page";
import { MdDeleteForever } from "react-icons/md";
import EditorTextWeb from "../../../../../../../components/EditorTextWeb";
import ReactDOMServer from "react-dom/server";
import FullTextToHtml from "./FullTextToHtml";
import {clone} from "lodash";

export default function Faqs(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    const [loadingSave, setLoadingSave] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openModalFile, setOpenModalFile] = React.useState(false)
    const [itemRemove, setItemRemove] = React.useState(null)
    const myRef = useRef(null)
    const [itemsFaqs, setItemFaqs] = React.useState([])




    useEffect(() => {

        if (state.blockText.itemOne && state.blockText.itemOne.length > 0) {
            setItemFaqs(JSON.parse(state.blockText.itemOne))
        }
        else {
            setItemFaqs([])
        }

    }, [state.blockText.itemOne])


    const handleOpenModalItem = (id) => {
        setOpenModalFile(true)
        setItemRemove(id)
    }


    const addFaq = () => {
        setItemFaqs([...itemsFaqs, { id: itemsFaqs.length + 1, p: "", r: "" }])
        myRef.current.scrollIntoView()
    }


    const handleSubmit = () => {


        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)

        let dataFaqs = {
            items: itemsFaqs
        }

        Object.entries(dataFaqs?.items).forEach(item => {
            item[1]['htmlCode'] = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={item[1]?.r} />);
        });
        state.dataFaqs = dataFaqs

        

        apiSaveBlockText(state).then(resp => {
            handleRefresh()
            setMsgSuccess(resp.data?.msg)
            setLoadingSave(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
            handleRefresh()
        })
       
    }


    const handleDelete = () => {

        let temp = []
        let temp2 = []

        itemsFaqs.map((element, key) => {

            if (itemRemove != element.id) {
                temp.push(element)
            }

        })

        temp.map((element, key) => {

            element.id = key + 1;
            temp2.push(element)

        })

        setItemFaqs(temp2)
        setItemRemove(null)


    }

    const handleChange = key => (e) => {

        itemsFaqs[key][e.target.name] = e.target.value
        setItemFaqs([...itemsFaqs])
    }

    const handleChangeBlockText = (e) => {
        state.blockText[e.target.name] = e.target.value
        setState({ ...state })
    }

    const handleRefresh = () => {
        setReloadPreview(Math.random())
    }

    return (
        <>
            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title={state.block.webBlockName} borderTop={false} borderBottom={false} />
            </Grid>

            <Grid item md={12}>
                <InputText
                    label={"Títol"}
                    name="text"
                    disabled={loadingSave}
                    value={state?.blockText?.text}
                    onChange={handleChangeBlockText}
                    rows={2}
                />
            </Grid>


            <Grid item md={12}>
                <InputButton
                    style={{ float: "left", margin: "5px 10px 10px 0px" }}
                    onClick={addFaq}
                    text=" + Afegir pregunta - resposta"
                />
            </Grid>

            {itemsFaqs.map((element, key) => (
                <>
                    <Grid container spacing={3} style={{ padding: '0px' }} key={key} >
                        {key === 0 ? (
                            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: 0 }}>
                                <TitlePage title={`Pregunta - Resposta ${key + 1}`} />
                            </Grid>
                        ) : (
                            <Grid item xs={12} md={12} lg={12} >
                                <TitlePage title={`Pregunta - Resposta ${key + 1}`} borderTop={true} />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item md={12} style={{ marginBottom: "15px", paddingTop: "0px" }}>
                        <InputText
                            label={"Pregunta"}
                            name="p"
                            disabled={loadingSave}
                            value={itemsFaqs[key].p}
                            onChange={handleChange(key)}
                            rows={1}
                        />
                    </Grid>

                    <Grid item md={12} style={{ marginBottom: "15px", paddingTop: "0px" }}>
                        <EditorTextWeb
                            label={"Resposta"}
                            style={{ height: 250 }}
                            options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'history']}
                            content={itemsFaqs[key].r}
                            setContent={(e) => {
                                itemsFaqs[key].r = e
                                itemsFaqs[key].htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={e} />)
                            }}
                        />
                    </Grid>
                    <Grid item md={2} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "15px", paddingTop: "0px" }}>
                        <InputButtonLoading
                            onClick={() => handleOpenModalItem(itemsFaqs[key].id)}
                            loading={false}
                            label={(
                                <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar </>
                            )}
                        />
                    </Grid>

                </>
            )
            )}

            <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDelete} text="Eliminar la pregunta - resposta" />



            <Grid item md={12}>
                <InputButtonLoading
                    onClick={handleSubmit}
                    loading={loadingSave}
                    label="Modificar"
                    position="left"
                    style={{ float: 'right' }}
                />
            </Grid>

            <div ref={myRef}></div>

        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        model: {
            id: null,
            blockId: null,
            langSelect: state.langSelect,
            text: "",
            itemOne: "",
            loading: false,
            loadingDelete: false,
            loadingDeleteFile: false,
            msgSuccess: [],
            msgError: []
        },
    }
}

function validationSchema(schema) {

    let error = []

    return error
}
