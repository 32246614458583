import Homepage from "../pages/User/WebApp/Homepage"
import Stories from "../pages/User/WebApp/Stories"
import Language from "../pages/User/WebApp/Language"
import Onboarding from "../pages/User/WebApp/Onboarding"
import Bookmark from "../pages/User/WebApp/Bookmark"
import Search from "../pages/User/WebApp/Search"
import Splash from "../pages/User/WebApp/Splash"
import Disclaimer from "../pages/User/WebApp/Disclaimer"
import ImageScanner from "../pages/User/WebApp/ImageScanner"
import Mapa from "../pages/User/WebApp/Mapa"
import MapaResults from "../pages/User/WebApp/MapaResults"
import Edifici from "../pages/User/WebApp/Edifici"
import GameIntro from "../pages/User/WebApp/GameIntro"
import Game from "../pages/User/WebApp/Game"
import GameResult from "../pages/User/WebApp/GameResult"
import Menu from "../components/Layouts/User/Menu"
import NotFoundPage from "../pages/User/WebApp/NotFoundPage"
import SessionExpired from "../pages/User/WebApp/SessionExpired"

const baseTitle = "Offing - "
const urlBase = "/webapp"

export const RouteUser = {
    SCANNER: {
        path: `${urlBase}/image-scaner`,
        page: ImageScanner,
        title: `${baseTitle}Image Scanner`,
        roles: ["ROLE_USER"]
    },
    STORIES: {
        path: `${urlBase}/story`,
        params: "/:storyId",
        page: Stories,
        title: `${baseTitle}Stories`,
        roles: ["ROLE_USER", "ROLE_ADMIN"],
    },
    STORIES_SLIDE: {
        path: `${urlBase}/story`,
        params: "/:storyId/:slideId",
        page: Stories,
        title: `${baseTitle}Stories`,
        roles: ["ROLE_USER", "ROLE_ADMIN"],
        adminBar: false
    },
    STORIES_SLIDE_2: {
        path: `${urlBase}/story`,
        params: "/:storyId/:slideId/:lang",
        page: Stories,
        title: `${baseTitle}Stories`,
        roles: ["ROLE_USER", "ROLE_ADMIN"],
        adminBar: false
    },
    LANGUAGE: {
        path: `${urlBase}/language`,
        page: Language,
        title: `${baseTitle}Language`,
        roles: ["ROLE_USER"]
    },
    ONBOARDING: {
        path:`${urlBase}/onboarding`,
        page: Onboarding,
        title: `${baseTitle}Onboarding`,
        roles: ["ROLE_USER"]
    },
    BOOKMARK: {
        path: `${urlBase}/bookmark`,
        page: Bookmark,
        title: `${baseTitle}Bookmark`,
        roles: ["ROLE_USER"]
    },
    SEARCH: {
        path: `${urlBase}/search`,
        page: Search,
        title: `${baseTitle}Search`,
        roles: ["ROLE_USER"]
    },
    SPLASH: {
        path: `${urlBase}/splash`,
        page: Splash,
        title: `${baseTitle}Splash`,
        roles: ["ROLE_USER"]
    },
    DISCLAIMER: {
        path: `${urlBase}/disclaimer`,
        page: Disclaimer,
        title: `${baseTitle}Disclaimer`,
        roles: ["ROLE_USER"]
    },
    MAPA: {
        path: `${urlBase}/mapa`,
        params: "/:planta",
        page: Mapa,
        title: `${baseTitle}Mapa`,
        roles: ["ROLE_USER"]
    },
    MAPARESULTS: {
        path: `${urlBase}/mapa-results`,
        params: "/:salaId/:planta",
        page: MapaResults,
        title: `${baseTitle}Mapa`,
        roles: ["ROLE_USER"]
    },
    EDIFICI: {
        path: `${urlBase}/edifici`,
        page: Edifici,
        title: `${baseTitle}Edifici`,
        roles: ["ROLE_USER"]
    },
    GAMEINTRO: {
        path: `${urlBase}/game-intro`,
        page: GameIntro,
        title: `${baseTitle}Game Intro`,
        roles: ["ROLE_USER"]
    },
    GAME: {
        path: `${urlBase}/game`,
        page: Game,
        title: `${baseTitle}Game`,
        roles: ["ROLE_USER"]
    },
    GAMERESULT: {
        path: `${urlBase}/game-result`,
        params: "/:points/:votes/:totalVotes",
        page: GameResult,
        title: `${baseTitle}Game Result`,
        roles: ["ROLE_USER"]
    },
    MENU: {
        path: `${urlBase}/menu`,
        page: Menu,
        title: `${baseTitle}Game Result`,
        roles: ["ROLE_USER"]
    },
    NOTFOUNDPAGE: {
        path: `${urlBase}/not-found-page`,
        page: NotFoundPage,
        title: `${baseTitle}Not Found Page`,
        roles: ["ROLE_USER"]
    },
    SESSIONEXPIRED: {
        path: `${urlBase}/session-expired`,
        page: SessionExpired,
        title: `${baseTitle}Session Expired`,
        roles: ["ROLE_PUBLIC"]
    }
}
