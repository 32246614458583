import React from "react";
import {Grid, IconButton} from "@mui/material";
import {ArrowBackIos} from "@mui/icons-material";

export default function TitlePage(props) {
    const {title, borderTop, borderBottom, children} = props

    const borderTopIn = borderTop ? borderTop : false
    const borderBottomIn = borderBottom ? borderBottom : false

    return (
        <div style={{
            width: '100%',
            padding: '30px 0px 50px 0px',
            margin: '0px 0px 0px 0px',
            borderBottom: borderBottomIn ? '1px solid #e8ebef': 'none',
            borderTop: borderTopIn ? '1px solid #e8ebef' :  'none'
        }}>
            <div style={{float: 'left'}}>
                <h3 style={{fontSize: 23, letterSpacing: 1, margin: 0, padding: 0}}>{title}</h3>
            </div>
            <div style={{float: 'right'}}>
                {children}
            </div>
        </div>
    )
}
