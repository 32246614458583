import Header from "./Header"
import AdminBar from "../Admin/AdminBar";
import "../../../scss/User/index.scss"

export default function Basic(props) {

    const {adminBar} = props

    return (
        <div>
            {adminBar && <AdminBar />}
            <Header/>
            {props.children}
        </div>
    );
}
