import React, {useState} from "react";
import { useTranslation } from "react-i18next";

export default function ContactFormBlock() {

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    });

    const { i18n } = useTranslation()

    let stringName="Nom"
    let stringSurnames="Cognoms"
    let stringEmail="Adreça electrònica"
    let stringPhone="Telèfon de contacte"
    let stringMessage="Hola. M'interessa llogar/reservar un espai del museu..."

    if(i18n.resolvedLanguage == 'es')
    {
        stringName="Nombre"
        stringSurnames="Apellidos"
        stringEmail="Email"
        stringPhone="Teléfono de contacto"
        stringMessage="Hola. Me interesa alquilar/reservar un espacio del museo..."
    }
    else if(i18n.resolvedLanguage == 'en')
    {
        stringName="Name"
        stringSurnames="Surnames"
        stringEmail="Email"
        stringPhone="Telephone contact"
        stringMessage="Hello. I am interested in renting/booking a museum space..."
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };
    
    return (
        <div className="form-contact-block">
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <div className="form-group input margin-right">
                        <input
                            type="text"
                            name="name"
                            placeholder={stringName}
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group input">
                        <input
                            type="text"
                            name="surnames"
                            placeholder={stringSurnames}
                            value={formData.surnames}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group input margin-right">
                        <input
                            type="email"
                            name="email"
                            placeholder={stringEmail}
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group input">
                        <input
                            type="tel"
                            name="phone"
                            placeholder={stringPhone}
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group textarea">
                        <textarea
                            style={{resize: "none", outline: "none"}}
                            name="message"
                            placeholder={stringMessage}
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="submit-container">
                        <button type="submit" className="submit-button" style={{border:'none', color: '#fff', background:'#f00'}}>Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
