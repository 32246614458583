import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import {RouteAdmin} from "../../../../../routes/RouteAdmin";
import {Grid, IconButton} from "@mui/material";
import {
    apiGetSlide,
    getStorySlideSection,
    apiSaveSlide,
    apiUpdatePositionSlideSection,
    apiSaveFileSlide,
    apiDeleteSlideSection,
    apiSaveNameSection,
    apiDeleteFile
} from "../../../../../services/Admin/story";
import InputSelect from '../../../../../components/Admin/Inputs/InputSelect'
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";
import DndElements from "../../../../../components/DndElements";
import ButtonFile from "../../../../../components/Admin/Inputs/ButtonFile";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import ModalSection from "./ModalSection";
import LoadingBar from "../../../../../components/LoadingBar";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import {lang} from "../../../../../utils/lang";
import PlayerAudio from "../../../../../components/PlayerAudio";
import {MdDeleteForever} from "react-icons/md";
import Preview from "./Preview";
import {AiOutlineReload} from "react-icons/ai";
import {URL_WEB} from "../../../../../utils/constants";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import ModalConfirm from "../../../../../components/Admin/ModalConfirm";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import {useTranslation} from "react-i18next";

export default function SlideEdit(props) {

    const {i18n} = useTranslation()

    const {idStory, idSlide} = useParams()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues({storyId: idStory, id: idSlide}))
    const [elements, setElementDnd] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [loadingSlide, setLoadingSlide] = useState(true)
    const [loadingSave, setLoadingSave] = useState(false)
    const [detectChangeModalSection, setDetectChangeModalSection] = useState(null)
    const [loadingPosition, setLoadingPosition] = useState(false)
    const [nameSubmitButton, setNameSubmitButton] = useState(false)
    const [sectionName, setSectionName] = useState([])
    const [reloadPreview, setReloadPreview] = useState(null)
    const [openModalFile, setOpenModalFile] = React.useState(false)
    const [sectionTypeRemove, setSectionTypeRemove] = React.useState(null)

    const optionsColorArtist = [{id: "#E30613", name: 'Vermell'}, {id: "#ffffff", name: 'Blanc'}]
    const optionsColorScroll = [{id: "#AFAFAF", name: 'Gris'}, {id: "#FDFDFD", name: 'Blanc'}]
    const optionsTemplate = [{id: 1, name: 'Principal'}, {id: 2, name: 'Secundari'}]

    const [sectionTypeArticle, setSectionTypeArticle] = React.useState(0) // Solo puede haber un el slide

    useEffect(() => {
        getSlide()
        getSection()
    }, [state.langSelect])

    useEffect(() => {
        getSection()
    }, [detectChangeModalSection])

    useEffect(() => {
        if (idSlide && elements.length > 0) {
            setLoadingPosition(true)
            const idElementsSlide = elements.map((item) => item.id);

            const timeOutId = setTimeout(() => {
                apiUpdatePositionSlideSection({
                    id: idElementsSlide
                }).then(resp => {
                    setLoadingPosition(false)
                }).catch(err => {
                    setLoadingPosition(false)
                    setMsgError([err.response.data.msg])
                })
                handleRefresh()
            }, 2000)
            return () => clearTimeout(timeOutId)
        } else {
            handleRefresh()
        }

    }, [elements])

    const getSlide = () => {
        setLoadingGetInfo(true)

        apiGetSlide(idSlide, state.langSelect).then(resp => {
            setState({...state, ...resp.data})
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data.msg)
        })

    }

    const getSection = () => {
        setLoadingSlide(true)
        getStorySlideSection(idSlide).then(resp => {
            let tempArticle = 0
            const slide = resp.data?.map((item, key) => {
                if (item.artworkArticle) {
                    tempArticle++
                }
                return {
                    id: `${item.id}`,
                    content: (
                        <SectionsView
                            numSlide={key + 1}
                            section={item}
                            handleRemove={handleRemove}
                            sectionName={sectionName}
                            setMsgError={setMsgError}
                        />
                    )
                }
            })
            setSectionTypeArticle(tempArticle)
            setElementDnd(slide)
            setLoadingSlide(false)
        }).catch(err => {
            setMsgError(err.response.data.msg)
            setLoadingSlide(false)
        })
    }

    const handleChange = (e) => {
        state[e.target.name] = e.target.value
        setState({...state})
    }

    const handleChangeAudioFile = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangePhotoFile = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)

        state["template"] = state.templateView
        apiSaveSlide(state).then(resp => {
            let items = state
            
            let fileFeaturedImageBinary = items?.fileFeaturedImage?.length > 0 ? items?.fileFeaturedImage[0].binary : null
            let fileFeaturedImageName = items?.fileFeaturedImage?.length > 0 ? items?.fileFeaturedImage[0].name : null
            let fileBackgroundImageBinary = items?.fileBackgroundImage?.length > 0 ? items?.fileBackgroundImage[0].binary : null
            let fileBackgroundImageName = items?.fileBackgroundImage?.length > 0 ? items?.fileBackgroundImage[0].name : null
            let fileAudioBinary = items?.fileAudio?.length > 0 ? items?.fileAudio[0].binary : null
            let fileAudioName = items?.fileAudio?.length > 0 ? items?.fileAudio[0].name : null

            handleSubmitFile(idSlide, fileFeaturedImageBinary, fileFeaturedImageName, fileBackgroundImageBinary, fileBackgroundImageName, fileAudioBinary, fileAudioName)
            handleRefresh()
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
            handleRefresh()
        })
    }

    const handleSubmitFile = (idTemp, fileFeaturedImageBinary, fileFeaturedImageName, fileBackgroundImageBinary, fileBackgroundImageName, fileAudioBinary, fileAudioName) => {
        let f = new FormData()
        f.append(`fileFeaturedImage[]`, fileFeaturedImageBinary)
        f.append(`fileBackgroundImage[]`, fileBackgroundImageBinary)
        f.append(`fileAudio[]`, fileAudioBinary)

        f.append(`featuredImageOriginalName`, fileFeaturedImageName)
        f.append(`backgroundImageOriginalName`, fileBackgroundImageName)
        f.append(`audioOriginalName`, fileAudioName)
        
        f.append(`langCode`, state.langSelect)

        apiSaveFileSlide(f, idTemp).then(resp => {
            setLoadingSave(false)
            setMsgSuccess(resp.data?.msg)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingSave(false)
        })
    }

    const handleRemove = (id) => {
        setLoadingSlide(true)
        apiDeleteSlideSection({
            id: id
        }).then(resp => {
            getSection()
        })
    }

    const handleRefresh = () => {
        setReloadPreview(Math.random())
    }

    const handleOpenModalFile = (fileType) => {
        setOpenModalFile(true)
        setSectionTypeRemove(fileType)
    }

    const handleDeleteFile = () => {
        apiDeleteFile({
            id: state.id,
            langCode: state.langSelect,
            fileType: sectionTypeRemove
        }).then(resp => {
            window.location.reload();
            setSectionTypeRemove(null)
        }).catch(err => {
            setSectionTypeRemove(null)
        })
    }

    return (
        <>
            <Grid
                container
                spacing={3}
            >
                <Grid item xs={6}>

                    <HeaderPage
                        title="Tornar"
                        urlBack={`${RouteAdmin.STORIES_EDIT.path}/${idStory}`}
                    >
                        <div style={{float: 'right'}}>
                            <InputSelect
                                label="Idioma"
                                options={lang}
                                name="langSelect"
                                value={state.langSelect}
                                selectText="Selecciona un idioma"
                                onChange={(e) => {
                                    setState({...state, langSelect: e.target.value})
                                    localStorage.setItem('langSelect', e.target.value)
                                    i18n.changeLanguage(e.target.value)
                                }}
                            />
                        </div>
                    </HeaderPage>

                    <Grid
                        container
                        spacing={3}
                    >

                        <MsgError msg={msgError}/>
                        <MsgSuccess msg={msgSuccess}/>

                        <Grid item xs={12}>
                            <InputSelect 
                                label="Plantilla"
                                name="templateView"
                                options={optionsTemplate}
                                selectText="Selecciona una plantilla"
                                onChange={handleChange}
                                value={state?.templateView}
                            />
                        </Grid>

                        {state.templateView == 1 &&
                            <Grid item xs={12}>
                                <div style={{textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto'}}>
                                    <img alt="" src={
                                        state?.fileFeaturedImage?.[0]?.base64 ??
                                        state?.featuredImage
                                    } style={{width: '100%', objectFit: 'cover'}} />

                                    <span style={{wordWrap: 'break-word', overflowWrap: 'break-word'}}>{
                                        state?.fileFeaturedImage?.[0]?.name ??
                                        state?.featuredImageOriginalName
                                    }</span>
                                </div>
                            </Grid>
                        }

                        {state.templateView == 2 && (
                            <>
                                <Grid item xs={6}>
                                    <div style={{textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto'}}>
                                        <img alt="" src={
                                            state?.fileFeaturedImage?.[0]?.base64 ??
                                            state?.featuredImage
                                        } style={{width: '100%', objectFit: 'cover'}}/>

                                        <span style={{wordWrap: 'break-word', overflowWrap: 'break-word'}}>{
                                            state?.fileFeaturedImage?.[0]?.name ??
                                            state?.featuredImageOriginalName
                                        }</span>
                                    </div>
                                </Grid>
                                
                                <Grid item xs={6}>
                                    <div style={{textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto'}}>
                                        <img src={
                                            state?.fileBackgroundImage?.[0]?.base64 ??
                                            state?.backgroundImage
                                        } style={{width: '100%', objectFit: 'cover'}}/>

                                        <span style={{wordWrap: 'break-word', overflowWrap: 'break-word'}}>{
                                            state?.fileBackgroundImage?.[0]?.name ??
                                            state?.backgroundImageOriginalName
                                        }</span>
                                    </div>
                                </Grid>
                            </>
                        )}

                        {state.templateView == 1 &&
                            <>
                                <Grid item xs={12}>
                                    <ButtonFile
                                        label="+ Imatge destacada"
                                        required
                                        name="fileFeaturedImage"
                                        typeButton="secondary"
                                        multiple={false}
                                        onChange={handleChangePhotoFile}
                                        accept="image/png, image/jpg, image/jpeg, image/gif"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {state?.featuredImage &&
                                        <InputButtonLoading
                                            onClick={() => handleOpenModalFile("featuredImage")}
                                            loading={false}
                                            label={(
                                                <><MdDeleteForever fontSize="inherit" style={{width: '20px', height: '20px', marginBottom: '-3px'}} /> Eliminar imatge destacada</>
                                            )}
                                            position="left"
                                        />
                                    }
                                </Grid>
                            </>
                        }

                        {state.templateView == 2 && (
                            <>
                                <Grid item xs={6}>
                                    <ButtonFile
                                        label="+ Imatge destacada"
                                        required
                                        name="fileFeaturedImage"
                                        typeButton="secondary"
                                        multiple={false}
                                        onChange={handleChangePhotoFile}
                                        accept="image/png, image/jpg, image/jpeg, image/gif"
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <ButtonFile
                                        label="+ Imatge de fons"
                                        required
                                        name="fileBackgroundImage"
                                        typeButton="secondary"
                                        multiple={false}
                                        onChange={handleChangePhotoFile}
                                        accept="image/png, image/jpg, image/jpeg, image/gif"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {state?.featuredImage &&
                                        <InputButtonLoading
                                            onClick={() => handleOpenModalFile("featuredImage")}
                                            loading={false}
                                            label={(
                                                <><MdDeleteForever fontSize="inherit" style={{width: '20px', height: '20px', marginBottom: '-3px'}} /> Eliminar imatge destacada</>
                                            )}
                                            position="left"
                                        />
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    {state?.backgroundImage &&
                                        <InputButtonLoading
                                            onClick={() => handleOpenModalFile("backgroundImage")}
                                            loading={false}
                                            label={(
                                                <><MdDeleteForever fontSize="inherit" style={{width: '20px', height: '20px', marginBottom: '-3px'}} /> Eliminar imatge de fons</>
                                            )}
                                            position="left"
                                        />
                                    }
                                </Grid>
                            </>
                        )}
                        {state.templateView == 1 && (
                            <>
                                {(state?.fileAudio[0]?.base64 || state?.audio) && (
                                    <>
                                        <Grid item xs={6}>
                                            <div style={{
                                                textAlign: 'center',
                                                minWidth: 300,
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}>

                                                <PlayerAudio
                                                    url={
                                                        state?.fileAudio[0]?.base64 ??
                                                        state?.audio
                                                    }
                                                    className="admin-audio"
                                                />

                                                <span style={{wordWrap: 'break-word', overflowWrap: 'break-word'}}>{
                                                    state?.fileAudio[0]?.name ??
                                                    state?.audioOriginalName
                                                }</span>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} style={{padding: '0px'}}/>
                                    </>
                                )}

                                <Grid item xs={6}>
                                    <ButtonFile
                                        label="+ Audio"
                                        required
                                        name="fileAudio"
                                        multiple={false}
                                        onChange={handleChangeAudioFile}
                                        typeButton="secondary"
                                        accept="audio/mp3"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {state?.audio &&
                                        <InputButtonLoading
                                            onClick={() => handleOpenModalFile("audio")}
                                            loading={false}
                                            label={(
                                                <><MdDeleteForever fontSize="inherit" style={{width: '20px', height: '20px', marginBottom: '-3px'}} /> Eliminar audio</>
                                            )}
                                            position="left"
                                        />
                                    }
                                </Grid>

                                <Grid item xs={6}>
                                    <div style={{marginTop: 6}}>
                                        <InputCheckbox
                                            label="Mostrar audio"
                                            className="mb-12"
                                            checked={state?.audioIsActive}
                                            onChange={(e) => {
                                                state.audioIsActive = e.target.checked
                                                setState({...state})
                                            }}
                                            disabled={loadingGetInfo}
                                        />
                                    </div>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12} md={12} lg={12}>
                            <InputSelect
                                label="Color nom artista"
                                options={optionsColorArtist}
                                name="colorTextArtist"
                                selectText="Selecciona un color"
                                value={state.colorTextArtist}
                                onChange={handleChange}
                            />
                        </Grid>

                        {state.templateView == 2 && (
                            <Grid item xs={12} md={12} lg={12}>
                                <InputSelect
                                    label="Color text scroll"
                                    options={optionsColorScroll}
                                    name="colorTextScroll"
                                    required
                                    selectText="Selecciona un color"
                                    value={state.colorTextScroll}
                                    onChange={handleChange}
                                />
                            </Grid>
                        )}

                        {state.templateView == 2 && (
                            <Grid item xs={12} md={12} lg={12}>
                                <InputText
                                    label={"Text Desplaçar-se"}
                                    name="textScroll"
                                    required
                                    rows={5}
                                    value={state?.textScroll}
                                    onChange={handleChange}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} md={12} lg={12}>
                            <InputButtonLoading
                                onClick={handleSubmit}
                                loading={loadingSave}
                                label="Modificar"
                                position="left"
                                style={{float: 'right'}}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <ModalSection
                                slideId={idSlide}
                                idStory={idStory}
                                setDetectChangeModalSection={setDetectChangeModalSection}
                                sectionTypeArticle={sectionTypeArticle}
                            />
                        </Grid>

                        <Grid item xs={12} style={{minHeight: 300}}>

                            {loadingSlide || loadingPosition && (
                                <LoadingSpinner show={true}/>
                            )}

                            <div style={{display: loadingSlide || loadingPosition ? 'none' : ''}}>
                                <DndElements
                                    elementDnd={elements}
                                    setElementDnd={setElementDnd}
                                />
                            </div>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <div style={{position: 'fixed', marginLeft: 50}}>
                        <div style={{display: 'flex'}}>
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                onClick={handleRefresh}
                                style={{marginTop: '-30px'}}
                            >
                                <AiOutlineReload color="#F00214" />
                            </IconButton>
                            <p style={{marginTop: '-10px'}}>{`${URL_WEB}/webapp/story/${idStory}/${idSlide}/${state.langSelect}`}</p>
                        </div>
                        <Preview
                            width={'33.275vh'}
                            height={'76.63vh'}
                            src={`/webapp/story/${idStory}/${idSlide}/${state.langSelect}`}
                            reload={reloadPreview}
                        />
                    </div>
                </Grid>
                <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile} text="Eliminar el fitxer?" />
            </Grid>
        </>
    )
}

function SectionsView(props) {
    const {numSlide, section, handleRemove, setMsgError} = props

    const nameIni = section?.name ? section?.name : `Apartat ${numSlide}`

    const [loadingSave, setLoadingSave] = useState(false)
    const [name, setName] = useState(nameIni)

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            handleSubmit(name, section?.id)
        }, 1000)
        return () => clearTimeout(timeOutId)
    }, [name])
    const handleChange = (e) => {
        setName(e.target.value)
    }

    const handleSubmit = (name, idSection) => {
        if (nameIni !== name) {
            setLoadingSave(true)
            apiSaveNameSection({
                name,
                id: idSection
            }).then(resp => {
                setLoadingSave(false)
            }).catch(err => {
                setMsgError(err.response.data.msg)
            })
        }
    }

    return (
        <Grid container spacing={3} style={{
            padding: 0
        }}>
            <Grid item md={6}>
                {loadingSave && "Guardant nom..."}
                <InputText
                    name="name"
                    value={name}
                    label="Nom"
                    onChange={handleChange}
                />
            </Grid>
            <Grid item md={3}></Grid>
            <Grid item md={3} style={{textAlign: 'right'}}>
                <div style={{marginTop: 5}}>
                    <IconButton
                        size="medium"
                        color="error"
                        onClick={() => handleRemove(section.id)}
                    >
                        <MdDeleteForever fontSize="inherit"/>
                    </IconButton>
                </div>
            </Grid>
        </Grid>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        id: props.id || null,
        storyId: props.storyId || null,
        audioIsActive: false,
        fileAudio: [],
        audioOriginalName: [],
        templateView: null,
        fileFeaturedImage: [],
        featuredImageOriginalName: "",
        featuredImage: "",
        fileBackgroundImage: [],
        backgroundImageOriginalName: "",
        textScroll: "Desliza para...",
        colorTextArtist: "#E30613",
        colorTextScroll: "#AFAFAF"
    }
}
