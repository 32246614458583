import * as React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {Box} from '@mui/material';
import logo from "../../../assets/images/Logo_MuseuArtProhibit_Red2.png";
import {useEffect} from "react";
import {apiMaintenance} from "../../../services/Auth/maintenence";
import {useNavigate} from "react-router-dom";
import {RouteAuth} from "../../../routes/RouteAuth";

export default function Maintenance() {

    const navigate = useNavigate();
    const [comeBackTime, setComeBackTime] = React.useState("")

    useEffect(() => {
        apiMaintenance({}).then(resp => {
            if (!resp.data?.isActive) {
                navigate(RouteAuth.LOGIN.path)
            } else {
                setComeBackTime(resp.data?.comeBackTime)
            }
        })
    }, [])

    return (
        <ThemeProvider >
            <Box sx={{display: 'flex'}}>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: '#ffffff',
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <div style={{"height": "20vh"}}></div>

                    <div style={{width: 200, margin: 'auto'}}><img src={logo} style={{width: '100%'}} /></div>

                    <h1 style={{fontSize: 60, margin: 20, textAlign: "center"}}>Maintenance</h1>
                    <h2 style={{textAlign: "center"}}>We are performing maintenance operations, please come back later.<br/> {comeBackTime !== "" ? (<>Estimated time to resume service {comeBackTime}</>) : ''}</h2>
                </Box>
            </Box>
        </ThemeProvider>
    )
}
