import React, { useRef, useState, useEffect } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import CloseIcon from "./Icons/CloseIcon";

/**
 * Muestra un reproductor de audio
 *
 * ## PROPS
 * - **url** *string*
 */
export default function PlayerAudio(props) {

    const {url, style, closePlayer, className, handleButtonClickStopComponent} = props;

    const styleDisplay = {
        ...style,
        display: 'flex',
        backgroundColor: '#141414',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'nowrap'
    }

    return (
        <div className={`component-playerAudio ${className}`} style={style} onClick={handleButtonClickStopComponent}>
            <div className="audio-player-button-background"></div>
            <AudioPlayer
                style={{backgroundColor: 'transparent',
                    borderRadius: '0px',
                    border: 'none'}}
                src={url}
                showSkipControls={false}
                showJumpControls={false}
                showDownloadProgress={false}
                showFilledProgress={false}
                showFilledVolume={false}
                hasDefaultKeyBindings={false}
            />
            <button onClick={closePlayer} style={{background: 'none',
                        border: 'none',
                        padding: '0px'}}>
                <CloseIcon className="audio-close-icon icon" color="#ffffff" />
            </button>
        </div>
    )
}
