import React from "react";
import {useTranslation} from "react-i18next";
import {RouteAdmin} from "../../../routes/RouteAdmin";
import {Link} from "react-router-dom";
import {BASE_PATH} from "../../../utils/constants";
import {RouteAdminObras} from "../../../routes/RouteAdminObras";
import {RouteWebBack} from "../../../routes/Web/RouteWebBack";

export default function MenuHeader(props) {
    const {menu, menuChild, setOpen} = props

    return (
        <div id="admin-menu-left">
            <MenuLogs setOpen={setOpen} menu={menu} menuChild={menuChild}/>
            <MenuArtistes setOpen={setOpen} menu={menu} menuChild={menuChild}/>
            <MenuObres setOpen={setOpen} menu={menu} menuChild={menuChild}/>
            <MenuDoc setOpen={setOpen} menu={menu} menuChild={menuChild}/>
            <MenuWebApp setOpen={setOpen} menu={menu} menuChild={menuChild}/>
            <MenuWeb setOpen={setOpen} menu={menu} menuChild={menuChild}/>
        </div>
    )
}

function MenuLogs(props) {
    const {menu, menuChild, setOpen} = props
    // Mostrar solo en el caso de que menú sea Log
    if (menu === 'log') {
        return (
            <>
                <h3>Logs</h3>
                <MenuLink setOpen={setOpen} type="report" menu={menuChild} to={RouteAdmin.LOG_REPORT.path} name="Reports" />
                <MenuLink setOpen={setOpen} type="mail" menu={menuChild} to={RouteAdmin.LOG_MAIL.path} name="Mails"/>
                <MenuLink setOpen={setOpen} type="cronjob" menu={menuChild} to={RouteAdmin.LOG_CRONJOB.path} name="Tasques programades"/>
                <MenuLink setOpen={setOpen} type="scanner" menu={menuChild} to={RouteAdmin.LOG_SCANNER.path} name="Scanner"/>
            </>
        )
    }
}

function MenuArtistes(props) {
    const {menu, menuChild, setOpen} = props
    // Mostrar solo en el caso de que menú sea Log
    if (menu === 'artistes') {
        return (
            <>
                <h3>Enllaços</h3>
                <MenuLink setOpen={setOpen} type="artistes" menu={menuChild} to={RouteAdmin.ARTISTA_MANAGEMENT.path} name="Artistes" />
                <MenuLink setOpen={setOpen} type="colectius" menu={menuChild} to={RouteAdmin.COLECTIU_MANAGEMENT.path} name="Col·lectius"/>
            </>
        )
    }
}

function MenuObres(props) {
    const {menu, menuChild, setOpen} = props
    // Mostrar solo en el caso de que menú sea Log
    if (menu === 'obres') {
        return (
            <>
                <h3>Enllaços</h3>
                <MenuLink setOpen={setOpen} type="obres" menu={menuChild} to={RouteAdminObras.OBRA_MANAGEMENT.path} name="Obres" />
                <MenuLink setOpen={setOpen} type="series" menu={menuChild} to={RouteAdmin.SERIE_MANAGEMENT.path} name="Sèries"/>
            </>
        )
    }
}

function MenuDoc(props) {
    const {menu, menuChild, setOpen} = props
    // Mostrar solo en el caso de que menú sea Log
    if (menu === 'doc') {
        return (
            <>
                <h3>Documentació</h3>
                <MenuLink setOpen={setOpen} type="install" menu={menuChild} to={RouteAdmin.DOC_INSTALL.path} name="Librerías" />
                <MenuHref setOpen={setOpen} type="apiDos" menu={menuChild} to={`${BASE_PATH}/api/doc`} name="API DOC"/>
            </>
        )
    }
}

function MenuWebApp(props) {
    const {menu, menuChild, setOpen} = props
    // Mostrar solo en el caso de que menú sea Log
    if (menu === 'webapp') {
        return (
            <>
                <h3>WebApp</h3>
                <MenuLink setOpen={setOpen} type="stories" menu={menuChild} to={RouteAdmin.STORIES_MANAGEMENT.path} name="Stories" />
                <MenuLink setOpen={setOpen} type="onboarding" menu={menuChild} to={RouteAdmin.ONBOARDING_MANAGEMENT.path} name="On boarding"/>
                <MenuLink setOpen={setOpen} type="joc" menu={menuChild} to={RouteAdmin.JOC_MANAGEMENT.path} name="Joc"/>
                <MenuLink setOpen={setOpen} type="joc-screen" menu={menuChild} to={RouteAdmin.JOC_SCREEN.path} name="Joc Pantallas"/>
            </>
        )
    }
}

function MenuWeb(props) {
    const {menu, menuChild, setOpen} = props
    // Mostrar solo en el caso de que menú sea Log
    if (menu === 'web') {
        return (
            <>
                <h3>Web</h3>
                <MenuLink setOpen={setOpen} type="page" menu={menuChild} to={RouteWebBack.WEB_BACK_MANAGEMENT.path} name="Pàgines" />
                <MenuLink setOpen={setOpen} type="menu" menu={menuChild} to={RouteWebBack.WEB_BACK_MENU.path} name="Menu"/>
            </>
        )
    }
}



function MenuLink(props) {
    const {to, name, menu, type, setOpen} = props

    let isActive = false
    if (menu === type) {
        isActive = true
    }


    return (
        <>
            <div className="menu-links">
                <Link
                    to={to}
                    className={isActive ? 'active' : ''}
                    onClick={() => setOpen(false)}
                >
                    {name}
                </Link>
            </div>
        </>
    )
}

function MenuHref(props) {
    const {to, name} = props

    return (
        <>
            <div className="menu-links">
                <a
                    href={to}
                    target="_blank"
                >
                    {name}
                </a>
            </div>
        </>
    )
}

