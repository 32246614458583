import React, { useState } from "react";
import {useTranslation} from "react-i18next";

export default function ButtonBlock(props) {
    
    const {scrollId} = props
    const {t} = useTranslation()

    const scrollToTop = () =>{
        document.querySelector('.stories').scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleButtonClickStopButton = (event) => {
        event.stopPropagation(); // Evita que el evento se propague al div padre
        // Código para manejar el clic en el botón derecho
    };

    function handleScrollTopAndStopFunction(event) {
        scrollToTop();
        handleButtonClickStopButton(event);
    }

    return(
        <div className="button-block" style={{marginTop: '34px'}}>
            <button onClick={handleScrollTopAndStopFunction} style={{
                fontFamily: 'SohneBreitDreiviertelfett',
                fontSize: '16px',
                lineHeight: '20px',
                textAlign: 'Center',
                padding: '10px',
                backgroundColor: '#E30613',
                border: 'none',
                color: '#ffffff',
                width: '100%'
            }}>{t('story.go_back_up')}</button>
        </div>
    )
}
