import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import QrCode from "../../../Auth/LoginQr/view";
import logo from '../../../../assets/images/Logo_MuseuArtProhibit_White.png';

export default function QrScreen() {

    const {t} = useTranslation()
    const [language, setLanguage] = useState('ca')
    const languageTexts = {
        ca: [
          `Escaneja el QR per accedir a la guia digital del Museu de l’Art Prohibit.`
        ],
        es: [
          'Escanea el QR para acceder a la guía digital del Museu de l’Art Prohibit.'
        ],
        en: [
          'Scan the QR code to access the Museu de l’Art Prohibit digital guide.'
        ],
    };

    useEffect(() => {
        if(language == 'ca'){
            const timer = setTimeout(() => {
                setLanguage('es')
            }, 10000)
            return () => clearTimeout(timer)
        }else if(language == 'es'){
            const timer = setTimeout(() => {
                setLanguage('en')
            }, 10000)
            return () => clearTimeout(timer)
        }else if(language == 'en'){
            const timer = setTimeout(() => {
                setLanguage('ca')
            }, 10000)
            return () => clearTimeout(timer)
        }
    }, [language])
        
    return (
        <div className="qr-screen">
            <div className="top-container">
                <div className="text-container">
                    <p>{languageTexts[language]}</p>
                </div>
                <div className="qr-container">
                    <QrCode />
                </div>
            </div>
            <div className="logo-container">
                <img src={logo} />
            </div>
        </div>
    );
}
