import React, {useEffect} from "react";

export default function Preview(props) {

    const {
        height,
        width,
        src,
        reload
    } = props

    useEffect(() => {
        document.getElementById( "iframe").contentWindow.location.reload()
    },[reload])

    return (
        <>
            <div style={{height: height, width: width, marginLeft: 'auto', marginRight: 'auto', marginTop: 10}}>

                {/* recargar idame si */}

                <iframe
                    id={"iframe"}
                    src={src}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{height: height, width: width, borderRadius: 20, background: '#fff', border: '10px solid #000', boxShadow: '0px 0px 10px #8c8c8c'}}
                />
            </div>
        </>
    )
}
