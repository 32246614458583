import React, {useEffect} from "react";
import {styled} from "@mui/material/styles";
import {RouteUser} from "../../../../routes/RouteUser";
import MuiAppBar from "@mui/material/AppBar";
import {Link, useNavigate} from "react-router-dom";
import CloseIcon from "../../../Icons/CloseIcon";
import {useTranslation} from "react-i18next";

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

export default function Menu(props) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const handleCloseModal = () => {
        navigate(-1)
    }

    return (
        <div className="modal">
            <div className="button-container">
                <button
                    className="close-button"
                    onClick={handleCloseModal}
                    style={{
                        backgroundColor: '#ffffff',
                        borderRadius: '100%',
                        display: 'flex',
                        boxShadow: '0px 2px 6px rgba(0, 33, 68, 0.1)'
                    }}
                >
                    <CloseIcon className="close-icon icon" color="#F00214" />
                </button>
            </div>
            <div className="menu-list">
                <Link to={RouteUser.SCANNER.path}>
                    <span className="font-queens">{t('menu.scan')}</span>
                </Link>
                <Link to={RouteUser.SEARCH.path}>
                    <span className="font-sohne">{t('menu.search')}</span>
                </Link>
                <Link to={`${RouteUser.MAPA.path}/0`}>
                    <span className="font-queens">{t('menu.map')}</span>
                </Link>
                <Link to={RouteUser.GAMEINTRO.path}>
                    <span className="font-sohne">{t('menu.game')}</span>
                </Link>
                <Link to={RouteUser.LANGUAGE.path}>
                    <span className="font-queens">{t('menu.language')}</span>
                </Link>
            </div>
            <div className="texto-legal-container">
                <a href="#" className="cky-banner-element">{t('menu.cookies')}</a>
            </div>
        </div>
    )
}
