import React, {useEffect, useState} from 'react';
import {EditorState} from 'draft-js';
import {stateToHTML} from "draft-js-export-html";
import {stateFromHTML} from "draft-js-import-html";
import {Editor} from 'react-draft-wysiwyg';

export default function EditorText(props) {

    const {content, setContent, style, options, optionsInline, fonts} = props

    const [text, setText] = useState(EditorState.createEmpty())

    const fontsIn = ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana']

    useEffect(() => {
        if (content) {
            let textCheck = stateToHTML(text.getCurrentContent())
            if (content !== textCheck) {
                const contentState = stateFromHTML(content)
                setText(EditorState.createWithContent(contentState))
            }
        } else {
            setText(EditorState.createEmpty())
        }
    }, [content])

    const onEditorStateChange = (newText) => {
        const blocks = stateToHTML(newText.getCurrentContent())
        setContent(blocks)

        setText(newText)
    }

    return (
        <div style={{background: '#fff', padding: 0}}>
            <Editor
                editorState={text}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onEditorStateChange}
                editorStyle={style}
                toolbar={{
                    options: options ? options : ['inline', 'history'],
                    inline: { inDropdown: false, options: optionsInline ? optionsInline : ['bold', 'italic', 'underline']},
                    list: { inDropdown: false },
                    link: { inDropdown: false, options: ['link']},
                    image: { inDropdown: false },
                    history: { inDropdown: false },
                    fontFamily: {
                        options: fonts ? fonts : fontsIn,
                      },
                }}
            />
        </div>
    )
}
