import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

/**
 * Muestra un reproductor de audio
 *
 * ## PROPS
 * - **url** *string*
 */
export default function CloseIcon(props) {

    const {className} = props;

    return (
        <div className={className}>
            <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="100" height="100" rx="50" fill="#E30613"/>
                <path d="M37.1104 50.7505C43.2516 58.2671 50.2125 66.7865 40.7649 79.5549C40.704 79.6359 40.6126 79.6881 40.5106 79.6984C40.4979 79.7006 40.4862 79.7006 40.4735 79.7006C40.3854 79.7006 40.2996 79.6685 40.2336 79.6109C39.9583 79.3668 39.6254 79.0819 39.2426 78.7536C34.8232 74.9636 23.0832 64.8958 22.2678 52.2816C21.8445 45.7305 24.401 39.3474 29.8625 33.3092C29.976 33.1847 30.1627 33.1548 30.3106 33.2374C30.4591 33.3206 30.5261 33.4945 30.4724 33.6538C28.3359 40.0146 32.5973 45.2296 37.1105 50.7517L37.1104 50.7505ZM65.4855 38.4218C65.3614 38.3125 65.1764 38.3028 65.039 38.3979C64.9022 38.493 64.8507 38.6675 64.9144 38.8197C71.9945 55.7909 62.6321 65.3154 51.793 76.3429C49.9428 78.2244 48.0317 80.171 46.1609 82.1954C46.0751 82.2873 46.0451 82.4178 46.0839 82.5368C46.121 82.6559 46.2213 82.7467 46.3459 82.7755C47.9574 83.144 49.6608 83.3327 51.4119 83.3327H51.4152C62.1586 83.3327 72.9309 76.4315 76.4733 67.2772C80.0712 57.9705 76.1717 47.7222 65.4855 38.4218ZM51.3215 65.2014C51.2888 65.3579 51.3675 65.5156 51.5137 65.5879C51.5647 65.6118 51.6195 65.6243 51.6744 65.6243C51.7746 65.6243 51.8727 65.5824 51.943 65.5058C65.0983 50.9505 58.7932 42.5964 53.2304 35.2262C49.0624 29.7069 45.1266 24.4925 49.026 17.182C49.108 17.0287 49.0631 16.839 48.9213 16.7357C48.7789 16.6319 48.58 16.6455 48.456 16.7667C31.0188 33.5459 37.9381 41.5869 44.6331 49.362C48.7611 54.1584 52.6609 58.6857 51.3215 65.2014Z" fill="#FDFDFD"/>
            </svg>
        </div>
    )
}
