import * as React from 'react';
import Grid from '@mui/material/Grid';
import MenuAccount from './MenuAccount'
import MenuHeaderLeft from './MenuHeaderLeft';
import MenuHeaderRight from './MenuHeaderRight';
import MenuLeft from './MenuLeft';
import logo from '../../../assets/images/Logo_MuseuArtProhibit_Red2.png'
import "../../../scss/Admin/index.scss"
import {useMediaQuery, useTheme} from "@mui/material";
import {CgMenuGridR} from "react-icons/cg";
import PhoneMenu from "./PhoneMenu";
import {useEffect, useState} from "react";
import MenuLanguage from "./MenuLanguage";

export default function Basic(props) {

    const {menu, menuChild} = props;
    const [phoneMenuOpen, setPhoneMenuOpen] = useState(false)
    const screenDesck = useMediaQuery(useTheme().breakpoints.up("md"))

    return (
        <>
            <div id="admin-header">
                <div className="first-menu">
                    <Grid container>

                        <Grid item xs={8} sx={{
                            paddingBottom: "8px"
                        }}>
                            {!screenDesck && (
                                <button
                                    type="text"
                                    style={{border: 0, background: 'transparent', fontSize: 25, marginRight: 10}}
                                    onClick={() => setPhoneMenuOpen(true)}
                                >
                                    <CgMenuGridR />
                                </button>
                            )}
                            <img id="logo" src={logo}/>
                        </Grid>
                        <Grid item xs={4} sx={{
                            paddingBottom: "8px"
                        }}>
                            <MenuAccount />
                        </Grid>
                    </Grid>
                </div>
                {screenDesck && (
                    <div className="second-menu">
                        <Grid container>
                            <Grid item xs={8}>
                                <MenuHeaderLeft
                                    menu={menu}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <MenuHeaderRight
                                    menu={menu}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}

            </div>

            <div id="admin-content">
                <Grid container>

                    {screenDesck && (
                        <Grid item xs={menuChild !== "" ? 2 : 0}>
                            <MenuLeft
                                menu={menu}
                                menuChild={menuChild}
                            />
                        </Grid>
                    )}

                    <Grid item xs={(menuChild === "" && screenDesck) ? 12 : 10} style={{marginBottom: 80}}>
                        {props.children}
                    </Grid>
                </Grid>
            </div>

            <PhoneMenu
                open={phoneMenuOpen}
                setOpen={setPhoneMenuOpen}
                menu={menu}
                menuChild={menuChild}
            />

        </>
    )
}
