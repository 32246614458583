import * as React from 'react';
import ButtonPrimary from "../../../../components/Buttons/ButtonPrimary";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { VscChromeClose } from "react-icons/vsc";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { apiGetListWebBlocks, apiAddBlock } from "../../../../services/Admin/screen";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import TitlePage from "../../../../components/Admin/TitlePage";
import InputButton from "../../../../components/Admin/Inputs/InputButton";

export default function ModalSection(props) {

    const { idScreen, langSelect, setDetectAddBlock, setMsgError, setMsgSuccess } = props

    const [openModal, setOpenModal] = React.useState(false)
    const [optionsSection, setOptionsSection] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getBlockTypes()
    }, [])

    const handleOpenModal = () => {
        setOpenModal(true)
        setMsgError([])
        setMsgSuccess([])
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleAddBlock = (displayBlockId) => {

        apiAddBlock({
            screenId: idScreen,
            displayBlockId: displayBlockId,
        }).then(resp => {
            setDetectAddBlock(true)
            setLoading(false)
            setMsgSuccess(resp.data.msg)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setDetectAddBlock(false)
            setLoading(false)
        })
        handleCloseModal();
    }

    const getBlockTypes = () => {
        setLoading(true)
        apiGetListWebBlocks().then(resp => {
            setOptionsSection(
                resp.data
            )

            setLoading(false)
        }).catch(err => {
            setLoading(false)
            setMsgError(err.response.data?.msg)
        })

    }

    return (
        <>
            <Grid item xs={12} md={12}>

                {idScreen && (

                    <TitlePage title="Blocs">
                        <InputButton
                            style={{ float: "right", margin: "0px 0px 10px 0px" }}
                            onClick={handleOpenModal}
                            text="+ Crear block"
                            variant="outlined"
                        />
                    </TitlePage>
                )}
            </Grid>


            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...styleModal }}>
                    <Grid container style={{ marginBottom: '20px' }}>
                        <Grid item md={6}>
                            <h3 style={{ marginTop: 5 }}>Seleccionar un bloc</h3>
                        </Grid>
                        <Grid item md={6}>
                            <div
                                style={{
                                    background: '#f6f6f6',
                                    padding: '5px 5px 0px 4px',
                                    width: 15,
                                    borderRadius: 50,
                                    float: 'right'
                                }}
                            >
                                <VscChromeClose
                                    onClick={() => {
                                        handleCloseModal();
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>

                        <LoadingSpinner loading={loading} />

                        {!loading && (
                            <>

                                {optionsSection?.map((item, key) => {
                                    return (
                                        <>
                                            <Grid item md={4} key={key}>
                                                <ButtonPrimary
                                                    onClick={() => handleAddBlock(item.id)}
                                                    label={item.name}
                                                    style={{ width: '100%' }}
                                                    key={key}
                                                />
                                            </Grid>
                                        </>
                                    )

                                })}

                            </>
                        )}
                    </Grid>


                </Box>
            </Modal>
        </>
    )
}


const styleModal = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '8px',
    backgroundColor: '#fff',
}
