import React, {useEffect, useState, useRef} from "react"
import {useTranslation} from "react-i18next";
import MobileMessage from "../../../../components/User/MobileMessage";
import {useParams} from "react-router-dom";
import {getGameScreen} from "../../../../services/User/game";
import {RouteUser} from "../../../../routes/RouteUser";
import {useNavigate} from "react-router-dom";
import LoadingSpinnerBig from "../../../../components/LoadingSpinnerBig";
import MsgError from "../../../../components/MsgError";
import CloseIcon from "../../../../components/Icons/CloseIcon";
import Casco from '../../../../components/Icons/Casco';
import CascoLlama from '../../../../components/Icons/Casco_Llama';
import Llama from '../../../../components/Icons/Llama';
import InputButtonClose from "../../../../components/Layouts/User/InputButtonClose";
import TopBar from "../../../../components/Layouts/User/Header/TopBar";
import ReactGA from "react-ga4";

export default function Game() {

    const {t, i18n} = useTranslation()
    const {points, votes, totalVotes} = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState(initialValues())
    const resultLevel = points // Porcentaje del resultado
    const [resultCode,setResultCode] = useState(null)
    const [resultImage,setResultImage] = useState(null)
    const [customBackgroundColor,setCustomBackgroundColor] = useState('#FDFDFD')
    const [titlesColor,setTitlesColor] = useState('#E30613')
    const [textColor,setTextColor] = useState('#141414')
    const [buttontextColor,setButtonTextColor] = useState('#FDFDFD')
    const [buttonbackgroundColor,setButtonBackgroundColor] = useState('#E30613')

    const handleGoToGame = () => {
        navigate(RouteUser.GAME.path)
    }

    useEffect(() => {
        if(resultLevel < 45){
            setResultCode("END_SCREEN_1")
            setResultImage(<Casco className='icon' />)
            setCustomBackgroundColor('#FDFDFD')
            setTitlesColor('#E30613')
            setTextColor('#141414')
            setButtonTextColor('#FDFDFD')
            setButtonBackgroundColor('#E30613')
        }else if(resultLevel > 44 && resultLevel < 56){
            setResultCode("END_SCREEN_2")
            setResultImage(<CascoLlama className='icon' />)
            setCustomBackgroundColor('#FDFDFD')
            setTitlesColor('#E30613')
            setTextColor('#141414')
            setButtonTextColor('#FDFDFD')
            setButtonBackgroundColor('#E30613')
        }else if(resultLevel > 55){
            setResultCode("END_SCREEN_3")
            setResultImage(<Llama className='icon' />)
            setCustomBackgroundColor('#E30613')
            setTitlesColor('#FDFDFD')
            setTextColor('#FDFDFD')
            setButtonTextColor('#E30613')
            setButtonBackgroundColor('#FDFDFD')
        }

        // Corregir el bug del 100vh en móvil
        handleResize()
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    useEffect(() => {
        getScreen()
    }, [resultCode])

    const getScreen = () => {
        setLoading(true);
        getGameScreen({ code: resultCode }, i18n.resolvedLanguage, i18n.resolvedLanguage).then(resp => {
            setState(prevState => ({ ...prevState, ...resp.data[0] }));

            ReactGA.event({
                category: "Game",
                action: `Game Fin - ${resp.data[0].title}`,
            });

            setLoading(false);

            ReactGA.event({
                category: "Game",
                action: `Game Fin - ${resp.data[0].title}`,
            });

        }).catch(err => {
            setMsgError(err.response?.data?.msg);
            setLoading(false);
        });
    }
    
    const handleResize = () => {
        var elementoMapa = document.querySelector('.game-result-element');
        elementoMapa.style.height = window.innerHeight + 'px';
    };
    
    return (
        <>
            <MobileMessage />
            <div className='game-result' style={{background: 'black',
                width: '100vw',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                position: 'relative',
                zIndex: '3',
                overflowY: 'hidden'}}>
                <div className='game-result-element' style={{
                    background: customBackgroundColor,
                    maxWidth: '980px',
                    width: '100vw',
                    margin: 'auto',
                    position: 'relative',
                    overflow: 'hidden', 
                    height: '100vh',
                    minHeight: '-webkit-fill-available',
                    minHeight: '-moz-available',
                    minHeight: 'fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    alignItems: 'stretch'
                }}>
                    <div className="game-navbar" style={{backgroundColor: 'transparent'}}>
                        <div>
                            <h1 style={{color: titlesColor}}>{t('game.title')}</h1>
                        </div>
                        <div>
                            <InputButtonClose goToGameIntro={true} />
                        </div>
                    </div>
                    <div className='game-result-container'>

                        <LoadingSpinnerBig show={loading}/>
                        <MsgError msg={msgError}/>
                        
                        {!loading && (
                            <div className='game-info'>
                                <div className='info-card'>
                                    <div className="info-card-scroll">
                                        <div className="profile-container">
                                            <p style={{color: titlesColor}}>{t('game.result_profile')}</p>
                                        </div>
                                        <div className='img-container'>
                                            {resultImage && resultImage}
                                        </div>
                                        <div className='text-container'>
                                            <h2 className="title" style={{color: titlesColor}}>{state.title ?? 'Valor predeterminado'}</h2>
                                            <p style={{color: titlesColor}} className="counter-result">{t('game.burned_artwork_1')} {votes} {t('game.burned_artwork_2')} {totalVotes} {t('game.burned_artwork_3')}</p>
                                            <div dangerouslySetInnerHTML={{ __html: state.text }} style={{color: textColor}} />
                                        </div>
                                    </div>
                                </div>
                                <button className='button-block' onClick={() => handleGoToGame()} style={{color: buttontextColor, backgroundColor: buttonbackgroundColor}}>{t('game.play_it_again')}</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

function initialValues(props) {
    return {
        id: null,
        code: "",
        title: "",
        subtitle: "",
        text: ""
    }
}
