import React from "react";
import {useTranslation} from "react-i18next";
import {RouteAdmin} from "../../../routes/RouteAdmin";
import {Link} from "react-router-dom";
import {RouteAdminObras} from "../../../routes/RouteAdminObras";
import {RouteWebBack} from "../../../routes/Web/RouteWebBack";

export default function MenuHeaderLeft(props) {
    const {t} = useTranslation()
    const {menu} = props

    return (
        <>
            <MenuLink type="user" menu={menu} to={RouteAdmin.USER_MANAGEMENT.path} name="Usuaris"/>
            <MenuLink type="artistes" menu={menu} to={RouteAdmin.ARTISTA_MANAGEMENT.path} name="Artistes"/>
            <MenuLink type="obres" menu={menu} to={RouteAdminObras.OBRA_MANAGEMENT.path} name="Obres"/>
            {/*<MenuLink type="espai" menu={menu} to={RouteAdmin.USER_MANAGEMENT.path} name="Espai/Sales"/>*/}
            {/*<MenuLink type="agenda" menu={menu} to={RouteAdmin.USER_MANAGEMENT.path} name="Agenda"/>*/}
            {/*<MenuLink type="comercial" menu={menu} to={RouteAdmin.USER_MANAGEMENT.path} name="Comercial"/>*/}

            <div className="admin-menu-line">&nbsp;</div>

            <MenuLink type="document" menu={menu} to={RouteAdmin.DOCUMENT_MANAGEMENT.path} name="Documents" bold={true} />
            <MenuLink type="webapp" menu={menu} to={RouteAdmin.STORIES_MANAGEMENT.path} name="WebApp" bold={true} />
            <MenuLink type="web" menu={menu} to={RouteWebBack.WEB_BACK_MANAGEMENT.path} name="Web" bold={true} />
            <MenuLink type="display" menu={menu} to={RouteAdmin.DISPLAY_MANAGEMENT.path} name="Display" bold={true} />
        </>
    )
}

function MenuLink(props) {
    const {to, name, menu, type, bold} = props

    let isActive = false
    if (menu === type) {
        isActive = true
    }

    const styles = {
        fontBold:{
          fontFamily: bold ? 'AdminFontBold' : 'AdminFontMedium',
          color: bold ? '#2f3cc2' : '#1A1B25'
        }
    }

    return (
        <>
            <div className="admin-menu-left">
                <Link
                    to={to}
                    className={isActive ? 'active' : ''}
                    style={styles.fontBold}
                >
                    {name}
                </Link>
            </div>
        </>
    )
}
