import React, {useEffect, useState, useRef} from "react"
import LoadingSpinnerBig from "../../../../components/LoadingSpinnerBig";
import MsgError from "../../../../components/MsgError";
import Cronometro from "../../../../components/Cronometro";
import ObraQuemadaFilter from './ObraQuemadaFilter'
import ObraSalvadaFilter from './ObraSalvadaFilter'

export default function GameBlock(props) {

    const { 
        image,
        setImage,
        state,
        setState,
        handleResetAnimation,
        loading,
        setLoading,
        time,
        setTime,
        pointsResult,
        setPointsResult,
        artworkBurn,
        setArtworkBurn,
        calculateScore,
        updateVote,
        actionSaveIsVisible,
        actionBurnIsVisible,
        setArtworkDoneCountBurn,
        setActionBurnIsVisible,
        setGameMessageBurn,
        artworkDoneCountBurn,
        setArtworkDoneCountSave,
        artworkDoneCountSave,
        totalArtworks,
        currentArtwork,
        setCurrentArtwork,
        cronometroIsVisible,
        setCronometroIsVisible,
        countIsVisible,
        setCountIsVisible,
        artworkButtonCountBurn,
        setArtworkButtonCountBurn,
        showImage,
        setShowImage
    } = props
    const [msgError, setMsgError] = useState([]);

    // Función al terminar el tiempo
    useEffect(() => {
        if (time === 0 && state.length > 0) {
            const newPoints = (artworkBurn + 1);
            setArtworkBurn(newPoints)
            // Controlar el contador de acciones para mostrar o no el mensaje
            setArtworkDoneCountBurn((prevCount) => prevCount + 1);
            setArtworkButtonCountBurn((prevCount) => prevCount + 1);
            setArtworkDoneCountSave(0)
            // Activar filtro de pantalla quemada
            setActionBurnIsVisible(true)
            setCronometroIsVisible(false)
        }
    }, [time]);

    return (
        <>
            <LoadingSpinnerBig show={loading}/>
            <MsgError msg={msgError}/>
            {!loading && (
                <div className='game-block'>
                    <div className='artwork' style={{boxShadow: showImage ? '0px 0.625vw 3.75vw 0px #0021441A' : 'none'}}>
                        {showImage && 
                            <img src={image} />
                        }
                        {(!actionSaveIsVisible || !actionBurnIsVisible) && showImage && 
                            <div className="image-filter-artwork" />
                        }
                        {actionBurnIsVisible && 
                            <ObraQuemadaFilter />
                        }
                        {actionSaveIsVisible && 
                            <ObraSalvadaFilter />
                        }
                    </div>
                    {!(actionSaveIsVisible || actionBurnIsVisible) && (
                        <div className='game-chrono'>
                            {countIsVisible && 
                                <div>
                                    <p>{currentArtwork}/{totalArtworks}</p>
                                </div>
                            }
                            {cronometroIsVisible && 
                                <Cronometro
                                    time={time}
                                    setTime={setTime}
                                    showTime={true}
                                    showProgressBar={true}
                                />
                            }
                        </div>
                    )}
                </div>
            )}
        </>
    )
}