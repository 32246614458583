import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../../../components/Admin/TitlePage";
import InputButtonLoading from "../../../../../../../components/Admin/Inputs/InputButtonLoading";
import { apiSaveBlockText } from "../../../../../../../services/Admin/page";
import InputText from "../../../../../../../components/Admin/Inputs/InputText";
import EditorTextWeb from "../../../../../../../components/EditorTextWeb";
import ReactDOMServer from "react-dom/server";
import FullTextToHtml from "./FullTextToHtml";
import {clone} from "lodash";

export default function Transport(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    const [loadingSave, setLoadingSave] = useState(false)

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)


        state.blockText.htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.text} />);
        state.blockText.btnRedNameHtmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.btnRedName} />);
        state.blockText.btnWhiteNameHtmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.btnWhiteName} />);
        state.blockText.itemOneNameHtmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.itemOneName} />);

        apiSaveBlockText(state).then(resp => {
            handleRefresh()
            setMsgSuccess(resp.data?.msg)
            setLoadingSave(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
            handleRefresh()
        })
       
    }

    const handleChangeBlockText = (e) => {
        state.blockText[e.target.name] = e.target.value
        setState({ ...state })
    }

    const handleRefresh = () => {
        setReloadPreview(Math.random())
    }

    return (
        <>
            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title={state.block.webBlockName} borderTop={false} borderBottom={false} />
            </Grid>
            
                <Grid item md={6}>
                    <InputText
                        label={"Tipus de transport"}
                        name="backgroundImageName"
                        disabled={loadingSave}
                        value={state?.blockText?.backgroundImageName}
                        onChange={handleChangeBlockText}
                    />
                </Grid>

                <Grid item md={6}>
                    <EditorTextWeb
                      label={"Parades / Línies"}
                        style={{height: 150}}
                        options={['inline', 'blockType','fontFamily']}
                        content={state.blockText.text}
                        setContent={(e) => {
                            state.blockText.text = e
                            setState({ ...state })
                        }}
                    />
                </Grid>

                <Grid item md={6}>
                    <InputText
                        label={"Tipus de transport"}
                        name="backgroundImage"
                        disabled={loadingSave}
                        value={state?.blockText?.backgroundImage}
                        onChange={handleChangeBlockText}
                    />
                </Grid>

                <Grid item md={6}>
                     <EditorTextWeb
                      label={"Parades / Línies"}
                        style={{height: 150}}
                        options={['inline', 'blockType', 'fontFamily']}
                        content={state.blockText.btnRedName}
                        setContent={(e) => {
                            state.blockText.btnRedName = e
                            setState({ ...state })
                        }}
                    />
                    
                </Grid>

                <Grid item md={6}>
                    <InputText
                        label={"Tipus de transport"}
                        name="btnRedLink"
                        disabled={loadingSave}
                        value={state?.blockText?.btnRedLink}
                        onChange={handleChangeBlockText}
                    />
                </Grid>


                <Grid item md={6}>
                     <EditorTextWeb
                      label={"Parades / Línies"}
                        style={{height: 150}}
                        options={['inline', 'blockType', 'fontFamily']}
                        content={state.blockText.btnWhiteName}
                        setContent={(e) => {
                            state.blockText.btnWhiteName = e
                            setState({ ...state })
                        }}
                    />
                </Grid>

                <Grid item md={6}>
                    <InputText
                       label={"Tipus de transport"}
                        name="btnWhiteLink"
                        disabled={loadingSave}
                        value={state?.blockText?.btnWhiteLink}
                        onChange={handleChangeBlockText}
                    />
                </Grid>

                <Grid item md={6}>
                     <EditorTextWeb
                      label={"Parades / Línies"}
                        style={{height: 150}}
                        options={['inline', 'blockType', 'fontFamily']}
                        content={state.blockText.itemOneName}
                        setContent={(e) => {
                            state.blockText.itemOneName = e
                            setState({ ...state })
                        }}
                    />
                </Grid>

              

            <Grid item md={12}>
                <InputButtonLoading
                    onClick={handleSubmit}
                    loading={loadingSave}
                    label="Modificar"
                    position="left"
                    style={{ float: 'right' }}
                />
            </Grid>
        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        backgroundImage: "",

    }
}

