import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../components/Admin/TitlePage";
import { MdDeleteForever } from "react-icons/md";
import ButtonFile from "../../../../../components/Admin/Inputs/ButtonFile";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import ModalConfirm from "../../../../../components/Admin/ModalConfirm";
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import { apiSaveBlockScreenText, apiSaveFileBlockScreenText, apiDeleteFileBlockScreenText } from "../../../../../services/Admin/screen";


export default function Stream(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    const [loadingSave, setLoadingSave] = useState(false)
    const [openModalFile, setOpenModalFile] = React.useState(false)

   
    const handleChangeImageFile = (event) => {
        state[event.target.name] = event.target.value
        setState({ ...state })
    }

    const handleOpenModalFile = () => {
        setOpenModalFile(true)
    }

    const handleDeleteFile = () => {
        apiDeleteFileBlockScreenText({
            blockScreenId: state.blockScreenText.blockScreenId,
            langCode: state.langSelect,
        }).then(resp => {
            window.location.reload();
            setMsgSuccess(resp.data?.msg)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
        })
    }

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)
        apiSaveBlockScreenText(state).then(resp => {
            let fileImageBinary = state?.fileImg?.length > 0 ? state?.fileImg[0].binary : null
            handleSubmitFile(state.blockScreenText.blockScreenId, fileImageBinary)
            setLoadingSave(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
        })

    }


    const handleSubmitFile = (idBlockScreen, fileImg) => {
        let f = new FormData()
        f.append(`fileImg[]`, fileImg)
        f.append(`langCode`, state.langSelect)

        apiSaveFileBlockScreenText(f, idBlockScreen).then(resp => {
            setLoadingSave(false)
            setMsgSuccess(resp.data?.msg)
            window.location.reload();
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingSave(false)
        })
    }


    const handleChange = (e) => {
        state.blockScreenText[e.target.name] = e.target.value
        setState({ ...state })
    }



    return (
        <>
            <Grid item xs={2}>
                <InputCheckbox
                    label="Bloc Actiu"
                    className="mb-12"
                    checked={state?.blockScreenText.isActiveVideo}
                    onChange={(e) => {
                        state.blockScreenText.isActiveVideo = e.target.checked
                        setState({ ...state })
                    }}
                    disabled={loadingSave}
                    />
            </Grid>

            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title={state.blockScreen.displayBlockName} borderTop={false} borderBottom={false} />
            </Grid>

                <Grid item md={6}>
                    {((state?.fileImg?.length > 0 || state?.blockScreenText.urlFileImg?.length > 0) &&
                        <div style={{ textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto' }}>
                            <img alt="" src={
                                state.fileImg?.[0]?.base64 ??
                                state.blockScreenText.urlFileImg
                        } style={{ width: '100%', objectFit: 'cover' }} />

                    </div>
                    )}
                </Grid>

                <Grid item md={6} />

                <Grid item md={6}>
                    <ButtonFile
                        label="+ Imatge"
                        required
                        name="fileImg"
                        typeButton="secondary"
                        multiple={false}
                        onChange={handleChangeImageFile}
                        accept="image/png, image/jpg, image/jpeg, image/gif, image/*"
                    />
                </Grid>


                {(state?.fileImg?.length > 0 || state?.blockScreenText.urlFileImg?.length > 0) &&
                    <Grid item md={2}>
                        <InputButtonLoading
                            onClick={() => handleOpenModalFile()}
                            loading={false}
                            label={(
                                <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar imatge</>
                            )}
                            position="left"
                        />
                    </Grid>
                }


                <Grid item xs={2}>
                    {/* <InputCheckbox
                        label="Imatge Activada"
                        className="mb-12"
                        checked={state?.blockScreenText.isActiveImg}
                        onChange={(e) => {
                            state.blockScreenText.isActiveImg = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingSave}
                    /> */}
                </Grid>

            <Grid item md={6} style={{ paddingTop: '16px', paddingLeft: '16px' }}>
                <p>Format URL Stream Youtube:</p>
                <p>
                    https://www.youtube.com/<strong>watch?v=ID</strong> o https://www.youtube.com/<strong>live/ID</strong>
                    <br/>
                    sustituir per   https://www.youtube.com/<strong>embed/ID</strong>
                </p>
                <br/>
                <InputText
                    label={"URL Stream Youtube (https://www.youtube.com/embed/ID)"}
                    name="textoLineOne"
                    disabled={loadingSave}
                    value={state?.blockScreenText.textoLineOne}
                    onChange={handleChange}
                />
            </Grid>
            


            <Grid item md={12}>
                <InputButtonLoading
                    onClick={handleSubmit}
                    loading={loadingSave}
                    label="Modificar"
                    position="left"
                    style={{ float: 'right' }}
                />
            </Grid>

            <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile} text="Eliminar el fitxer?" />

        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        fileImg: [],
        urlFileImg: props?.blockScreenText.urlFileImg || "",
        isActiveVideo: props?.blockScreenText.isActiveVideo === undefined ? true : props.blockScreenText.isActiveVideo,
        isActiveImg: props?.blockScreenText.isActiveImg === undefined ? true : props.blockScreenText.isActiveImg,
        textoLineOne:  props?.blockScreenText.textoLineOne || "",
    }
}

