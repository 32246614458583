import React, {useEffect, useState, useRef} from "react";
import { convertFromRaw } from 'draft-js';
import { EditorState } from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export default function Transport({item}) {

    const [state, setState] = useState(initialValues(item))

    return (
        <div className="blocks-full-width">
            <div className="transport-block">
                <div className="transport-row">
                    <div className="transport-first-column">
                        <p>{state?.transportTypeOne}</p>
                    </div>
                    <div className="transport-second-column" dangerouslySetInnerHTML={{ __html: state?.linesOne }} />
                </div>
                <div className="transport-row">
                    <div className="transport-first-column">
                        <p>{state?.transportTypeTwo}</p>
                    </div>
                    <div className="transport-second-column" dangerouslySetInnerHTML={{ __html: state?.linesTwo }} />
                </div>
                <div className="transport-row">
                    <div className="transport-first-column">
                        <p>{state?.transportTypeThree}</p>
                    </div>
                    <div className="transport-second-column" dangerouslySetInnerHTML={{ __html: state?.linesThree }} />
                </div>
                <div className="transport-row">
                    <div className="transport-first-column">
                        <p>{state?.transportTypeFour}</p>
                    </div>
                    <div className="transport-second-column" dangerouslySetInnerHTML={{ __html: state?.linesFour }} />
                </div>
            </div>
        </div>
    )
}

function initialValues(props) {

    let lines1;
    if (props?.text) {
        lines1 = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.text))).getCurrentContent()
    } else {
        lines1 = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentState1 = convertToRaw(lines1);
    let markup1 = draftToHtml(rawContentState1);
    let linesOne = markup1.replace(/<p><\/p>/gi, "<br/>");

    let lines2;
    if (props?.btnRedName) {
        lines2 = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.btnRedName))).getCurrentContent()
    } else {
        lines2 = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentState2 = convertToRaw(lines2);
    let markup2 = draftToHtml(rawContentState2);
    let linesTwo = markup2.replace(/<p><\/p>/gi, "<br/>");

    let lines3;
    if (props?.btnWhiteName) {
        lines3 = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.btnWhiteName))).getCurrentContent()
    } else {
        lines3 = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentState3 = convertToRaw(lines3);
    let markup3 = draftToHtml(rawContentState3);
    let linesThree = markup3.replace(/<p><\/p>/gi, "<br/>");

    let lines4;
    if (props?.itemOneName) {
        lines4 = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.itemOneName))).getCurrentContent()
    } else {
        lines4 = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentState4 = convertToRaw(lines4);
    let markup4 = draftToHtml(rawContentState4);
    let linesFour = markup4.replace(/<p><\/p>/gi, "<br/>");


    return {
        "transportTypeOne": props?.backgroundImageName,
        "linesOne": linesOne,
        "transportTypeTwo": props?.backgroundImage,
        "linesTwo": linesTwo,
        "transportTypeThree": props?.btnRedLink,
        "linesThree": linesThree,
        "transportTypeFour": props?.btnWhiteLink,
        "linesFour": linesFour
    }
}
