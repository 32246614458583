import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../../../components/Admin/TitlePage";
import InputText from "../../../../../../../components/Admin/Inputs/InputText";
import { MdDeleteForever } from "react-icons/md";
import ButtonFile from "../../../../../../../components/Admin/Inputs/ButtonFile";
import InputButtonLoading from "../../../../../../../components/Admin/Inputs/InputButtonLoading";
import InputButton from "../../../../../../../components/Admin/Inputs/InputButton";
import ModalConfirm from "../../../../../../../components/Admin/ModalConfirm";
import { apiSaveBlockText, apiSaveFileBlockText, apiDeleteFileBlockText } from "../../../../../../../services/Admin/page";
import InputCheckbox from "../../../../../../../components/Admin/Inputs/InputCheckbox";

export default function Carousel(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    const [loadingSave, setLoadingSave] = useState(false)
    const [openModalFile, setOpenModalFile] = React.useState(false)
    const [numCarouselRemove, setNumCarouselRemove] = React.useState(null)
    
    const handleChangePhotoFile = (event) => {
        state[event.target.name] = event.target.value
        setState({ ...state })
    }
    
    const handleOpenModalFile = (numCarousel) => {
        setOpenModalFile(true)
        setNumCarouselRemove(numCarousel)
    }

    const handleDeleteFile = () => {
        apiDeleteFileBlockText({
            blockId: state.blockText.blockId,
            langCode: state.langSelect,
            numCarousel: numCarouselRemove
        }).then(resp => {
            setNumCarouselRemove(null)
            window.location.reload();
            setMsgSuccess(resp.data?.msg)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setNumCarouselRemove(null)
        })
    }

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)

        apiSaveBlockText(state).then(resp => {
            let fileBackgroundImageBinary = state?.fileBackgroundImage?.length > 0 ? state?.fileBackgroundImage[0].binary : null
            
            let fileItemOneBynary = state?.fileItemOne?.length > 0 ? state?.fileItemOne[0].binary : null
            let fileItemTwoBynary = state?.fileItemTwo?.length > 0 ? state?.fileItemTwo[0].binary : null
            let fileItemThreeBynary = state?.fileItemThree?.length > 0 ? state?.fileItemThree[0].binary : null
            let fileItemFourBynary = state?.fileItemFour?.length > 0 ? state?.fileItemFour[0].binary : null

            handleSubmitFile(state.blockText.blockId, fileBackgroundImageBinary, fileItemOneBynary, fileItemTwoBynary, fileItemThreeBynary, fileItemFourBynary)
            handleRefresh()
            setLoadingSave(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
            handleRefresh()
        })
       
    }

    const handleSubmitFile = (idBlock, fileBackgroundImage, fileItemOne, fileItemTwo, fileItemThree, fileItemFour ) => {
        let f = new FormData()
        f.append(`fileBackgroundImage[]`, fileBackgroundImage)
        
        f.append(`fileItemOne[]`, fileItemOne)
        f.append(`fileItemTwo[]`, fileItemTwo)
        f.append(`fileItemThree[]`, fileItemThree)
        f.append(`fileItemFour[]`, fileItemFour)
        
        f.append(`langCode`, state.langSelect)

        apiSaveFileBlockText(f, idBlock).then(resp => {
            setLoadingSave(false)
            setMsgSuccess(resp.data?.msg)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingSave(false)
        })
    }


    const handleChangeBlockText = (e) => {
        state.blockText[e.target.name] = e.target.value
        setState({ ...state })
    }

    const handleRefresh = () => {
        setReloadPreview(Math.random())
    }

    return (
        <>
            <Grid item md={12} style={{paddingTop:'0px'}}>
                <TitlePage title={state.block.webBlockName} borderTop={false} borderBottom={false} />
            </Grid>
           
            <Grid item md={4}>
                <div style={{ textAlign: 'center', width: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                    <img alt="" src={
                        state?.fileBackgroundImage?.[0]?.base64 ??
                        state?.blockText?.fileBackgroundImage
                    } style={{ width: '100%', objectFit: 'cover' }} />
                </div>
            </Grid>

            <Grid item md={6}/>

            <Grid item md={4}>
                <ButtonFile
                    label="+ Imatge Carrousel 1"
                    required
                    name="fileBackgroundImage"
                    typeButton="secondary"
                    multiple={false}
                    onChange={handleChangePhotoFile}
                    accept="image/png, image/jpg, image/jpeg, image/gif"
                />
            </Grid>

            <Grid item md={3}>
                {(state?.fileBackgroundImage || state?.blockText?.fileBackgroundImage) &&
                    <InputButtonLoading
                        onClick={() => handleOpenModalFile("fileBackgroundImage")}
                        loading={false}
                        label={(
                            <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar imatge 1 del carrousel</>
                        )}
                        position="left"
                    />
                }
            </Grid>

            <Grid item md={3}>
                <InputText
                    label={"Nom imatge 1"}
                    name="backgroundImageName"
                    disabled={loadingSave}
                    value={state?.blockText?.backgroundImageName}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"SEO: Títol de la imatge 1"}
                    name="itemFive"
                    disabled={loadingSave}
                    value={state?.blockText?.itemFive}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"SEO: Atribut alt de la imatge 1"}
                    name="itemFiveName"
                    disabled={loadingSave}
                    value={state?.blockText?.itemFiveName}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={12}></Grid>

            <Grid item md={4}>
                <div style={{ textAlign: 'center', width: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                    <img alt="" src={
                        state?.fileItemOne?.[0]?.base64 ??
                        state?.baseUrl + state?.blockText?.itemOne
                    } style={{ width: '100%', objectFit: 'cover' }} />
                </div>
            </Grid>

            <Grid item md={6}/>

            <Grid item md={4}>
                <ButtonFile
                    label="+ Imatge Carrousel 2"
                    required
                    name="fileItemOne"
                    typeButton="secondary"
                    multiple={false}
                    onChange={handleChangePhotoFile}
                    accept="image/png, image/jpg, image/jpeg, image/gif"
                />
            </Grid>

            <Grid item md={3}>
                {(state?.fileItemOne || state?.blockText?.itemOne) &&
                    <InputButtonLoading
                        onClick={() => handleOpenModalFile("itemOne")}
                        loading={false}
                        label={(
                            <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar imatge 2 del carrousel</>
                        )}
                        position="left"
                    />
                }
            </Grid>
            <Grid item md={3}>
                <InputText
                    label={"Nom imatge 2"}
                    name="itemOneName"
                    disabled={loadingSave}
                    value={state?.blockText?.itemOneName}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"SEO: Títol de la imatge 2"}
                    name="itemSix"
                    disabled={loadingSave}
                    value={state?.blockText?.itemSix}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"SEO: Atribut alt de la imatge 2"}
                    name="itemSixName"
                    disabled={loadingSave}
                    value={state?.blockText?.itemSixName}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={12}></Grid>


            <Grid item md={4}>
                <div style={{ textAlign: 'center', width: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                    <img alt="" src={
                        state?.fileItemTwo?.[0]?.base64 ??
                        state?.baseUrl + state?.blockText?.itemTwo
                    } style={{ width: '100%', objectFit: 'cover' }} />
                </div>
            </Grid>

            <Grid item md={6}/>

            <Grid item md={4}>
                <ButtonFile
                    label="+ Imatge Carrousel 3"
                    required
                    name="fileItemTwo"
                    typeButton="secondary"
                    multiple={false}
                    onChange={handleChangePhotoFile}
                    accept="image/png, image/jpg, image/jpeg, image/gif"
                />
            </Grid>

            <Grid item md={3}>
                {(state?.fileItemTwo || state?.blockText?.itemTwo) &&
                    <InputButtonLoading
                        onClick={() => handleOpenModalFile("itemTwo")}
                        loading={false}
                        label={(
                            <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar imatge 3 del carrousel</>
                        )}
                        position="left"
                    />
                }
            </Grid>
            <Grid item md={3}>
                <InputText
                    label={"Nom imatge 3"}
                    name="itemTwoName"
                    disabled={loadingSave}
                    value={state?.blockText?.itemTwoName}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"SEO: Títol de la imatge 3"}
                    name="itemSeven"
                    disabled={loadingSave}
                    value={state?.blockText?.itemSeven}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"SEO: Atribut alt de la imatge 3"}
                    name="itemSevenName"
                    disabled={loadingSave}
                    value={state?.blockText?.itemSevenName}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={12}></Grid>
            

            <Grid item md={4}>
                <div style={{ textAlign: 'center', width: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                    <img alt="" src={
                        state?.fileItemThree?.[0]?.base64 ??
                        state?.baseUrl + state?.blockText?.itemThree
                    } style={{ width: '100%', objectFit: 'cover' }} />
                </div>
            </Grid>

            <Grid item md={6}/>

            <Grid item md={4}>
                <ButtonFile
                    label="+ Imatge Carrousel 4"
                    required
                    name="fileItemThree"
                    typeButton="secondary"
                    multiple={false}
                    onChange={handleChangePhotoFile}
                    accept="image/png, image/jpg, image/jpeg, image/gif"
                />
            </Grid>

            <Grid item md={3}>
                {(state?.fileItemThree || state?.blockText?.itemThree) &&
                    <InputButtonLoading
                        onClick={() => handleOpenModalFile("itemThree")}
                        loading={false}
                        label={(
                            <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar imatge 4 del carrousel</>
                        )}
                        position="left"
                    />
                }
            </Grid>
            <Grid item md={3}>
                <InputText
                    label={"Nom imatge 4"}
                    name="itemThreeName"
                    disabled={loadingSave}
                    value={state?.blockText?.itemThreeName}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"SEO: Títol de la imatge 4"}
                    name="itemEight"
                    disabled={loadingSave}
                    value={state?.blockText?.itemEight}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"SEO: Atribut alt de la imatge 4"}
                    name="itemEightName"
                    disabled={loadingSave}
                    value={state?.blockText?.itemEightName}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={12}></Grid>
    
            <Grid item md={4}>
                <div style={{ textAlign: 'center', width: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                    <img alt="" src={
                        state?.fileItemFour?.[0]?.base64 ??
                        state?.baseUrl + state?.blockText?.itemFour
                    } style={{ width: '100%', objectFit: 'cover' }} />
                </div>
            </Grid>

            <Grid item md={6}/>

            <Grid item md={4}>
                <ButtonFile
                    label="+ Imatge Carrousel 5"
                    required
                    name="fileItemFour"
                    typeButton="secondary"
                    multiple={false}
                    onChange={handleChangePhotoFile}
                    accept="image/png, image/jpg, image/jpeg, image/gif"
                />
            </Grid>

            <Grid item md={3}>
                {(state?.fileItemOne || state?.blockText?.itemFour) &&
                    <InputButtonLoading
                        onClick={() => handleOpenModalFile("itemFour")}
                        loading={false}
                        label={(
                            <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar imatge 5 del carrousel</>
                        )}
                        position="left"
                    />
                }
            </Grid>
            <Grid item md={3}>
                <InputText
                    label={"Nom imatge 5"}
                    name="itemFourName"
                    disabled={loadingSave}
                    value={state?.blockText?.itemFourName}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"SEO: Títol de la imatge 5"}
                    name="itemNine"
                    disabled={loadingSave}
                    value={state?.blockText?.itemNine}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"SEO: Atribut alt de la imatge 5"}
                    name="itemNineName"
                    disabled={loadingSave}
                    value={state?.blockText?.itemNineName}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={12}/>
            
            <Grid item md={12}>
                <InputText
                    label={"Títol"}
                    name="text"
                    disabled={loadingSave}
                    value={state?.blockText?.text}
                    onChange={handleChangeBlockText}
                    rows={2}
                />
            </Grid>

            <Grid item md={12}>
                <InputText
                    label={"SubTítol"}
                    name="btnRedName"
                    disabled={loadingSave}
                    value={state?.blockText?.btnRedName}
                    onChange={handleChangeBlockText}
                    rows={2}
                />
            </Grid>


            <Grid item md={4}>
                <InputText
                    label={"Text Botó Blanc"}
                    name="btnWhiteName"
                    disabled={loadingSave}
                    value={state?.blockText?.btnWhiteName}
                    onChange={handleChangeBlockText}

                />
            </Grid>

            <Grid item md={4}>
                <InputText
                    label={"Enllaç Botó Blanc (Shop)"}
                    name="btnWhiteLink"
                    disabled={loadingSave}
                    value={state?.blockText?.btnWhiteLink}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={4}>
                <InputText
                    label={"SEO ID Botó Blanc (Shop)"}
                    name="itemTen"
                    disabled={loadingSave}
                    value={state?.blockText?.itemTen}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={12}>
                <InputButtonLoading
                    onClick={handleSubmit}
                    loading={loadingSave}
                    label="Modificar"
                    position="left"
                    style={{ float: 'right' }}
                />
            </Grid>

            <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile} text="Eliminar el fitxer?" />

        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        fileBackgroundImage: [],
        fileItemOne: [],
        fileItemTwo: [],
        fileItemThree: [],
        fileItemFour: [],
    }
}

