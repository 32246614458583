import React, { useEffect, useRef, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import { apiGetDocument, apiSaveDocument, apiSaveFileDocument, apiDeleteFileDocument } from "../../../../services/Admin/document"
import InputSelect from '../../../../components/Admin/Inputs/InputSelect'
import HeaderPage from "../../../../components/Admin/HeaderPage";
import { RouteAdmin } from "../../../../routes/RouteAdmin";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { lang } from "../../../../utils/lang";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../components/Admin/Inputs/InputButtonLoading";
import ButtonFile from "../../../../components/Admin/Inputs/ButtonFile";
import { MdDeleteForever } from "react-icons/md";
import ModalConfirm from "../../../../components/Admin/ModalConfirm";
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InputText from "../../../../components/Admin/Inputs/InputText";

export default function Fields() {


    const { id } = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [loadingSave, setLoadingSave] = useState(false)
    const [state, setState] = useState(initialValues())
    const myRef = useRef(null)
    const { t, i18n } = useTranslation()
    const [openModalFile, setOpenModalFile] = React.useState(false)
  


    useEffect(() => {
        setLoadingGetInfo(true)
        if (id) {
            getDocument()
        } else {
            setLoadingGetInfo(false)
            setState(initialValues())
        }
    }, [state.langSelect])

    const getDocument = () => {

        setMsgError([])
        setMsgSuccess([])

        apiGetDocument(id, state.langSelect, i18n.resolvedLanguage).then(resp => {
            
            setState(initialValues(resp.data.data[0]))
            setLoadingGetInfo(false)
         
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
            setState(initialValues())

        })
    }

    const handleDeleteFile = () => {
        apiDeleteFileDocument({
            documentId: state.idDocument,
            langCode: state.langSelect,
        }).then(resp => {
            setMsgSuccess(resp.data?.msg)
            window.location.reload();

        }).catch(err => {
            setMsgError(err.response.data?.msg)
        })
    }

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)

        if(!state.idDocument){state.idDocument=id}
        
        apiSaveDocument(state).then(resp => {
           
             let idx=resp.data?.id
             state.id=idx
            
            let fileBinary = state?.file?.length > 0 ? state?.file[0].binary : null
          
            handleSubmitFile(idx, fileBinary)
           
             setLoadingSave(false)
           
            
            
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoadingSave(false)
        })

    }


    const handleSubmitFile = (id, file) => {
    
        let f = new FormData()
        f.append(`documentId`, id)
        f.append(`file[]`, file)
        f.append(`langCode`, state.langSelect)

        apiSaveFileDocument(f).then(resp => {
             setLoadingSave(false)
             setMsgSuccess(resp.data?.msg)
            let id=resp.data?.id
           window.location.assign(`${RouteAdmin.DOCUMENT_EDIT.path}/${id}`)
          

        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoadingSave(false)
        })
    }

    const handleChangeFile = (event) => {
        state[event.target.name] = event.target.value
        setState({ ...state })
    }

    const handleOpenModalFile = () => {
        setOpenModalFile(true)
    }

    const handleChangeTitle = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    return (
        <>
            <div ref={myRef}></div>

            <HeaderPage
                title="Tornar"
                urlBack={RouteAdmin.DOCUMENT_MANAGEMENT.path}
            >
                <div style={{ float: 'right' }}>
                    <InputSelect
                        label="Idioma"
                        options={lang}
                        name="langSelect"
                        value={state.langSelect}
                        selectText="Selecciona un idioma"
                        style={{ width: 100 }}
                        onChange={(e) => {
                            setState({...state, langSelect: e.target.value})
                            localStorage.setItem('langSelect', e.target.value)
                        }}
                    />
                </div>
            </HeaderPage>

            <LoadingSpinner show={loadingGetInfo} />

            <MsgError msg={msgError} />
            <MsgSuccess msg={msgSuccess} />

            <Grid container spacing={2} style={{ display: loadingGetInfo ? 'none' : '' }}>

                <Grid item md={12}>
                    {state?.urlFile && (
                        <>
                            Preview: <a href={state.urlFile} target="_blank"><strong>{state.name}</strong></a>
                        </>
                    )}
                </Grid>
                <Grid item md={12}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {state?.urlFile && (
                            <Tooltip onClick={(e) => {

                                let item = `url-` + e.currentTarget.id.slice(5)
                                let copyText = document.getElementById(item);
                                navigator.clipboard.writeText(copyText.innerHTML);
                                const tooltip = e.currentTarget;
                                tooltip.setAttribute('title', 'Text copiat al portapapers');

                                }} 
                            >
                                <IconButton size="small" style={{ cursor: "pointer" }} id={`urlx-${id}`}>
                                    <ContentCopyIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        )}
                        <span id={`url-${id}`}>
                            {state?.urlFile}
                        </span>
                    </div>
                </Grid>

                <Grid item md={6}>
                    <InputText
                        label="Títol original"
                        name="title"
                        value={state.title}
                        onChange={handleChangeTitle}
                    />
                </Grid>

                <Grid item md={6}/>

                <Grid item md={6}>
                    <ButtonFile
                        label="+ Fitxer"
                        required
                        name="file"
                        typeButton="secondary"
                        multiple={false}
                        onChange={handleChangeFile}
                    />
                </Grid>

                <Grid item md={6}>
                    {(state?.file?.length > 0 || state?.urlFile) &&
                        <InputButtonLoading
                            onClick={() => handleOpenModalFile()}
                            loading={false}
                            label={(
                                <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar fitxer</>
                            )}
                            position="left"
                        />
                    }
                </Grid>

                <Grid item md={12}>
                    <InputButtonLoading
                        onClick={handleSubmit}
                        loading={loadingSave}
                        label={id ? 'Modificar' : 'Crear'}
                        disabled={loadingSave}
                    />
                </Grid>

                <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile} text="Eliminar el fitxer?" />

            </Grid>
        </>
    );
}

function initialValues(props) {
    
    let langSelect = localStorage.getItem('langSelect')
   
    return {
        idDocument: props?.idDocument || "",
        title: props?.title || "",
        
        idDocumentText: props?.idDocumentText || "",
        langCode: props?.langCode || "",
        name: props?.name || "",
        url: props?.url || "",
        urlFile: props?.urlFile || "",

        file: [],
        langSelect: langSelect ? langSelect : 'ca',
    }
}