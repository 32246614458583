import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {CircularProgress, Grid} from '@mui/material';
import LoadingSpinner from "../../../../../components/LoadingSpinner";


const ServiceComponent = ({loadingList1, loadingList2, loadingTitleList2,list1, setList1, list2, setList2}) => {
    const handleDragEnd = (result) => {
        if (!result.destination) return; // No destination, do nothing

        const sourceListId = result.source.droppableId;
        const destinationListId = result.destination.droppableId;
        const sourceList = sourceListId === 'list1' ? list1 : list2;
        const destinationList = destinationListId === 'list1' ? list1 : list2;

        const updatedItem = sourceList.splice(result.source.index, 1)[0];
        destinationList.splice(result.destination.index, 0, updatedItem);

        setList1([...list1]);
        setList2([...list2]);
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Grid container spacing={0}>
                <Grid item md={6}>
                    <Droppable droppableId="list1">
                        {(provided) => (
                            <ul
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    listStyle: 'none',
                                    marginRight: '20px',
                                    background: 'rgb(252, 252, 252)',
                                    border: '1px solid rgb(214, 212, 212)',
                                    paddingLeft: 0,
                                    height: '100%',
                                    borderRadius: 3,
                                    padding: 10,
                                }}
                            >
                                <h3 style={{ marginTop: 10 }}>Obras ( {list1.length} )</h3>

                                <LoadingSpinner show={loadingList1} />

                                {!loadingList1 && (
                                    <Grid container spacing={0} style={{ margin: '-5px' }}>
                                        {list1.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <Grid
                                                        item
                                                        md={3}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                        style={{
                                                            userSelect: 'none',
                                                            margin: 5,
                                                            padding: 5,
                                                            transition: snapshot.isDragging ? 'background-color 0.9s ease' : 'none',
                                                            backgroundColor: snapshot.isDragging ? '#f5d1d4' : '#fff',
                                                            ...provided.draggableProps.style,
                                                        }}
                                                    >
                                                        {item.content}
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        ))}
                                    </Grid>
                                )}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </Grid>

                <Grid item md={6}>
                    <Droppable droppableId="list2">
                        {(provided) => (
                            <ul
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    listStyle: 'none',
                                    background: 'rgb(252, 252, 252)',
                                    border: '1px solid rgb(214, 212, 212)',
                                    paddingLeft: 0,
                                    height: '100%',
                                    borderRadius: 3,
                                    padding: 10,
                                }}
                            >
                                <h3 style={{ margin: 10, paddingBottom: 20 }}>
                                    <div style={{float: 'left'}}>
                                        Obras asignadas al juego ( {list2.length} )
                                    </div>
                                    {loadingTitleList2  && (
                                        <div style={{float: 'right'}}>
                                            <CircularProgress sx={{ color: '#bababa' }} size={25}/>

                                        </div>
                                    )}
                                </h3>

                                <LoadingSpinner show={loadingList2} />

                                {!loadingList2 && (
                                    <Grid container spacing={0} style={{ margin: '-5px' }}>
                                        {list2.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <Grid
                                                        item
                                                        md={3}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                        style={{
                                                            userSelect: 'none',
                                                            margin: 5,
                                                            backgroundColor: '#fff',
                                                            padding: 5,
                                                            transition: snapshot.isDragging ? 'background-color 0.9s ease' : 'none',
                                                            backgroundColor: snapshot.isDragging ? '#909090' : '#fff',
                                                            ...provided.draggableProps.style,
                                                        }}
                                                    >
                                                        {item.content}
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        ))}
                                </Grid>
                                )}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </Grid>
            </Grid>
        </DragDropContext>
    );
};

export default ServiceComponent;
