import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

/**
 * Muestra un reproductor de audio
 *
 * ## PROPS
 * - **url** *string*
 */
export default function PlayIcon(props) {

    const {color, width, height, className, style} = props;

    return (
        <div className={className} style={{width: width, height: height, ...style}}>
            <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M55.9523 28.5343C55.9523 36.0263 53.0403 43.2114 47.8569 48.5091C42.6736 53.8067 35.6434 56.7829 28.313 56.7829C20.9826 56.7829 13.9524 53.8067 8.76902 48.5091C3.58564 43.2114 0.673645 36.0263 0.673645 28.5343C0.673645 21.0423 3.58564 13.8571 8.76902 8.55948C13.9524 3.26183 20.9826 0.285645 28.313 0.285645C35.6434 0.285645 42.6736 3.26183 47.8569 8.55948C53.0403 13.8571 55.9523 21.0423 55.9523 28.5343Z" fill="white"/>
                <path d="M21.4031 15.8223V39.8336L39.3687 27.8279L21.4031 15.8223Z" fill={color}/>
            </svg>
        </div>
    )
}
