import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}/block-activity`;

export function apiList(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.title) { query += `&title=${props.title}`; }
    if (props.dateFrom) { query += `&dateFrom=${props.dateFrom}`; }
    if (props.dateTo) { query += `&dateTo=${props.dateTo}`; }
    if (props.langSelect) { query += `&langSelect=${props.langSelect}`; }
   
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiGetActivity(blockId, activityId, langSelect, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/${blockId}/${activityId}/${langSelect}`, config)
}

export function apiSaveActivity(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/add-activity`, props, config)
}

export function apiDeleteActivities(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/delete`, props, config)
}

export function apiSaveFileActivity(props, idBlock, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-file/${idBlock}`, props, config)
}

export function apiDeleteFileActivity(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/delete-file`, props, config)
}