import * as React from 'react';
import ButtonPrimary from "../Buttons/ButtonPrimary";
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import {VscChromeClose} from "react-icons/vsc";
import Modal from "@mui/material/Modal";
import InputButtonLoading from "../Admin/Inputs/InputButtonLoading";
import {useEffect, useState} from "react";
import LoadingSpinner from "../LoadingSpinner";

export default function ModalConfirm(props) {

    const {openModal, setOpenModal, handleAction, text} = props

    const handleCloseModal = () => {
        setOpenModal(false)
        handleAction()
    }

    return (
        <>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{...styleModal}}>
                    <Grid container style={{marginBottom: '20px'}}>
                        <Grid item md={11}>
                            <h3 style={{marginTop: 5, textAlign: 'left'}}>{text ? text : 'Segur que vols eliminar?'}</h3>
                        </Grid>
                        <Grid item md={1}>
                            <div
                                style={{
                                    background: '#f6f6f6',
                                    padding: '5px 5px 0px 4px',
                                    width: 15,
                                    borderRadius: 50,
                                    float: 'right'
                                }}
                            >
                                <VscChromeClose
                                    onClick={() => setOpenModal(false)}
                                    style={{cursor: 'pointer'}}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item md={6} style={{display: 'flex', justifyContent: 'center'}}>
                            <InputButtonLoading
                                onClick={() => setOpenModal(false)}
                                label='No'
                                position="center"
                                style={{marginRight: '15px', width: '100%'}}
                                variant="outline"
                            />
                        </Grid>
                        <Grid item md={6} style={{display: 'flex', justifyContent: 'center'}}>
                            <InputButtonLoading
                                onClick={() => handleCloseModal()}
                                label='Sí'
                                position="center"
                                style={{marginRight: '15px', width: '100%'}}
                            />
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
        </>
    )
}

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '8px',
    backgroundColor: '#fff',
}
