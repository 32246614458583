import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

/**
 * Muestra un reproductor de audio
 *
 * ## PROPS
 * - **url** *string*
 */
export default function KeyboardArrowDownIcon(props) {

    const {color, width, height, className, style} = props;

    return (
        <div className={className} style={{width: width, height: height, ...style}}>
            <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.32026 20.4507C12.9795 24.9606 17.1273 30.0723 11.4978 37.7333C11.4615 37.7819 11.4071 37.8132 11.3463 37.8194C11.3387 37.8207 11.3318 37.8207 11.3242 37.8207C11.2717 37.8207 11.2206 37.8015 11.1813 37.7669C11.0172 37.6205 10.8189 37.4496 10.5908 37.2525C7.95741 34.9785 0.961975 28.9379 0.476099 21.3693C0.223916 17.4387 1.74723 13.6088 5.00151 9.98589C5.06917 9.9112 5.18041 9.89326 5.26854 9.94283C5.35701 9.99274 5.39694 10.0971 5.36492 10.1927C4.09184 14.0092 6.63107 17.1381 9.32029 20.4514L9.32026 20.4507ZM26.2279 13.0535C26.154 12.9879 26.0437 12.982 25.9619 13.0391C25.8803 13.0962 25.8496 13.2009 25.8876 13.2922C30.1064 23.4749 24.5276 29.1896 18.069 35.8061C16.9666 36.935 15.8278 38.103 14.7131 39.3177C14.662 39.3728 14.6441 39.4511 14.6672 39.5225C14.6894 39.5939 14.7491 39.6484 14.8234 39.6657C15.7835 39.8868 16.7986 40 17.842 40H17.844C24.2455 40 30.6643 35.8593 32.7751 30.3667C34.919 24.7827 32.5954 18.6337 26.2279 13.0535ZM17.7881 29.1212C17.7686 29.2152 17.8155 29.3097 17.9026 29.3531C17.933 29.3675 17.9657 29.375 17.9984 29.375C18.0581 29.375 18.1165 29.3499 18.1585 29.3039C25.9972 20.5707 22.2402 15.5582 18.9256 11.1361C16.442 7.82454 14.0968 4.69591 16.4203 0.30962C16.4691 0.217637 16.4424 0.103811 16.3579 0.0418377C16.2731 -0.0204602 16.1546 -0.0123065 16.0806 0.0604296C5.6905 10.1279 9.81344 14.9525 13.8028 19.6176C16.2625 22.4954 18.5862 25.2118 17.7881 29.1212Z" fill={color} />
            </svg>
        </div>
    )
}
