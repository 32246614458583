import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import MenuHeaderLeft from "./MenuHeaderLeft";
import MenuHeaderRight from "./MenuHeaderRight";
import MenuLeft from "./MenuLeft";
import {useEffect} from "react";
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function PhoneMenu(props) {
    const {open, setOpen, menu, menuChild} = props
    const [showPrimaryMenu, setShowPrimaryMenu] = React.useState(false)

    const handleClose = () => {
        setOpen(false)
        setShowPrimaryMenu(false)
    }

    useEffect(() => {
        setShowPrimaryMenu(false)
        if (menuChild === "") {
            setOpen(false)
        }
    }, [menu])

    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <div style={{padding: '5px 20px'}} className="first-menu">
                    {(showPrimaryMenu && menuChild !== "") && (
                        <button
                            type="button"
                            onClick={() => setShowPrimaryMenu(false)}
                            className="admin-menu-btn"
                        >
                            Mostra menú secundari <AiOutlineArrowRight />
                        </button>
                    )}

                    {(!showPrimaryMenu && menuChild !== "") && (
                        <button
                            type="button"
                            onClick={() => setShowPrimaryMenu(true)}
                            className="admin-menu-btn"
                        >
                            <AiOutlineArrowLeft /> Torna al menú principal
                        </button>
                    )}

                    <div style={{float: 'right'}}>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </div>

                <div style={{padding: '0px 20px'}} id="admin-menu-phone">
                    {(menuChild === "" || showPrimaryMenu) && (
                        <>
                            <MenuHeaderLeft
                                menu={menu}
                            />

                            <MenuHeaderRight
                                menu={menu}
                            />
                        </>
                    )}
                    {(!showPrimaryMenu && menuChild !== "") && (
                        <MenuLeft
                            menu={menu}
                            menuChild={menuChild}
                            setOpen={setOpen}
                        />
                    )}
                </div>
            </Dialog>
        </>
    )
}
