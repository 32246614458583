import React, {useEffect, useState, useRef} from "react"
import {useTranslation} from "react-i18next";
import MobileMessage from "../../../../components/User/MobileMessage";
import {getRandomArtworks} from "../../../../services/User/game";
import {useNavigate} from "react-router-dom";
import {RouteUser} from "../../../../../src/routes/RouteUser";
import Info from "./info";
import GameBlock from "./gameBlock";
import FlamesAnimation from "./flamesAnimation";
import FlamesIcon from '../../../../components/Icons/FlamesIcon'
import ExtractorIcon from '../../../../components/Icons/ExtractorIcon'
import InputButtonClose from "../../../../components/Layouts/User/InputButtonClose";

export default function Game() {

    const {t} = useTranslation()
    const [state, setState] = useState([]);
    const [image, setImage] = useState('');
    const [time, setTime] = React.useState(10)
    const [showImage, setShowImage] = useState(true);
    const navigate = useNavigate()
    const [currentIndex, setCurrentIndex] = useState(0);
    const [resetAnimation, setResetAnimation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pointsResult, setPointsResult] = useState(null);
    const [artworkBurn, setArtworkBurn] = useState(0);
    const [artworkSave, setArtworkSave] = useState(0);
    const [endVotes, setEndVotes] = useState(false);
    const [actionSaveIsVisible, setActionSaveIsVisible] = useState(false);
    const [actionBurnIsVisible, setActionBurnIsVisible] = useState(false);
    const [artworkDoneCountBurn, setArtworkDoneCountBurn] = useState(0);
    const [artworkButtonCountBurn, setArtworkButtonCountBurn] = useState(0);
    const [gameMessageBurn, setGameMessageBurn] = useState(false);
    const [artworkDoneCountSave, setArtworkDoneCountSave] = useState(0);
    const [artworkButtonCountSave, setArtworkButtonCountSave] = useState(0);
    const [gameMessageSave, setGameMessageSave] = useState(false);
    const [artwork, setArtwork] = useState(null);
    const [actionSaveIsVisibleAnimation, setActionSaveIsVisibleAnimation] = useState(false);
    const [actionBurnIsVisibleAnimation, setActionBurnIsVisibleAnimation] = useState(false);
    const [calculateScoreActive, setCalculateScoreActive] = useState(false);
    const [currentArtwork, setCurrentArtwork] = useState(1);
    const [messageBurnShown, setMessageBurnShown] = useState(false);
    const [messageSaveShown, setMessageSaveShown] = useState(false);
    const [cronometroIsVisible, setCronometroIsVisible] = useState(true);
    const [countIsVisible, setCountIsVisible] = useState(true);
    const [showAnimation, setShowAnimation] = useState(true);
    const [clicHabilitado, setClicHabilitado] = useState(true);

    // Botones de quemar y salvar
    const handleNextImage = (buttonType) => {
        setCronometroIsVisible(false)
        setClicHabilitado(false)
        if (buttonType === 'flames') {
            // Sumar un punto a obras quemadas
            const newArtworkBurn = artworkBurn + 1
            setArtworkBurn(newArtworkBurn)
            // Controlar el contador de acciones para mostrar o no el mensaje
            setArtworkDoneCountBurn((prevCount) => prevCount + 1);
            setArtworkButtonCountBurn((prevCount) => prevCount + 1);
            setArtworkDoneCountSave(0)
            // Activar la animación rápida tras la acción
            setActionBurnIsVisibleAnimation(true)
        } else if (buttonType === 'extractor') {
            // Sumar un punto a obras salvadas
            const newArtworkSave = artworkSave + 1
            setArtworkSave(newArtworkSave)
            // Controlar el contador de acciones para mostrar o no el mensaje
            setArtworkDoneCountSave((prevCount) => prevCount + 1);
            setArtworkButtonCountSave((prevCount) => prevCount + 1);
            setArtworkDoneCountBurn(0);
            // Activar la animación rápida tras la acción
            setActionSaveIsVisibleAnimation(true)
        }
    };

    // Activar filtro de pantalla quemada o salvada
    useEffect(() => {
        if(actionSaveIsVisible){
            setTimeout(() => {
                setShowImage(false)
                setCountIsVisible(false)
                setShowAnimation(false)
                setActionSaveIsVisible(false);
                if (artworkDoneCountSave === 3 && artworkButtonCountBurn === 0 && !messageSaveShown) {
                    setGameMessageSave(true);
                    setArtworkDoneCountSave(0);
                    setArtworkDoneCountBurn(0);
                    setMessageSaveShown(true)
                }else{
                    updateVote()
                }
            }, 1500);
        }else if (actionBurnIsVisible){
            setTimeout(() => {
                setShowImage(false)
                setCountIsVisible(false)
                setShowAnimation(false)
                setActionBurnIsVisible(false);
                if (artworkDoneCountBurn === 3 && artworkButtonCountSave === 0 && !messageBurnShown) {
                    setGameMessageBurn(true);
                    setArtworkDoneCountBurn(0);
                    setArtworkDoneCountSave(0);
                    setMessageBurnShown(true)
                }else{
                    updateVote()
                }
            }, 1500);
        }
    }, [actionSaveIsVisible, actionBurnIsVisible]);
    
    // Buscar las obras solo al cargar la página y evitarlo al continuar tras el mensaje durante el juego
    useEffect(() => {
        if(!artwork){
            getArtwork();
        }
    }, []);

    // Buscar las obras asignadas al juego
    const getArtwork = () => {
        setLoading(true);
        getRandomArtworks()
        .then((resp) => {
            try {
                setState(resp.data);
                if (resp.data && resp.data[0]) {
                    setImage(resp.data[0].photo);
                    setArtwork(resp.data[0]);
                }
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setLoading(false);
            }
        })
        .catch((err) => {
            setMsgError(err.response?.data?.msg);
            setLoading(false);
        });
    };
    
    // Pasar a la siguiente obra/imagen
    const updateVote = () => {
        const currentIndex = state.findIndex(item => item.photo === image);
        const limitIndex = currentIndex + 1
        const nextIndex = (currentIndex + 1) % state.length;
        setTimeout(() => {
            setGameMessageBurn(false)
            setGameMessageSave(false)
            setClicHabilitado(true)
            setCurrentArtwork(currentArtwork + 1)
            if(limitIndex === state.length){
                setCountIsVisible(false)
                setShowAnimation(false)
                setCalculateScoreActive(true)
            }else{
                setImage(state[nextIndex].photo);
                setTime(10);
                handleResetAnimation()
                setCronometroIsVisible(true);
                setShowAnimation(true)
                setCountIsVisible(true)
                setShowImage(true)
            }
        }, 500);
    };

    // Reiniciar la animación
    const handleResetAnimation = () => {
        setResetAnimation(true);
    };
    
    useEffect(() => {
        if (resetAnimation) {
            setCurrentIndex(0);
            setResetAnimation(false);
        }
    }, [resetAnimation]);
    
    // Calcular la puntación
    const calculateScore = () => {
        const totalVotes = state.length;
        const positiveVotes = artworkBurn;
        const negativeVotes = artworkSave;
        const positivePercentage = (positiveVotes / totalVotes) * 100; 
        const score = Math.round(positivePercentage);
        setPointsResult(score)
        if (state.length > 0) {
            setEndVotes(true);
        }
    };

    useEffect(() => {
        if(calculateScoreActive){
            calculateScore();
        }
    }, [artworkBurn, artworkSave, calculateScoreActive]);

    // Navegación hacia los resultados
    useEffect(() => {
        const currentIndex = state.findIndex(item => item.photo === image);
        const limitIndex = currentIndex + 1
        if(pointsResult != null && endVotes == true){
            navigate(`${RouteUser.GAMERESULT.path}/${pointsResult}/${artworkBurn}/${state.length}`);
        }
    }, [pointsResult, endVotes]);

    // Corregir el bug del 100vh en móvil
    useEffect(() => {
        var elementoMapa = document.querySelector('.game-element');
        elementoMapa.style.height = window.innerHeight + 'px';
    }, []);

    const buttons = document.querySelectorAll('.animated-button');
    buttons.forEach(button => {
        button.addEventListener('click', () => {
            button.classList.add('active');
        });

        button.addEventListener('transitionend', () => {
            button.classList.remove('active');
        });
    });


    return (
        <>
            <MobileMessage />
            <div className='game' style={{background: 'black',
                width: '100vw',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                position: 'relative',
                zIndex: '3',
                overflow: 'hidden'}}>
                <div className='game-element' style={{
                    background: '#FFFFFF',
                    maxWidth: '980px',
                    width: '100vw',
                    margin: 'auto',
                    position: 'relative',
                    overflow: 'hidden',
                    height: '100vh',
                    minHeight: '-webkit-fill-available',
                    minHeight: '-moz-available',
                    minHeight: 'fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap'
                }}>
                    <div className={`game-navbar ${gameMessageBurn ? 'extintor-info' : 'flames-info'}`}>
                        <div>
                            <h1>{t('game.title')}</h1>
                        </div>
                        <div>
                            <InputButtonClose goToGameIntro={true} />
                        </div>
                    </div>
                    {!loading && !gameMessageBurn && !gameMessageSave && !actionBurnIsVisible && !actionSaveIsVisible && showAnimation && (
                        <FlamesAnimation resetAnimation={resetAnimation} setResetAnimation={setResetAnimation} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} actionSaveIsVisibleAnimation={actionSaveIsVisibleAnimation} setActionSaveIsVisibleAnimation={setActionSaveIsVisibleAnimation} actionBurnIsVisibleAnimation={actionBurnIsVisibleAnimation} setActionBurnIsVisibleAnimation={setActionBurnIsVisibleAnimation} actionSaveIsVisible={actionSaveIsVisible} setActionSaveIsVisible={setActionSaveIsVisible} actionBurnIsVisible={actionBurnIsVisible} setActionBurnIsVisible={setActionBurnIsVisible} />
                    )}
                    <div className='game-container'>
                        {!gameMessageBurn && !gameMessageSave && 
                            <GameBlock image={image} setImage={setImage} state={state} setState={setState} handleResetAnimation={handleResetAnimation} loading={loading} setLoading={setLoading} time={time} setTime={setTime} pointsResult={pointsResult} setPointsResult={setPointsResult} artworkBurn={artworkBurn} setArtworkBurn={setArtworkBurn} calculateScore={calculateScore} updateVote={updateVote} actionSaveIsVisible={actionSaveIsVisible} actionBurnIsVisible={actionBurnIsVisible} setArtworkDoneCountBurn={setArtworkDoneCountBurn} setActionBurnIsVisible={setActionBurnIsVisible} setGameMessageBurn={setGameMessageBurn} artworkDoneCountBurn={artworkDoneCountBurn} setArtworkDoneCountSave={setArtworkDoneCountSave} artworkDoneCountSave={artworkDoneCountSave} totalArtworks={state.length} currentArtwork={currentArtwork} setCurrentArtwork={setCurrentArtwork} cronometroIsVisible={cronometroIsVisible} setCronometroIsVisible={setCronometroIsVisible} countIsVisible={countIsVisible} setCountIsVisible={setCountIsVisible} artworkButtonCountBurn={artworkButtonCountBurn} setArtworkButtonCountBurn={setArtworkButtonCountBurn} showImage={showImage} setShowImage={setShowImage} />
                        }
                        {(gameMessageBurn || gameMessageSave) && 
                            <Info gameMessageBurn={gameMessageBurn} gameMessageSave={gameMessageSave} updateVote={updateVote} />
                        }
                    </div>
                    {!loading && !actionSaveIsVisible && !actionBurnIsVisible && !gameMessageBurn && !gameMessageSave && (
                        <div className='game-buttons'>
                            <button className='flames-button animated-button' onClick={() => handleNextImage('flames')} disabled={!clicHabilitado}>
                                <FlamesIcon className='flames-icon icon' />
                            </button>
                            <button className='extractor-button animated-button' onClick={() => handleNextImage('extractor')} disabled={!clicHabilitado}>
                                <ExtractorIcon className='extractor-icon icon' />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
