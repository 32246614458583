import React, {useEffect, useRef, useState} from "react";
import {Grid} from "@mui/material";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import Header from "./Header";
import {useParams} from "react-router-dom";
import {apiSaveCredits, apiGetCredits} from "../../../../../services/Admin/obra";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import EditorText from "../../../../../components/EditorText";

export default function TabCredits() {
    const {id} = useParams()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getData()
    }, [state.langSelect])

    const getData = () => {
        setLoadingGetInfo(true)

        apiGetCredits({id}, state?.langSelect).then(resp => {
            setState(resp.data)
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data.msg)
        })
    }
    
    const handleChangeTrans = (event) => {
        const tempText = event.target.value;
        setState({ ...state, text: tempText });
    }    
    
    const handleSubmit = async () => {
        setMsgError([])
        setMsgSuccess([])
        setLoading(true)

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            return
        }

        await apiSaveCredits(state).then(resp => {
            state.id = resp.data?.id
            setState({...state})
            setMsgSuccess(resp.data.msg)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
        })
    }

    return (
        <>
            <Header
                state={state}
                setState={setState}
                id={id}
                tabType={8}
                msgError={msgError}
                msgSuccess={msgSuccess}
            >
            </Header>

            <LoadingSpinner show={loadingGetInfo}/>

            <div style={{display: loadingGetInfo ? 'none' : ''}}>
                <Grid container spacing={3} style={{padding: '0px'}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <p style={{fontSize: '11px', fontFamily: 'AdminFontMedium', color: '#778', marginTop: '0px', paddingLeft: '15px'}}>Text</p>
                        <EditorText
                            style={{height: '132px'}}
                            content={state?.text}
                            setContent={(newContent) => {
                                setState((prevState) => ({
                                    ...prevState,
                                    text: newContent
                                }));
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={4}>
                        <InputButtonLoading
                            onClick={handleSubmit}
                            loading={state.loading}
                            label={state.id ? 'Modificar' : 'Crear'}
                            position="left"
                            style={{marginRight: '15px'}}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <div style={{height: 5}}></div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        artworkId: null,
        id: null,
        text:""
    }
}
function validationSchema(schema) {

    let error = []

    return error
}
