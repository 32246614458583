import React from "react";
import Editor from "./Editor";
import ServiceComponent from "./ServiceComponent";

export default function Testing() {

    const [time, setTime] = React.useState(30) // 30 segundos

    return (
        <div style={{padding: "20px"}}>
            <h1>TESTING</h1>

            {/*<ServiceComponent/>*/}

            <Editor/>

            {/*<Cronometro*/}
            {/*    time={time}*/}
            {/*    setTime={setTime}*/}
            {/*    showTime={true}*/}
            {/*    showProgressBar={true}*/}
            {/*/>*/}

        </div>
    )
}
