import * as React from 'react';
import ButtonPrimary from "../../../../../components/Buttons/ButtonPrimary";
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import {VscChromeClose} from "react-icons/vsc";
import Modal from "@mui/material/Modal";
import Documentation from "./SectionsForm/Documentation";
import Incident from "./SectionsForm/Incident";
import Insult from "./SectionsForm/Insult";
import Photo from "./SectionsForm/Photo";
import Quote from "./SectionsForm/Quote";
import Credits from "./SectionsForm/Credits";
import Video from "./SectionsForm/Video";
import Article from "./SectionsForm/Article";
import {useEffect, useState} from "react";
import {apiGetListSections, apiGet, setStorySlideSectionSave} from "../../../../../services/Admin/story";
import {apiGetCredits} from "../../../../../services/Admin/obra";
import MsgError from "../../../../../components/MsgError";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import TitlePage from "../../../../../components/Admin/TitlePage";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";
import {RouteAdmin} from "../../../../../routes/RouteAdmin";

export default function ModalSection(props) {

    const {slideId, idStory, setDetectChangeModalSection, sectionTypeArticle} = props

    const [openModal, setOpenModal] = React.useState(false)
    const [sectionSelected, setSectionSelected] = useState(selectionInit)
    const [optionsSection, setOptionsSection] = useState([])
    const [loading, setLoading] = useState(true)
    const [msgError, setMsgError] = useState([])

    useEffect(() => {
        getSectionType()
    }, [])

    const handleOpenModal = () => {
        setOpenModal(true)
        setMsgError([])
    }
    const handleCloseModal = () => {
        setDetectChangeModalSection(Math.random())
        setOpenModal(false)
        handleShowSelect(null)
    }


    const [showSelect, setShowSelect] = React.useState(false)
    const handleShowSelect = (sectionId, sectionName) => {
        setShowSelect(true)
        setSectionSelected({id: sectionId, name: sectionName})
    }
    const handleHideSelect = () => setShowSelect(false)

    const getSectionType = () => {
        setLoading(true)
        apiGetListSections().then(resp => {
            setOptionsSection(
                resp.data
            )

            setLoading(false)
        }).catch(err => {
            setLoading(false)
            setMsgError(err.response.data.msg)
        })

    }

    return (
        <>
            <TitlePage title="Apartats" borderTop={true}>
                <InputButton
                    style={{float: "right", margin: "0px 0px 10px 0px"}}
                    onClick={handleOpenModal}
                    text="+ Introduir apartat"
                    variant="outlined"
                />
            </TitlePage>


            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{...styleModal}}>
                    <Grid container style={{marginBottom: '20px'}}>
                        <Grid item md={6}>
                            <h3 style={{marginTop: 5}}>Seleccionar un apartat</h3>
                        </Grid>
                        <Grid item md={6}>
                            <div
                                style={{
                                    background: '#f6f6f6',
                                    padding: '5px 5px 0px 4px',
                                    width: 15,
                                    borderRadius: 50,
                                    float: 'right'
                                }}
                            >
                                <VscChromeClose
                                    onClick={() => {
                                        handleCloseModal();
                                        handleHideSelect();
                                    }}
                                    style={{cursor: 'pointer'}}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>

                        <LoadingSpinner loading={loading}/>

                        {!loading && (
                            <>
                                {!sectionSelected.id && (
                                    <>
                                        {optionsSection?.map((item, key) => {
                                            return (
                                                <>
                                                    <Grid item md={4}>
                                                        <ButtonPrimary
                                                            disabled={(sectionTypeArticle > 0 && item.id === 4) ? true : false}
                                                            onClick={() => handleShowSelect(item.id, item.name)}
                                                            label={item.name}
                                                            style={{width: '100%'}}
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                        })}
                                    </>
                                )}

                                {sectionSelected.id && (
                                    <>
                                        <Grid item md={12}>
                                            <FormReturn
                                                idStory={idStory}
                                                sectionSelected={sectionSelected}
                                                setSectionSelected={setSectionSelected}
                                                setMsgError={setMsgError}
                                                slideId={slideId}
                                                handleCloseModal={handleCloseModal}
                                                loading={loading}
                                                setLoading={setLoading}
                                            />
                                            <LoadingSpinner show={loading}/>
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}

                        <MsgError msg={msgError}/>

                    </Grid>

                </Box>
            </Modal>
        </>
    )
}


function FormReturn(props) {

    const {
        idStory,
        sectionSelected,
        setSectionSelected,
        setMsgError,
        slideId,
        handleCloseModal,
        loading,
        setLoading
    } = props

    const [artistId, setArtistId] = useState(null)
    const [artworkId, setArtworkId] = useState(null)

    useEffect( () => {
        getStory()
    }, [])

    // Guardar secciones sin formulario (1 y 2)
    useEffect( () => {
        if (artistId || artworkId) {
            if(sectionSelected.id === 1){
                simpleSave({
                    slideId: slideId,
                    sectionId: sectionSelected.id,
                    artistId: artistId
                })
            }

            if(sectionSelected.id === 2){
                simpleSave({
                    slideId: slideId,
                    sectionId: sectionSelected.id,
                    artworkId: artworkId
                })
            }

            if(sectionSelected.id === 10){
                getCredits()
            }
        }
    }, [artistId, artworkId])

    const getCredits = () => {
        apiGetCredits({
            id: artworkId
        }).then(resp => {
            simpleSave({
                slideId: slideId,
                sectionId: sectionSelected.id,
                artworkCreditsId: resp.data?.id
            })
        }).catch(err => {
            
        })
    }

    const getStory = () => {
        apiGet(
            idStory
        ).then(resp => {
            setArtistId(resp.data?.artist)
            setArtworkId(resp.data?.artwork)
        }).catch(err => {
            setMsgError(err.response.data.msg)
            setLoading(false)
        })
    }

    const simpleSave = (value) => {
        setLoading(true)

        setStorySlideSectionSave(value).then(resp => {
            setLoading(false)
            handleCloseModal()
        }).catch(err => {
            setMsgError(err.response.data.msg)
            setLoading(false)
        })
    }

    return (
        <>
            {sectionSelected.id === 8 && (
                <Documentation
                    artworkId={artworkId}
                    section={sectionSelected}
                    setSectionSelected={setSectionSelected}
                    selectionInit={selectionInit}
                    setMsgError={setMsgError}
                    slideId={slideId}
                    colseSection={handleCloseModal}
                />
            )}
            {sectionSelected.id === 3 && (
                <Incident
                    artworkId={artworkId}
                    section={sectionSelected}
                    setSectionSelected={setSectionSelected}
                    selectionInit={selectionInit}
                    setMsgError={setMsgError}
                    slideId={slideId}
                    colseSection={handleCloseModal}
                />
            )}

            {sectionSelected.id === 6 && (
                <Insult
                    artworkId={artworkId}
                    section={sectionSelected}
                    setSectionSelected={setSectionSelected}
                    selectionInit={selectionInit}
                    setMsgError={setMsgError}
                    slideId={slideId}
                    colseSection={handleCloseModal}
                />
            )}

            {sectionSelected.id === 9 && (
                <Photo
                    artworkId={artworkId}
                    section={sectionSelected}
                    setSectionSelected={setSectionSelected}
                    selectionInit={selectionInit}
                    setMsgError={setMsgError}
                    slideId={slideId}
                    colseSection={handleCloseModal}
                />
            )}

            {sectionSelected.id === 7 && (
                <Quote
                    artworkId={artworkId}
                    section={sectionSelected}
                    setSectionSelected={setSectionSelected}
                    selectionInit={selectionInit}
                    setMsgError={setMsgError}
                    slideId={slideId}
                    colseSection={handleCloseModal}
                />
            )}

            {sectionSelected.id === 5 && (
                <Video
                    artworkId={artworkId}
                    section={sectionSelected}
                    setSectionSelected={setSectionSelected}
                    selectionInit={selectionInit}
                    setMsgError={setMsgError}
                    slideId={slideId}
                    colseSection={handleCloseModal}
                />
            )}
            {sectionSelected.id === 4 && (
                <Article
                    artworkId={artworkId}
                    section={sectionSelected}
                    setSectionSelected={setSectionSelected}
                    selectionInit={selectionInit}
                    setMsgError={setMsgError}
                    slideId={slideId}
                    colseSection={handleCloseModal}
                />
            )}
        </>
    )
}

function selectionInit() {
    return {
        id: null,
        name: ''
    }
}


const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 630,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '8px',
    backgroundColor: '#fff',
}
