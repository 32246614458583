import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

/**
 * Muestra un reproductor de audio
 *
 * ## PROPS
 * - **url** *string*
 */
export default function CloseIcon(props) {

    const {color, width, height, className, style} = props;

    return (
        <div className={className} style={{width: width, height: height, ...style}}>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '100%', height: '100%' }} viewBox="0 0 22 19" fill="none">
                <path d="M17.86 10.7324C17.86 11.1269 17.5469 11.4467 17.1606 11.4467C16.7743 11.4467 16.4611 11.1269 16.4611 10.7324C16.4611 10.3379 16.7743 10.0181 17.1606 10.0181C17.5469 10.0181 17.86 10.3379 17.86 10.7324Z" fill="#F00214"/>
                <path d="M17.1606 14.3039C17.5469 14.3039 17.86 13.9841 17.86 13.5896C17.86 13.1951 17.5469 12.8753 17.1606 12.8753C16.7743 12.8753 16.4611 13.1951 16.4611 13.5896C16.4611 13.9841 16.7743 14.3039 17.1606 14.3039Z" fill="#F00214"/>
                <path d="M17.86 16.4468C17.86 16.8413 17.5469 17.1611 17.1606 17.1611C16.7743 17.1611 16.4611 16.8413 16.4611 16.4468C16.4611 16.0523 16.7743 15.7325 17.1606 15.7325C17.5469 15.7325 17.86 16.0523 17.86 16.4468Z" fill="#F00214"/>
                <path d="M5.04869 6.15412C4.7712 6.15412 4.54625 6.38372 4.54625 6.66695C4.54625 6.95018 4.7712 7.17979 5.04869 7.17979H6.55599C6.83348 7.17979 7.05842 6.95018 7.05842 6.66695C7.05842 6.38372 6.83348 6.15412 6.55599 6.15412H5.04869Z" fill="#F00214"/>
                <path d="M9.57059 6.15412C9.29311 6.15412 9.06816 6.38372 9.06816 6.66695C9.06816 6.95018 9.29311 7.17979 9.57059 7.17979H11.0779C11.3554 7.17979 11.5803 6.95018 11.5803 6.66695C11.5803 6.38372 11.3554 6.15412 11.0779 6.15412H9.57059Z" fill="#F00214"/>
                <path d="M5.04869 8.71829C4.7712 8.71829 4.54625 8.94789 4.54625 9.23112C4.54625 9.51435 4.7712 9.74395 5.04869 9.74395H6.55599C6.83348 9.74395 7.05842 9.51435 7.05842 9.23112C7.05842 8.94789 6.83348 8.71829 6.55599 8.71829H5.04869Z" fill="#F00214"/>
                <path d="M9.57059 8.71829C9.29311 8.71829 9.06816 8.94789 9.06816 9.23112C9.06816 9.51435 9.29311 9.74395 9.57059 9.74395H11.0779C11.3554 9.74395 11.5803 9.51435 11.5803 9.23112C11.5803 8.94789 11.3554 8.71829 11.0779 8.71829H9.57059Z" fill="#F00214"/>
                <path d="M5.04869 11.2825C4.7712 11.2825 4.54625 11.5121 4.54625 11.7953C4.54625 12.0785 4.7712 12.3081 5.04869 12.3081H6.55599C6.83348 12.3081 7.05842 12.0785 7.05842 11.7953C7.05842 11.5121 6.83348 11.2825 6.55599 11.2825H5.04869Z" fill="#F00214"/>
                <path d="M9.57059 11.2825C9.29311 11.2825 9.06816 11.5121 9.06816 11.7953C9.06816 12.0785 9.29311 12.3081 9.57059 12.3081H11.0779C11.3554 12.3081 11.5803 12.0785 11.5803 11.7953C11.5803 11.5121 11.3554 11.2825 11.0779 11.2825H9.57059Z" fill="#F00214"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.06334 0C6.84892 0 5.83848 0.88175 5.60299 2.05133H3.03894C2.76146 2.05133 2.53651 2.28094 2.53651 2.56416V4.10266H2.03408C1.75659 4.10266 1.53165 4.33227 1.53165 4.6155V17.9565H0.417722C0.18702 17.9565 0 18.1434 0 18.3741C0 18.6047 0.18702 18.7917 0.417722 18.7917H21.5823C21.813 18.7917 22 18.6047 22 18.3741C22 18.1434 21.813 17.9565 21.5823 17.9565H20.6579V9.03563H21.1266C21.3829 9.03563 21.5907 8.8279 21.5907 8.57164C21.5907 8.31539 21.3829 8.10765 21.1266 8.10765H14.5949L14.5949 4.6155C14.5949 4.33227 14.37 4.10266 14.0925 4.10266H13.5901V2.56416C13.5901 2.28094 13.3651 2.05133 13.0876 2.05133H10.5237C10.2882 0.881749 9.27777 0 8.06334 0ZM19.7296 17.9565V9.03563H14.5949L14.5949 17.9565H19.7296ZM13.5901 17.9565V5.12833H13.0876C12.8101 5.12833 12.5852 4.89873 12.5852 4.6155V3.077H10.0731C9.79646 3.077 9.57188 2.8488 9.57063 2.56648C9.56686 1.71265 8.89128 1.02567 8.06334 1.02567C7.2354 1.02567 6.55983 1.71264 6.55606 2.56648C6.55481 2.8488 6.33023 3.077 6.05363 3.077H3.54138V4.6155C3.54138 4.89873 3.31643 5.12833 3.03894 5.12833H2.53651V17.9565H5.40512V15.8685C5.40512 15.0229 6.0784 14.3373 6.90893 14.3373H9.0963C9.92683 14.3373 10.6001 15.0229 10.6001 15.8685V17.9565H13.5901ZM9.77985 17.9565V15.8685C9.77985 15.4841 9.47381 15.1725 9.0963 15.1725H6.90893C6.53141 15.1725 6.22538 15.4841 6.22538 15.8685V17.9565H9.77985Z" fill="#F00214"/>
            </svg>
        </div>
    )
}
