import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import GirarIcon from '../../components/Icons/GirarIcon';

export default function MobileMessage() {
  const {t} = useTranslation()
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    handleResize()

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    // Eliminar el evento 'resize' al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  const handleResize = () => {
    if(window.innerWidth >= 981 || window.innerHeight < window.innerWidth){
      setShowMessage(true)
    }else{
      setShowMessage(false)
    }
  };

  return(
    <>
      {showMessage && 
        <div className="mobile-message">
          <div className="mobile-message-modal">
            <GirarIcon color='#E30613' width='25.69px' height='38px' />
            <p>{t('mobile_vertical_message')}</p>
          </div>
        </div>
      }
    </>
  )
}