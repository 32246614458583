import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../../../components/Admin/TitlePage";
import InputText from "../../../../../../../components/Admin/Inputs/InputText";
import { MdDeleteForever } from "react-icons/md";
import ButtonFile from "../../../../../../../components/Admin/Inputs/ButtonFile";
import InputButtonLoading from "../../../../../../../components/Admin/Inputs/InputButtonLoading";
import InputButton from "../../../../../../../components/Admin/Inputs/InputButton";
import ModalConfirm from "../../../../../../../components/Admin/ModalConfirm";
import { apiSaveBlockText, apiSaveFileBlockText, apiDeleteFileBlockText } from "../../../../../../../services/Admin/page";
import InputCheckbox from "../../../../../../../components/Admin/Inputs/InputCheckbox";

export default function Video(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    const [loadingSave, setLoadingSave] = useState(false)
    const [openModalFile, setOpenModalFile] = React.useState(false)
    
    const handleChangePhotoFile = (event) => {
        state[event.target.name] = event.target.value
        setState({ ...state })
    }
    const handleOpenModalFile = () => {
        setOpenModalFile(true)
    }

    const handleDeleteFile = () => {
        apiDeleteFileBlockText({
            blockId: state.blockText.blockId,
            langCode: state.langSelect,
        }).then(resp => {
            window.location.reload();
            setMsgSuccess(resp.data?.msg)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
        })
    }

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)

        let fileBackgroundImageBinary = state?.fileBackgroundImage?.length > 0 ? state?.fileBackgroundImage[0].binary : null
        handleSubmitFile(state.blockText.blockId, fileBackgroundImageBinary)
           
        }
      

    const handleSubmitFile = (idBlock, fileBackgroundImage) => {
        let f = new FormData()
        f.append(`fileBackgroundImage[]`, fileBackgroundImage)
        f.append(`langCode`, state.langSelect)

        apiSaveFileBlockText(f, idBlock).then(resp => {
            setLoadingSave(false)
            setMsgSuccess(resp.data?.msg)
            window.location.reload();
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingSave(false)
        })
    }


    const handleChangeBlockText = (e) => {
        state.blockText[e.target.name] = e.target.value
        setState({ ...state })
    }

   

    return (
        <>
            <Grid item md={12} style={{paddingTop:'0px'}}>
                <TitlePage title={state.block.webBlockName} borderTop={false} borderBottom={false} />
            </Grid>
           
            <Grid item md={6}>
            {(state?.blockText?.fileBackgroundImage &&
                <div style={{ textAlign: 'center', width: 400, marginLeft: 'auto', marginRight: 'auto' }}>
                    <video controls style={{ width: '100%', objectFit: 'cover' }}>
                    <source src={
                        state?.blockText?.fileBackgroundImage
                    } ></source>
                    </video>
                </div>
            )}
            </Grid>

            <Grid item md={6}/>

            <Grid item md={6}>
                <ButtonFile
                    label="+ Video"
                    required
                    name="fileBackgroundImage"
                    typeButton="secondary"
                    multiple={false}
                    onChange={handleChangePhotoFile}
                    accept="video/mp4,video/mkv,video/x-m4v,video/*"
                />
            </Grid>

            <Grid item md={6}>
                {(state?.fileBackgroundImage || state?.blockText?.fileBackgroundImage) &&
                    <InputButtonLoading
                        onClick={() => handleOpenModalFile()}
                        loading={false}
                        label={(
                            <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar video</>
                        )}
                        position="left"
                    />
                }
            </Grid>
            

            <Grid item md={12}>
                <InputButtonLoading
                    onClick={handleSubmit}
                    loading={loadingSave}
                    label="Modificar"
                    position="left"
                    style={{ float: 'right' }}
                />
            </Grid>

            <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile} text="Eliminar el fitxer?" />

        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        fileBackgroundImage: [],
        
    }
}

