import React, {useEffect, useState} from "react";
import {apiGameScreen, apiGameScreenSave} from "../../../../../services/Admin/game";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import EditorText from "../../../../../components/EditorText";
import {Grid} from "@mui/material";
import TitlePage from "../../../../../components/Admin/TitlePage";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import {RouteAdminObras} from "../../../../../routes/RouteAdminObras";
import InputSelect from "../../../../../components/Admin/Inputs/InputSelect";
import {lang} from "../../../../../utils/lang";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import MsgSuccess from "../../../../../components/MsgSuccess";
import MsgError from "../../../../../components/MsgError";

export default function Management() {

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [screen, setScreen] = useState([])
    const [loading, setLoading] = useState(true)
    const [langSelect, setLangSelect] = useState(localStorage.getItem('langSelect') ?? 'ca')

    useEffect(() => {
        getGameScreen()
    }, [langSelect])

    const getGameScreen = () => {
        setLoading(true)
        setMsgError([])
        apiGameScreen(langSelect).then(resp => {
            let temp = resp.data?.map(doc => {
                return {
                    ...doc,
                }
            }) ?? []

            setScreen(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
        })
    }

    return (
        <>
            <HeaderPage>
                <div style={{float: 'right'}}>
                    <div style={{display: 'flex'}}>
                        <div style={{width: 100, height: 20}}>
                            <InputSelect
                                label="Idioma"
                                options={lang}
                                name="langSelect"
                                value={langSelect}
                                selectText="Selecciona un idioma"
                                onChange={(e) => {
                                    setLangSelect( e.target.value)
                                    localStorage.setItem('langSelect', e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </HeaderPage>

            <LoadingSpinner show={loading} />

            {!loading && (
                <>
                    {screen.map((item, key) => {
                        return(
                            <div key={key}>
                                <Card item={item} langSelect={langSelect} />
                            </div>
                        )
                    })}
                </>
            )}

        </>
    )
}

function Card(props) {
    const {item, langSelect} = props

    const [fields, setFields] = useState(item)
    const [loading, setLoading] = useState(false)
    const [msgSuccess, setMsgSuccess] = useState([])
    const [msgError, setMsgError] = useState([])

    const handleChange = (e) => {
        fields[e.target.name] = e.target.value
        setFields({...fields})
    }

    const codeTitle = {
        'INI_SCREEN_1': 'Pantalla inicial 1',
        'INI_SCREEN_2': 'Pantalla inicial 2',
        'INI_SCREEN_3': 'Pantalla inicial 3',
        'INI_SCREEN_4': 'Pantalla inicial 4',
        'ALERT_SCREEN_SAVE': 'Pantalla alerta salvar',
        'ALERT_SCREEN_BURN': 'Pantalla alerta cremar',
        'END_SCREEN_1': 'Pantalla final 1',
        'END_SCREEN_2': 'Pantalla final 2',
        'END_SCREEN_3': 'Pantalla final 3',
    }

    const save = () => {
        setMsgSuccess([])
        setMsgError([])
        setLoading(true)
        fields['lang'] = langSelect
        apiGameScreenSave(fields).then(resp => {
            setLoading(false)
            setMsgSuccess(['Dades guardades amb èxit'])
        }).catch(err => {
            setLoading(false)
            setMsgError(['Error al guardar'])
        })
    }

    return (
        <Grid container spacing={2}>

            <Grid item sm={12}>
                <TitlePage title={codeTitle[item.code]} />
            </Grid>

            {(item.code !== "ALERT_SCREEN_SAVE" && item.code !== "ALERT_SCREEN_BURN") && (
                <Grid item sm={12}>
                    <InputText
                        label="Títol"
                        name="title"
                        value={fields.title}
                        onChange={handleChange}
                    />
                </Grid>
            )}

            {item.code === "INI_SCREEN_1" && (
                <Grid item sm={12}>
                    <InputText
                        label="Subtítol"
                        name="subtitle"
                        value={fields.subtitle}
                        onChange={handleChange}
                    />
                </Grid>
            )}

            <Grid item sm={12}>
                <EditorText
                    style={{height: '120px'}}
                    content={fields.text}
                    setContent={(newContent) => { setFields({...fields, text: newContent}) }}
                />
            </Grid>

            <Grid item sm={12}>

                <MsgSuccess msg={msgSuccess} />
                <MsgError msg={msgError} />

                <InputButtonLoading
                    onClick={() => save()}
                    loading={loading}
                    label="Modificar"
                    position="left"
                />
                <div style={{
                    height: 50,
                    borderBottom: '1px solid rgb(232, 235, 239)'
                }}></div>
            </Grid>

        </Grid>
    )
}
