import TabGeneral from "../pages/Admin/General/Obra/Fields/TabGeneral"
import TabArticles from "../pages/Admin/General/Obra/Fields/TabArticles"
import TabCites from "../pages/Admin/General/Obra/Fields/TabCites"
import TabCredits from "../pages/Admin/General/Obra/Fields/TabCredits"
import TabDocAdicional from "../pages/Admin/General/Obra/Fields/TabDocAdicional"
import TabIncidents from "../pages/Admin/General/Obra/Fields/TabIncidents"
import TabInsults from "../pages/Admin/General/Obra/Fields/TabInsults"
import TabFotos from "../pages/Admin/General/Obra/Fields/TabFotos"
import TabVideos from "../pages/Admin/General/Obra/Fields/TabVideos"
import TabXarxes from "../pages/Admin/General/Obra/Fields/TabXarxes"
import ObraManagement from "../pages/Admin/General/Obra/Management"
import TabScanner from "../pages/Admin/General/Obra/Fields/TabScanner";

const baseTitle = "Offing - "
const urlBase = "/dash"

export const RouteAdminObras = {
    OBRA_MANAGEMENT: {
        path: `${urlBase}/obra/management`,
        menu: "obres",
        menuChild: "obres",
        page: ObraManagement,
        title: `${baseTitle}Obra management`,
        roles: ["ROLE_ADMIN"]
    },
    OBRA_CREATE: {
        path: `${urlBase}/obra/create/general`,
        menu: "obres",
        menuChild: "obres",
        page: TabGeneral,
        title: `${baseTitle}Obra create general`,
        roles: ["ROLE_ADMIN"]
    },
    OBRA_EDIT_GENERAL: {
        path: `${urlBase}/obra/edit/general`,
        params: "/:id",
        menu: "obres",
        menuChild: "obres",
        page: TabGeneral,
        title: `${baseTitle}Obra edit general`,
        roles: ["ROLE_ADMIN"]
    },
    OBRA_EDIT_INCIDENTS: {
        path: `${urlBase}/obra/edit/incidents`,
        params: "/:id",
        menu: "obres",
        menuChild: "obres",
        page: TabIncidents,
        title: `${baseTitle}Obra edit incidents`,
        roles: ["ROLE_ADMIN"]
    },
    OBRA_EDIT_INSULTS: {
        path: `${urlBase}/obra/edit/insults`,
        params: "/:id",
        menu: "obres",
        menuChild: "obres",
        page: TabInsults,
        title: `${baseTitle}Obra edit insults`,
        roles: ["ROLE_ADMIN"]
    },
    OBRA_EDIT_ARTICLES: {
        path: `${urlBase}/obra/edit/articles`,
        params: "/:id",
        menu: "obres",
        menuChild: "obres",
        page: TabArticles,
        title: `${baseTitle}Obra edit articles`,
        roles: ["ROLE_ADMIN"]
    },
    OBRA_EDIT_FOTO: {
        path: `${urlBase}/obra/edit/foto`,
        params: "/:id",
        menu: "obres",
        menuChild: "obres",
        page: TabFotos,
        title: `${baseTitle}Obra edit foto`,
        roles: ["ROLE_ADMIN"]
    },
    OBRA_EDIT_VIDEO: {
        path: `${urlBase}/obra/edit/video`,
        params: "/:id",
        menu: "obres",
        menuChild: "obres",
        page: TabVideos,
        title: `${baseTitle}Obra edit video`,
        roles: ["ROLE_ADMIN"]
    },
    OBRA_EDIT_XARXES: {
        path: `${urlBase}/obra/edit/xarxes`,
        params: "/:id",
        menu: "obres",
        menuChild: "obres",
        page: TabXarxes,
        title: `${baseTitle}Obra edit xarxes`,
        roles: ["ROLE_ADMIN"]
    },
    OBRA_EDIT_CITES: {
        path: `${urlBase}/obra/edit/cites`,
        params: "/:id",
        menu: "obres",
        menuChild: "obres",
        page: TabCites,
        title: `${baseTitle}Obra edit cites`,
        roles: ["ROLE_ADMIN"]
    },
    OBRA_EDIT_CREDITS: {
        path: `${urlBase}/obra/edit/credits`,
        params: "/:id",
        menu: "obres",
        menuChild: "obres",
        page: TabCredits,
        title: `${baseTitle}Obra edit credits`,
        roles: ["ROLE_ADMIN"]
    },
    OBRA_EDIT_DOC: {
        path: `${urlBase}/obra/edit/doc-addicional`,
        params: "/:id",
        menu: "obres",
        menuChild: "obres",
        page: TabDocAdicional,
        title: `${baseTitle}Obra edit doc addicional`,
        roles: ["ROLE_ADMIN"]
    },
    OBRA_EDIT_SCANNER: {
        path: `${urlBase}/obra/edit/scanner`,
        params: "/:id",
        menu: "obres",
        menuChild: "obres",
        page: TabScanner,
        title: `${baseTitle}Obra edit scanner`,
        roles: ["ROLE_ADMIN"]
    }
}
