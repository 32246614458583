import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

/**
 * Muestra un reproductor de audio
 *
 * ## PROPS
 * - **url** *string*
 */
export default function HeadphonesIcon(props) {

    const {color, width, height, className, style} = props;

    return (
        <div className={className} style={{width: width, height: height, ...style}}>
            <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1.91667C7.12138 1.91667 5.31971 2.66294 3.99133 3.99133C2.66294 5.31971 1.91667 7.12138 1.91667 9V10.4167H3.33333C3.70906 10.4167 4.06939 10.5659 4.33507 10.8316C4.60074 11.0973 4.75 11.4576 4.75 11.8333V16.0833C4.75 16.4591 4.60074 16.8194 4.33507 17.0851C4.06939 17.3507 3.70906 17.5 3.33333 17.5H1.91667C1.54094 17.5 1.18061 17.3507 0.914932 17.0851C0.649255 16.8194 0.5 16.4591 0.5 16.0833V9C0.5 7.88376 0.719859 6.77846 1.14702 5.74719C1.57419 4.71592 2.20029 3.77889 2.98959 2.98959C3.77889 2.20029 4.71592 1.57419 5.74719 1.14702C6.77846 0.719859 7.88376 0.5 9 0.5C10.1162 0.5 11.2215 0.719859 12.2528 1.14702C13.2841 1.57419 14.2211 2.20029 15.0104 2.98959C15.7997 3.77889 16.4258 4.71592 16.853 5.74719C17.2801 6.77846 17.5 7.88376 17.5 9V16.0833C17.5 16.4591 17.3507 16.8194 17.0851 17.0851C16.8194 17.3507 16.4591 17.5 16.0833 17.5H14.6667C14.2909 17.5 13.9306 17.3507 13.6649 17.0851C13.3993 16.8194 13.25 16.4591 13.25 16.0833V11.8333C13.25 11.4576 13.3993 11.0973 13.6649 10.8316C13.9306 10.5659 14.2909 10.4167 14.6667 10.4167H16.0833V9C16.0833 7.12138 15.3371 5.31971 14.0087 3.99133C12.6803 2.66294 10.8786 1.91667 9 1.91667Z" fill={color}/>
            </svg>
        </div>
    )
}
