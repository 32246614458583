import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}/general`;

export function apiGeneralUpdate(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/update`, props, config)
}


export function apiGetGeneral(code, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/${code}`, config)
}
