import React, { useEffect, useState } from "react";
import QrCode from "../../../Auth/LoginQr/view";
import image from '../../../../assets/images/display/Frame3.svg';
import {getDisplayScreen, apiIsUpdateScreens} from "../../../../services/User/display"
import logo from '../../../../assets/images/Logo_MuseuArtProhibit_White.png';
import tarifas from '../../../../assets/images/display/CA_VW1_Tarifas.png';
import { VscUnmute } from "react-icons/vsc"
import { width } from "@mui/system";

export default function NewOne() {
    
    // Idiomas para la rotación
    const languages = ["ca", "es", "en"];
    
    // Pantalla pequeña estados
    const [getScreensLoaded, setGetScreensLoaded] = useState(false)
    const [currentLanguage, setCurrentLanguage] = useState(languages[0]);
    const [screens, setScreens] = useState({});
    const [state, setState] = useState([initialValues()])
    const [languageIndex, setLanguageIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    
    // Pantalla grande estados
    const [getScreensLoaded2, setGetScreensLoaded2] = useState(false)
    const [showQr2, setShowQr2] = useState(false)
    const [hideQr2, setHideQr2] = useState(false)
    const [currentLanguage2, setCurrentLanguage2] = useState(languages[0]);
    const [screens2, setScreens2] = useState({});
    const [state2, setState2] = useState(initialValues())
    const [languageIndex2, setLanguageIndex2] = useState(0);
    const [currentIndex2, setCurrentIndex2] = useState(0);

    // Reiniciar pantallas
    const [reloadScreen, setReloadScreen] = useState(0)

    // GET
    useEffect(() => {
        getDisplayScreenOne()
    }, [])

    // GET
    const getDisplayScreenOne = () => {
        getDisplayScreen({screenId:1}, 'ca').then(resp => {
            state.baseUrl = resp.data['baseUrl']
            setScreens(resp.data);
            const newState = resp.data[currentLanguage].map((element) => {
                // Variable para saber si está activo o no cada bloque
                let blockActive;
                if (element.displayBlockCode === "TYPE_VIDEO") {
                    blockActive = element.isActiveVideo;
                } else if (element.displayBlockCode === "TYPE_IMAGE") {
                    blockActive = element.isActiveImg;
                } else {
                    blockActive = element.isActiveTexto;
                }
              
                // Aplica la función initialValues al elemento
                const initializedElement = initialValues(element);
              
                return {
                    ...initializedElement,
                    blockActive: blockActive,
                };
            });
            setState(newState);
            setGetScreensLoaded(true)
        }).catch(err => {
            setState(initialValues())
        })

        getDisplayScreen({screenId:2}, 'ca').then(resp => {
            state2.baseUrl = resp.data['baseUrl']
            setScreens2(resp.data);
            const newState = resp.data[currentLanguage2].map((element) => {
                // Variable para saber si está activo o no cada bloque
                let blockActive;
                if (element.displayBlockCode === "TYPE_VIDEO") {
                    blockActive = element.isActiveVideo;
                } else if (element.displayBlockCode === "TYPE_IMAGE") {
                    blockActive = element.isActiveImg;
                } else {
                    blockActive = element.isActiveTexto;
                }
                // Variable para saber si está activo o no el QR
                let qrActive;
                if (element.displayBlockCode === "TYPE_VIDEO") {
                    qrActive = element.qrVideo;
                } else if (element.displayBlockCode === "TYPE_IMAGE") {
                    qrActive = element.qrImg;
                } else {
                    qrActive = element.qrTexto;
                }

                // Aplica la función initialValues al elemento
                const initializedElement = initialValues(element);
              
                return {
                    ...initializedElement,
                    blockActive: blockActive,
                    qrActive: qrActive
                };
            });
            setState2(newState);
            setGetScreensLoaded2(true)
        }).catch(err => {
            setState2(initialValues())
        })
    }

    // Cambio de idioma
    const changeLanguage = () => {
        // Ocultar el contenedor para que no se vean los bloques volviendo a su posición original
        document.querySelector(".led-1-text-container").classList.add("display-led-none");

        // Pasar al siguiente idioma
        const timerOut = setTimeout(() => {
            const nextLanguageIndex = (languageIndex + 1) % languages.length;
            setLanguageIndex(nextLanguageIndex);
            const newLanguage = languages[nextLanguageIndex];
            setCurrentLanguage(newLanguage);
        }, 500);
        return () => {
            clearTimeout(timerOut);
        }; 
    };

    // Reproducción de bloques
    const playBlock = (blockIndex) => {
        if (blockIndex < state.length) {
            // Eliminar la clase 'out' de todos los elementos con delay para que desaparezca mientras se muestra el bloque actual.
            document.querySelector(".led-1-text-container").classList.remove("display-led-none"); // Dejar de ocultar el contenedor mientras el último bloque vuelve a su sitio
            const timerOut = setTimeout(() => {
                const removeOut = document.querySelectorAll(".block-screen-one");
                removeOut?.forEach((element) => {
                    element.classList.remove("out");
                });
            }, 3400);

            // Activar bloque con un breve delay por la transición del bloque anterior (para todos menos bloque video)
            let timerVisible
            // Verificar si el último bloque activo no es de tipo vídeo
            const lastActiveIndex = findLastActiveBlockIndex(blockIndex);
            if (lastActiveIndex >= 0 && state[lastActiveIndex]?.displayBlockCode !== "TYPE_VIDEO") {
                // Ajustar temporizador según la condición
                const addVisible = document.querySelectorAll(`.block-screen-${state[blockIndex].blockScreenId}`)
                timerVisible = setTimeout(() => {
                    addVisible?.forEach((element) => {
                        element.classList.add("visible");
                    });
                }, 1700);
            }else{
                timerVisible = setTimeout(() => {},1)
                const addVisible = document.querySelectorAll(`.block-screen-${state[blockIndex].blockScreenId}`)
                addVisible?.forEach((element) => {
                    element.classList.add("visible");
                });
            }
            
            // Si es un vídeo, no utilizar temporizador y pasar al siguiente bloque una vez finalizada la reproducción. En caso contrario, mantener el bloque durante X segundos y ocultarlo
            if (state[blockIndex].displayBlockCode === "TYPE_VIDEO" && state[blockIndex].blockActive) {
                const videoElement = document.querySelector(`.block-screen-${state[blockIndex].blockScreenId}`);
    
                if (videoElement) {
                    videoElement.addEventListener("ended", () => {
                        let nextIndex = blockIndex + 1;
                        // Pasar al siguiente bloque si el que debía está desactivado
                        while (nextIndex < state.length && !state[nextIndex].blockActive) {
                            if (state[nextIndex].blockActive) {
                                setCurrentIndex(nextIndex);
                                break; // Termina el bucle cuando encuentra un bloque activado
                            }
                            nextIndex++;
                        }
    
                        // Hacer desaparecer el bloque actual
                        const removeVisible = document.querySelectorAll(`.block-screen-${state[blockIndex].blockScreenId}`);
                        removeVisible?.forEach((element) => {
                            element.classList.remove("visible");
                           // element.classList.add("out");
                        });
                        
                        // Pasar al siguiente bloque
                        if (nextIndex < state.length) {
                            setCurrentIndex(nextIndex);
                        } else {
                            changeLanguage(); // Cambia al siguiente idioma y desencadena una nueva reproducción
                        }
                    });
                }
            }else{
                const timer = setTimeout(() => {
                    // Verifica si el siguiente bloque está activado
                    let nextIndex = blockIndex + 1;
                    while (nextIndex < state.length && !state[nextIndex].blockActive) {
                        if (state[nextIndex].blockActive) {
                            setCurrentIndex(nextIndex);
                            break; // Termina el bucle cuando encuentra un bloque activado
                        }
                        nextIndex++;
                    }

                    // Hacer desaparecer el bloque actual
                    const removeVisible = document.querySelectorAll(`.block-screen-${state[blockIndex].blockScreenId}`)
                    removeVisible?.forEach((element) => {
                        element.classList.remove("visible")
                        element.classList.add("out")
                    });
    
                    // Pasar al siguiente bloque
                    if (nextIndex < state.length) {
                        setCurrentIndex(nextIndex);
                    } else {
                        changeLanguage(); // Cambia al siguiente idioma y desencadena una nueva reproducción
                    }
                }, 5000);
    
                return () => clearTimeout(timer);
            }
            return () => {
                clearTimeout(timerOut);
                clearTimeout(timerVisible);
            };  
        }
    };

    // Función para encontrar el índice del último bloque activo antes del bloque actual
    const findLastActiveBlockIndex = (tempCurrentIndex) => {
        for (let i = tempCurrentIndex - 1; i >= 0; i--) {
            if (state[i]?.blockActive) {
                return i;
            }
        }
        return -1;
    };

    // Actualizar state con el nuevo idioma
    useEffect(() => {
        if (screens[currentLanguage] && screens[currentLanguage].length > 0) {
            // Reiniciar los bloques
            const removeOut = document.querySelectorAll(".block-screen-one");
            removeOut?.forEach((element) => {
                element.classList.remove("out");
            });

            // Actualiar state
            const newState = screens[currentLanguage].map((element) => {
                // Variable para saber si está activo o no cada bloque
                let blockActive;
                if (element.displayBlockCode === "TYPE_VIDEO") {
                    blockActive = element.isActiveVideo;
                } else if (element.displayBlockCode === "TYPE_IMAGE") {
                    blockActive = element.isActiveImg;
                } else {
                    blockActive = element.isActiveTexto;
                }
        
                const initializedElement = initialValues(element);
        
                return {
                    ...initializedElement,
                    blockActive: blockActive,
                };
            });
            setState(newState);
            setCurrentIndex(0);
        } else {
            console.error(`No se encontró contenido para el idioma ${currentLanguage}`);
        }
    }, [currentLanguage]);

    useEffect(() => {
        // Si el contenido se ha cargado
        if (getScreensLoaded) {
            playBlock(currentIndex);
        }
    }, [currentIndex, state, getScreensLoaded]);

    // Pantalla grande

    // Cambio de idioma
    const changeLanguage2 = () => {
        // Ocultar el contenedor para que no se vean los bloques volviendo a su posición original
        document.querySelector(".led-2-text-container").classList.add("display-led-none");

        // Pasar al siguiente idioma después de reiniciar los bloques
        const timerLang = setTimeout(() => {
            const nextLanguageIndex2 = (languageIndex2 + 1) % languages.length;
            setLanguageIndex2(nextLanguageIndex2);
            const newLanguage = languages[nextLanguageIndex2];
            setCurrentLanguage2(newLanguage);
        }, 500);
        return () => {
            clearTimeout(timerLang);
        };
    };

    // Reproducción de bloques
    const playBlock2 = (blockIndex) => {
        if((state2[blockIndex].displayBlockCode === "TYPE_STREAM" && state2[blockIndex].isActiveVideo)){
            const addVisible = document.querySelectorAll(`.block-screen-2-${state2[blockIndex].blockScreenId}`)
            addVisible?.forEach((element) => {
                element.classList.add("visible");
            });
            if(state2[blockIndex].qrActive){
                setShowQr2(true)
            }else{
                setShowQr2(false)
            }
            setHideQr2(true)
        }else{
            setHideQr2(false)
            if (blockIndex < state2.length) {
                // Eliminar la clase 'out' de todos los elementos con delay para que desaparezca mientras se muestra el bloque actual.
                document.querySelector(".led-2-text-container").classList.remove("display-led-none"); // Dejar de ocultar el contenedor mientras el último bloque vuelve a su sitio
                const timerOut = setTimeout(() => {
                    const removeOut = document.querySelectorAll(".block-screen-two");
                    removeOut?.forEach((element) => {
                        element.classList.remove("out");
                    });
                }, 3400);
    
                // Activar bloque con un breve delay por la transición del bloque anterior (para todos menos bloque video)
                let timerVisible
                // Verificar si el último bloque activo no es de tipo vídeo
                const lastActiveIndex = findLastActiveBlockIndex2(blockIndex);
                if (lastActiveIndex >= 0 && state2[lastActiveIndex]?.displayBlockCode !== "TYPE_VIDEO") {
                    // Ajustar temporizador según la condición
                    const addVisible = document.querySelectorAll(`.block-screen-2-${state2[blockIndex].blockScreenId}`)
                    timerVisible = setTimeout(() => {
                        addVisible?.forEach((element) => {
                            element.classList.add("visible");
                        });
                        if(state2[blockIndex].qrActive){
                            setShowQr2(true)
                        }else{
                            setShowQr2(false)
                        }
                    }, 1700);
                }else{
                    timerVisible = setTimeout(() => {},1)
                    const addVisible = document.querySelectorAll(`.block-screen-2-${state2[blockIndex].blockScreenId}`)
                    addVisible?.forEach((element) => {
                        element.classList.add("visible");
                    });
                    if(state2[blockIndex].qrActive){
                        setShowQr2(true)
                    }else{
                        setShowQr2(false)
                    }

                }
                
                if (state2[blockIndex].displayBlockCode === "TYPE_VIDEO" && state2[blockIndex].blockActive) {
                    const videoElement = document.querySelector(`.block-screen-2-${state2[blockIndex].blockScreenId}`);
        
                    if (videoElement) {
                        videoElement.addEventListener("ended", () => {
                            let nextIndex = blockIndex + 1;
                            while (nextIndex < state2.length && !state2[nextIndex].blockActive) {
                                if (state2[nextIndex].blockActive) {
                                    setCurrentIndex2(nextIndex);
                                    break; // Termina el bucle cuando encuentra un bloque activado
                                }
                                nextIndex++;
                            }
        
                            const removeVisible = document.querySelectorAll(`.block-screen-2-${state2[blockIndex].blockScreenId}`);
                            removeVisible?.forEach((element) => {
                                element.classList.remove("visible");
                                element.classList.add("out");
                            });
                            
        
                            if (nextIndex < state2.length) {
                                setCurrentIndex2(nextIndex);
                            } else {
                                changeLanguage2(); // Cambia al siguiente idioma y desencadena una nueva reproducción
                            }
                        });
                    }
                }else{
                    const timer = setTimeout(() => {
                        // Verifica si el siguiente bloque está activado
                        let nextIndex = blockIndex + 1;
                        while (nextIndex < state2.length && !state2[nextIndex].blockActive) {
                            if (state2[nextIndex].blockActive) {
                                setCurrentIndex2(nextIndex);
                                break; // Termina el bucle cuando encuentra un bloque activado
                            }
                            nextIndex++;
                        }
                        
                        const removeVisible = document.querySelectorAll(`.block-screen-2-${state2[blockIndex].blockScreenId}`)
                        removeVisible?.forEach((element) => {
                            element.classList.remove("visible")
                            element.classList.add("out")
                        });
        
                        if (nextIndex < state2.length) {
                            setCurrentIndex2(nextIndex);
                        } else {
                            changeLanguage2(); // Cambia al siguiente idioma y desencadena una nueva reproducción
                        }
                    }, 5000);
        
                    return () => clearTimeout(timer);
                }
                return () => {
                    clearTimeout(timerOut);
                    clearTimeout(timerVisible);
                };            
            }
        }
    };

    // Función para encontrar el índice del último bloque activo antes del bloque actual
    const findLastActiveBlockIndex2 = (tempCurrentIndex) => {
        for (let i = tempCurrentIndex - 1; i >= 0; i--) {
            if (state[i]?.blockActive) {
                return i;
            }
        }
        return -1;
    };

    // Actualizar state con el nuevo idioma
    useEffect(() => {
        if (screens2[currentLanguage2] && screens2[currentLanguage2].length > 0) {
            // Reiniciar los bloques
            const removeOut = document.querySelectorAll(".block-screen-two");
            removeOut?.forEach((element) => {
                element.classList.remove("out");
            });

            // Actualiar state
            const newState = screens2[currentLanguage2].map((element) => {
                // Variable para saber si está activo o no cada bloque
                let blockActive;
                if (element.displayBlockCode === "TYPE_VIDEO") {
                    blockActive = element.isActiveVideo;
                } else if (element.displayBlockCode === "TYPE_IMAGE") {
                    blockActive = element.isActiveImg;
                } else {
                    blockActive = element.isActiveTexto;
                }
                // Variable para saber si está activo o no el QR
                let qrActive;
                if (element.displayBlockCode === "TYPE_VIDEO") {
                    qrActive = element.qrVideo;
                } else if (element.displayBlockCode === "TYPE_IMAGE") {
                    qrActive = element.qrImg;
                } else {
                    qrActive = element.qrTexto;
                }
        
                const initializedElement = initialValues(element);
        
                return {
                    ...initializedElement,
                    blockActive: blockActive,
                    qrActive: qrActive
                };
            });
            setState2(newState);
            setCurrentIndex2(0);
        } else {
            console.error(`No se encontró contenido para el idioma ${currentLanguage2}`);
        }
    }, [currentLanguage2]);

    useEffect(() => {
        // Si el contenido se ha cargado
        if (getScreensLoaded2) {
            playBlock2(currentIndex2);
        }
    }, [currentIndex2, state2, getScreensLoaded2]);
    
    const UnMutedVideo = () => {
        var video = document.getElementById("videoTwo")
        var btnUnMuted = document.getElementById("btnUnMuted")
        video.muted=false;
        btnUnMuted.style.display="none";
    }
    
    useEffect(() => {
        const interval = setInterval(() => {
            checkScreensUpdate()
            setReloadScreen(Math.random())
        }, 300000) 
        return () => clearInterval(interval)
    }, [reloadScreen])

    const checkScreensUpdate = () => {
        apiIsUpdateScreens().then(resp => {
            if(resp.data?.updatedOneOrTwo){
                // reload page
                window.location.reload()
            }
        })
    }

    return (
        <>
            <div style={{ boxSizing: "border-box" }}>
                <div style={{ width: "2560px", height: "360px", backgroundColor: '#141414', position: 'relative'}}>
                    {/*<button style={{border: 'none', backgroundColor:"transparent", color: '#fff', fontSize: 30, cursor: "pointer", position:"fixed", zIndex:10}} id="btnUnMuted" onClick={UnMutedVideo}><VscUnmute/></button>*/}
                    {getScreensLoaded2 && (
                        <div className="led-2-text-container">
                            {state2.map((element, key) => (
                                <>
                                    {element.displayBlockCode === "TYPE_STREAM"  && element.isActiveVideo ? ( 
                                        <div className="led-2-streaming-block">
                                            <div style={{width:"640px"}}>
                                                <iframe width="100%" height="100%" src={`${element.textoLineOne}?controls=1&autoplay=1&showinfo=0&modestbranding=1&mute=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                            </div>
                                            <div style={{width:"1280px"}}>
                                                {element.isActiveImg && (
                                                    <div style={{width:"100%", height: "100%"}}>
                                                        <img src={element.urlFileImg} key={element.urlFileImg} style={{width:"100%", objectFit:"cover"}}/>
                                                    </div>
                                                )}
                                            </div>
                                            <div style={{width:"640px"}}>
                                                <iframe width="100%" height="100%" src={`${element.textoLineOne}?controls=0&autoplay=1&showinfo=0&modestbranding=1&mute=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    ) : element.displayBlockCode === "TYPE_VIDEO" && element.isActiveVideo ? (
                                        <>
                                            <video className={`video-display-2 block-screen-two block-screen-2-${element.blockScreenId}`} autoPlay muted style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}} key={element.urlFileVideo}>
                                                <source src={element.urlFileVideo}></source>
                                            </video>
                                        </>
                                    ) : (
                                        <>
                                            {element.displayBlockCode === "TYPE_IMAGE" ? (
                                                <>
                                                    {element.isActiveImg && (
                                                        <div className={`rates-container-2 block-screen-two block-screen-2-${element.blockScreenId}`}>
                                                            <img src={element.urlFileImg} key={element.urlFileImg}/>
                                                        </div>
                                                    )}
                                                </>
                                            ) : element.displayBlockCode === "TYPE_TEXT" || element.displayBlockCode === "TYPE_EXPO" ? (
                                                <>
                                                    <div className={`led-2-full-width-block block-screen-two block-screen-2-${element.blockScreenId}`}>
                                                        {element.isActiveTexto && (
                                                            <>
                                                                {element.displayBlockCode === "TYPE_EXPO" ? (
                                                                    <div className="led-2-full-width-expo">
                                                                        <p>{element.textoLineOne}</p>
                                                                        <p>{element.textoLineTwo}</p>
                                                                        <p>{element.textoLineThree}</p>
                                                                        <p style={{fontFamily: 'QueensCondensedBoldItalic'}}>
                                                                            <span>{element.textoLineFour}</span> - <span>{element.textoLineFive}</span>
                                                                        </p>
                                                                    </div>
                                                                ) : element.displayBlockCode === "TYPE_TEXT" && (
                                                                    <div className="led-2-full-width-text">
                                                                        <p>{element.textoLineOne}</p>
                                                                        <p>{element.textoLineTwo}</p>
                                                                        <p>{element.textoLineThree}</p>
                                                                        <p>{element.textoLineFour}</p>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </>
                                            ) : element.displayBlockCode === "TYPE_TXTMEDIA" ? (
                                                <>
                                                    {element.isActiveTexto && (
                                                        <>
                                                        {element.isActiveVideo ? (
                                                            <>
                                                                <div className={`led-2-left-block-1 block-screen-two block-screen-2-${element.blockScreenId}`}>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        flexWrap: 'nowrap',
                                                                        alignItems: 'flex-start',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <p>{element.textoLineOne}</p>
                                                                        <p>{element.textoLineTwo}</p>
                                                                        <p>{element.textoLineThree}</p>
                                                                        <p>{element.textoLineFour}</p>
                                                                    </div>
                                                                </div>
                                                                <div className={`led-2-right-block-1 block-screen-two block-screen-2-${element.blockScreenId}`}>
                                                                    {element.isActiveImg ? (
                                                                        <video autoPlay muted loop key={element.urlFileVideo}>
                                                                            <source src={element.urlFileVideo}></source>
                                                                        </video>
                                                                    ) : (
                                                                        <img src={element.urlFileVideo} key={element.urlFileVideo} />
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className={`led-2-left-block-2 block-screen-two block-screen-2-${element.blockScreenId}`}>
                                                                    {element.isActiveImg ? (
                                                                        <video autoPlay muted loop key={element.urlFileVideo}>
                                                                            <source src={element.urlFileVideo}></source>
                                                                        </video>
                                                                    ) : (
                                                                        <img src={element.urlFileVideo} key={element.urlFileVideo}/>
                                                                    )}
                                                                </div>
                                                                <div className={`led-2-right-block-2 block-screen-two block-screen-2-${element.blockScreenId}`}>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        flexWrap: 'nowrap',
                                                                        alignItems: 'flex-start',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <p>{element.textoLineOne}</p>
                                                                        <p>{element.textoLineTwo}</p>
                                                                        <p>{element.textoLineThree}</p>
                                                                        <p>{element.textoLineFour}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        </>
                                                    )}
                                                </>
                                            ) : element.displayBlockCode === "TYPE_MEDIA" ? (
                                                <>
                                                    {element.isActiveTexto && (
                                                        <>
                                                            <div className={`led-2-left-block-3 block-screen-two block-screen-2-${element.blockScreenId}`}>
                                                                {element.isActiveVideo ? (
                                                                    <video autoPlay muted loop key={element.urlFileVideo}>
                                                                        <source src={element.urlFileVideo}></source>
                                                                    </video>
                                                                ) : (
                                                                    <img src={element.urlFileVideo}  key={element.urlFileVideo}/>
                                                                )}
                                                            </div>
                                                            <div className={`led-2-right-block-3 block-screen-two block-screen-2-${element.blockScreenId}`}>
                                                                {element.isActiveImg ? (
                                                                    <video autoPlay muted loop  key={element.urlFileImg}>
                                                                        <source src={element.urlFileImg}></source>
                                                                    </video>
                                                                ) : (
                                                                    <img src={element.urlFileImg}  key={element.urlFileImg}/>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            ) : null }
                                        </>
                                    )}
                                </>
                            ))}
                        </div>
                    )}
                { hideQr2 == false ? (
                    <>
                    <div className={`logo-qr-container ${showQr2 ? 'visible' : ''}`}>
                        <img src={logo} className="logo-image-display" />
                        <div className="qr-right-block">
                            <p className="url-text">www.museuartprohibit.org</p>
                            <div className="qr-block">
                                <QrCode />
                            </div>
                            {currentLanguage2 === "ca" ? (
                                <p className="scanner-text">Escaneja el codi QR per accedir a la guia digital del Museu de l’Art Prohibit.</p>
                            ) : currentLanguage2 === "es" ? (
                                <p className="scanner-text">Escanea el código QR para acceder a la guía digital del Museu de l’Art Prohibit.</p>
                            ) : currentLanguage2 === "en" && (
                                <p className="scanner-text">Scan the QR code to access the digital guide of the Museu de l’Art Prohibit.</p>
                            )}
                        </div>
                    </div>
                    </>
                    ) : null }
                </div>
                <div style={{ width: "1280px", height: "360px", backgroundColor: '#141414'}}>
                    {getScreensLoaded && (
                        <div className="led-1-text-container">
                            {state.map((element, key) => (
                                <>
                                    {element.displayBlockCode === "TYPE_VIDEO" && element.isActiveVideo ? (
                                        <>
                                            <video className={`video-display block-screen-one block-screen-${element.blockScreenId}`} autoPlay muted style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}} key={element.urlFileVideo}>
                                                <source src={element.urlFileVideo}></source>
                                            </video>
                                        </>
                                    ) : (
                                        <>
                                            {element.displayBlockCode === "TYPE_IMAGE" ? (
                                                <>
                                                    {element.isActiveImg && (
                                                        <div className={`rates-container block-screen-one block-screen-${element.blockScreenId}`}>
                                                            <img src={element.urlFileImg} key={element.urlFileImg}/>
                                                        </div>
                                                    )}
                                                </>
                                            ) : element.displayBlockCode === "TYPE_TEXT" || element.displayBlockCode === "TYPE_EXPO" ? (
                                                <>
                                                    <div className={`led-1-full-width-block block-screen-one block-screen-${element.blockScreenId}`}>
                                                        {element.isActiveTexto && (
                                                            <>
                                                                {element.displayBlockCode === "TYPE_EXPO" ? (
                                                                    <div className="led-1-full-width-expo">
                                                                        <p>{element.textoLineOne}</p>
                                                                        <p>{element.textoLineTwo}</p>
                                                                        <p>{element.textoLineThree}</p>
                                                                        <p style={{fontFamily: 'QueensCondensedBoldItalic'}}>
                                                                            <span>{element.textoLineFour}</span> - <span>{element.textoLineFive}</span>
                                                                        </p>
                                                                    </div>
                                                                ) : element.displayBlockCode === "TYPE_TEXT" && (
                                                                    <div className="led-1-full-width-text">
                                                                        <p>{element.textoLineOne}</p>
                                                                        <p>{element.textoLineTwo}</p>
                                                                        <p>{element.textoLineThree}</p>
                                                                        <p>{element.textoLineFour}</p>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </>
                                            ) : element.displayBlockCode === "TYPE_TXTMEDIA" ? (
                                                <>
                                                    {element.isActiveTexto && (
                                                        <>
                                                        {element.isActiveVideo ? (
                                                            <>
                                                                <div className={`led-1-left-block-1 block-screen-one block-screen-${element.blockScreenId}`}>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        flexWrap: 'nowrap',
                                                                        alignItems: 'flex-start',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <p>{element.textoLineOne}</p>
                                                                        <p>{element.textoLineTwo}</p>
                                                                        <p>{element.textoLineThree}</p>
                                                                        <p>{element.textoLineFour}</p>
                                                                    </div>
                                                                </div>
                                                                <div className={`led-1-right-block-1 block-screen-one block-screen-${element.blockScreenId}`}>
                                                                    {element.isActiveImg ? (
                                                                        <video autoPlay muted loop key={element.urlFileVideo}>
                                                                            <source src={element.urlFileVideo}></source>
                                                                        </video>
                                                                    ) : (
                                                                        <img src={element.urlFileVideo} key={element.urlFileVideo}/>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className={`led-1-left-block-2 block-screen-one block-screen-${element.blockScreenId}`}>
                                                                    {element.isActiveImg ? (
                                                                        <video autoPlay muted loop key={element.urlFileVideo}>
                                                                            <source src={element.urlFileVideo}></source>
                                                                        </video>
                                                                    ) : (
                                                                        <img src={element.urlFileVideo} key={element.urlFileVideo} />
                                                                    )}
                                                                </div>
                                                                <div className={`led-1-right-block-2 block-screen-one block-screen-${element.blockScreenId}`}>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        flexWrap: 'nowrap',
                                                                        alignItems: 'flex-start',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <p>{element.textoLineOne}</p>
                                                                        <p>{element.textoLineTwo}</p>
                                                                        <p>{element.textoLineThree}</p>
                                                                        <p>{element.textoLineFour}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        </>
                                                    )}
                                                </>
                                            ) : element.displayBlockCode === "TYPE_MEDIA" ? (
                                                <>
                                                    {element.isActiveTexto && (
                                                        <>
                                                            <div className={`led-1-left-block-3 block-screen-one block-screen-${element.blockScreenId}`}>
                                                                {element.isActiveVideo ? (
                                                                    <video autoPlay muted loop key={element.urlFileVideo}>
                                                                        <source src={element.urlFileVideo}></source>
                                                                    </video>
                                                                ) : (
                                                                    <img src={element.urlFileVideo} key={element.urlFileVideo}/>
                                                                )}
                                                            </div>
                                                            <div className={`led-1-right-block-3 block-screen-one block-screen-${element.blockScreenId}`}>
                                                                {element.isActiveImg ? (
                                                                    <video autoPlay muted loop key={element.urlFileImg}>
                                                                        <source src={element.urlFileImg}></source>
                                                                    </video>
                                                                ) : (
                                                                    <img src={element.urlFileImg} key={element.urlFileImg} />
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            ) : (<></>)}
                                        </>
                                    )}
                                </>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

function initialValues(props) {

    return {
        blockScreenId: props?.blockScreenId || null,
        displayBlockCode: props?.displayBlockCode || "",
        displayBlockName: props?.displayBlockName || "",
        isActiveTexto: props?.isActiveTexto === undefined ? true : props.isActiveTexto,
        qrTexto: props?.qrTexto === undefined ? false : props.qrTexto,
        textoLineOne: props?.textoLineOne || "",
        textoLineTwo: props?.textoLineTwo || "",
        textoLineThree: props?.textoLineThree || "",
        textoLineFour: props?.textoLineFour || "",
        textoLineFive: props?.textoLineFive || "",
        urlFileImg: props?.fileImg || "",
        isActiveImg: props?.isActiveImg === undefined ? true : props.isActiveImg,
        qrImg: props?.qrImg === undefined ? false : props.qrImg,
        urlFileVideo: props?.fileVideo || "",
        isActiveVideo: props?.isActiveVideo === undefined ? false : props.isActiveVideo,
        qrVideo: props?.qrVideo === undefined ? false : props.qrVideo,
    }
}
