import * as React from 'react';
import MobileMessage from "../../../../components/User/MobileMessage";
import Language from "../Language";
import {useEffect} from "react";
import {RouteUser} from "../../../../routes/RouteUser";
import animation from "../../../../assets/images/front/animaciones/splash/gif/Logo.gif";

export default function Splash() {

    const [showLang, setShowLang] = React.useState(false)

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            setShowLang(true)
        }, 3500)
        return () => clearTimeout(timeOutId)
    }, [])

    return (
        <>
            {showLang ? (
                <Language link={RouteUser.ONBOARDING.path}/>
            ) : (
                <>
                    <MobileMessage/>
                    <div className='splash' style={{
                        background: 'black',
                        width: '100vw',
                        top: '0',
                        bottom: '0',
                        left: '0',
                        right: '0',
                        position: 'relative',
                        zIndex: '3'
                    }}>
                        <div style={{
                            background: '#FFFFFF',
                            maxWidth: '980px',
                            width: '100vw',
                            height: '100vh',
                            margin: 'auto',
                            position: 'relative'
                        }}>
                            <div className='splash-container'>
                                {/* <Animation showLang={showLang} setShowLang={setShowLang} /> */}
                                <img src={animation} alt="logo" />

                                {/*<video autoPlay muted onEnded={() => setShowLang(true)}>*/}
                                {/*    <source src="http://localhost:3000/videos/logo.mp4"></source>*/}
                                {/*</video>*/}

                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
