import React, {useEffect, useState, useRef} from "react";

import {convertFromRaw} from 'draft-js';
import {EditorState} from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';


export default function Faqs2col({item}) {

    const [state, setState] = useState(initialValues(item))

    const [openIndexIZQ, setOpenIndexIZQ] = useState(null);
    const [accordionHeightsIZQ, setAccordionHeightsIZQ] = useState([]);
    const accordionRefsIZQ = useRef([]);

    const [openIndexDER, setOpenIndexDER] = useState(null);
    const [accordionHeightsDER, setAccordionHeightsDER] = useState([]);
    const accordionRefsDER = useRef([]);

    const handleAccordionClickIZQ = (index) => {
        setOpenIndexIZQ((prevIndex) => (prevIndex === index ? null : index));
        if (openIndexIZQ !== null && openIndexIZQ !== index) {
            setAccordionHeightsIZQ((prevHeights) => {
                const newHeights = [...prevHeights];
                newHeights[openIndexIZQ] = 0;
                return newHeights;
            });
        }
    };

    const handleAccordionClickDER = (index) => {
        setOpenIndexDER((prevIndex) => (prevIndex === index ? null : index));
        if (openIndexDER !== null && openIndexDER !== index) {
            setAccordionHeightsDER((prevHeights) => {
                const newHeights = [...prevHeights];
                newHeights[openIndexDER] = 0;
                return newHeights;
            });
        }
    };

    useEffect(() => {
        if (openIndexIZQ !== null) {
            const newAccordionHeights = [...accordionHeightsIZQ];
            newAccordionHeights[openIndexIZQ] =
                accordionRefsIZQ.current[openIndexIZQ]?.clientHeight;
            setAccordionHeightsIZQ(newAccordionHeights);
        } else {
            setAccordionHeightsIZQ(new Array(state?.itemOne?.length).fill(0));
        }
    }, [openIndexIZQ]);

    useEffect(() => {
        if (openIndexDER !== null) {
            const newAccordionHeights = [...accordionHeightsDER];
            newAccordionHeights[openIndexDER] =
                accordionRefsDER.current[openIndexDER]?.clientHeight;
            setAccordionHeightsDER(newAccordionHeights);
        } else {
            setAccordionHeightsDER(new Array(state?.itemTwo?.length).fill(0));
        }
    }, [openIndexDER]);

    const isAllUpperCase = (str) => {
        return str === str.toUpperCase();
    };

    useEffect(() => {
        state?.itemOne?.forEach(elemento => {
            const modifiedContents = state.itemOne.map((elemento) => {
                const contenido = elemento.p;
                const contenidoModificado = contenido.replace(/\*/g, '<span style="color: red;">*</span>');
                return { ...elemento, p: contenidoModificado };
            });
            setState((prevState) => ({ ...prevState, itemOne: modifiedContents }));
        });

        state?.itemTwo?.forEach(elemento => {
            const modifiedContents = state.itemTwo.map((elemento) => {
                const contenido = elemento.p;
                const contenidoModificado = contenido.replace(/\*/g, '<span style="color: red;">*</span>');
                return { ...elemento, p: contenidoModificado };
            });
            setState((prevState) => ({ ...prevState, itemTwo: modifiedContents }));
        });
    }, [state]);

    return (
        <div className="blocks-full-width">
            <div className="faqs-block-2-col">
                <div className="accordion">
                    {state?.itemOne?.map((doc, index) => (
                        <div key={index} className={`accordion-item ${openIndexIZQ === index ? "open-accordion" : ""}`}>
                            <div className="accordion-header" onClick={() => handleAccordionClickIZQ(index)}>
                                {/* <p className={`${isAllUpperCase(doc?.p) ? 'uppercase-style' : 'lowercase-style'} header-title`}>{doc?.p}</p> */}
                                <p className={`uppercase-style header-title`} dangerouslySetInnerHTML={{ __html: doc?.p }} />
                                <span className="accordion-icon">
                                    {openIndexIZQ === index ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path d="M0 9.77588H17.7105" stroke="black"/>
                                        <path d="M8.85352 0.868652L8.85351 18.6839" stroke="black"/>
                                    </svg>
                                     : 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path d="M0 9.77588H17.7105" stroke="black"/>
                                        <path d="M8.85352 0.868652L8.85351 18.6839" stroke="black"/>
                                    </svg>}
                                </span>
                            </div>
                               
                            <div style={{ height: `${accordionHeightsIZQ[index]}px` }} className="accordion-content">
                                <div ref={(el) => (accordionRefsIZQ.current[index] = el)}>
                                    <div className="content-text dynamic-html-text" dangerouslySetInnerHTML={{__html: convertHTML(doc?.r)}} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="accordion">
                    {state?.itemTwo?.map((doc, index) => (
                            <div key={index} className={`accordion-item ${openIndexDER === index ? "open-accordion" : ""}`}>
                            <div className="accordion-header" onClick={() => handleAccordionClickDER(index)}>
                                {/* <p className={`${isAllUpperCase(doc?.p) ? 'uppercase-style' : 'lowercase-style'} header-title`}>{doc?.p}</p> */}
                                <p className={`uppercase-style header-title`} dangerouslySetInnerHTML={{ __html: doc?.p }} />
                                <span className="accordion-icon">
                                    {openIndexDER === index ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path d="M0 9.77588H17.7105" stroke="black"/>
                                        <path d="M8.85352 0.868652L8.85351 18.6839" stroke="black"/>
                                    </svg>
                                     : 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path d="M0 9.77588H17.7105" stroke="black"/>
                                        <path d="M8.85352 0.868652L8.85351 18.6839" stroke="black"/>
                                    </svg>}
                                </span>
                            </div>
                               
                            <div style={{ height: `${accordionHeightsDER[index]}px` }} className="accordion-content">
                                <div ref={(el) => (accordionRefsDER.current[index] = el)}>
                                    <div className="content-text dynamic-html-text" dangerouslySetInnerHTML={{__html: convertHTML(doc?.r)}} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

function initialValues(props) {
    
    return {
        "text": props?.text,
        "itemOne": props?.itemOne ? JSON.parse(props?.itemOne) : [],
        "itemTwo": props?.itemTwo ? JSON.parse(props?.itemTwo) : [],
        "itemOneExample": [{"id":1,"p":"p1","r":"r1"}]
    }
}

function convertHTML(item)
{
    let a;
    
    if(item)
    {
        a=EditorState.createWithContent(convertFromRaw(JSON.parse(item))).getCurrentContent()
    }
    else
    {
        a=EditorState.createEmpty().getCurrentContent()
    }

    const rawContentState = convertToRaw(a);

    let markup = draftToHtml(
    rawContentState
    );

    markup=markup.replace(/<p><\/p>/gi, "<br/>");

    return markup
}
