import React, {useEffect, useState} from "react";
import {apiLogScanner, apiLogScannerId} from "../../../../services/Admin/log";
import moment from "moment";
import {Chip, Grid, IconButton} from "@mui/material";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import Table from "../../../../components/Table/Table";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import PreviewIcon from "@mui/icons-material/Preview";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import {RouteAdminObras} from "../../../../routes/RouteAdminObras";
import Options from "../User/Fields/Options";

export default function LogScanner() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [selection, setSelection] = useState(["92"])
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [loadingId, setLoadingId] = useState(true)
    const [dataId, setDataId] = useState({})
    const [saveLog, setSaveLog] = useState(false)

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field
        if (state.sort.field === "roleName") { field = "role" }
        if (state.sort.field === "active") { field = "isActive" }
        if (state.sort.field === "access") { field = "lastActivity" }

        let params = {
            //limit: DASH_MAX_ROW,
            limit:100,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.email) { params.email = state.email }
        if (state.role) { params.role = state.role }

        apiLogScanner(params).then(resp => {
            setRowCount(resp.data?.total)

            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc,
                    id: doc.id,
                    creationDate: moment.unix(doc.creationDate).format("D/M/Y HH:mm"),
                }
            })
            setRows(temp)
            setLoading(false)
            setSaveLog(resp.data?.saveLog)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    const handleChangeSelection = (e) => {
        if (selection.includes(e.target.value)) {
            setSelection(selection.filter(item => item !== e.target.value))
        } else {
            setSelection([...selection, e.target.value])
        }
    }

    const handleClick = (id) => {
        handleOpen()
        setLoadingId(true)
        setDataId({})
        apiLogScannerId(id).then(resp => {
            setLoadingId(false)
            setDataId({
                ...resp.data,
                fileName: resp.data.fileName,
                label: JSON.parse(resp.data.label),
                text: JSON.parse(resp.data.text),
                color: JSON.parse(resp.data.color),
                infoResult: JSON.parse(resp.data.infoResult),
            })

        }).catch(err => {
            console.log(err)
            setLoadingId(false)
        })
    }

    return (
        <>
            <HeaderPage title="Scanner">
                <Options
                    saveLog={saveLog}
                    setSaveLog={setSaveLog}
                    loading={loading}
                    getItemsData={getItemsData}
                />
            </HeaderPage>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MsgError msg={msgError}/>
                </Grid>

                <Grid item xs={12}>
                    <Table
                        columns={tableColumns(handleClick)}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <LoadingSpinner show={loadingId}/>

                    {!loadingId && (
                        <>
                            <h3 style={{marginTop: 0}}>{moment.unix(dataId?.creationDate).format("D/M/Y HH:mm")}</h3>

                            <PrintJosn
                                index={0}
                                artworkScannerId={null}
                                urlImage={dataId?.fileName}
                                urlImageDos={null}
                                color={dataId?.color}
                                text={dataId?.text}
                                textLength={dataId?.textLength}
                                label={dataId?.label}
                                originalText={null}
                                originalLabel={null}

                                listText={null}
                                listLabel={null}

                                textSimilitud={null}
                                labelSimilitud={null}
                                colorSimilitud={null}
                                totalSimilitud={null}
                                listLabelSimilitud={null}
                                listTextSimilitud={null}
                                artworkId={null}
                                isPoster={null}
                            />

                            <h2>Results</h2>

                            {dataId?.infoResult?.map((item, i) => (
                                <div key={i}>
                                    <PrintJosn
                                        index={i + 1}
                                        artworkScannerId={item?.artworkScannerId}
                                        urlImage={item?.original_scanner_img}
                                        urlImageDos={item?.original_story_img}
                                        color={JSON.parse(item?.color)}
                                        text={JSON.parse(item?.text)}
                                        label={JSON.parse(item?.label)}
                                        originalText={dataId?.text}
                                        originalLabel={dataId?.label}
                                        textLength={item?.textLength}


                                        listText={JSON.parse(item?.listText)}
                                        listLabel={JSON.parse(item?.listLabel)}

                                        textSimilitud={item?.textSimilitud}
                                        labelSimilitud={item?.labelSimilitud}
                                        colorSimilitud={item?.colorSimilitud}
                                        totalSimilitud={item?.totalSimilitud}
                                        listLabelSimilitud={item?.listLabelSimilitud}
                                        listTextSimilitud={item?.listTextSimilitud}
                                        artworkId={item?.artworkId}
                                        isPoster={item?.isPoster ? 'Yes' : 'No'}
                                    />
                                </div>
                            ))}
                            <style>
                                {`
                                    #info table {
                                        width: 100%;
                                        border: 1px solid #ccc;
                                    }
                                    
                                    #info tr{
                                        border: 1px solid #ccc;
                                    }
                                    
                                    #info td {
                                        padding: 5px;
                                        border: 1px solid #ccc;
                                    }
                                `}
                            </style>
                        </>
                    )}
                </Box>
            </Modal>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        email: "",
        role: ""
    }
}

function tableColumns(handleClick) {
    return [
        {
            field: "id",
            headerName: "ID",
            sortable: false,
            flex: 1
        },
        {
            field: "creationDate",
            headerName: "CREATED AT",
            sortable: false,
            flex: 1
        },
        {
            field: "textLength",
            headerName: "TEXT LENGTH",
            sortable: false,
            flex: 1
        },
        {
            field: "langUser",
            headerName: "LANG USER",
            sortable: false,
            flex: 1
        },
        {
            field: "actions",
            headerName: "ACCIONS",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <IconButton aria-label="delete" onClick={() => handleClick(params.id)}>
                            <PreviewIcon fontSize="small" />
                        </IconButton>
                    </>
                )
            },
        },
    ]
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    height: '90vh'
}

function PrintJosn(props) {

    const {
        index,
        artworkScannerId,
        urlImage,
        urlImageDos,
        color,
        text,
        label,
        textSimilitud,
        labelSimilitud,
        totalSimilitud,
        artworkId,
        textLength,
        colorSimilitud,
        listLabelSimilitud,
        listTextSimilitud,
        listText,
        listLabel,
        originalText,
        originalLabel,
        isPoster
    } = props

    const colorList = (type, name) => {
        if (type === 'label') {
            // Buscamos si la palabra existe en la lista blanca solo si el id coinicide
            if (listLabel) {
                const find = listLabel.find(item => item === name)
                if (find) {
                    // Comparamos si el nombre se encuantra en el array de originalLabel dentro la propiedad DetectedText
                    const findOriginal = originalLabel.find(item => item.Name === name)
                    if (findOriginal) {
                        return '#77dd77'
                    } else {
                        return 'red'
                    }
                }
            }
        } else if (type === 'text') {
            // Buscamos si la palabra existe en la lista blanca solo si el id coinicide
            if (listText) {
                const find = listText.find(item => item === name)
                if (find) {
                    // Comparamos si el nombre se encuantra en el array de originalText dentro la propiedad DetectedText
                    const findOriginal = originalText.find(item => item.DetectedText === name)
                    if (findOriginal) {
                        return '#77dd77'
                    } else {
                        return 'red'
                    }
                }
            }
        }

        return '#e7e7e7'
    }

    return (
        <>
            <hr/>
            {index > 0 && (<h4>Nº {index} - ID {artworkScannerId}</h4>)}
            <table id="info" style={{ width: '100%', marginBottom: 20}}>
                <tbody>
                    {artworkId && (
                        <tr>
                            <td colSpan={2}>
                                {isPoster == "No" && (
                                    <>
                                        <p>textSimilitud: {parseInt(textSimilitud)} %</p>
                                        <p>listTextSimilitud: {parseInt(listTextSimilitud)} %</p>

                                        <hr/>

                                        <p>labelSimilitud: {parseInt(labelSimilitud)} %</p>
                                        <p>listLabelSimilitud: {parseInt(listLabelSimilitud)} %</p>
                                        <hr/>

                                        <p>colorSimilitud: {parseInt(colorSimilitud)} %</p>
                                        <hr/>
                                    </>
                                )}
                                <p>totalSimilitud: <strong style={{fontFamily: 'arial'}}>{parseInt(totalSimilitud)} %</strong></p>

                                <p>artworkId: <a href={`${RouteAdminObras.OBRA_EDIT_SCANNER.path}/${artworkId}`} target={"_blank"}>link {artworkId}</a></p>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td style={{verticalAlign: 'top'}}>Camera</td>
                        <td>
                            {urlImageDos && (
                                <h4>Scanner image</h4>
                            )}
                            <img src={urlImage} alt="img" style={{width: 200}}/>
                            {urlImageDos && (
                                <>
                                    <h4>Story image</h4>
                                    <img src={urlImageDos} alt="img" style={{width: 200}}/>
                                </>
                            )}
                            <a href={urlImage} target="_blank" rel="noreferrer">Descargar</a>
                        </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>Color</td>
                        <td>
                            {color && (
                                <>
                                    {Object.keys(color).length > 0 && (
                                        <>
                                            {Object.keys(color).map((key) => {
                                                const label = color[key];
                                                return (
                                                    <Chip
                                                        key={key}
                                                        label={`${key} ${Math.floor(label)}%`}
                                                        style={{ margin: 3, background: `${key}`, color: key === 'white' ? 'black' : 'white' }}
                                                        size="small"
                                                    />
                                                );
                                            })}
                                        </>
                                    )}
                                </>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>Text {textLength}</td>
                        <td>
                            {text && (
                                <>
                                    {text.length > 0 && (
                                        <>
                                            {text.map((label, j) => (
                                                <Chip
                                                    key={j}
                                                    label={`${label?.DetectedText} ${Math.floor(label?.Confidence)}%`}
                                                    style={{margin: 3, background: colorList('text',`${label?.DetectedText}`)}}
                                                    size="small"
                                                />
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign: 'top'}}>Label</td>
                        <td>
                            {label && (
                                <>
                                    {label.length > 0 && (
                                        <>
                                            {label.map((label, j) => (
                                                <Chip
                                                    key={j}
                                                    label={`${label?.Name} ${Math.floor(label?.Confidence)}%`}
                                                    style={{margin: 3, background: colorList('label',`${label?.Name}`)}}
                                                    size="small"
                                                />
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </td>
                    </tr>

                    <tr>
                        <td style={{verticalAlign: 'top'}}>Cartela</td>
                        <td>
                            {isPoster}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}
