import React, {useEffect, useState} from "react"

// Secuencia de imágenes
import frame0 from '../../../../assets/images/front/animaciones/game/intro/secuencia/FLAMES_Inici_espera_00000.png';
import frame1 from '../../../../assets/images/front/animaciones/game/intro/secuencia/FLAMES_Inici_espera_00001.png';
import frame2 from '../../../../assets/images/front/animaciones/game/intro/secuencia/FLAMES_Inici_espera_00002.png';
import frame3 from '../../../../assets/images/front/animaciones/game/intro/secuencia/FLAMES_Inici_espera_00003.png';
import frame4 from '../../../../assets/images/front/animaciones/game/intro/secuencia/FLAMES_Inici_espera_00004.png';
import frame5 from '../../../../assets/images/front/animaciones/game/intro/secuencia/FLAMES_Inici_espera_00005.png';
import frame6 from '../../../../assets/images/front/animaciones/game/intro/secuencia/FLAMES_Inici_espera_00006.png';
import frame7 from '../../../../assets/images/front/animaciones/game/intro/secuencia/FLAMES_Inici_espera_00007.png';
import frame8 from '../../../../assets/images/front/animaciones/game/intro/secuencia/FLAMES_Inici_espera_00008.png';
import frame9 from '../../../../assets/images/front/animaciones/game/intro/secuencia/FLAMES_Inici_espera_00009.png';

export default function FlamesAnimation(props) {

    const { currentIndex, setCurrentIndex } = props
    const frames = [
        frame0,
        frame1,
        frame2,
        frame3,
        frame4,
        frame5,
        frame6,
        frame7,
        frame8,
        frame9
    ];

    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentIndex(prevIndex => (prevIndex + 1) % frames.length); // Número total de frames total
        }, 80); // Frame delay 80
      
        return () => {
          clearInterval(interval);
        };
    }, []);

    return (
        <div className="animation-flames">
            <img src={frames[currentIndex]} />
        </div>
    )
}
