import React, { useRef, useState, useEffect } from "react";

export default function IncidentAnyLloc(props) {

    const {content} = props

    return(
        <div className="text-block" style={{maxWidth: '100%'}}>
            {content.text && 
                <div style={{paddingBottom: '24px', paddingTop: '8px', borderTop: '1px solid #FE7A84'}}>
                    <div style={{fontFamily: 'SohneBuch', fontSize: '16px', lineHeight: '21px', color: '#141414', margin: '0px'}} dangerouslySetInnerHTML={{ __html: content.text }} />
                </div>
            }
        </div>
    )
}
