import React, {useEffect, useRef, useState} from "react";
import {Grid} from "@mui/material";
import ButtonFile from "../../../../../components/Admin/Inputs/ButtonFile";
import Header from "./Header";
import {useParams} from "react-router-dom";
import clone from "../../../../../utils/clone";
import {apiSaveVideo, apiGetVideo, apiSaveFileVideo} from "../../../../../services/Admin/obra";
import {apiDeleteVideo, apiDeleteVideoFile} from "../../../../../services/Admin/obraDelete";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import ReactPlayer from "react-player";
import {MdOutlineSubtitles} from "react-icons/md";
import {MdDeleteForever} from "react-icons/md";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";
import ModalConfirm from "../../../../../components/Admin/ModalConfirm";
import EditorText from "../../../../../components/EditorText";
import TitlePage from "../../../../../components/Admin/TitlePage";

export default function TabVideos() {
    const {id} = useParams()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [state, setState] = useState(initialValues())
    const myRef = useRef(null)
    const [openModal, setOpenModal] = React.useState(false)
    const [pendingIndexRemove, setPendingIndexRemove] = React.useState(null)
    const [openModalFile, setOpenModalFile] = React.useState(false)
    const [pendingIndexRemoveFile, setPendingIndexRemoveFile] = React.useState(null)
    const [sectionTypeRemove, setSectionTypeRemove] = React.useState(null)

    const handleOpenModal = (key) => {
        setOpenModal(true)
        setPendingIndexRemove(key)
    }

    const handleOpenModalFile = (key, fileType) => {
        setOpenModalFile(true)
        setPendingIndexRemoveFile(key)
        setSectionTypeRemove(fileType)
    }

    // Si no existe el idioma iniciamos el modelo. Es decir, montamos estructura
    useEffect(() => {
        getData()
    }, [state.langSelect])

    const getData = () => {
        setLoadingGetInfo(true)

        apiGetVideo({id}, state?.langSelect).then(resp => {
            let videosNew = resp.data.videos?.map(doc => {
                return {
                    ...doc
                }
            })

            setState({...state, videos: videosNew})
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data.msg)
        })
    }

    const handleChangeFile = key => (event) => {
        state.videos[key][event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = index => async () => {
        stateUpdateSimple(index, true, [], [])

        let error = validationSchema(state)
        if (error.length !== 0) {
            stateUpdateSimple(index, false, error, [])
            return
        }

        let videos = state.videos[index]
        videos.langSelect = state.langSelect

        await apiSaveVideo(videos).then(resp => {
            // Actualizamos el id en caso de creación
            state.videos[index].id = resp.data?.id
            setState({...state})

            let successMessage = resp.data?.msg

            // Enviamos para guardar la imagen
            let text = state.videos[index]

            // Comprobamos si existe file
            let fileVideoBinary = text?.fileVideo.length > 0 ? text?.fileVideo[0].binary : null
            let fileVideoName = text?.fileVideo.length > 0 ? text?.fileVideo[0].name : null

            let fileThumbnailBinary = text?.fileThumbnail.length > 0 ? text?.fileThumbnail[0].binary : null
            let fileThumbnailName = text?.fileThumbnail.length > 0 ? text?.fileThumbnail[0].name : null

            let fileSubtitleBinary = text?.fileSubtitle.length > 0 ? text?.fileSubtitle[0].binary : null
            let fileSubtitleName = text?.fileSubtitle.length > 0 ? text?.fileSubtitle[0].name : null

            handleSubmitFile(
                index,
                state.videos[index].id,
                state.langSelect,

                fileVideoBinary,
                fileVideoName,

                fileThumbnailBinary,
                fileThumbnailName,

                fileSubtitleBinary,
                fileSubtitleName,

                successMessage
            )

        }).catch(err => {
            stateUpdateSimple(index, false, err.response?.data?.msg, [])
        })
    }

    const stateUpdateSimple = (index, loading, msgError, msgSuccess) => {
        state.videos[index].loading = loading
        state.videos[index].msgError = msgError
        state.videos[index].msgSuccess = msgSuccess

        setState({...state})
    }

    const stateDeleteSimple = (index, loadingDelete, msgError, msgSuccess) => {
        state.videos[index].loadingDelete = loadingDelete
        state.videos[index].msgError = msgError
        state.videos[index].msgSuccess = msgSuccess

        setState({...state})
    }

    const handleSubmitFile = (
        index,
        idTemp,
        langCode,

        fileVideoBinary,
        fileVideoName,

        fileThumbnailBinary,
        fileThumbnailName,

        fileSubtitleBinary,
        fileSubtitleName,

        successMessage
    ) => {
        if (idTemp) {
            let f = new FormData()
            f.append(`fileVideo[]`, fileVideoBinary)
            f.append(`originalVideoName`, fileVideoName)

            f.append(`fileThumbnail[]`, fileThumbnailBinary)
            f.append(`originalThumbnailName`, fileThumbnailName)

            f.append(`fileSubtitle[]`, fileSubtitleBinary)
            f.append(`originalSubtitleName`, fileSubtitleName)

            f.append(`langCode`, langCode)

            apiSaveFileVideo(f, idTemp).then(resp => {
                state.videos[index].video = resp.data?.data?.video
                state.videos[index].videoOriginalName = resp.data?.data?.videoOriginalName
                state.videos[index].videoUrl = resp.data?.data?.videoUrl
                state.videos[index].fileVideo = []

                state.videos[index].thumbnail = resp.data?.data?.thumbnail
                state.videos[index].thumbnailOriginalName = resp.data?.data?.thumbnailOriginalName
                state.videos[index].thumbnailUrl = resp.data?.data?.thumbnailUrl
                state.videos[index].fileThumbnail = []

                state.videos[index].subtitle = resp.data?.data?.subtitle
                state.videos[index].subtitleOriginalName = resp.data?.data?.subtitleOriginalName
                state.videos[index].subtitleUrl = resp.data?.data?.subtitleUrl
                state.videos[index].fileSubtitle = []

                setState({...state})

                stateUpdateSimple(index, false, [], resp.data.msg)
            }).catch(err => {
                stateUpdateSimple(index, false, err.response?.data?.msg, [])
            })
        }else{
            stateUpdateSimple(index, false, [], successMessage)
        }
    }


    const addVideo = () => {
        let model = state.model
        model.artworkId = id
        setState({...state, videos: [...state.videos, clone(model)]})
        myRef.current.scrollIntoView()
    }

    const handleRemove = () => {
        stateDeleteSimple(pendingIndexRemove, true, [], [])
        apiDeleteVideo({
            id: state.videos[pendingIndexRemove].id
        }).then(resp => {
            window.location.reload();
            stateDeleteSimple(pendingIndexRemove, false, [], resp.data?.msg)
            setPendingIndexRemove(null)
        }).catch(err => {
            stateDeleteSimple(pendingIndexRemove, false, err.response?.data?.msg, [])
            setPendingIndexRemove(null)
        })
    }

    const handleDeleteFile = () => {
        apiDeleteVideoFile({
            id: state.videos[pendingIndexRemoveFile].id,
            fileType: sectionTypeRemove,
            langCode: state.langSelect
        }).then(resp => {
            window.location.reload();
            setPendingIndexRemoveFile(null)
            setSectionTypeRemove(null)
        }).catch(err => {
            setPendingIndexRemoveFile(null)
            setSectionTypeRemove(null)
        })
    }

    return (
        <>
            <Header
                state={state}
                setState={setState}
                id={id}
                tabType={4}
                msgError={msgError}
                msgSuccess={msgSuccess}
            >
                <InputButton
                    style={{float: "right", margin: "5px 10px 10px 0px"}}
                    onClick={addVideo}
                    text=" + Afegir vídeo"
                />
            </Header>

            <LoadingSpinner show={loadingGetInfo}/>

            <div style={{display: loadingGetInfo ? 'none' : ''}}>
                {state.videos.map((element, key) => (
                    <Grid container spacing={3} style={{padding: '0px'}} key={key}>
                        {key === 0 ? (
                            <Grid item xs={12} md={12} lg={12} style={{paddingTop: 0}}>
                                <TitlePage title={`Vídeo ${key + 1}`} />
                            </Grid>
                        ) : (
                            <Grid item xs={12} md={12} lg={12}>
                                <TitlePage title={`Vídeo ${key + 1}`} borderTop={true} />
                            </Grid>
                        )}

                        {/* Video */}
                        <Grid item xs={6}>
                            <div style={{textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto'}}>
                                <ReactPlayer
                                    width="100%"
                                    height="auto"
                                    url={
                                        state.videos[key]?.fileVideo[0]?.base64 ??
                                        state.videos[key]?.videoUrl
                                    }
                                    playing={false}
                                    loop
                                    muted
                                    controls={true}
                                />
                                <span style={{wordWrap: 'break-word', overflowWrap: 'break-word'}}>{
                                    state.videos[key]?.fileVideo[0]?.name ??
                                    state.videos[key]?.videoOriginalName
                                }</span>
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div style={{textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto'}}>
                                <img alt="" src={
                                    state.videos[key]?.fileThumbnail[0]?.base64 ??
                                    state.videos[key]?.thumbnailUrl
                                } style={{width: '100%', objectFit: 'cover'}}/>

                                <span style={{wordWrap: 'break-word', overflowWrap: 'break-word'}}>{
                                    state.videos[key]?.fileThumbnail[0]?.name ??
                                    state.videos[key]?.thumbnailOriginalName
                                }</span>
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <ButtonFile
                                label="+ Vídeo"
                                name="fileVideo"
                                onChange={handleChangeFile(key)}
                                multiple={false}
                                variant="outlined"
                                accept="video/mp4"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <ButtonFile
                                label="+ PNG Miniatura"
                                name="fileThumbnail"
                                onChange={handleChangeFile(key)}
                                multiple={false}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={6} style={{paddingTop: 10}}>
                            {state.videos[key]?.video && 
                                <InputButtonLoading
                                    onClick={() => handleOpenModalFile(key, "video")}
                                    loading={state.videos[key].loadingDeleteFile}
                                    label={(
                                        <><MdDeleteForever fontSize="inherit" style={{width: '20px', height: '20px', marginBottom: '-3px'}} /> Eliminar vídeo</>
                                    )}
                                    position="left"
                                />
                            }
                        </Grid>

                        <Grid item xs={6} style={{paddingTop: 10}}>
                            {state.videos[key]?.thumbnail && 
                                <InputButtonLoading
                                    onClick={() => handleOpenModalFile(key, "thumbnail")}
                                    loading={state.videos[key].loadingDeleteFile}
                                    label={(
                                        <><MdDeleteForever fontSize="inherit" style={{width: '20px', height: '20px', marginBottom: '-3px'}} /> Eliminar miniatura</>
                                    )}
                                    position="left"
                                />
                            }
                        </Grid>

                        <Grid item xs={6} style={(state.videos[key]?.fileSubtitle[0]?.name ||
                            state.videos[key]?.subtitleOriginalName) ? null : { paddingTop: '0px' }}>
                            <div style={{textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto'}}>
                                {(state.videos[key]?.fileSubtitle[0]?.name ||
                                    state.videos[key]?.subtitleOriginalName) && (
                                    <a href={
                                        state.videos[key]?.fileSubtitle[0]?.base64 ??
                                        state.videos[key]?.subtitleUrl
                                    } target="_blank" rel="noreferrer">
                                        <MdOutlineSubtitles style={{fontSize: '60px'}}/>
                                    </a>
                                )}

                                <br/>

                                <span style={{wordWrap: 'break-word', overflowWrap: 'break-word'}}>{
                                    state.videos[key]?.fileSubtitle[0]?.name ??
                                    state.videos[key]?.subtitleOriginalName
                                }</span>
                            </div>
                        </Grid>
                        <Grid item xs={6}></Grid>

                        <Grid item xs={6} style={(state.videos[key]?.fileSubtitle[0]?.name ||
                            state.videos[key]?.subtitleOriginalName) ? null : { paddingTop: '0px' }}>
                            <ButtonFile
                                label="+ VTT Subtítols"
                                name="fileSubtitle"
                                onChange={handleChangeFile(key)}
                                multiple={false}
                                variant="outlined"
                                accept=".vtt"
                            />
                        </Grid>

                        <Grid item xs={6} style={{paddingTop: 10}}>
                            {state.videos[key]?.subtitle && 
                                <InputButtonLoading
                                    onClick={() => handleOpenModalFile(key, "subtitle")}
                                    loading={state.videos[key].loadingDeleteFile}
                                    label={(
                                        <><MdDeleteForever fontSize="inherit" style={{width: '20px', height: '20px', marginBottom: '-3px'}} /> Eliminar subtítols</>
                                    )}
                                    position="left"
                                />
                            }
                        </Grid>
                        {/* END Video */}

                        <Grid item xs={12} md={12} lg={12}>
                            <p style={{fontSize: '11px', fontFamily: 'AdminFontMedium', color: '#778', marginTop: '0px', paddingLeft: '15px'}}>Text peu</p>
                            <EditorText
                                style={{height: '132px'}}
                                content={state.videos[key]?.footer}
                                setContent={(newContent) => {
                                    setState((prevState) => {
                                        const videos = [...prevState.videos];
                                        videos[key] = {
                                            ...videos[key],
                                            footer: newContent
                                        };
                                        return { ...prevState, videos };
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={4}>
                            <div style={{display: 'block'}}>
                                <MsgSuccess msg={state.videos[key].msgSuccess}/>
                                <MsgError msg={state.videos[key].msgError}/>
                            </div>
                            <InputButtonLoading
                                onClick={handleSubmit(key)}
                                loading={state.videos[key].loading}
                                label={state.videos[key].id ? 'Modificar' : 'Crear'}
                                position="left"
                                style={{marginRight: '15px'}}
                            />
                            {state.videos[key].id && (
                                <InputButtonLoading
                                    onClick={() => handleOpenModal(key)}
                                    loading={state.videos[key].loadingDelete}
                                    label="Eliminar"
                                    position="left"
                                    variant="outline"
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div style={{height: 5}}></div>
                        </Grid>
                    </Grid>
                ))}
                <ModalConfirm openModal={openModal} setOpenModal={setOpenModal} handleAction={handleRemove} text="Eliminar el bloc de vídeos?" />
                <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile} text="Eliminar el fitxer?" />
            </div>
            <div ref={myRef}></div>
        </>
    );
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        videos: [],
        model: {
            artworkId: null,
            id: null,
            video: "",
            videoOriginalName: "",
            videoUrl: "",
            thumbnail: "",
            thumbnailOriginalName: "",
            thumbnailUrl: "",
            subtitle: "",
            subtitleOriginalName: "",
            subtitleUrl: "",
            footer: "",
            fileVideo: [],
            fileThumbnail: [],
            fileSubtitle: [],
            loading: false,
            loadingDelete: false,
            loadingDeleteFile: false,
            msgSuccess: [],
            msgError: [],
        }
    }
}

function validationSchema(schema) {

    let error = []

    return error
}

