import React from "react";
import {TextField} from "@mui/material";

/**
 * Muestra un input de texto
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **value** *string*
 * - **onChange** *function*
 * - **required** *bool*
 */
export default function InputText(props) {

    const {label, name, value, onChange, required, style, className, rows, disabled, placeholder, InputProps} = props

    const rowsIn = rows ? rows : 1
    const disabledIn = disabled ? disabled : false
    const requiredIn = required ? required : false
    const placeholderIn = placeholder ? placeholder : ""
    const InputPropsIn = InputProps ? InputProps : null

    const color = getComputedStyle(document.documentElement).getPropertyValue('--primary-color')

    return (
        <TextField
            type="text"
            variant="outlined"
            placeholder={placeholderIn}
            className={`${className} form-control admin-text-field`}
            size="small"
            fullWidth
            disabled={disabledIn}
            rows={rowsIn}
            multiline={rowsIn > 1}
            label={`${label} ${requiredIn ? "*" : ""}`}
            name={name}
            value={value}
            onChange={onChange}
            style={style}
            inputProps={{min: 0}}
            InputLabelProps={{ shrink: true }}
            InputProps={InputPropsIn}
            sx={{
                '& label.Mui-focused': {
                    color: color,
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: color,
                },
                '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                        borderColor: color,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: color,
                    },
                },
            }}
        />
    )
}
