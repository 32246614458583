import * as React from "react";
import {useEffect} from "react";
import {apiMaintenance} from "../../services/Auth/maintenence";
import {useNavigate} from "react-router-dom";
import {RouteAuth} from "../../routes/RouteAuth";

export default function MaintenanceUser(props) {

    const {path} = props

    const navigate = useNavigate()

    useEffect(() => {
        apiMaintenance({}).then(resp => {
            if (resp.data?.isActive) {
                navigate(RouteAuth.MAINTENANCE.path)
            }
        })
    }, [path])

    return (<></>)
}
