import FullText from "../../pages/User/Web/Symfony/FullText"

const baseTitle = "Offing - "
const urlBase = "/api-symfony"

export const RouteSymfony = {

    SYMFONY_FULLTEXT: {
        path: `${urlBase}/full-text`,
        params: "",
        page: FullText,
        title: `${baseTitle}Page`,
        roles: ["ROLE_PUBLIC"]
    },

   
}
