import React, {useEffect, useRef, useState} from "react";
import {Grid} from "@mui/material";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import ButtonFile from "../../../../../components/Admin/Inputs/ButtonFile";
import {apiFields, apiGet, apiFieldsFile, apiDeleteFile} from "../../../../../services/Admin/artist";
import InputSelect from '../../../../../components/Admin/Inputs/InputSelect'
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import {RouteAdmin} from "../../../../../routes/RouteAdmin";
import {useNavigate, useParams} from "react-router-dom";
import {lang} from "../../../../../utils/lang";
import {LoadingButton} from "@mui/lab";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import {MdDeleteForever} from "react-icons/md";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import EditorText from "../../../../../components/EditorText";
import clone from "../../../../../utils/clone";

export default function Fields() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(false)
    const myRef = useRef(null)

    useEffect(() => {
        setLoadingGetInfo(true)
        if (id) {
            getArtist()
        } else {
            setLoadingGetInfo(false)
            setState({...state, loading: false})
        }
    }, [state.langSelect])

    const getArtist = () => {

        apiGet(id, state?.langSelect).then(resp => {
            let items = resp.data
            items["file"] = []
            setState(items)
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
        })
    }


    const handleSubmit = async () => {
        setMsgError([])
        setMsgSuccess([])
        setLoading(true)

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        state.id = id
        setState({...state})

        await apiFields(state).then(resp => {
            handleSubmitFile(resp.data?.id)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
        })
    }

    const handleSubmitFile = (idTemp) => {
        let f = new FormData()
        f.append(`file[]`, state.file[0]?.binary)
        f.append(`originalName`, state.file[0]?.name)

        apiFieldsFile(f, idTemp).then(resp => {
            setLoading(false)
            setMsgSuccess(resp.data?.msg)
            state.photo = resp.data?.data?.photo
            state.photoOriginalName = resp.data?.data?.photoOriginalName
            state.file = []
            setState({...state})
            if (!id) {
                navigate(`${RouteAdmin.ARTISTA_EDIT.path}/${idTemp}`)
            }
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
        })
    }

    const handleChange = (e) => {
        state[e.target.name] = e.target.value
        setState({...state})
    }

    const handleDeleteFile = (fileName) => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingDelete(true)

        apiDeleteFile({
            id: id,
            fileName: fileName
        }).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoadingDelete(false)

            state.photo = resp.data?.data?.photo
            state.photoOriginalName = resp.data?.data?.photoOriginalName
            setState({...state})
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingDelete(false)
        })
    }

    return (
        <>
            <div ref={myRef}></div>

            <HeaderPage
                title="Tornar"
                urlBack={RouteAdmin.ARTISTA_MANAGEMENT.path}
            >
                <div style={{float: 'right'}}>
                    <InputSelect
                        label="Idioma"
                        options={lang}
                        name="langSelect"
                        value={state.langSelect}
                        selectText="Selecciona un idioma"
                        style={{width: 100}}
                        onChange={(e) => {
                            setState({...state, langSelect: e.target.value})
                            localStorage.setItem('langSelect', e.target.value)
                        }}
                    />
                </div>
            </HeaderPage>

            <LoadingSpinner show={loadingGetInfo}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={2} style={{display: loadingGetInfo ? 'none' : ''}}>

                <Grid item xs={3} style={state.file[0]?.base64 || state.photo ? {
                    paddingTop: '16px',
                    height: 'auto'
                } : {paddingTop: '0px', height: '0px'}}>
                    <img
                        src={state.file[0]?.base64 ?? state.photo}
                        alt=""
                        style={{width: '100%', maxWidth: 200}}
                    />
                </Grid>
                <Grid item xs={9} style={state.file[0]?.name || state.photoOriginalName ? {
                    paddingTop: '16px',
                    height: 'auto'
                } : {paddingTop: '0px', height: '0px'}}>
                    <p></p>
                    <span style={{wordWrap: 'break-word', overflowWrap: 'break-word'}}>{state.file[0]?.name ?? state.photoOriginalName}</span>
                </Grid>

                <Grid item xs={3}>
                    <ButtonFile
                        label="+ FOTO ARTISTA"
                        name="file"
                        onChange={handleChange}
                        multiple={true}
                    />
                </Grid>
                <Grid item xs={3}>
                    {state.photo &&
                        <InputButtonLoading
                            onClick={() => handleDeleteFile(state.photo)}
                            loading={loadingDelete}
                            label={(
                                <><MdDeleteForever fontSize="inherit" style={{width: '20px', height: '20px', marginBottom: '-3px'}} /> Eliminar foto</>
                            )}
                            position="left"
                        />
                    }
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <InputText
                        label={"Nom artista"}
                        name="name"
                        disabled={loadingGetInfo}
                        value={state?.name}
                        onChange={handleChange}
                    />
                </Grid>

                {/* <Grid item xs={12} md={12} lg={12}>
                    <InputText
                        label={"Ciutat naixement"}
                        name="cityBirth"
                        disabled={loadingGetInfo}
                        value={state?.cityBirth}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <InputText
                        label="Any naixement"
                        name="dateBirth"
                        disabled={loadingGetInfo}
                        value={state?.dateBirth}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <InputText
                        label={"Ciutat defunció"}
                        name="cityDeath"
                        disabled={loadingGetInfo}
                        value={state?.cityDeath}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <InputText
                        label="Any defunció"
                        name="dateDeath"
                        disabled={loadingGetInfo}
                        value={state?.dateDeath}
                        onChange={handleChange}
                    />
                </Grid> */}

                <Grid item xs={12} md={12} lg={12}>
                    <p style={{
                        fontSize: '11px',
                        fontFamily: 'AdminFontMedium',
                        color: '#778',
                        marginTop: '0px',
                        paddingLeft: '15px'
                    }}>Bio</p>
                    <EditorText
                        style={{height: '132px'}}
                        content={state?.bio}
                        setContent={(newContent) => {
                            setState((prevState) => ({
                                ...prevState,
                                bio: newContent
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputCheckbox
                        label="Activat"
                        className="mb-12"
                        checked={state?.isActive}
                        onChange={(e) => {
                            state.isActive = e.target.checked
                            setState({...state})
                        }}
                        disabled={loadingGetInfo}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputButtonLoading
                        onClick={handleSubmit}
                        loading={loading}
                        label={id ? 'Modificar' : 'Crear'}
                        disabled={loading}
                    />
                </Grid>

            </Grid>

        </>
    );
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        photo: "",
        file: [],
        photoOriginalName: "",
        name: "",
        isActive: true,
        bio: ""
    }
}

function validationSchema(schema) {

    let error = []

    return error
}
