import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {apiGetListSubmenu, apiSavePositionSubmenu} from "../../../../../services/Admin/menu";
import DndElements from "../../../../../components/DndElements";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import TitlePage from "../../../../../components/Admin/TitlePage";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";
import {RouteWebBack} from "../../../../../routes/Web/RouteWebBack";
import {useNavigate} from "react-router-dom";

export default function SubmenuList({id, langSelect, setMsgError}) {

    const navigate = useNavigate()
    const [elementDnd, setElementDnd] = useState([])

    const [loadingSubmenu, setLoadingSubmenu] = useState(true)
    const [loadingPosition, setLoadingPosition] = useState(false)

    useEffect(() => {
        if (id) {
            getInfo()
        }
    }, [id, langSelect])

    useEffect(() => {
        if (elementDnd.length > 0) {
            setLoadingPosition(true)

            const timeOutId = setTimeout(() => {
                updateElementPosition()
            }, 2000)

            return () => clearTimeout(timeOutId)
        }
    }, [elementDnd])

    const updateElementPosition = () => {
        const menuIdArray = elementDnd.map((item) => item.id);

        apiSavePositionSubmenu({
            id: menuIdArray
        }).then(resp => {
            setLoadingPosition(false)
        }).catch(err => {
            setMsgError([err.response.data.msg])
            setLoadingPosition(false)
        })
    }

    const addSubmenu = () => {
        navigate(`${RouteWebBack.WEB_BACK_SUBMENU_CREATE.path}/${id}`)
    }

    const getInfo = () => {
        setLoadingSubmenu(true)
        apiGetListSubmenu({
            id,
            langSelect: langSelect
        }).then(resp => {
            let temp = resp.data?.map(doc => {
                return {
                    id: `${doc.submenuId}`,
                    content: <ViewMenu menuId={id} submenuId={doc.submenuId} name={doc.name} handleNavigate={handleNavigate}/>
                }
            })
            
            setElementDnd(temp)
            setLoadingSubmenu(false)
        }).catch(err => {
            setLoadingSubmenu(false)
            setMsgError(err)
        })
    }

    const handleNavigate = (submenuId, menuId) => {
        navigate(`${RouteWebBack.WEB_BACK_SUBMENU_EDIT.path}/${submenuId}/${menuId}`)
    }

    return (
        <>
            {id &&
                <>
                    <Grid item xs={12}/>

                    <Grid item xs={12} md={12}>
                        <TitlePage title="Submenu" borderTop={true}>
                            <InputButtonLoading
                                onClick={addSubmenu}
                                label="+ Crear submenu"
                                position="center"
                                style={{float: 'right'}}
                                variant="outlined"
                            />
                        </TitlePage>
                    </Grid>

                    <Grid item xs={12}>
                        {!loadingPosition ? (
                            <DndElements
                                elementDnd={elementDnd}
                                setElementDnd={setElementDnd}
                                title="Posició"
                            />
                        ) : (
                            <LoadingSpinner show={true}/>
                        )}
                    </Grid>
                </>
            }
        </>
    )
}
function ViewMenu({submenuId, menuId, name, handleNavigate}) {

    return (
        <Grid container spacing={0} style={{
            padding: 0,
            margin: 0
        }}>
            <Grid item md={12}>
                <p>Nom: {name}</p>
            </Grid>
            <Grid item md={12}>
                <InputButton
                    style={{float: "left", margin: "0px 10px 0px 0px"}}
                    onClick={() => handleNavigate(submenuId, menuId)}
                    variant="outlined"
                    text="Editar"
                />
            </Grid>
        </Grid>
    )
}
