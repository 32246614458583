import React, {useEffect, useState} from "react";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import InputButton from "../../../../components/Admin/Inputs/InputButton";
import {RouteAdminDisplay} from "../../../../routes/RouteAdminDisplay";
import {RouteAdminDisplayEdit} from "../../../../routes/RouteAdminDisplayEdit";
import {Grid} from "@mui/material";

export default function Management() {


    return (
        <>
            <HeaderPage title="Displays"/>
            <Grid container>
                <Grid md={6}>
                    <Grid container>
                        <Grid md={12} >
                            <h4 style={{marginTop: 0}}>URL</h4>
                        </Grid>
                        <Grid md={12}>
                            {/*<InputButton*/}
                            {/*    style={{float: 'none', margin: "0px 20px 10px 0px", minWidth: 200}}*/}
                            {/*    href={RouteAdminDisplay.DISPLAY_ONE.path}*/}
                            {/*    text="OLD VW1: Pantalla 1"*/}
                            {/*/>*/}
                            <InputButton
                                style={{float: 'none', margin: "0px 20px 10px 0px", minWidth: 200}}
                                href={RouteAdminDisplay.DISPLAY_NEW_ONE.path}
                                text="Pantallas LED"
                            />
                            <InputButton
                                style={{float: 'none', margin: "0px 20px 10px 0px", minWidth: 200}}
                                href={RouteAdminDisplay.RATES.path}
                                text="Tarifas"
                            />
                            <InputButton
                                style={{float: 'none', margin: "0px 20px 10px 0px", minWidth: 200}}
                                href={RouteAdminDisplay.QRSCREEN.path}
                                text="Login Qr"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid md={6}>
                    <Grid container>
                        <Grid md={12}>
                            <h4 style={{marginTop: 0}}>EDIT</h4>
                        </Grid>
                        <Grid md={12}>
                            <InputButton
                                style={{float: 'none', margin: "0px 20px 10px 0px", minWidth: 200}}
                                href={`${RouteAdminDisplayEdit.DISPLAY_EDIT.path}/1`}
                                variant="outlined"
                                text="Pantalla LED (1280x360)"
                            />
                            <InputButton
                                style={{float: 'none', margin: "0px 20px 10px 0px", minWidth: 200}}
                                href={`${RouteAdminDisplayEdit.DISPLAY_EDIT.path}/2`}
                                variant="outlined"
                                text="Pantalla LED (2560x360)"
                            />
                            <InputButton
                                style={{float: 'none', margin: "0px 20px 10px 0px", minWidth: 200}}
                                href={RouteAdminDisplayEdit.DISPLAY_RATES_EDIT.path}
                                variant="outlined"
                                text="Tarifas"
                            />

                        </Grid>
                        {/*<Grid md={12}>*/}
                        {/*    <InputButton*/}
                        {/*        style={{float: 'none', margin: "0px 20px 10px 0px", minWidth: 200}}*/}
                        {/*        href={RouteAdminDisplayEdit.DISPLAY_ONE_EDIT_BLOCK1.path}*/}
                        {/*        variant="outlined"*/}
                        {/*        text="OLD VW1: Pantalla 1 (1280x360)"*/}
                        {/*    />*/}
                        {/*    <InputButton*/}
                        {/*        style={{float: 'none', margin: "0px 20px 10px 0px", minWidth: 200}}*/}
                        {/*        href={RouteAdminDisplayEdit.DISPLAY_ONE_EDIT_BLOCK2.path}*/}
                        {/*        variant="outlined"*/}
                        {/*        text="OLD VW1: Pantalla 2 (2560x360)"*/}
                        {/*    />*/}

                        {/*</Grid>*/}
                    </Grid>
                </Grid>
            </Grid>

        </>
    );
}
