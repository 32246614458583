import React, { useRef, useState, useEffect } from "react";
import CloseIcon from '../../../../components/Icons/CloseIcon';
import {Link} from "react-router-dom";
import {RouteUser} from "../../../../routes/RouteUser";
import SavedIcon from '../../../../components/Icons/SavedIcon';
import image from '../../../../assets/images/front/uploads/La-revolución-de-Fabián-Cháirez_1.png';

export default function Bookmark() {

    return (
        <>
            {/* <div className='bookmark' style={{background: 'black',
                width: '100vw',
                height: '100vh',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                position: 'relative',
                zIndex: '3',
                height: '-webkit-fill-available',
                overflowY: 'auto'
            }}>
                <div style={{
                    background: '#FDFDFD',
                    maxWidth: '980px',
                    width: '100%',
                    minHeight: '100vh',
                    minHeight: '-webkit-fill-available',
                    margin: 'auto',
                    position: 'relative'
                }}>
                    <div className="bookmark-navbar">
                        <div>
                            <h1>GUARDADO</h1>
                        </div>
                        <div>
                            <a className="close-button" href={RouteUser.ROOT.path} style={{backgroundColor: '#FDFDFD', borderRadius: '100%', display: 'flex', boxShadow: '0px 2px 6px rgba(0, 33, 68, 0.1)'}}><CloseIcon className="close-icon icon" color="#E30613" /></a>
                        </div>
                    </div>
                    <div className="bookmark-content">
                        <div className="bookmark-element">
                            <div className="bookmark-image">
                                <img src={image} />
                            </div>
                            <div className="bookmark-text">
                                <div>
                                    <h2>Con flores a María</h2>
                                </div>
                                <div>
                                    <p>Artista nombre</p>
                                    <p>2014</p>
                                    <p>Sala 1</p>
                                </div>
                            </div>
                            <div className="bookmark-save">
                                <SavedIcon className="save-icon icon" color="#E30613" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='bookmark-empty' style={{
                position: 'fixed',
                background: 'black',
                width: '100vw',
                height: '-webkit-fill-available',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                zIndex: '3'
            }}>
                <div style={{
                    background: '#F00214',
                    maxWidth: '980px',
                    width: '100vw',
                    minHeight: '100vh',
                    minHeight: '-webkit-fill-available',
                    margin: 'auto',
                    position: 'relative'
                }}>
                    <div className="bookmark-navbar">
                        <div>
                            <h1>GUARDADO</h1>
                        </div>
                        <div>
                            <a className="close-button" href={RouteUser.MENU.path} style={{backgroundColor: '#FDFDFD', borderRadius: '100%', display: 'flex', boxShadow: '0px 2px 6px rgba(0, 33, 68, 0.1)'}}><CloseIcon className="close-icon icon" color="#E30613" /></a>
                        </div>
                    </div>
                    <div className="bookmark-content">
                        <div className="bookmark-text">
                            <h2>¡Parece que no tienes contenido guardado!</h2>
                            <p>Risus, lacinia pulvinar est id dolor odio a. Porttitor vel dignissim pulvinar augue feugiat dui magna at. Amet scelerisque.</p>
                        </div>
                        <div className="bookmark-button">
                            <Link to='/'>
                                <button>Volver al inicio</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
