import React, { useState } from "react";

export default function Map({item}) {

    const [state, setState] = useState(initialValues(item))

    return (
        <div className="blocks-full-width">
            <div className="map-block">
                <p className="title">{state?.text}</p>
                <a href="https://goo.gl/maps/PSacsnoYrKdvkJN68" target="_blank>"><img title={state?.itemOne} alt={state?.itemTwo} src={state?.fileBackgroundImage} /></a>
                {/* <img title={state?.itemOne} alt={state?.itemTwo} src={state?.fileBackgroundImage} /> */}
                <p className="text">{state?.btnRedName}</p>  {/* Peu de foto */}
            </div>
        </div>
    )
}
function initialValues(props) {

    return {
        "fileBackgroundImage": props?.fileBackgroundImage,
        "text": props?.text,
        "btnRedName": props?.btnRedName,
        "itemOne": props?.itemOne,
        "itemTwo": props?.itemTwo,
    }
}
