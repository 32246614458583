import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}/front-menu`;

export function apiList(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.langSelect) { query += `&langSelect=${props.langSelect}`; }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list?1=1${query}`, config)
}

export function apiGetMenu(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/get-menu/${props.id}?langSelect=${props.langSelect}`, config)
}

export function apiGetSubmenu(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/get-submenu/${props.id}?langSelect=${props.langSelect}`, config)
}

export function apiSaveMenu(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save`, props, config)
}

export function apiSaveSubmenu(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save-submenu`, props, config)
}

export function apiRemove(id, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.delete(`${BASE_PATH}/${lang}/${baseUrl}/remove/${id}`, config)
}

export function apiRemoveSubmenu(id, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.delete(`${BASE_PATH}/${lang}/${baseUrl}/remove-submenu/${id}`, config)
}

export function apiGetListSubmenu(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list-submenu/${props.id}/${props.langSelect}`, config)
}

export function apiSavePositionMenu(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/position-menu`, props, config)
}

export function apiSavePositionSubmenu(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/position-submenu`, props, config)
}

