import { BASE_PATH, BASE_PATH_EXTENSION } from "../../utils/constants";
import axios from 'axios';
import {getToken} from "./token";

const baseUrl = `${BASE_PATH_EXTENSION}/auth`;

export function apiLogin(data) {
    return axios.post(`${BASE_PATH}/api/login-check`, data)
}

export function apiRegister(data, lang = 'ca') {
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/register`, data)
}

export function apiSendRecoveryEmail(data, lang = 'ca') {
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/recovery-email`, data)
}

export function apiRecoverPassword(data, token, lang = 'ca') {
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/change-password/${token}/${data.password}`, {})
}

export function apiImpersonate(id, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/impersonate/${id}`, config)
}

export function apiGetLoginQr(lang = 'ca') {
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/get-login-qr`, {})
}

export function apiGetLoginQrCreate(token, lang = 'ca') {
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/login-qr-create/${token}`, {})
}

