import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../../../components/Admin/TitlePage";
import InputButtonLoading from "../../../../../../../components/Admin/Inputs/InputButtonLoading";
import { apiSaveBlockText, apiSaveFileBlockText, apiDeleteFileBlockText } from "../../../../../../../services/Admin/page";
import EditorTextWeb from "../../../../../../../components/EditorTextWeb";
import InputText from "../../../../../../../components/Admin/Inputs/InputText";
import ModalConfirm from "../../../../../../../components/Admin/ModalConfirm";
import { MdDeleteForever } from "react-icons/md";
import ButtonFile from "../../../../../../../components/Admin/Inputs/ButtonFile";
import InputCheckbox from "../../../../../../../components/Admin/Inputs/InputCheckbox";

import InputAdornment from '@mui/material/InputAdornment';
import ExploreIcon from '@mui/icons-material/Explore';
import WifiIcon from '@mui/icons-material/Wifi';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ReactDOMServer from "react-dom/server";
import FullTextToHtml from "./FullTextToHtml";
import {clone} from "lodash";

export default function Ticket(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    const [loadingSave, setLoadingSave] = useState(false)
    const [openModalFile, setOpenModalFile] = React.useState(false)

    const handleChangePhotoFile = (event) => {
        state[event.target.name] = event.target.value
        setState({ ...state })
    }
    const handleOpenModalFile = () => {
        setOpenModalFile(true)
    }

    const handleDeleteFile = () => {
        apiDeleteFileBlockText({
            blockId: state.blockText.blockId,
            langCode: state.langSelect,
        }).then(resp => {
            window.location.reload();
            setMsgSuccess(resp.data?.msg)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
        })
    }
   
    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)

        const htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.text} />);

        const params = clone(state)
        params.blockText.htmlCode = htmlCode

        apiSaveBlockText(params).then(resp => {
            let fileBackgroundImageBinary = state?.fileBackgroundImage?.length > 0 ? state?.fileBackgroundImage[0].binary : null
            handleSubmitFile(state.blockText.blockId, fileBackgroundImageBinary)
            setLoadingSave(false)
            handleRefresh()
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
            handleRefresh()
        })
        
    }

    const handleSubmitFile = (idBlock, fileBackgroundImage) => {
        let f = new FormData()
        f.append(`fileBackgroundImage[]`, fileBackgroundImage)
        f.append(`langCode`, state.langSelect)

        apiSaveFileBlockText(f, idBlock).then(resp => {
            setLoadingSave(false)
            setMsgSuccess(resp.data?.msg)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingSave(false)
        })
    }


    const handleChangeBlockText = (e) => {
        state.blockText[e.target.name] = e.target.value
        setState({ ...state })
    }

    const handleRefresh = () => {
        setReloadPreview(Math.random())
    }

   


    return (
        <>
            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title={state.block.webBlockName} borderTop={false} borderBottom={false} />
            </Grid>

            <Grid item md={6}>
                <div style={{ textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto' }}>
                    <img alt="" src={
                        state?.fileBackgroundImage?.[0]?.base64 ??
                        state?.blockText?.fileBackgroundImage
                    } style={{ width: '100%', objectFit: 'cover' }} />
                </div>
            </Grid>

            <Grid item md={6}/>

            <Grid item md={6}>
                <ButtonFile
                    label="+ Imatge"
                    required
                    name="fileBackgroundImage"
                    typeButton="secondary"
                    multiple={false}
                    onChange={handleChangePhotoFile}
                    accept="image/png, image/jpg, image/jpeg, image/gif"
                />
            </Grid>

            <Grid item md={6}>
                {(state?.fileBackgroundImage || state?.blockText?.fileBackgroundImage) &&
                    <InputButtonLoading
                        onClick={() => handleOpenModalFile()}
                        loading={false}
                        label={(
                            <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar imatge</>
                        )}
                        position="left"
                    />
                }
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"SEO: Títol de la imatge"}
                    name="itemFour"
                    disabled={loadingSave}
                    value={state?.blockText?.itemFour}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"SEO: Atribut alt de la imatge"}
                    name="itemFive"
                    disabled={loadingSave}
                    value={state?.blockText?.itemFive}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={12}/>
            <Grid item md={12}/>

            <Grid item md={12}>
                <InputText
                    label={"Títol"}
                    name="backgroundImageName"
                    disabled={loadingSave}
                    value={state?.blockText?.backgroundImageName}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={12}>
                <p>Text</p>
                <EditorTextWeb
                    style={{height: 250}}
                    options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']}
                    content={state.blockText.text}
                    setContent={(e) => {
                        state.blockText.text = e
                        setState({ ...state })
                    }}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"Guia digital"}
                    name="itemOne"
                    disabled={loadingSave}
                    value={state?.blockText?.itemOne}
                    onChange={handleChangeBlockText}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><ExploreIcon/></InputAdornment>,
                    }}
                    variant="filled"
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"Wifi"}
                    name="itemTwo"
                    disabled={loadingSave}
                    value={state?.blockText?.itemTwo}
                    onChange={handleChangeBlockText}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><WifiIcon/></InputAdornment>,
                    }}
                    variant="filled"
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"Temps. aprox visita"}
                    name="itemThree"
                    disabled={loadingSave}
                    value={state?.blockText?.itemThree}
                    onChange={handleChangeBlockText}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><WatchLaterIcon /></InputAdornment>,
                    }}
                    variant="filled"
                />
            </Grid>

            <Grid item md={12}/>

            <Grid item md={6}>
                <InputCheckbox
                    labelLeft="Botó Vermell: Formulari (Reserva)"
                    label="Comprar (enllaç)"
                    name="titleColor"
                    checked={state.blockText?.titleColor || false}
                    onChange={(e) => {
                        state.blockText.titleColor = e.target.checked
                        setState({ ...state })
                    }}
                    disabled={loadingSave}
                />
            </Grid>

            <Grid item md={12} />

            <Grid item md={4}>
                <InputText
                    label={"Text Botó Vermell"}
                    name="btnRedName"
                    disabled={loadingSave}
                    value={state?.blockText?.btnRedName}
                    onChange={handleChangeBlockText}

                />
            </Grid>

            {state?.blockText?.titleColor && (
            <>
                <Grid item md={4}>
                    <InputText
                        label={"Enllaç Botó Vermell"}
                        name="btnRedLink"
                        disabled={loadingSave}
                        value={state?.blockText?.btnRedLink}
                        onChange={handleChangeBlockText}
                    />
                </Grid>

                <Grid item md={4}>
                <InputText
                    label={"SEO ID Botó Vermell"}
                    name="itemSix"
                    disabled={loadingSave}
                    value={state?.blockText?.itemSix}
                    onChange={handleChangeBlockText}
                />
                </Grid>
            </>
            )}

            <Grid item md={12}>
                <InputButtonLoading
                    onClick={handleSubmit}
                    loading={loadingSave}
                    label="Modificar"
                    position="left"
                    style={{ float: 'right' }}
                />
            </Grid>
            
            <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile} text="Eliminar el fitxer?" />
        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        fileBackgroundImage: [],

    }
}

