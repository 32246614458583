export const BASE_PATH = process.env.REACT_APP_BASE_PATH
export const BASE_PATH_EXTENSION = process.env.REACT_APP_BASE_PATH_EXTENSION
export const BASE_PATH_EXTENSION_ADMIN = process.env.REACT_APP_BASE_PATH_EXTENSION_ADMIN
export const TOKEN = process.env.REACT_APP_TOKEN
export const DASH_MAX_ROW = 100
export const URL_WEB = process.env.REACT_APP_URL_WEB
export const URL_WEB_CORPORATIVA = process.env.REACT_APP_URL_CORPORATIVA

// Analytics
export const GOOGLE__ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID

// TEXT_FONTS

export const TEXT_FONTS=['SohneLeicht','SohneBuch','SohneKraftig','SohneDreiviertelfett',
                            'SohneBreitLeicht','SohneBreitBuch','SohneBreitKraftig','SohneBreitDreiviertelfett',
                            'QueensLightIt','QueensCondensedMediumItalic','QueensCondensedBoldItalic']

// TEXT_SIZES

export const TEXT_SIZES=[8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 24, 28, 30, 31, 36, 37, 38, 45, 48, 50, 52, 60, 72, 86, 96]

// COLOR_PICKER

// export const COLOR_PICKER=[]
export const COLOR_PICKER = ['rgb(255,255,255)','rgb(245,245,245)','rgb(236,236,236)','rgb(195,195,195)','rgb(0,0,0)','#ea0c1e'];
