import React, {useState} from "react";

export default function Video({item}) {

    const [state, setState] = useState(initialValues(item))

    return (
        <div className="blocks-full-width">
            <div className="video-block">
                <video controls>
                    <source src={state?.fileBackgroundImage}></source>
                </video>
            </div>
        </div>
    )
}

function initialValues(props) {

    return {
        "fileBackgroundImage": props?.fileBackgroundImage,
    }
}
