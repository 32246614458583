import React from 'react';

export default function InputCheckboxDos(props) {

    const {disabled, label, checked, value, onChange, required, name} = props

    const disabledIn = disabled ? disabled : false
    const requiredIn = required ? required : false

    return (
        <div>
            <div className="inputCheckbox">
                <label className="container" style={{color: disabledIn ? '#c7c7c7' : 'black'}}>
                    {label}
                    <input
                        type="checkbox"
                        name={name}
                        checked={checked}
                        value={value}
                        disabled={disabledIn}
                        onClick={onChange}
                    />
                    <span className="checkmark" style={{background: requiredIn ? '#e7bcc0' : ''}}></span>
                </label>
            </div>
            <style>
                {`
                    /* The container */
                    .inputCheckbox .container {
                        display: block;
                        position: relative;
                        padding-left: 35px;
                        margin-bottom: 12px;
                        cursor: pointer;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        margin-top: 5px
                    }
                
                    /* Hide the browser's default checkbox */
                    .inputCheckbox .container input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }
                
                    /* Create a custom checkbox */
                    .inputCheckbox .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 18px;
                        width: 18px;
                        background-color: #fff;
                        border: 1px solid #cdcdcd;
                        border-radius: 6px;
                        margin-top: 3px;
                    }
                
                    /* When the checkbox is checked, add a blue background */
                    .inputCheckbox .container input:checked ~ .checkmark {
                        background-color: #ea0c1f;
                        border-color: #ea0c1f;
                    }
                
                    /* Create the checkmark/indicator (hidden when not checked) */
                    .inputCheckbox .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                
                    /* Show the checkmark when checked */
                    .inputCheckbox .container input:checked ~ .checkmark:after {
                        display: block;
                    }
                
                    /* Style the checkmark/indicator */
                    .inputCheckbox .container .checkmark:after {
                        left: 6px;
                        top: 2px;
                        width: 3px;
                        height: 8px;
                        border: solid white;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }

                `}
            </style>
        </div>
    )
}
