import React from "react";
import MenuIcon from '../../../../Icons/MenuIcon';
import {RouteUser} from "../../../../../routes/RouteUser";
import {useNavigate} from "react-router-dom";

export default function TopBar() {
    const navigate = useNavigate()

    return (
        <>
            <div className="top-bar">
                <div className="button-container">
                    <button
                        className="menu-button"
                        type="button"
                        onClick={() => navigate(RouteUser.MENU.path)}
                        style={{
                            backgroundColor: '#F00214',
                            borderRadius: '100%',
                            display: 'flex',
                            boxShadow: '0px 2px 6px rgba(0, 33, 68, 0.1)'
                    }}>
                        <MenuIcon className="menu-icon icon" color="#ffffff" />
                    </button>
                </div>
            </div>
        </>
    )
}
