import { Grid } from "@mui/material";
import TitlePage from "../../../../../../../components/Admin/TitlePage";



export default function ContacForm(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    
    return (
        <>
            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title={state.block.webBlockName} borderTop={false} borderBottom={false} />
            </Grid>

            <Grid item md={12}>
               Cap configuració adicional.
            </Grid>
        </>
    )
}
