import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}/page`;

export function apiList(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.title) { query += `&title=${props.title}`; }
   
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiSave(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/save`, props, config)
}

export function apiGetPage(id, langSelect, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/${id}/${langSelect}`, config)
}


export function apiGetListBlock(pageId, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list-block/${pageId}`, config)    
}

export function apiAddBlock(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/add-block`, props, config)
}

export function apiUpdatePositionBlock(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/position-block`, props, config)
}

export function apiDeleteBlock(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.delete(`${BASE_PATH}/${lang}/${baseUrl}/delete-block/${props.id}`, config)
}

export function apiGetListWebBlocks(lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/block/get-blocks`, config)
}

export function apiGetPageBlock(idPage, idBlock, langSelect, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/block/get-block/${idPage}/${idBlock}/${langSelect}`, config)
}

export function apiSaveBlockText(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/block/save-block`, props, config)
}

export function apiSaveFileBlockText(props, idBlock, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/block/save-block-file/${idBlock}`, props, config)
}

export function apiDeleteFileBlockText(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/block/delete-block-file`, props, config)
}



export function apiGetListBlockFooter(lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list-footer-block`, config)  
}

export function apiAddBlockFooter(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/add-block-footer`, props, config)
}


export function apiGetBlockFooter(langSelect, lang = "ca", ) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/block/get-footer-block/${langSelect}`, config)
}