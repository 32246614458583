import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

/**
 * Muestra un reproductor de audio
 *
 * ## PROPS
 * - **url** *string*
 */
export default function MenuIcon(props) {

    const {color, width, height, className, style} = props;

    return (
        <div className={className} style={{width: width, height: height, ...style}}>
            <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.5 8.5C0.5 8.22386 0.723858 8 1 8H11C11.2761 8 11.5 8.22386 11.5 8.5C11.5 8.77614 11.2761 9 11 9H1C0.723858 9 0.5 8.77614 0.5 8.5Z" fill={color}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0.5 4.5C0.5 4.22386 0.723858 4 1 4H11C11.2761 4 11.5 4.22386 11.5 4.5C11.5 4.77614 11.2761 5 11 5H1C0.723858 5 0.5 4.77614 0.5 4.5Z" fill={color}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0.5 0.5C0.5 0.223858 0.723858 0 1 0H11C11.2761 0 11.5 0.223858 11.5 0.5C11.5 0.776142 11.2761 1 11 1H1C0.723858 1 0.5 0.776142 0.5 0.5Z" fill={color}/>
            </svg>
        </div>
    )
}
