import React from 'react';

export default function InputCheckbox(props) {
    const {
        label,
        checked,
        name,
        required,
        disabled,
        onChange,
        style,
        labelLeft,
    } = props

    const disabledIn = disabled ? disabled : false
    const labelLeftIn = labelLeft ? labelLeft : null

    const handleSwitchToggle = () => {
        if (!disabledIn) {
            onChange({target: {name: name, checked: !checked}})
        }
    }

    return (
        <div style={{...style}}>
            <div style={{display: 'flex', marginTop: 6}}>
                {labelLeftIn && <label style={{marginRight: 10, color: '#5e5e5e'}}>{labelLeftIn}{required && ("*")}</label>}
                <div
                    className={`switch ${checked === true ? 'checked' : ''} ${disabledIn ? 'disabled' : ''}`}
                    style={{minWidth: 40}}
                    onClick={handleSwitchToggle}
                >
                    <div className="toggle" />
                </div>
                {label && <label style={{marginLeft: 10, color: '#5e5e5e'}}>{label}{required && ("*")}</label>}
            </div>
            <style>
                {`
                    .switch {
                      display: inline-block;
                      position: relative;
                      width: 40px;
                      height: 20px;
                      background-color: #d3d3d3;
                      border-radius: 12px;
                      cursor: pointer;
                    }
                    
                    .switch.checked {
                      background-color: #E30613;
                    }
                    
                    .switch.disabled {
                      opacity: 0.5;
                      cursor: not-allowed;
                      background-color: #e8e8e8;
                    }
                    
                    .switch.disabled.checked {
                      background-color: #b8b8b8;
                    }
                    
                    .toggle {
                      position: absolute;
                      top: 2px;
                      left: 2px;
                      width: 16px;
                      height: 16px;
                      background-color: #fff;
                      border-radius: 50%;
                      transition: transform 0.2s;
                    }
                    
                    .switch.checked .toggle {
                      transform: translateX(20px);
                    }
                    
                    .switch.disabled .toggle {
                      background-color: #cccccc;
                    }

                `}
            </style>
        </div>
    )
}
