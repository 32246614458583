import React, {useEffect, useState} from "react";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";
import ModalConfirm from "../../../../../components/Admin/ModalConfirm";
import {apiSaveLog, apiRemoveLog} from "../../../../../services/Admin/log";

export default function Options({saveLog, setSaveLog, loading, getItemsData}) {
    const [openModal, setOpenModal] = useState(false)

    const handleRemove = () => {
        apiRemoveLog().then(resp => {
            getItemsData()
        }).catch(err => {
            console.log(err)
        })
    }

    const handleSaveLog = () => {
        apiSaveLog().then(resp => {
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <div>
            <div style={{float: 'right', marginLeft: 20}}>
                <InputButton
                    onClick={() => setOpenModal(true)}
                    text="Eliminar"
                    variant="outlined"
                />
            </div>

            <div style={{float: 'right'}}>
                <InputCheckbox
                    label="Registrar log"
                    disabled={loading}
                    checked={saveLog}
                    onChange={(e) => {
                        setSaveLog(e.target.checked)
                        handleSaveLog()
                    }}
                />
            </div>

            <ModalConfirm
                openModal={openModal}
                setOpenModal={setOpenModal}
                handleAction={handleRemove}
                text="Eliminar todos los logs"
            />

        </div>
    );
}
