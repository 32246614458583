import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

/**
 * Muestra un reproductor de audio
 *
 * ## PROPS
 * - **url** *string*
 */
export default function CloseIcon() {

    // const {className} = props;

    return (
        <div className='icon'>
            <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 106 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M63.4035 38.2107C74.352 39.0249 83.9563 41.1044 90.9597 43.7929C91.073 39.5784 91.0452 29.8587 88.4742 24.2893C85.1339 17.0701 80.4878 8.88698 65.3226 5.98047C65.3226 5.98047 65.8505 7.80772 64.5767 9.60078C63.0487 11.7422 60.435 16.1639 60.6316 18.7306C60.6316 18.7306 63.506 20.263 65.2158 23.0776C66.9191 25.8901 64.4229 29.6878 64.3994 31.3975C64.3673 33.1072 64.7627 35.2507 65.609 36.1248C65.609 36.1248 64.1899 37.0652 63.4035 38.2107Z" fill="#E30613"/>
                <path d="M45.35 18.7306C45.5467 16.1618 42.9351 11.7422 41.4049 9.60078C40.1312 7.80772 40.6569 5.98047 40.659 5.98047C25.4939 8.88698 20.8499 17.0701 17.5074 24.2893C14.9385 29.863 14.9086 39.587 15.0219 43.7993C22.0232 41.1086 31.6296 39.0292 42.5825 38.2107C41.7938 37.0652 40.3748 36.1248 40.3748 36.1248C41.2211 35.2529 41.6165 33.1072 41.5844 31.3975C41.5609 29.6878 39.0647 25.8901 40.768 23.0776C42.4777 20.263 45.3522 18.7285 45.3522 18.7306H45.35Z" fill="#E30613"/>
                <path d="M47.4798 17.0139C50.3158 15.7466 52.4016 13.836 52.9936 13.2611C53.5835 13.836 55.6715 15.7466 58.5075 17.0139C58.5075 17.0139 59.8752 12.9918 61.57 10.4572C63.2605 7.92464 63.9358 5.96702 61.4631 2.78268C59.2319 -0.0896319 54.0002 -0.0362035 52.9936 0.0129507C51.987 -0.0362035 46.7553 -0.0896319 44.5241 2.78268C42.0515 5.96488 42.7268 7.92251 44.4173 10.4572C46.112 12.9918 47.4819 17.0139 47.4798 17.0139Z" fill="#E30613"/>
                <path d="M51.7777 20.9434C51.7841 24.7304 50.2689 26.4166 48.3412 27.904C46.4092 29.3936 46.0266 32.5544 47.9629 34.4287C49.897 36.3073 51.5917 36.0935 51.5939 36.0935C48.5869 31.6846 52.8591 28.9363 52.8698 28.9384C52.656 30.8319 53.7289 31.8834 54.7953 32.9391C55.8596 33.9949 54.9984 35.8862 55.0005 35.8862C59.465 34.8561 60.4032 30.039 58.0566 26.4358C55.71 22.8347 51.7777 20.9434 51.7777 20.9455V20.9434Z" fill="#E30613"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.9995 70C81.1008 69.9316 104.712 66.837 105.885 58.9018C107.082 51.082 83.4773 41.1058 52.9995 41.1486C22.5197 41.1058 -1.08291 51.082 0.113885 58.9018C1.28717 66.837 24.8983 69.9316 52.9995 70ZM52.9995 64.563C72.6398 64.5181 88.9312 63.7444 89.4933 59.9126C90.0724 56.1597 73.7832 50.6395 52.9995 50.6716C32.2158 50.6395 15.9265 56.1597 16.5057 59.9126C17.0678 63.7466 33.3592 64.5181 52.9995 64.563Z" fill="#E30613"/>
            </svg>
        </div>
    )
}
