import React, {useRef, useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import MobileMessage from "../../../../components/User/MobileMessage";
import {getArtworkByName} from "../../../../services/User/story";
import {RouteUser} from "../../../../routes/RouteUser";
import {useNavigate} from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {styled, alpha} from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '../../../../components/Icons/SearchIcon';
import CloseIcon from '../../../../components/Icons/CloseIcon';
import KeyboardArrowDownIcon2 from '../../../../components/Icons/KeyboardArrowDownIcon2';
import LoadingSpinnerBig from "../../../../components/LoadingSpinnerBig";
import MsgError from "../../../../components/MsgError";
import SaveIcon from '../../../../components/Icons/SaveIcon';
import TopBar from "../../../../components/Layouts/User/Header/TopBar";
import ReactGA from "react-ga4";

const SearchBlock = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export default function Search() {
    const navigate = useNavigate()

    const {t, i18n} = useTranslation()

    const [order, setOrder] = React.useState("artworkTitle");
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState(initialValues)
    const [timer, setTimer] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        ReactGA.event({
            category: "Search",
            action: `Search`,
        });
    }, [])

    useEffect(() => {
        if (searchTerm !== '' && searchTerm !== ' ') {
            if (timer) {
                clearTimeout(timer);
            }
            setTimer(
                setTimeout(() => {
                    setLoading(true)
                    getArtwork(searchTerm);
                }, 1000)
            );
        } else {
            setState({
                ...state,
                artworks: []
            })
        }
    }, [searchTerm, order])

    const getArtwork = async (searchTerm) => {
        setLoading(true)

        setMsgError([])
        let params = {
            name: searchTerm,
            order: order
        }
        getArtworkByName(params, i18n.resolvedLanguage).then(resp => {
            setState({
                ...state,
                artworks: resp.data?.data
            })

            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
        })

    }

    const handleChange = (event) => {
        const {value} = event.target
        setSearchTerm(value)
    }

    useEffect(() => {
        getArtwork(searchTerm)
    }, [order])

    const handleChangeOrder = (event) => {
        setOrder(event.target.value)
    }


    const handleClickArtwork = (storyId, name) => {
        ReactGA.event({
            category: "Search",
            action: `Search ${name}`,
        });

        navigate(`${RouteUser.STORIES.path}/${storyId}`)
    }

    const searchContentRef = useRef(null)

    useEffect(() => {
        handleResizeFilter()
        window.addEventListener("resize", handleResizeFilter);

        const targetElement = document.querySelector('.MuiSelect-select');

        // Crear una instancia de MutationObserver con una función de callback
        const observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach((mutation) => {
            // Verificar si el atributo que nos interesa ha cambiado
            if (mutation.attributeName === 'aria-expanded') {
                const newValue = mutation.target.getAttribute('aria-expanded');
                handleResizeFilter()
            }
            });
        });
    
        // Configurar el observador para que observe cambios en atributos
        observer.observe(targetElement, { attributes: true });

        return () => {
            window.removeEventListener("resize", handleResizeFilter);
            observer.disconnect();
        }
        
    }, []);

    const handleResizeFilter = () => {
        var elementoFiltro = document.querySelector('.MuiPaper-root');
        var orderByWidth = document.querySelector('.MuiFormControl-root').offsetWidth;
        const screenWith = window.innerWidth;
        const searchContent = searchContentRef.current;
        if(elementoFiltro){
            const estiloCalculado = window.getComputedStyle(searchContent);
            const paddingDerecha = parseInt(estiloCalculado.paddingRight, 10);
            elementoFiltro.style.left = `${screenWith - (orderByWidth + paddingDerecha)}px`;
            elementoFiltro.style.minWidth = `${orderByWidth}px`;
        }

    };

    return (
        <>
            <MobileMessage />
            <div className='search' style={{
                background: 'black',
                width: '100vw',
                height: '-webkit-fill-available',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                position: 'relative',
                zIndex: '3'
            }}>
                <div style={{
                    background: '#FDFDFD',
                    maxWidth: '980px',
                    width: '100vw',
                    minHeight: '100vh',
                    minHeight: '-webkit-fill-available',
                    overflowY: 'auto',
                    margin: 'auto',
                    position: 'relative',
                    height: '100vh'
                }}>
                    <div className="search-navbar">
                        <div>
                            <h1>{t('search.title')}</h1>
                        </div>
                        <div>
                            <TopBar />
                        </div>
                    </div>
                    <div className="search-content" ref={searchContentRef}>
                        <div className="input-container">
                            <SearchBlock className="search-block">
                                <SearchIconWrapper className="search-icon-wrapper">
                                    <SearchIcon className="search-icon" color="#F00214"/>
                                </SearchIconWrapper>
                                <StyledInputBase
                                    className="styled-input-base"
                                    placeholder={t('search.write_your_text')}
                                    inputProps={{'aria-label': 'search'}}
                                    name="name"
                                    value={searchTerm}
                                    onChange={handleChange}
                                />
                            </SearchBlock>
                            <div className="order-block">
                                <div>
                                    <span>{t('search.sort_by')}</span>
                                </div>
                                <div>
                                    <FormControl sx={{m: 1, minWidth: 120}}>
                                        <Select
                                            value={order}
                                            onChange={handleChangeOrder}
                                            displayEmpty
                                            inputProps={{'aria-label': 'Without label'}}
                                            IconComponent={() => (
                                                <KeyboardArrowDownIcon2
                                                    className="keyboard-arrow-down-icon-2"
                                                    color='#FDFDFD'
                                                />
                                            )}>
                                                <MenuItem value="artworkTitle" className="search-li">{t('search.artwork_title')}</MenuItem>
                                                <MenuItem value="dateArtwork" className="search-li">{t('search.incident')}</MenuItem>
                                                <MenuItem value="roomName" className="search-li">{t('search.room')}</MenuItem>
                                                <MenuItem value="artistName" className="search-li">{t('search.artist')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className="results-container">
                            {state.artworks?.length > 0 && (
                                <div className="results-text">
                                    <span>{t('search.results')}:</span>
                                </div>
                            )}

                            <LoadingSpinnerBig show={loading}/>
                            <MsgError msg={msgError}/>

                            {!loading && (
                                <div className="results-list">
                                    {state.artworks.length > 0 && (
                                        <>
                                        {state.artworks?.map((element, key) => (
                                            <div key={key} className="results-element" onClick={() => handleClickArtwork(element.id, `${element.artworkTitle}`)}>
                                                <div className="results-image">
                                                    <img src={element.featuredImage} alt={""}/>
                                                </div>
                                                <div className="results-text">
                                                    <div className="artwork-title" dangerouslySetInnerHTML={{__html: element.artworkTitle}} />
                                                    <div>
                                                        <p>{element?.artistName}</p>
                                                        <p>{element?.dateArtwork}</p>
                                                        {/* <p>{element?.roomName && (
                                                            <>
                                                                Sala {element?.roomName}
                                                            </>
                                                        )}</p> */}
                                                        <p>{element?.roomName == 'Entrada' ? t('map.entrance') : element?.roomName == 'Terraza' ? t('map.terrace') : `${t('map.room')} ${element?.roomName}`}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function initialValues() {
    return {
        artworks: []
    }
}
