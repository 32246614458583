import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

/**
 * Muestra un reproductor de audio
 *
 * ## PROPS
 * - **url** *string*
 */
export default function KeyboardArrowDownIcon(props) {

    const {color, width, height, className, style} = props;

    return (
        <div className={className} style={{width: width, height: height, ...style}}>
            <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 26 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.105548 0.552761C0.352537 0.0587828 0.95321 -0.141442 1.44719 0.105548L13 5.88194L24.5528 0.105548C25.0467 -0.141442 25.6474 0.0587828 25.8944 0.552761C26.1414 1.04674 25.9412 1.64741 25.4472 1.8944L13.4472 7.8944C13.1657 8.03517 12.8343 8.03517 12.5528 7.8944L0.552761 1.8944C0.0587828 1.64741 -0.141442 1.04674 0.105548 0.552761Z" fill={color}/>
            </svg>
        </div>
    )
}
