import React, {useRef, useState, useEffect} from "react";
import MobileMessage from "../../../../components/User/MobileMessage";
import Instructions from "./Instructions";
import {getStoryContent} from "../../../../services/User/story";
import {RouteUser} from "../../../../routes/RouteUser";
import SlideFirstCover from '../../../../components/SlideFirstCover'
import ButtonScrollTop from '../../../../components/ButtonScrollTop'
import Section from "./Section";
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../../scss/User/swiper-stories.scss";
// import required modules
import SwiperCore, {Navigation, Pagination, FreeMode, Scrollbar, Mousewheel} from "swiper";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import LoadingSpinnerBig from "../../../../components/LoadingSpinnerBig";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import SlideCover from "../../../../components/SlideCover";
import CloseIcon from "../../../../assets/images/front/Icons/CloseIcon.svg"
import InputButtonClose from "../../../../components/Layouts/User/InputButtonClose";
import ReactGA from "react-ga4";
SwiperCore.use([Mousewheel])


export default function Stories(props) {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const {storyId, slideId, lang} = useParams()
    const [state, setState] = useState(initialValues())
    const elRef = useRef(null)
    const swiperRef = useRef(null)
    const [swiper, setSwiper] = useState(null)
    const observerRef = useRef(null);
    const containerRef = useRef(null);
    const activeBulletRef = useRef(null);
    const [articleLoaded, setArticleLoaded] = useState(false)
    const [slideNumber, setSlideNumber] = useState(0)
    const [colorTextArtistDinamic, setColorTextArtistDinamic] = useState("#E30613")
    const [initialSlide, setInitialSlide] = useState(false)
    const [segundoBloqueEmpty, setSegundoBloqueEmpty] = useState(false)

    const [loading, setLoading] = useState(false)
    const [loadingSections, setLoadingSections] = useState(false)

    const [slide, setSlide] = useState([])
    const [sections, setSections] = useState(null)

    const {i18n} = useTranslation()

    useEffect(() => {
        // Send a custom event
        ReactGA.event({
            category: "your category",
            action: "your action",
        });
    }, [])

    useEffect(() => {
        if (window.location !== window.parent.location) {
            document.querySelector('.stories').classList.add('stories-iframe-style')
        }
    }, [])

    useEffect(() => {
        getStory()
    }, [slideNumber])


    const getStory = () => {
        setLoading(true)
        setLoadingSections(true)
        setSections([])
        getStoryContent({
            storyId,
            slideId,
            slideNumber,
        }, lang, i18n.resolvedLanguage).then(resp => {
            if (!initialSlide) { // Solo en la primera carga del story
                setSlide(resp.data?.slides.map((slide) => {
                    return slide
                }))
                setState({...state, story: resp.data.story });

                // Send a custom event
                ReactGA.event({
                    category: "Stories",
                    action: resp.data.story?.artworkTitle,
                })
            }

            setInitialSlide(true)
            setLoadingSections(false)
            if (slideNumber === resp.data?.slideNumber) {
                setSections(resp.data?.slides[slideNumber].sections)
                setLoading(false)
                if(resp.data?.slides[slideNumber].sections.length > 0){
                    setSegundoBloqueEmpty(false)
                }else{
                    setSegundoBloqueEmpty(true)
                }
            }
        }).catch(err => {
            setInitialSlide(true)
            setLoading(false)
            setLoadingSections(false)
        })

    }

    const styles = (theme) => {

        return {
            root: {
                // ...
            },
            slide: {
                // ...
            },
            pagination: {
                position: 'static',
                marginTop: theme.spacing.unit * 3,
                '@media (min-width: 701px)': {
                    display: "none",
                },
            },
            paginationBullet: {
                background: theme.palette.primary.main,
            },
            paginationBulletActive: {
                background: "purple",
            },
            // ...
        }
    }

    const swiperOptions = {
        slidesPerView: 3,
        autoHeight: true,
        allowTouchMove: false,
        spaceBetween: 50,
        breakpoints: {
            1: { // including the number, so basically its as `<=`
                slidesPerView: 1,
                allowTouchMove: true,
                spaceBetween: 0,
                pagination: {
                    bulletClass: styles.paginationBullet + " swiper-pagination-bullet",
                    bulletActiveClass: styles.paginationBulletActive + " swiper-pagination-bullet-active",
                }
            },
        }
    }

    const handlePrevClick = () => {
        containerRef.current.swiper.slidePrev(500)
    }

    const handleNextClick = () => {
        containerRef.current.swiper.slideNext(500)
    }

    const handleClick = (event) => {
        const {clientX, clientY} = event;
        const {innerWidth} = window;

        // Definir límites para las áreas de clic
        const leftLimit = innerWidth / 3;
        const rightLimit = (innerWidth / 3) * 2

        // Determinar si el clic ocurrió en la zona izquierda o derecha
        if (clientX < leftLimit) {
            handlePrevClick()
        } else if (clientX > rightLimit) {
            handleNextClick()
        }
    };

    const handleButtonClickStop = (event) => {
        event.stopPropagation(); // Evita que el evento se propague al div padre
        // Código para manejar el clic en el botón derecho
    }

    const handleSlideChange = (swiper) => {
        setSlideNumber(swiper.activeIndex)
    }

    useEffect(() => {
        if (slide[slideNumber]?.colorTextArtist) {
            setColorTextArtistDinamic(slide[slideNumber].colorTextArtist)
        } else {
            setColorTextArtistDinamic("#E30613")
        }
    }, [slideNumber, slide])

    const handleClickToScroll = (index) => {
        const element = document.getElementById(`box-section-${index}`);

        if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
        }
    };

    return (
        <>
            <MobileMessage />
            <Instructions />
            <div 
                 className="stories"
                 style={{
                     position: 'fixed',
                     background: 'black',
                     width: '100vw',
                     height: '-webkit-fill-available',
                     top: '0',
                     bottom: '0',
                     left: '0',
                     right: '0',
                     zIndex: '3',
                     overflowY: 'auto'
                 }}>
                <div style={{
                    background: 'white',
                    maxWidth: '980px',
                    width: '100vw',
                    minHeight: '100vh',
                    minHeight: '-webkit-fill-available',
                    margin: 'auto'
                }}>

                    <LoadingSpinnerBig
                        show={loading && !initialSlide}
                        style={{
                            position: 'absolute',
                            left: '0',
                            right: '0',
                            top: '0',
                            bottom: '0',
                            margin: 'auto'
                        }}
                    />

                    <Swiper
                        swiperoptions={swiperOptions}
                        spaceBetween={0}
                        slidesPerView={1}
                        pagination={{clickable: true}}
                        navigation={{
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev"
                        }}
                        modules={[FreeMode, Navigation, Pagination]}
                        className="mySwiper hide-scroll"
                        autoHeight={true}
                        style={{overflowY: 'hidden'}}
                        ref={containerRef}
                        onSlideChange={handleSlideChange}
                    >
                        <div ref={elRef} style={{
                            position: 'absolute',
                            top: '50px',
                            right: '24px',
                            width: 'calc(100% - 48px)',
                            left: '24px',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            zIndex: '1'
                        }} className="name-closeicon-container">
                            <div>
                                <h2 style={{
                                    fontFamily: 'SohneBreitHalbfettKursiv',
                                    color: colorTextArtistDinamic,
                                    margin: '0px',
                                    marginRight: '5px'
                                }}>{storyId == 66 ? t('map.temporary_installation') : state.story.artistName}</h2>
                            </div>
                            <div>
                               <InputButtonClose />
                            </div>
                        </div>

                        {slide.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div id={`swiper-slide-${index}`} style={{height: 'auto', overflowY: 'hidden'}}
                                     className="hide-scroll">

                                    <TemplateView
                                        templateView={item.template}
                                        item={item}
                                        state={state}
                                        elRef={elRef}
                                        handleClick={handleClick}
                                        segundoBloqueEmpty={segundoBloqueEmpty}
                                        handleClickToScroll={handleClickToScroll}
                                        index={index}
                                        handleButtonClickStop={handleButtonClickStop}
                                        colorTextArtistDinamic={colorTextArtistDinamic}
                                    />
                                    {!segundoBloqueEmpty && 
                                        <div id={`box-section-${index}`} className="segundo-bloque"
                                            style={{padding: item.templateView === 1 ? '0px 25px 40px' : '29px 25px 40px'}}>

                                            {loadingSections ? (
                                                <>
                                                    <LoadingSpinner show={true} />
                                                </>
                                            ) : (
                                                <>
                                                    {sections.map((i, key) => (
                                                        <React.Fragment key={key}>
                                                            <Section
                                                                sections={sections}
                                                                item={item}
                                                                index={key}
                                                                sectionItem={i}
                                                                setArticleLoaded={setArticleLoaded}
                                                                storyId={storyId}
                                                            />
                                                        </React.Fragment>
                                                    ))}
                                                    <ButtonScrollTop scrollId={`swiper-slide-${index}`} />
                                                </>
                                            )}
                                            
                                        </div>
                                    }
                                </div>
                            </SwiperSlide>
                        ))}

                    </Swiper>


                    <style>{`
                      .swiper-pagination-bullet {
                        width: calc(100% / ${state?.slides.length} - 1 * 1em - 2 * 1px);
                      }
                    `}</style>

                </div>
            </div>
        </>
    )
}

function initialValues() {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        story: {},
        slides: []
    }
}

function TemplateView(props) {
    const {templateView, item, state, elRef, handleClick, segundoBloqueEmpty, handleClickToScroll, index, handleButtonClickStop, colorTextArtistDinamic} = props

    if (templateView === 1) {
        return (
            <SlideFirstCover
                featuredImage={item.featuredImage}
                audio={item.audio}
                audioIsActive={item.audioIsActive}
                colorText={item.colorText}
                artistName={state.story.artistName}
                artworkTitle={state.story.artworkTitle}
                artworkDate={state.story.artworkDate}
                handleClick={handleClick}
                segundoBloqueEmpty={segundoBloqueEmpty}
                handleClickToScroll={handleClickToScroll}
                index={index}
                handleButtonClickStop={handleButtonClickStop}
                colorTextArtistDinamic={colorTextArtistDinamic}
            />
        )
    } else {
        return (
            <SlideCover
                featuredImage={item.featuredImage}
                backgroundImage={item.backgroundImage}
                colorText={item.colorText}
                textScroll={item.textScroll}
                colorTextScroll={item.colorTextScroll}
                artistName={state.story.artistName}
                elRef={elRef}
                handleClick={handleClick}
                segundoBloqueEmpty={segundoBloqueEmpty}
                handleClickToScroll={handleClickToScroll}
                index={index}
                handleButtonClickStop={handleButtonClickStop}
                colorTextArtistDinamic={colorTextArtistDinamic}
            />
        )
    }
}
