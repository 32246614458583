import React, { useState } from "react";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../components/Admin/TitlePage";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import { apiSaveBlockScreenText } from "../../../../../services/Admin/screen";
import { style } from "@mui/system";


export default function Text(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    const [loadingSave, setLoadingSave] = useState(false)
    

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)
        apiSaveBlockScreenText(state).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoadingSave(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
        })

    }

    const handleChange = (e) => {
        state.blockScreenText[e.target.name] = e.target.value
        setState({ ...state })
    }

 

    return (
        <>
            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title={state.blockScreen.displayBlockName} borderTop={false} borderBottom={false} />
            </Grid>

            <Grid item xs={2}>
                    <InputCheckbox
                        label="Text Centrat Activat"
                        className="mb-12"
                        checked={state?.blockScreenText.isActiveTexto}
                        onChange={(e) => {
                            state.blockScreenText.isActiveTexto = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingSave}
                    />
                </Grid>

                <Grid item md={2}>
                    <InputCheckbox
                        label="QR Activat"
                        className="mb-12"
                        checked={state?.blockScreenText.qrTexto}
                        onChange={(e) => {
                            state.blockScreenText.qrTexto = e.target.checked
                            setState({ ...state })
                        }}
                        disabled={loadingSave}
                    />
                </Grid>

                <Grid item md={8} />

                <Grid item md={12} style={{marginTop: '10px'}}>
                    <InputText
                        label={"Text Centrat: línia 1"}
                        name="textoLineOne"
                        disabled={loadingSave}
                        value={state?.blockScreenText.textoLineOne}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item md={12}>
                    <InputText
                        label={"Text Centrat: línia 2"}
                        name="textoLineTwo"
                        disabled={loadingSave}
                        value={state?.blockScreenText.textoLineTwo}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item md={12}>
                    <InputText
                        label={"Text Centrat: línia 3"}
                        name="textoLineThree"
                        disabled={loadingSave}
                        value={state?.blockScreenText.textoLineThree}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item md={12}>
                    <InputText
                        label={"Text Centrat: línia 4"}
                        name="textoLineFour"
                        disabled={loadingSave}
                        value={state?.blockScreenText.textoLineFour}
                        onChange={handleChange}
                    />
                </Grid>

            

            <Grid item md={12}>
                <InputButtonLoading
                    onClick={handleSubmit}
                    loading={loadingSave}
                    label="Modificar"
                    position="left"
                    style={{ float: 'right' }}
                />
            </Grid>

        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        isActiveTexto: props?.blockScreenText.isActiveTexto === undefined ? true : props.blockScreenText.isActiveTexto,
        qrTexto: props?.blockScreenText.qrTexto === undefined ? false : props.blockScreenText.qrTexto,
        textoLineOne: props?.blockScreenText.textoLineOne || "",
        textoLineTwo: props?.blockScreenText.textoLineTwo || "",
        textoLineThree: props?.blockScreenText.textoLineThree || "",
        textoLineFour: props?.blockScreenText.textoLineFour || "",
        
    }
}

