import React, {useState} from "react";
import EditorText from "../../../../components/EditorText";

export default function Editor() {
    const [text, setText] = useState("<p>Esto es el texto del editor</p>")

    return (
        <div>
            <h2>Editor</h2>

            <EditorText
                style={{height: 250}}
                content={text}
                setContent={setText}
                options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']}
            />
            <hr/>

            <div style={{background: '#fff', padding: 10, fontFamily: "Arial"}}>
                <strong>Resultado:</strong>
                <br/>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </div>
    )
}
