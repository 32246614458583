import React, { useEffect, useState } from "react";
import {getScreen, apiIsUpdateScreens} from "../../../../services/User/display"


export default function Rates() {

    const languages = ["ca", "es", "en"];
    const [currentLanguage, setCurrentLanguage] = useState(languages[0]);
    const [state, setState] = useState(initialValues())
    const [reloadScreen, setReloadScreen] = useState(0)

    
    useEffect(() => {
        getDisplayScreenRates()
    }, [])

    const getDisplayScreenRates = () => {
        getScreen({screenId:3}, 'ca').then(resp => {
            state.baseUrl = resp.data['baseUrl']
            setState(initialValues(resp.data[currentLanguage][0]))
        }).catch(err => {
            setState(initialValues())
        })
    }
    
    useEffect(() => {
        const interval = setInterval(() => {
            checkScreensUpdate()
            setReloadScreen(Math.random())
        }, 300000) 
        return () => clearInterval(interval)
    }, [reloadScreen])

    const checkScreensUpdate = () => {
        apiIsUpdateScreens().then(resp => {
            if(resp.data?.updatedPrices){
                // reload page
                window.location.reload()
            }
        })
    }

    return (
        <>
            <div style={{ boxSizing: "border-box",}}>
                <div style={{ width: "1920px", height: "1080px", overflow:"hidden"}}>
                   
                {state.mediaOneType ? (
                    <video autoPlay muted loop>
                            <source src={state.urlFileMediaOne}  type="video/mp4"></source>
                    </video>
                ) : (
                    <img src={state.urlFileMediaOne} alt={state.urlFileMediaOne} style={{ width: "100%", height:"100%"}}/>
                )}
                </div>
            </div>
        </>
    );
}

function initialValues(props) {

    return {
        mediaOneType: props?.mediaOneType === undefined ? false : props.mediaOneType,
        fileMediaOne: props?.fileMediaOne || [],
        urlFileMediaOne: props?.urlFileMediaOne || "",
        qrMediaOne: props?.qrMediaOne === undefined ? false : props.qrMediaOne,
        isActiveMediaOne: props?.isActiveMediaOne === undefined ? true : props.isActiveMediaOne,
    }
}