import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { convertFromRaw } from 'draft-js';
import { EditorState } from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export default function ScheduleAndRate({ item }) {

    const [state, setState] = useState(initialValues(item))
    const { i18n } = useTranslation()

    return (
        <div className="blocks-full-width">
            <div className="schedule-rate-block">
                <div className="first-column">
                    <h6 className="schedule-title">
                        {i18n.resolvedLanguage == 'ca' && (<>HORARI</>)}
                        {i18n.resolvedLanguage == 'es' && (<>HORARIO</>)}
                        {i18n.resolvedLanguage == 'en' && (<>SCHEDULE</>)}
                        </h6>
                    <div className="schedule-text dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.rate }} />
                        <h6 className="guide-title">
                        {i18n.resolvedLanguage == 'ca' && (<>guia digital</>)}
                        {i18n.resolvedLanguage == 'es' && (<>guía digital</>)}
                        {i18n.resolvedLanguage == 'en' && (<>digital guide</>)}
                        </h6>
                    <div className="guide-text dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.guide }} />
                </div>
                <div className="second-column">
                    <h6 className="rate-title">
                        {i18n.resolvedLanguage == 'ca' && (<>TARIFES</>)}
                        {i18n.resolvedLanguage == 'es' && (<>TARIFAS</>)}
                        {i18n.resolvedLanguage == 'en' && (<>RATES</>)}
                    </h6>
                    <div className="rate-container">
                        <p className="subtitle">
                        {i18n.resolvedLanguage == 'ca' && (<>ENTRADA GENERAL</>)}
                        {i18n.resolvedLanguage == 'es' && (<>ENTRADA GENERAL</>)}
                        {i18n.resolvedLanguage == 'en' && (<>GENERAL ENTRANCE</>)}
                        </p>
                        <div className="price-container">
                            <div className="dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.scheduleOneName }} />
                            <div className="dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.scheduleOnePrice }} />
                        </div>
                        <div className="rate-button-container">
                            <a href={state?.scheduleOneButtonLink} id={state?.itemFive} className="rate-link">{state?.scheduleOneButtonName}</a>
                        </div>
                    </div>
                    <div className="rate-container border-top">
                        <p className="subtitle">
                        {i18n.resolvedLanguage == 'ca' && (<>ENTRADA reduïda*</>)}
                        {i18n.resolvedLanguage == 'es' && (<>ENTRADA reducida*</>)}
                        {i18n.resolvedLanguage == 'en' && (<>Reduced ENTRANCE*</>)}
                        </p>
                        <div className="price-container">
                            <div className="dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.scheduleTwoName }} />
                            <div className="dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.scheduleTwoPrice }} />
                        </div>
                        <div className="rate-button-container">
                            <a href={state?.scheduleTwoButtonLink} id={state?.itemSix} className="rate-link">{state?.scheduleTwoButtonName}</a>
                        </div>
                    </div>
                    <div className="rate-container border-top">
                        <p className="subtitle">
                            {i18n.resolvedLanguage == 'ca' && (<>entrada gratuïta</>)}
                            {i18n.resolvedLanguage == 'es' && (<>entrada gratuita</>)}
                            {i18n.resolvedLanguage == 'en' && (<>free entrance</>)}
                            </p>
                        <div className="price-container" />
                        <div className="pmt-0 dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.scheduleThree }} />
                        <div className="rate-button-container">
                            <a href={state?.scheduleThreeButtonLink} id={state?.itemSeven} className="rate-link">{state?.scheduleThreeButtonName}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function initialValues(props) {

    let rate;
    if (props?.text) {
        rate = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.text))).getCurrentContent()
    } else {
        rate = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentState = convertToRaw(rate);
    let markup = draftToHtml(rawContentState);
    markup = markup.replace(/<p><\/p>/gi, "<br/>");

    let guide;
    if (props?.itemOneName) {
        guide = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.itemOneName))).getCurrentContent()
    } else {
        guide = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentStateB = convertToRaw(guide);
    let markupB = draftToHtml(rawContentStateB);
    markupB = markupB.replace(/<p><\/p>/gi, "<br/>");

    let scheduleOneName;
    if (props?.itemOne) {
        scheduleOneName = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.itemOne))).getCurrentContent()
    } else {
        scheduleOneName = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentStateC = convertToRaw(scheduleOneName);
    let markupC = draftToHtml(rawContentStateC);
    markupC = markupC.replace(/<p><\/p>/gi, "<br/>");

    let scheduleOnePrice;
    if (props?.itemTwoName) {
        scheduleOnePrice = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.itemTwoName))).getCurrentContent()
    } else {
        scheduleOnePrice = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentStateD = convertToRaw(scheduleOnePrice);
    let markupD = draftToHtml(rawContentStateD);
    markupD = markupD.replace(/<p><\/p>/gi, "<br/>");

    let scheduleTwoName;
    if (props?.itemTwo) {
        scheduleTwoName = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.itemTwo))).getCurrentContent()
    } else {
        scheduleTwoName = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentStateE = convertToRaw(scheduleTwoName);
    let markupE = draftToHtml(rawContentStateE);
    markupE = markupE.replace(/<p><\/p>/gi, "<br/>");

    let scheduleTwoPrice;
    if (props?.itemFourName) {
        scheduleTwoPrice = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.itemFourName))).getCurrentContent()
    } else {
        scheduleTwoPrice = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentStateF = convertToRaw(scheduleTwoPrice);
    let markupF = draftToHtml(rawContentStateF);
    markupF = markupF.replace(/<p><\/p>/gi, "<br/>");

    let scheduleThree;
    if (props?.itemThree) {
        scheduleThree = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.itemThree))).getCurrentContent()
    } else {
        scheduleThree = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentStateG = convertToRaw(scheduleThree);
    let markupG = draftToHtml(rawContentStateG);
    markupG = markupG.replace(/<p><\/p>/gi, "<br/>");

    return {
        "rate": markup,
        "guide": markupB,
        "scheduleOneName": markupC,
        "scheduleOnePrice": markupD,
        "scheduleTwoName": markupE,
        "scheduleTwoPrice": markupF,
        "scheduleThree": markupG,
        "scheduleOneButtonName": props?.btnRedName,
        "scheduleOneButtonLink": props?.btnRedLink,
        "scheduleTwoButtonName": props?.btnWhiteName,
        "scheduleTwoButtonLink": props?.btnWhiteLink,
        "scheduleThreeButtonName": props?.itemThreeName,
        "scheduleThreeButtonLink": props?.itemFour,
        "itemFive": props?.itemFive,
        "itemSix": props?.itemSix,
        "itemSeven": props?.itemSeven,
    }
}
