import {VscAdd, VscChromeClose, VscChromeMinimize} from "react-icons/vsc";
import TableCell from "@mui/material/TableCell";
import React from "react";

export default function InputNumber(props) {

    const {
        row,
        element,
        keyElement,
        keyRow,
        rowsIn,
        setRowsIn,
        handleChangeCell
    } = props

    // Actualizamos el valor de la celda segun la posición del array
    const handleClick = (value, key, field, row, type) => {
        let number = (value === '' || !value || value < 1) ? 0 : value
        number = parseInt(number)

        switch (type) {
            case 'plus':
                number = number + 1
                break
            case 'remove':
                number = number - 1
                break
            default:
                number = number
                break
        }

        if (number === 0) {
            number = ''
        }
        rowsIn[key][field] = number
        setRowsIn(rowsIn)

        // Enviamos el valor en el array de origen
        handleChangeCell(number, row)
    }

    return (
        <TableCell
            component="td"
            scope="row"
            key={keyElement}
            size="small"
            style={{padding: '8px'}}
        >

            <div style={{border: '1px solid #000', maxWidth: 100}}>
                <div style={{
                    display: 'flex'
                }}>
                    {row[element.field] > 0 && (
                        <button
                            onClick={(e) => handleClick(row[element.field], keyRow, element.field, row, 'remove')}
                            style={{
                                border: 'none',
                                width: 30,
                                height: 30,
                                marginRight: 5,
                                paddingTop: 5,
                                cursor: 'pointer',
                                outline: 'none',
                                backgroundColor: '#ededed'
                            }}
                        >
                            {row[element.field] > 1 &&
                                <VscChromeMinimize/>}
                            {row[element.field] === 1 &&
                                <VscChromeClose/>}
                        </button>
                    )}
                    <input
                        id={row.id}
                        style={{
                            textAlign: 'center',
                            fontSize: 14,
                            width: '100%',
                            border: 'none'
                        }}
                        onChange={(e) => handleClick(e.target.value, keyRow, element.field, row, 'input')}
                        value={row[element.field]}
                    />
                    <button
                        onClick={(e) => handleClick(row[element.field], keyRow, element.field, row, 'plus')}
                        style={{
                            border: 'none',
                            width: 30,
                            height: 30,
                            marginLeft: 5,
                            paddingTop: 5,
                            cursor: 'pointer',
                            outline: 'none',
                            backgroundColor: '#ededed'
                        }}
                    >
                        <VscAdd/>
                    </button>
                </div>
            </div>

        </TableCell>
    )
}
