import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {RouteAuth} from "../../../routes/RouteAuth";


export default function Homepage() {
    const navigate = useNavigate()

    useEffect(() => {
        navigate(RouteAuth.LOGIN.path)
    })

    return (<></>)
}
