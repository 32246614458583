import * as React from 'react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';
import "./pdf.css"
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {BASE_PATH} from "../utils/constants";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "../assets/images/front/Icons/CloseIcon.svg";

/**
 * Previsualiza un PDF
 *
 * ## PROPS
 * - **url** *string*
 * - **showPages** *bool*
 */
export default function PreviewPdf(props) {
    const {pluralTitle, hideTitle, content, setArticleLoaded} = props
    const {t} = useTranslation()

    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [title, setTitle] = useState("")

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    useEffect(() => {
        if(pluralTitle){
            setTitle(t('story.plural.document'))
        }else{
            setTitle(t('story.document'))
        }
    }, [title])

    return (
        <>
            {content.documentUrl &&
                <div style={{paddingBottom: '28px', paddingTop: '8px', borderTop: '1px solid #FE7A84'}} onClick={handleOpen}>
                    <h2 style={{fontFamily: 'SohneBreitHalbfett', fontSize: '15px', lineHeight: '18px', color: '#E30613', margin: '0px', marginBottom: '16px'}}>{t('story.document')}</h2>
                    {/*<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js">*/}
                    <Worker workerUrl={`${BASE_PATH}/web-app/react-pdf-viewer/pdf.worker.min.js`}>
                        <Viewer
                            fileUrl={content.documentUrl}
                            className="preview-pdf"
                            defaultScale={SpecialZoomLevel.PageWidth}
                        />
                    </Worker>
                    <div style={{fontSize: '12px', lineHeight: '16px', color: '#141414', fontFamily: 'SohneBuch', marginTop: '8px', marginBottom: '0px'}} dangerouslySetInnerHTML={{ __html: content.footer }} className="text-block-html" />
                </div>
            }

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{
                        position: 'fixed',
                        zIndex: 9999999,
                        float: 'right',
                        right: 50,
                        top: 50,
                    }}>
                        <button
                            className="close-button"
                            onClick={handleClose}
                            style={{
                                backgroundColor: '#ffffff',
                                borderRadius: '100%',
                                display: 'flex',
                                boxShadow: '0px 2px 6px rgba(0, 33, 68, 0.1)',
                                padding: '.125vw',
                                width: 'max-content',
                                border: 'none',
                            }}
                        >
                            <img
                                src={CloseIcon}
                                alt=""
                                style={{
                                    width: 16,
                                    height: 16,
                                    padding: 11
                                }}
                            />
                        </button>
                    </div>

                    <div style={{overflowY: 'auto', height: '100vh'}}>
                        <div style={{padding: 20, paddingTop: 25, paddingBottom: 25}}>
                            <Worker workerUrl={`${BASE_PATH}/web-app/react-pdf-viewer/pdf.worker.min.js`}>
                                <Viewer
                                    fileUrl={content.documentUrl}
                                    className="preview-pdf"
                                    defaultScale={SpecialZoomLevel.PageWidth}
                                />
                            </Worker>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

const style = {
    width: '100%',
    height: '100vh',
    bgcolor: 'background.paper',
};
