import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {useTranslation} from "react-i18next";
import image from "../../../../assets/images/front/scaner/Group_271.png";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Info(props) {
    const {open, setOpen} = props
    const {t} = useTranslation()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        // Guardamos en local storage si se ha mostrado esta pantalla
        localStorage.setItem('scanner-info', 'no')
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent
                    style={{
                        backgroundColor: '#E30613',
                        width: 'calc(100% - 15.31vw)',
                        zIndex: 9999999,
                        left: 0,
                        position: 'fixed',
                        height: '58.985vh',
                        bottom: 0,
                        borderRadius: '10vw 10vw 0px 0px',
                        padding: '8.4507vh 7.8125vw 7.04225vh 7.5vw',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        overflowY: 'visible'
                    }}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflowY: 'visible'
                    }}>
                        <img src={image} style={{width: '21.128vh', maxWidth: '37.5vw', objectFit: 'cover'}} />
                        <p style={{
                            fontFamily: 'SohneBreitHalbfettKursiv',
                            fontSize: 'clamp(3.16902vh, 18px, 5.625vw)',
                            lineHeight: '3.87324vh',
                            letterSpacing: '0em',
                            textAlign: 'center',
                            color: '#FDFDFD',
                            marginBottom: '0px',
                            marginTop: '5.6338vh',
                            maxWidth: '77.815vw'
                        }}>
                            {t('scan.acercarse')}
                        </p>
                        <button onClick={handleClose} style={{
                            fontFamily: 'SohneBreitDreiviertelfett',
                            textAlign: 'Center',
                            padding: '1.8486vh 1.5625vw',
                            backgroundColor: '#FDFDFD',
                            border: 'none',
                            color: '#E30613',
                            fontSize: 'clamp(2.8169vh, 16px, 5vw)',
                            lineHeight: '6.3.52113vh',
                            width: '100%',
                            marginTop: '8.4507vh'
                        }}>
                            {t('scan.continue')}
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
