import {BASE_PATH, BASE_PATH_EXTENSION} from "../../utils/constants";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION}/user/display`;


export function getScreen(props, lang = 'ca') {
  
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/list`, props, {})
}



export function apiIsUpdateScreens(lang = "ca") {
   
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/check-update`)
}


export function getDisplayScreen(props, lang = 'ca') {
  
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/get-screen`, props, {})
}
