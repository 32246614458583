import React, { useRef, useState, useEffect } from "react";
import {useTranslation} from "react-i18next";

export default function TextBlock(props) {

    const {pluralTitle, hideTitle, content} = props
    const {t} = useTranslation()

    const [title, setTitle] = useState("")
    const hideHeaderIn = hideTitle === false ? false : true

    useEffect(() => {
        if(pluralTitle){
            setTitle(t('story.plural.quote'))
        }else{
            setTitle(t('story.quote'))
        }
    }, [title]);

    return(
        <>
            {content.text && 
                <div className="text-block" style={{maxWidth: '100%', marginTop: hideHeaderIn ? '0px' : '-4px'}}>
                    <div style={{marginTop: '0px', paddingBottom: '0px', paddingTop: '8px', borderTop: hideHeaderIn ? '1px solid #FE7A84' : 'none'}}>
                        {hideHeaderIn && (<h2 style={{fontFamily: 'SohneBreitHalbfett', fontSize: '15px', lineHeight: '18px', color: '#E30613', margin: '0px', marginBottom: '16px'}}>{title}</h2>)}
                        <div style={{marginBottom: '24px'}}>
                            <p style={{fontFamily: 'QueensCondensedBoldIt', fontSize: '24px', lineHeight: '28px', color: '#141414', margin: '0px'}}>{content.text}</p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
