import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import {apiList, apiDeleteActivities } from "../../../../../../../../services/Admin/blockActivity";
import { Grid, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InputDateTime from "../../../../../../../../components/Admin/Inputs/InputDateTime";
import HeaderPage from "../../../../../../../../components/Admin/HeaderPage";
import Table from "../../../../../../../../components/Table/Table";
import InputButton from "../../../../../../../../components/Admin/Inputs/InputButton";
import {RouteWebBack} from "../../../../../../../../routes/Web/RouteWebBack";
import {DASH_MAX_ROW} from "../../../../../../../../utils/constants";
import moment from "moment"
import ModalConfirm from "../../../../../../../../components/Admin/ModalConfirm";
import InputButtonLoading from "../../../../../../../../components/Admin/Inputs/InputButtonLoading";


export default function ManagementActivity(props) {

    const { state, setState, setMsgError, langSelect } = props
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0) 
    const [rows, setRows] = useState([])
    const [stateBlock, setStateBlock] = useState(initialValues)
    const [openModalActivity, setOpenModalActivity] = React.useState(false)
    const [deleteActivityInfo, setDeleteActivityInfo] = useState(null)

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [stateBlock, langSelect])

    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = stateBlock.sort.field
        if (stateBlock.sort.field === "active") { field = "isActive" }

        let params = {
            limit: DASH_MAX_ROW,
            offset: stateBlock.offset ?? 0,
            field: field,
            sort: stateBlock.sort.sort,
            dateFrom: stateBlock.dateFrom, 
            dateTo: stateBlock.dateTo,
            langSelect: langSelect
        }

        apiList(params).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc,
                    id: doc.id,
                    eventDate: moment.unix(doc.eventDate).utc().format("DD/MM/YYYY HH:mm"),
                    active: doc.isActive ? "Sí" : "No",
                }
            })

            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    const handleOpenModalActivity = (info) => {
        setDeleteActivityInfo(null)
        setOpenModalActivity(true)
        setDeleteActivityInfo(info)
        
    }

    const handleDeleteActivity = () => {
        apiDeleteActivities({
            id: deleteActivityInfo.id,
            blockId: deleteActivityInfo.blockId,
            idActivity:deleteActivityInfo.idActivity,
            langSelect: state.langSelect,
        }).then(resp => {
            setDeleteActivityInfo(null)
            window.location.reload();
            setMsgSuccess(resp.data?.msg)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
               setDeleteActivityInfo(null)
        })
    }

    return (
        <>
           <HeaderPage title="Activitats">
                <InputButton
                    style={{float: "right", margin: "0px 0px 10px 0px"}}
                    href={`${RouteWebBack.WEB_ACTIVITY_CREATE.path}/${state.block.pageId}/${state.block.pageBlockId}/${state.block.blockId}`}
                    text=" + Crear activitat"
                />
            </HeaderPage>

            <Grid container spacing={2}>

                <Filters
                    state={stateBlock}
                    setState={setStateBlock}
                />

                <Grid item xs={12}>
                    <Table
                        columns={tableColumns(state, handleOpenModalActivity)}
                        rows={rows}
                        rowCount={rowCount}
                        filter={stateBlock}
                        setFilter={setStateBlock}
                        loading={loading}
                    />
                </Grid>
                <ModalConfirm openModal={openModalActivity} setOpenModal={setOpenModalActivity} handleAction={handleDeleteActivity} text="¿Eliminar l'esdeveniment en els 3 idiomes?" />
            </Grid>

            
        </>   
    )
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "eventDate", sort: "desc" },
        // dateFrom: moment.utc().set({hour:0, minute:0, second:0}).unix(),
        // dateTo: moment.utc().set({hour:23, minute:59, second:0}).unix()
    }
}

function tableColumns(state, handleOpenModalActivity) {
    return [
        {
            field: "id",
            headerName: "ID",
            sortable: true,
            flex: 1
        },
        {
            field: "eventDate",
            headerName: "DATA",
            sortable: true,
            flex: 1
        }, 
        {
            field: "htmlCode",
            headerName: "TEXT",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>  {params.htmlCode && (
                            <div dangerouslySetInnerHTML={{ __html: params.htmlCode }} className="boxScrollbar" style={{height:'75px', overflowY:'auto'}} />
                        )}
                    </>
                )
            },
        },
        {
            field: "imgUrl",
            headerName: "IMATGE",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>  {params.imgUrl && (
                            <img src={params.imgUrl} style={{ width: '100%', height:'75px', objectFit: 'cover' }}/>
                        )}
                    </>
                )
            },
        },
        {
            field: "active",
            headerName: "VISIBLE",
            sortable: true,
            flex: 1
        },
      
        {
            field: "actions",
            headerName: "ACCIONS",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`${RouteWebBack.WEB_ACTIVITY_EDIT.path}/${state.block.pageId}/${state.block.pageBlockId}/${state.block.blockId}/${params.idActivity}`}>
                            <IconButton size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                         <IconButton size="small" onClick={() =>{handleOpenModalActivity({id: params.id, blockId: params.blockId, idActivity:params.idActivity})}
                                                  }>
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                    </>
                )
            },
        },
    ]
}


function Filters(props) {

    const {state, setState} = props
    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <>
            <Grid  item md={4} style={{marginRight:20}}>
            <InputDateTime {...{
                label: "Data d'inici",
                onChange: (e) => {state[e.target.name] = e.target.value
                                  setState({...state})},
                value: state.dateFrom,
                name: "dateFrom"
                }}/>
            </Grid>
            <Grid item md={4}>
            <InputDateTime {...{
                label: "Data de fi",
                onChange: (e) => {state[e.target.name] = e.target.value
                                  setState({...state})},
                value: state.dateTo,
                name: "dateTo"
                }}/>
            </Grid>
        </>
    )
}
