import React, {useState} from "react";
import {apiRecoverPassword} from "../../../services/Auth/login";
import MsgError from "../../../components/MsgError";
import {Link, useNavigate, useParams} from "react-router-dom";
import {TextField} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {LoadingButton} from "@mui/lab";
import {RouteAuth} from "../../../routes/RouteAuth.js";
import {useTranslation} from "react-i18next";

const theme = createTheme();

export default function RecoverPasswordForm() {

    const {token} = useParams();
    const navigate = useNavigate()
    const [state, setState] = useState(initialValues())
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(false)
    const {i18n} = useTranslation()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        setMsgError([])
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            return false;
        }

        apiRecoverPassword(state, token, i18n.resolvedLanguage).then(resp => {
            setLoading(false)
            navigate(RouteAuth.LOGIN.path + `/?msg=${resp.data?.msg}`)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
        })
    }

    return (<ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}>
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Change password
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <MsgError msg={msgError}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                name="password"
                                type="password"
                                autoComplete="email"
                                onChange={handleChange}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                fullWidth
                                name="repPassword"
                                label="Repeat Password"
                                type="password"
                                id="repPassword"
                                autoComplete="current-password"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <LoadingButton
                                type="submit"
                                loading={loading}
                                loadingPosition="start"
                                variant="contained"
                                fullWidth
                                sx={{mt: 1, mb: 2}}
                            >
                                Change password
                            </LoadingButton>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <Link to={RouteAuth.LOGIN.path}>
                                {"Go back"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    </ThemeProvider>)
}

function initialValues() {
    return {
        password: "", repPassword: ""
    }
}

function validationSchema(schema) {
    let error = []

    if (!schema.password) {
        error.push("La contraseña es obligatoria")
    }

    if (!schema.repPassword) {
        error.push("La contraseña es obligatoria")
    }

    return error
}
