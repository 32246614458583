import React, {useEffect, useState} from 'react';

export default function Cronometro(props) {

    const {
        time,
        setTime,
        showTime,
        showProgressBar
    } = props

    const [originalTime, setOriginalTime] = useState(time)

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(time => time > 0 ? time - 0.1 : 0);
        }, 100)
        return () => clearInterval(interval)
    }, [])

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60)
        const seconds = time % 60
        return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
    }

    return (
        <div>
            {/* {showTime && (
                <p style={{textAlign: 'center', fontSize: 20}}>{formatTime(time)}</p>
            )} */}

            {showProgressBar && (
                <ProgressBar
                    time={time}
                    originalTime={originalTime}
                />
            )}
        </div>
    )
}


const ProgressBar = (props) => {

    const {
        time,
        originalTime
    } = props

    const calculatePercentage = (time, originalTime) => {
        const percentage = (time / originalTime) * 100;

        return Math.round(percentage);
    };

    return (
        <>
            <div className="progress-bar">
                <div className="progress" style={{width: `${calculatePercentage(time, originalTime)}%`}}></div>
            </div>
            <style>
                {`
                .progress-bar {
                      width: 100%;
                      height: 0.625vw;
                      background-color: #FDFDFD;
                      overflow: hidden;
                    }
                    
                    .progress {
                      height: 100%;
                      background-color: #F00214;
                      transition: width 0.3s ease-in-out;
                    }
                `}
            </style>
        </>
    );
};
