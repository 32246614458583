import React, { useRef, useState, useEffect } from "react";
import MobileMessage from "../../../../components/User/MobileMessage";
import {useTranslation} from "react-i18next";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {useNavigate, useParams} from "react-router-dom";
import {RouteUser} from "../../../../routes/RouteUser";
import CloseIcon from '../../../../components/Icons/CloseIcon';
import PinchIcon from '../../../../components/Icons/PinchIcon';
import EdificiIcon from '../../../../components/Icons/EdificiIcon';
import Map from '../../../../assets/images/front/map/MAPA_PB_Vertical.png';
import Map2 from '../../../../assets/images/front/map/MAPA_PLANT_Q_Vertical.png';
import InputButtonClose from "../../../../components/Layouts/User/InputButtonClose";
import TopBar from "../../../../components/Layouts/User/Header/TopBar";
// import MapButton from '../../../components/User/MapButton';
import ReactGA from "react-ga4";

const MapButton = ({ x, y, onClick, text, salaId, className }) => (
    <button
      className={"map-button " + className}
      style={{ left: x, top: y }}
      onClick={onClick}
    >
      {text}
    </button>
);


export default function Mapa() {

  const {planta} = useParams()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [activePlantaButton, setActivePlantaButton] = useState( planta ? parseInt(planta) : 0)
  const pinchZoomRef = useRef(null)

  useEffect(() => {

    ReactGA.event({
      category: "Map",
      action: `Map`,
    });

    // Corregir el bug del 100vh en móvil
    let elementoMapa = document.querySelector('.mapa-element')
    elementoMapa.style.height = window.innerHeight + 'px'
    
    scaleButtons()
  }, [])

  useEffect(() => {
    scaleButtons()
  }, [activePlantaButton])

  const scaleButtons = () => {
    // Obtén el elemento al que hacer seguimiento de los cambios de estilo
    const elemento = document.querySelector('.react-transform-component')
    const contenedor = document.querySelector('.react-transform-component')
    const button = contenedor.querySelectorAll('.map-button')
    
    function estiloCambiado() {
      button.forEach(item => {  
        // Obtiene la transformación de escala aplicada al contenedor
        const style = window.getComputedStyle(contenedor)
        const transformValue = style.getPropertyValue('transform')
      
        // Calcula la inversa de la escala del contenedor
        const scale = parseFloat(transformValue.split('(')[1].split(')')[0])
        const inverseScale = 1 / scale
      
        // Aplica la transformación inversa al botón
        item.style.transform = `scale(${inverseScale})`
        item.style.transformOrigin = 'top left'
      })
    }

    // Observar los cambios de estilo del elemento
    const observer = new MutationObserver(estiloCambiado)
    const opcionesObservador = {
      attributes: true,
      attributeFilter: ['style']
    }
    observer.observe(elemento, opcionesObservador)
  }

  const handleButtonPlantaClick = (buttonText) => {
    if (pinchZoomRef.current) {
      pinchZoomRef.current.resetTransform()
      ReactGA.event({
        category: "Map",
        action: `Map - Planta baja`,
      });
    } else {
      ReactGA.event({
        category: "Map",
        action: `Map - Planta primera`,
      });
    }
    setActivePlantaButton(buttonText)
  }

  const handleClickSala = (salaId) => {
    ReactGA.event({
      category: "Map",
      action: `Map - Sala ${salaId}`,
    });

    navigate(`${RouteUser.MAPARESULTS.path}/${salaId}/${activePlantaButton}`)
  }

  const handleClickInstallation = (storyId) => {
    ReactGA.event({
      category: "Map",
      action: `Map - Instalación temporal ${storyId}`,
    });

    navigate(`${RouteUser.STORIES.path}/${storyId}`)
  }

  return (
    <>
      <MobileMessage />
      <div className='mapa' style={{background: 'black',
        width: '100vw',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        position: 'relative',
        overflowY: 'hidden',
        zIndex: '3'}}>
        <div className='mapa-element' style={{
          background: '#FDFDFD',
          maxWidth: '980px',
          width: '100vw',
          height: '100%',
          margin: 'auto',
          position: 'relative',
          height: '100vh',
          minHeight: '-webkit-fill-available',
          minHeight: '-moz-available',
          minHeight: 'fill-available'
        }}>
          <div className="map-navbar">
              <div>
                  <h1>{t('map.title')}</h1>
              </div>
              <div>
                <TopBar />
              </div>
          </div>
          <div className='map-plantas'>
            <div>
              <button className={`mapa-planta-buttons ${activePlantaButton === 0 ? 'activated' : ''}`}
                onClick={() => handleButtonPlantaClick(0)}
              >
                {t('map.ground_floor')}
              </button>
            </div>
            <div>
              <button
                className={`mapa-planta-buttons ${activePlantaButton === 1 ? 'activated' : ''}`}
                onClick={() => handleButtonPlantaClick(1)}
              >
                {t('map.first_floor')}
              </button>
            </div>
          </div>
          <div className="app">
            <TransformWrapper defaultScale={1} ref={pinchZoomRef}>
              <TransformComponent>
                {activePlantaButton === 0 ? (
                  <>
                    <img src={Map} alt="test" style={{width: '100%', height: '100%', objectFit: 'contain'}} />
                    <MapButton x='47%' y='32%' onClick={() => handleClickSala(15)} text='15' salaId='15' />
                    <MapButton x='55%' y='16%' onClick={() => handleClickSala(16)} text='16' salaId='16' />
                    <MapButton x='57%' y='34%' onClick={() => handleClickSala(17)} text='17' salaId='17' />
                    <MapButton x='38%' y='54%' onClick={() => handleClickInstallation(66)} text={t('map.temporary_installation')} storyId='66' className="sala-text" />
                    <MapButton x='33%' y='80%' onClick={() => handleClickSala(20)} text={t('map.entrance')} salaId='20' className="sala-text" />
                  </>
                ) : null}
                {activePlantaButton === 1 ? (
                  <>
                    <img src={Map2} alt="test" style={{width: '100%', height: '100%', objectFit: 'contain'}} />
                    <MapButton x='67%' y='30%' onClick={() => handleClickSala(1)} text='1' salaId='1' />
                    <MapButton x='70%' y='51%' onClick={() => handleClickSala(2)} text='2' salaId='2' />
                    <MapButton x='71%' y='68%' onClick={() => handleClickSala(3)} text='3' salaId='3' />
                    <MapButton x='64%' y='69%' onClick={() => handleClickSala(4)} text='4' salaId='4' />
                    <MapButton x='48%' y='68%' onClick={() => handleClickSala(5)} text='5' salaId='5' />
                    <MapButton x='33%' y='68%' onClick={() => handleClickSala(6)} text='6' salaId='6' />
                    <MapButton x='21%' y='69%' onClick={() => handleClickSala(7)} text='7' salaId='7' />
                    <MapButton x='32%' y='61%' onClick={() => handleClickSala(8)} text='8' salaId='8' />
                    <MapButton x='32%' y='52%' onClick={() => handleClickSala(9)} text='9' salaId='9' />
                    <MapButton x='47%' y='38%' onClick={() => handleClickSala(10)} text='10' salaId='10' />
                    <MapButton x='47%' y='31%' onClick={() => handleClickSala(11)} text='11' salaId='11' />
                    <MapButton x='32%' y='32%' onClick={() => handleClickSala(12)} text='12' salaId='12' />
                    <MapButton x='57%' y='31%' onClick={() => handleClickSala(13)} text='13' salaId='13' />
                    <MapButton x='62%' y='11%' onClick={() => handleClickSala(19)} text={t('map.terrace')} salaId='19' className="sala-text" />
                    <MapButton x='59%' y='41%' onClick={() => handleClickSala(21)} text='0' salaId='21' />
                  </>
                ) : null}
              </TransformComponent>
            </TransformWrapper>
          </div>
          <div className="pinch-block">
            <PinchIcon className="pinch-icon icon" color="#E30613" />
            <p>{t('map.zoom')}</p>
          </div>
          <a className="edifici-button" href={RouteUser.EDIFICI.path} style={{backgroundColor: '#FDFDFD', borderRadius: '100%', display: 'flex', boxShadow: '0px 2px 12px rgba(0, 33, 68, 0.1)'}}>
            <EdificiIcon className="edifici-icon icon" color="#E30613" />
          </a>
        </div>
      </div>
    </>
  )
}
