import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useAuth from "../../../../../hooks/useAuth";
import {apiCreate, apiGetUser, apiUpdate} from "../../../../../services/Admin/user";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import InputPassword from "../../../../../components/Admin/Inputs/InputPassword";
import InputSelect from "../../../../../components/Admin/Inputs/InputSelect";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import HeaderPage from "../../../../../components/Admin/HeaderPage";
import {Button, Grid, IconButton} from "@mui/material";
import {useTranslation} from "react-i18next";
import {RouteAdmin} from "../../../../../routes/RouteAdmin";
import {RouteUser} from "../../../../../routes/RouteUser";
import {apiImpersonate} from "../../../../../services/Auth/login";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";

export default function Edit() {
    
    const {id} = useParams()
    const navigate = useNavigate()
    const {auth, login} = useAuth()
    const {t, i18n} = useTranslation()

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [loadingImpersonate, setLoadingImpersonate] = useState(false)
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)

    const optionsRole = [{id: 'ROLE_ADMIN', name: t('user.roles.admin')}, {id: 'ROLE_USER', name: t('user.roles.user')}]

    const [checkedAccess, setCheckedAccess] = useState(true)

    const myRef = useRef(null)
    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        if (id) {
            void getUser()
        } else {
            setLoadingGetInfo(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getUser = () => {
        setMsgError([])

        apiGetUser(id, i18n.resolvedLanguage).then(resp => {
            setState(initialValues(resp.data?.data))
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        if (id) {
            void updateUser()
        } else {
            void createUser()
        }
    }

    const updateUser = () => {
        apiUpdate(state, i18n.resolvedLanguage).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const createUser = () => {
        apiCreate(state, i18n.resolvedLanguage).then(resp => {
            navigate(RouteAdmin.USER_MANAGEMENT.path)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
        })
    }

    const clickImpersonate = () => {
        setMsgError([])
        setLoadingImpersonate(true)
        apiImpersonate(id, i18n.resolvedLanguage).then(resp => {
            impersonate(resp.data?.token)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingImpersonate(false)
        })
    }

    const impersonate = async (token) => {

        await login({
            primaryUser: token,
            secondaryUser: auth?.secondaryUser,
        })
        navigate(RouteUser.SPLASH.path);
    }

    return (
        <>
            <div ref={myRef}></div>
            <HeaderPage title="Configuració del compte" urlBack={RouteAdmin.USER_MANAGEMENT.path}>
                {id && (
                    <InputButtonLoading
                        onClick={clickImpersonate}
                        loading={loadingImpersonate}
                        label={t('user.impersonate')}
                        variant="outlined"
                    />
                )}
            </HeaderPage>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <LoadingSpinner show={loadingGetInfo}/>

            <Grid container spacing={2} style={{display: loadingGetInfo ? 'none' : ''}}>

                <Grid item xs={12} md={6}>
                    <InputText
                        label="Nom"
                        required
                        name="name"
                        value={state?.name}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputText
                        label="Cognom"
                        required
                        name="surnames"
                        value={state?.surnames}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <InputText
                        label="Correu electrònic"
                        required
                        name="email"
                        value={state?.email}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <h3 className="fields-title">Seguretat</h3>
                </Grid>

                <Grid item xs={12} md={12}>
                    <InputSelect
                        label="Rol"
                        name="role"
                        required
                        onChange={handleChange}
                        value={state?.role}
                        options={optionsRole}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <InputPassword
                        label="Contrasenya"
                        name="password"
                        value={state?.password}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputCheckbox
                        label="Te accés a l'aplicació?"
                        disabled={loading}
                        checked={state?.isActive}
                        onChange={(e) => {
                            state.isActive = e.target.checked
                            setState({...state})
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputButtonLoading
                        onClick={handleSubmit}
                        loading={loading}
                        label={id ? t('Modificar') : t('Crear')}
                    />
                </Grid>
            </Grid>
        </>
    )
}

function initialValues(props) {

    return {
        id: props?.id || "",
        name: props?.name || "",
        surnames: props?.surnames || "",
        email: props?.email || "",
        password: "",
        isActive: props?.isActive,
        role: props?.role?.key || "",
        mediaId: props?.mediaId || "",
        mediaUrl: props?.mediaUrl || ""
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.name) {
        error.push("El nombre es obligatorio")
    }
    if (!schema.email) {
        error.push("El email es obligatorio")
    }

    return error
}
