import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}/artist`;

export function apiList(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.limit) { query += `&limit=${props.limit}`; }
    if (props.offset) { query += `&offset=${props.offset}`; }
    if (props.field) { query += `&field=${props.field}`; }
    if (props.sort) { query += `&sort=${props.sort}`; }
    if (props.name) { query += `&name=${props.name}`; }
    if (props.lang) { query += `&lang=${props.lang}`; }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/list?1=1${query}`, config)
}

export function apiFields(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/fields`, props, config)
}

export function apiFieldsFile(props, id, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/fields-file/${id}`, props, config)
}

export function apiGet(id, langSelect, lang = "ca") {

    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/${id}?langSelect=${langSelect}`, config)
}

export function apiIsActive(id, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/is-active/${id}`, config)
}

export function apiDeleteFile(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/delete-photo`, props, config)
}
