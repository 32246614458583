import React, { useState } from "react";
import {useTranslation} from "react-i18next";

export default function ButtonBlock(props) {
    
    const {label, onClick} = props

    return(
        <div className="button-block">
            <button onClick={onClick} style={{
                fontFamily: 'SohneBreitDreiviertelfett',
                textAlign: 'Center',
                padding: '10px',
                backgroundColor: '#E30613',
                border: 'none',
                color: '#ffffff',
                width: '100%'
            }}>{label}</button>
        </div>
    )
}
