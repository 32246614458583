import {BASE_PATH, BASE_PATH_EXTENSION} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION}/front-menu`;

export function apiList(props, lang = "ca") {
    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/`, {})
}


export function apiListSlugs(props, lang = "ca") {
    return axios.post(`${BASE_PATH}/${lang}/${baseUrl}/get-slugs`, props, {})
}

