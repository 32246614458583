import React, {useEffect, useRef, useState} from "react";
import {Grid, IconButton, TextField} from "@mui/material";
import InputSelect from '../../../../../components/Admin/Inputs/InputSelect'
import {RouteAdmin} from "../../../../../routes/RouteAdmin";
import {apiSave, apiGet} from "../../../../../services/Admin/story";
import {apiSaveSlide, apiGetListSlide, apiUpdatePositionSlide, apiDeleteSlide} from "../../../../../services/Admin/story";
import {apiList as apiListArtists} from "../../../../../services/Admin/artist";
import {apiList as apiListArtworks} from "../../../../../services/Admin/obra";
import {Link, useNavigate, useParams} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import LoadingBar from "../../../../../components/LoadingBar";
import InputCheckbox from "../../../../../components/Admin/Inputs/InputCheckbox";
import DndElements from "../../../../../components/DndElements";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import NoImage from "../../../../../assets/images/no_image.png"
import {MdDeleteForever} from "react-icons/md";
import EditIcon from "@mui/icons-material/Edit";
import {URL_WEB} from "../../../../../utils/constants";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";
import TitlePage from "../../../../../components/Admin/TitlePage";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import {RouteAdminObras} from "../../../../../routes/RouteAdminObras";
import {VscLink} from "react-icons/vsc";

export default function StoryCreate(props) {
    const {id} = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(false)
    const [elementDnd, setElementDnd] = useState([])
    const [optionsArtists, setOptionsArtists] = useState([])
    const [optionsArtworks, setOptionsArtworks] = useState([])
    const [loadingSlide, setLoadingSlide] = useState(false)
    const [loadingPosition, setLoadingPosition] = useState(false)
    const [numSlide, setNumSlide] = useState([])
    const [updateElementDnd, setUpdateElementDnd] = useState(false);
    const optionsColor = [{id: "#E30613", name: 'Vermell'}, {id: "#ffffff", name: 'Blanc'}]

    const myRef = useRef(null)

    useEffect(() => {
        getArtists()
        getArtworks()
        if (id) {
            getStory()
            getSlide()
        } else {
            setLoadingGetInfo(false)
        }
    }, [])

    useEffect(() => {
        if (id && elementDnd.length > 0) {
            setLoadingPosition(true)
            setNumSlide([])

            const timeOutId = setTimeout(() => {
                updateElementPosition()
            }, 2000)

            return () => clearTimeout(timeOutId)
        }

    }, [elementDnd])

    const updateElementPosition = () => {
        const idElementsSlide = elementDnd.map((item) => item.id);

        apiUpdatePositionSlide({
            id: idElementsSlide
        }).then(resp => {
            setLoadingPosition(false)
        }).catch(err => {
            setMsgError([err.response.data.msg])
            setLoadingPosition(false)
        })
    }

    const getStory = () => {
        apiGet(id).then(resp => {
            setState({
                ...state,
                storyId: resp.data?.storyId,
                isActive: resp.data?.isActive,
                artist: resp.data?.artist,
                artwork: resp.data?.artwork,
                slides: resp.data?.slides
            })

            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data.msg)
        })

    }

    const getArtists = () => {
        apiListArtists({}).then(resp => {
            setOptionsArtists(resp.data.data)
        }).catch(err => {
            setMsgError(err.response.data.msg)
        })
    }

    const getArtworks = () => {
        apiListArtworks({
            lang: 'ca'
        }).then(resp => {

            let temp = resp.data.data?.map(doc => {
                const htmlString = doc.titleOriginal;
                const titleOriginal = stripTags(htmlString);

                return {
                    ...doc,
                    titleOriginal: titleOriginal
                }
            })

            setOptionsArtworks(temp)
        }).catch(err => {
            setMsgError(err.response.data.msg)
        })
    }

    function stripTags(html) {
        const tmp = document.createElement("div");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    const handleSubmit = async () => {
        setMsgError([])
        setMsgSuccess([])
        setLoading(true)

        let error = validationSchema(state)
        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)

            return
        }

        await apiSave({
            id: state.storyId,
            isActive: state.isActive,
            artistId: state.artist,
            artworkId: state.artwork

        }).then(resp => {
            state.storyId = resp.data?.id
            setState({...state})
            setLoading(false)
            if (!id) {
                navigate(`${RouteAdmin.STORIES_EDIT.path}/${resp.data.id}`)
            } else {
                setMsgSuccess(resp.data.msg)
            }
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
        })
    }

    const getSlide = () => {
        setLoadingSlide(true)
        setNumSlide([])

        apiGetListSlide(id).then(resp => {
            const slide = resp.data?.map((item, key) => {
                setNumSlide((prevNumSlide) => [...prevNumSlide, key + 1]);
                return {
                    id: `${item.id}`,
                    content: <SlideView numSlide={numSlide.length > 0 ? numSlide[key] : key + 1} slide={item}
                                        storyId={id} handleRemove={handleRemove} handleNavigate={handleNavigate}/>
                }
            })
            setElementDnd(slide)
            setLoadingSlide(false)
        }).catch(err => {
            setMsgError(err.response.data.msg)
            setLoadingSlide(false)
        })
    }

    const addSlide = () => {
        setLoadingSlide(true)
        setMsgError([])
        setMsgSuccess([])

        apiSaveSlide({
            items: {
                storyId: state.storyId,
                featuredImage: "",
                audio: "",
                audioIsActive: false,
                colorTextScroll: null,
                textScroll: "",
                colorTextArtist: null,
            }
        }).then(resp => {
            getSlide()
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoadingSlide(false)
        })
    }

    const handleRemove = (id) => {
        setLoadingSlide(true)
        apiDeleteSlide({
            id: id
        }).then(resp => {
            getSlide()
            setLoadingSlide(false)
        }).catch(err => {
            setLoadingSlide(false)
        })
    }

    const handleNavigate = (slideId) => {
        navigate(`${RouteAdmin.SLIDE_EDIT.path}/${id}/${slideId}`)
    }

    const handleChange = (e) => {
        state[e.target.name] = e.target.value
        setState({...state})
    }

    return (
        <>
            <Grid container spacing={2} sx={{
                marginBottom: '32px'
            }}>
                <MsgError msg={msgError}/>
                <MsgSuccess msg={msgSuccess}/>
                <LoadingSpinner show={loadingGetInfo}/>

                {!loadingGetInfo && (
                    <>
                        <Grid item xs={12} md={12} lg={6}>
                            <InputSelect
                                label="Artista"
                                options={optionsArtists}
                                selectText="Selecciona un artista"
                                value={state.artist}
                                onChange={(e) => setState({...state, artist: e.target.value})}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={5}>
                            <InputSelect
                                label="Obra"
                                options={optionsArtworks}
                                optionsName="titleOriginal"
                                selectText="Selecciona una obra"
                                value={state.artwork}
                                onChange={(e) => setState({...state, artwork: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={1}>
                            {state?.artwork && (
                                <Link to={`${RouteAdminObras.OBRA_EDIT_GENERAL.path}/${state?.artwork}`} style={{float: 'right'}}>
                                    <VscLink style={{fontSize: '20px'}}/>
                                </Link>
                            )}
                        </Grid>

                        <Grid item xs={3}>
                            {state.storyId &&
                                <p style={{
                                    textAlign: 'left',
                                    width: '100%',
                                    margin: 0,
                                    paddingTop: 0
                                }}>{`${URL_WEB}/webapp/story/${state.storyId}`}</p>
                            }
                        </Grid>

                        <Grid item xs={3}>
                            <InputCheckbox
                                label="Activat"
                                style={{float: 'right'}}
                                className="mb-12"
                                checked={state.isActive}
                                onChange={(e) => {
                                    state.isActive = e.target.checked
                                    setState({...state})
                                }}
                                disabled={loadingGetInfo}
                            />
                        </Grid>


                        <Grid item xs={12} md={12} lg={6}>
                            <InputButtonLoading
                                onClick={handleSubmit}
                                loading={loading}
                                label={state.storyId ? 'Modificar' : 'Crear'}
                                position="left"
                                style={{float: 'right'}}
                            />
                        </Grid>
                    </>
                )}

                {state.storyId &&
                    <>
                        <Grid item xs={12}/>

                        <Grid item xs={12} md={12}>
                            <TitlePage title="Slides" borderTop={true}>
                                <InputButtonLoading
                                    onClick={addSlide}
                                    label="+ Crear slide"
                                    position="center"
                                    style={{float: 'right'}}
                                    variant="outlined"
                                />
                            </TitlePage>
                        </Grid>

                        <Grid item xs={12}>
                            {!loadingSlide ? (
                                <DndElements
                                    elementDnd={elementDnd}
                                    setElementDnd={setElementDnd}
                                    getSlide={getSlide}
                                    title="Posició"
                                />
                            ) : (
                                <LoadingSpinner show={loadingSlide || loadingPosition}/>
                            )}
                        </Grid>

                        <div ref={myRef}></div>
                    </>
                }
            </Grid>
        </>
    )
        ;
}

function initialValues(props) {
    return {
        storyId: "",
        isActive: true,
        artist: "",
        artwork: "",
        modelSlide: {
            id: "",
            featuredImage: "",
            position: 0
        }
    }
}

function SlideView(props) {
    const {numSlide, slide, storyId, handleRemove, handleNavigate} = props

    return (
        <Grid container spacing={0} style={{
            padding: 0,
            margin: 0
        }}>
            <Grid item md={12}>
                <img alt="" src={slide.featuredImage ? slide.featuredImage : NoImage}
                     style={{height: "100px", width: "100px", objectFit: 'cover'}}/>
            </Grid>
            <Grid item md={12}>
                <InputButton
                    style={{float: "left", margin: "0px 10px 0px 0px"}}
                    onClick={() => handleNavigate(slide.id)}
                    text="Editar"
                />

                <InputButton
                    style={{float: "left", margin: "0px 0px 0px 0px"}}
                    onClick={() => handleRemove(slide.id)}
                    variant="outlined"
                    text="Eliminar"
                />
            </Grid>
        </Grid>
    )
}

function validationSchema(schema) {

    let error = []

    if (!schema?.artist || schema?.artist.length == 0) {
        error.push("L'artista és obligatori")
    }
    if (!schema?.artwork || schema?.artwork.length == 0) {
        error.push("L'obra és obligatoria")
    }

    return error
}
