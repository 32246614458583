import * as React from 'react';
import MobileMessage from "../../../../components/User/MobileMessage";
import {Link, useNavigate} from "react-router-dom";
import logo from '../../../../assets/images/Logo_MuseuArtProhibit_White.png'
import {useTranslation} from "react-i18next";
import {RouteUser} from "../../../../routes/RouteUser";

export default function Language(props) {

    const {link} = props

    const linkIn = link ? link : null

    const {i18n} = useTranslation()
    const navigate = useNavigate()

    const changeLanguage = (lng) => {
        void i18n.changeLanguage(lng)
        if (!linkIn) {
            navigate(-2)
        } else {
            navigate(linkIn)
        }
    }

    return (
        <>
            <MobileMessage />
            <div className='language' style={{position: 'fixed',
                background: 'black',
                width: '100vw',
                height: '100vh',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                zIndex: '3'}}>
                <div style={{
                    background: '#F00214',
                    maxWidth: '980px',
                    width: '100vw',
                    height: '100vh',
                    margin: 'auto',
                    position: 'relative'
                }}>
                    <div className="language-list">
                        <button onClick={() => changeLanguage('ca')}>
                            <span>Català</span>
                        </button>
                        <button onClick={() => changeLanguage('es')}>
                            <span>Español</span>
                        </button>
                        <button onClick={() => changeLanguage('en')}>
                            <span>English</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
