import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../../../components/Admin/TitlePage";
import InputText from "../../../../../../../components/Admin/Inputs/InputText";
import { MdDeleteForever } from "react-icons/md";
import ButtonFile from "../../../../../../../components/Admin/Inputs/ButtonFile";
import InputButtonLoading from "../../../../../../../components/Admin/Inputs/InputButtonLoading";
import InputButton from "../../../../../../../components/Admin/Inputs/InputButton";
import ModalConfirm from "../../../../../../../components/Admin/ModalConfirm";
import { apiSaveBlockText, apiSaveFileBlockText, apiDeleteFileBlockText } from "../../../../../../../services/Admin/page";
import InputCheckbox from "../../../../../../../components/Admin/Inputs/InputCheckbox";

export default function Header(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    const [loadingSave, setLoadingSave] = useState(false)
    const [openModalFile, setOpenModalFile] = React.useState(false)
    
    const handleChangePhotoFile = (event) => {
        state[event.target.name] = event.target.value
        setState({ ...state })
    }
    const handleOpenModalFile = () => {
        setOpenModalFile(true)
    }

    const handleDeleteFile = () => {
        apiDeleteFileBlockText({
            blockId: state.blockText.blockId,
            langCode: state.langSelect,
        }).then(resp => {
            window.location.reload();
            setMsgSuccess(resp.data?.msg)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
        })
    }

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)

        apiSaveBlockText(state).then(resp => {
            let fileBackgroundImageBinary = state?.fileBackgroundImage?.length > 0 ? state?.fileBackgroundImage[0].binary : null
            handleSubmitFile(state.blockText.blockId, fileBackgroundImageBinary)
            handleRefresh()
            setLoadingSave(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
            handleRefresh()
        })
       
    }

    const handleSubmitFile = (idBlock, fileBackgroundImage) => {
        let f = new FormData()
        f.append(`fileBackgroundImage[]`, fileBackgroundImage)
        f.append(`langCode`, state.langSelect)

        apiSaveFileBlockText(f, idBlock).then(resp => {
            setLoadingSave(false)
            setMsgSuccess(resp.data?.msg)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingSave(false)
        })
    }


    const handleChangeBlockText = (e) => {
        state.blockText[e.target.name] = e.target.value
        setState({ ...state })
    }

    const handleRefresh = () => {
        setReloadPreview(Math.random())
    }

    return (
        <>
            <Grid item md={12} style={{paddingTop:'0px'}}>
                <TitlePage title={state.block.webBlockName} borderTop={false} borderBottom={false} />
            </Grid>

            <Grid item md={12}>
                <InputCheckbox
                    labelLeft="Imatge de portada: No"
                    label=" Sí"
                    name="imgPortada"
                    checked={state.blockText?.imgPortada || false}
                    onChange={(e) => {
                        state.blockText.imgPortada = e.target.checked
                        setState({ ...state })
                    }}
                    disabled={loadingSave}
                />
            </Grid>
           
            <Grid item md={6}>
                <div style={{ textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto' }}>
                    <img alt="" src={
                        state?.fileBackgroundImage?.[0]?.base64 ??
                        state?.blockText?.fileBackgroundImage
                    } style={{ width: '100%', objectFit: 'cover' }} />
                </div>
            </Grid>

            <Grid item md={6}/>

            <Grid item md={6}>
                <ButtonFile
                    label="+ Imatge de fons"
                    required
                    name="fileBackgroundImage"
                    typeButton="secondary"
                    multiple={false}
                    onChange={handleChangePhotoFile}
                    accept="image/png, image/jpg, image/jpeg, image/gif"
                />
            </Grid>

            <Grid item md={6}>
                {(state?.fileBackgroundImage || state?.blockText?.fileBackgroundImage) &&
                    <InputButtonLoading
                        onClick={() => handleOpenModalFile()}
                        loading={false}
                        label={(
                            <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar imatge de fons</>
                        )}
                        position="left"
                    />
                }
            </Grid>
            
            <Grid item md={6}>
                <InputText
                    label={"SEO: Títol de la imatge"}
                    name="itemOne"
                    disabled={loadingSave}
                    value={state?.blockText?.itemOne}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={6}>
                <InputText
                    label={"SEO: Atribut alt de la imatge"}
                    name="itemTwo"
                    disabled={loadingSave}
                    value={state?.blockText?.itemTwo}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={12}></Grid>

            <Grid item md={3}>
                <InputCheckbox
                    labelLeft="Text imatge en color: Blanc"
                    label=" Negre"
                    name="titleColor"
                    checked={state.blockText?.titleColor || false}
                    onChange={(e) => {
                        state.blockText.titleColor = e.target.checked
                        setState({ ...state })
                    }}
                    disabled={loadingSave}
                />
            </Grid>
            <Grid item md={12}>
                <InputText
                    label={"Text imatge"}
                    name="text"
                    disabled={loadingSave}
                    value={state?.blockText?.text}
                    onChange={handleChangeBlockText}
                    rows={2}
                />
            </Grid>

            <Grid item md={4}>
                <InputText
                    label={"Text Botó Vermell"}
                    name="btnRedName"
                    disabled={loadingSave}
                    value={state?.blockText?.btnRedName}
                    onChange={handleChangeBlockText}

                />
            </Grid>

            <Grid item md={4}>
                <InputText
                    label={"Enllaç Botó Vermell"}
                    name="btnRedLink"
                    disabled={loadingSave}
                    value={state?.blockText?.btnRedLink}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={4}>
                <InputText
                    label={"SEO ID botó"}
                    name="itemThree"
                    disabled={loadingSave}
                    value={state?.blockText?.itemThree}
                    onChange={handleChangeBlockText}
                />
            </Grid>

            <Grid item md={12}>
                <InputButtonLoading
                    onClick={handleSubmit}
                    loading={loadingSave}
                    label="Modificar"
                    position="left"
                    style={{ float: 'right' }}
                />
            </Grid>

            <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile} text="Eliminar el fitxer?" />

        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        fileBackgroundImage: [],
        
    }
}

