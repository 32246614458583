import * as React from 'react';
import {useTranslation} from "react-i18next";

export default function ObraQuemadaFilter() {

    const {t} = useTranslation()

    return (
        <div className='obra-quemada-filter'>
            <span>{t('game.burn')}</span>
        </div>
    )
}
