import React, {useState} from "react";
import {apiSendRecoveryEmail} from "../../../services/Auth/login"
import MsgError from "../../../components/MsgError";
import {TextField} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {LoadingButton} from "@mui/lab";
import MsgSuccess from "../../../components/MsgSuccess";
import {RouteAuth} from "../../../routes/RouteAuth";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
const theme = createTheme();

export default function ForgottenPasswordForm() {

    const [state, setState] = useState(initialValues())
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loading, setLoading] = useState(false)
    const {i18n} = useTranslation()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        setMsgSuccess([])
        setMsgError([])

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            return false;
        }

        apiSendRecoveryEmail(state, i18n.resolvedLanguage).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
        })
    }

    return (<ThemeProvider theme={theme}>

            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
                    }}>
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Recover password
                    </Typography>

                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <MsgError msg={msgError}/>
                                <MsgSuccess msg={msgSuccess}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="username"
                                    label="Email Address"
                                    name="username"
                                    autoComplete="email"
                                    onChange={handleChange}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="start"
                                    variant="contained"
                                    fullWidth
                                    sx={{mt: 1, mb: 2}}
                                >
                                    Send email
                                </LoadingButton>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item>
                                <Link to={RouteAuth.LOGIN.path}>
                                    {"Go back"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>)
}

function initialValues() {
    return {
        username: ""
    }
}

function validationSchema(schema) {
    let error = []

    if (!schema.username) {
        error.push("El correo electrónico es obligatorio")
    }

    return error
}
