import {BASE_PATH, BASE_PATH_EXTENSION} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION}/user/game`;

export function getGameScreen(props, langSelect, lang = 'ca') {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    let query = "";
    if (props.code) {
        query += `?code=${props.code}`;
    }
    query += `?slideNumber=${props.slideNumber ?? 0}`;

    if (langSelect) {
        query += `&lang=${langSelect}`;
    }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/screen/${props.code}${query}`, config)
}

export function getRandomArtworks(langSelect, lang = 'ca') {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    let query = "";

    if (langSelect) {
        query += `&lang=${langSelect}`;
    }

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/`, config)
}
