import React, {useEffect, useRef} from 'react';

export default function MensajeCentral({textCenter, color, textCenterH1, loading, setHeight, height}) {

    const monitorRef = useRef(null)

    useEffect(() => {
        // Accede a la altura del elemento div después de que el componente se haya renderizado
        if (monitorRef.current) {
            const height = monitorRef.current.offsetHeight;
            setHeight(height -5)
        }
    }, [])

    return (
        <div>
            <div
                className={"camera"}
                style={{
                    color: '#fff',
                    textAlign: 'center',
                    fontSize: 20,
                    zIndex: 1,
                    maxWidth: 200,
                    height: 'max-content'
                }}
            >
                {textCenterH1 && (<span style={{
                    color: color,
                    textAlign: 'center',
                    fontSize: 20,
                    zIndex: 1,
                    maxWidth: 200,
                    height: 'max-content'
                }}>{textCenterH1}<br/></span>)}
                {textCenter}
            </div>

            <div className="camera">
                <div className="corner top-left"></div>
                <div className="corner top-right"></div>
                <div className="corner bottom-left"></div>
                <div className="corner bottom-right"></div>
            </div>

            <div id="monitor" ref={monitorRef}>
                <div className="scan"></div>
            </div>

            <style>
                {`
                    #monitor {
                        margin-top: -16px;
                        width: 85%;
                        height: 75.828%;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        overflow: hidden;
                        z-index: 1;
                    }
                    
                    #monitor .scan {
                        width:100%;
                        position:absolute;
                        z-index:9999;
                        -moz-animation: scan 5s infinite;
                        -webkit-animation: scan 5s infinite;
                        animation: scan 5s infinite;
                        -webkit-animation-direction: alternate-reverse;
                        box-shadow: 0px -5px 15px ${loading ? '#fff' : 'transparent'};
                        border-bottom: 2px solid ${loading ? 'red' : 'transparent'};
                    }
                    
                    @-webkit-keyframes scan {
                        0%, 100% {
                            transform: translateY(-100%);
                        }
                        100% {
                            transform: translateY(${loading ? height : '0'}px);
                        }
                    }
                `}
            </style>

            <style>
                {`
                    .camera {
                        margin-top: -16px;
                        width: 85%;
                        height: 75.828%;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        overflow: hidden;
                        z-index: 1;
                    }
                    
                    .corner {
                        width: 70px;
                        height: 70px;
                        position: absolute;
                    }
                    
                    .top-left {
                        top: 0;
                        left: 0;
                        border-radius: 5px 0 0 0;
                        border-top: 2px solid ${color};
                        border-bottom: 0px solid ${color};
                        border-right: 0px solid ${color};
                        border-left: 2px solid ${color};
                    }
                    
                    .top-right {
                        top: 0;
                        right: 0;
                        border-radius: 0 5px 0 0;
                        border-top: 2px solid ${color};
                        border-bottom: 0px solid ${color};
                        border-right: 2px solid ${color};
                        border-left: 0px solid ${color};
                    }
                    
                    .bottom-left {
                        bottom: 0;
                        left: 0;
                        border-radius: 0 0 0 5px;
                        border-top: 0px solid ${color};
                        border-bottom: 2px solid ${color};
                        border-right: 0px solid ${color};
                        border-left: 2px solid ${color};
                    }
                    
                    .bottom-right {
                        bottom: 0;
                        right: 0;
                        border-radius: 0 0 5px 0;
                        border-top: 0px solid ${color};
                        border-bottom: 2px solid ${color};
                        border-right: 2px solid ${color};
                        border-left: 0px solid ${color};
                    }    
                    
                    {/* BTN */}
                    
                    #container-circles {
                        display: none;
                    }
                    {/* END BTN */}
                    
                `}
            </style>
        </div>
    )
}
