import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import InputSelect from '../../../../components/Admin/Inputs/InputSelect'
import HeaderPage from "../../../../components/Admin/HeaderPage";
import { RouteAdmin } from "../../../../routes/RouteAdmin";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { lang } from "../../../../utils/lang";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../components/Admin/Inputs/InputButtonLoading";
import BlockDnd from "./BlockDnd";
import ModalSection from "./ModalSection";



export default function Fields() {

    let langSelectStorage = localStorage.getItem('langSelect')

    const { id } = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(false)
    const myRef = useRef(null)
    const { t, i18n } = useTranslation()
    const [detectAddBlock, setDetectAddBlock] = useState(false)
    const [blockFooter, setBlockFooter] = useState([])
    const host = window.location.origin;
    const [langSelect, setLangSelect] = useState(langSelectStorage ? langSelectStorage : 'ca')

    useEffect(() => {
        setLoadingGetInfo(true)
        if (id) {
            setState(initialValues({id: id}))
            setDetectAddBlock(true)
            setLoadingGetInfo(false)
        } else {
            setLoadingGetInfo(false)
            setState({ ...state })
        }
    }, [langSelect])

    return (
        <>
            <div ref={myRef}></div>

            <HeaderPage
                title="Tornar"
                urlBack={RouteAdmin.DISPLAY_MANAGEMENT.path}
            >
                <div style={{ float: 'right' }}>
                    <InputSelect
                        label="Idioma"
                        options={lang}
                        name="langSelect"
                        value={langSelect}
                        selectText="Selecciona un idioma"
                        style={{ width: 100 }}
                        onChange={(e) => {
                            setLangSelect(e.target.value)
                            localStorage.setItem('langSelect', e.target.value)
                        }}
                    />
                </div>
            </HeaderPage>

            <LoadingSpinner show={loadingGetInfo} />

            <MsgError msg={msgError} />
            <MsgSuccess msg={msgSuccess} />

            <Grid container spacing={2} style={{ display: loadingGetInfo ? 'none' : '' }}>

                <Grid item xs={12} md={12} lg={12}>
                    <ModalSection
                        idScreen={id}
                        langSelect={langSelect}
                        setDetectAddBlock={setDetectAddBlock}
                        setMsgError={setMsgError}
                        setMsgSuccess={setMsgSuccess}
                    />
                </Grid>

                <BlockDnd
                    id={id}
                    setMsgError={setMsgError}
                    setMsgSuccess={setMsgSuccess}
                    detectAddBlock={detectAddBlock}
                    setDetectAddBlock={setDetectAddBlock}
                />

               

            </Grid>
        </>
    );
}

function initialValues(props) {

    return {
        id: props?.id || "",
    }
}

