import {RouteAuth} from "../routes/RouteAuth";
import {useNavigate} from "react-router-dom";
import {getToken} from "./Auth/token";

export default function ErrorDetector() {

    const navigate = useNavigate()

    // Obtener todos los códigos de error de axios
    const axios = require('axios');
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response) {
            if (error.response.status === 403) {
                navigate(RouteAuth.LOGOUT.path)
            }
        }

        return Promise.reject(error);
    })

    return null;
}
