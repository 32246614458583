import React, {useEffect, useRef, useState} from "react";
import {Grid} from "@mui/material";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import InputButton from "../../../../components/Admin/Inputs/InputButton";
import DndElements from "../../../../components/DndElements";
import { apiGetListBlock, apiUpdatePositionBlock, apiDeleteBlock } from "../../../../services/Admin/screen";
import { RouteAdminDisplayEdit } from "../../../../routes/RouteAdminDisplayEdit";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function BlockDnd(props) {

    const {id, setMsgError, setMsgSuccess, detectAddBlock, setDetectAddBlock} = props
    const [loading, setLoading] = useState(false)
    const [loadingPosition, setLoadingPosition] = useState(false)
    const [numBlock, setNumBlock] = useState([])
    const [elementDnd, setElementDnd] = useState([])
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()
    let langSelect = localStorage.getItem('langSelect')

    useEffect(() => {
        if (id && detectAddBlock) {
            getBlock()
        } else {
            setLoading(false)
        }
    }, [detectAddBlock])

    useEffect(() => {
        if (id && elementDnd.length > 0) {
            setLoadingPosition(true)
            setNumBlock([])

            const timeOutId = setTimeout(() => {
                updateElementPosition()
            }, 2000)

            return () => clearTimeout(timeOutId)
        }

    }, [elementDnd])


    const getBlock = () => {
        setLoading(true)
        setNumBlock([])

        apiGetListBlock(id, i18n.resolvedLanguage).then(resp => {
            const block = resp.data?.map((item, key) => {
                setNumBlock((prevNumBlock) => [...prevNumBlock, key + 1]);
                
                return {
                    id: `${item.screenBlockId}`,
                    content: <BlockView numBlock={numBlock.length > 0 ? numBlock[key] : key + 1} block={item}
                                        screenId={id} displayBlockName={item.displayBlockName}
                                        handleRemove={handleRemove} handleNavigate={handleNavigate}/>
                }
            })
            setElementDnd(block)
            setLoading(false)
            setDetectAddBlock(false)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
        })
    }

    const updateElementPosition = () => {
        const idElementsSlide = elementDnd.map((item) => item.id);

        apiUpdatePositionBlock({
            id: idElementsSlide
        }).then(resp => {
            setLoadingPosition(false)
        }).catch(err => {
            setMsgError([err.response.data.msg])
            setLoadingPosition(false)
        })
    }

    const handleRemove = (id) => {
        setLoading(true)
        setMsgError([])
        setMsgSuccess([])
        apiDeleteBlock({
            id: id,
        }).then(resp => {
            setMsgSuccess(resp.data.msg)
            getBlock()
            setLoading(false)
        }).catch(err => {
            setMsgError(err.resp.msg)
            setLoading(false)
        })
    }

    const handleNavigate = (blockScreenId) => {
        navigate(`${RouteAdminDisplayEdit.DISPLAY_BLOCK_EDIT.path}/${id}/${blockScreenId}`)
    }

    return (
        <>
    {id &&
        <>
            <Grid item xs={12}/>

            <Grid item xs={12}>
                {!loading ? (
                    <DndElements
                        elementDnd={elementDnd}
                        setElementDnd={setElementDnd}
                        getBlock={getBlock}
                        title="Posició"
                    />
                ) : (
                    <LoadingSpinner show={loading || loadingPosition}/>
                )}
            </Grid>

            
        </>
    }
    

    </>

    )



}

function BlockView(props) {
    const {numSlide, block, screenId, handleRemove, handleNavigate, displayBlockName} = props
    return (
        <Grid container spacing={0} style={{
            padding: 0,
            margin: 0
        }}>
            <Grid item md={12}>
                <h1>{displayBlockName}</h1>
            </Grid>
            <Grid item md={12}>
                <InputButton
                    style={{float: "left", margin: "0px 10px 0px 0px"}}
                    onClick={() => handleNavigate(block.screenBlockId)}
                    text="Editar"
                />

                <InputButton
                    style={{float: "left", margin: "0px 0px 0px 0px"}}
                    onClick={() => handleRemove(block.screenBlockId)}
                    variant="outlined"
                    text="Eliminar"
                />
            </Grid>
        </Grid>
    )
}