import React, { useRef, useState, useEffect } from "react";
import { convertFromRaw } from 'draft-js';
import { EditorState } from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export default function Text2col({ item }) {

    const [state, setState] = useState(initialValues(item))

    return (

        <div className="blocks-full-width">
            <div className="schedule-rate-block">
                <div className="first-column dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.text }} />
                <div className="second-column dynamic-html-text" dangerouslySetInnerHTML={{ __html: state?.itemOne }} />
            </div>
        </div>
    )
}

function initialValues(props) {

    let a;

    if (props?.text) {
        a = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.text))).getCurrentContent()
    }
    else {
        a = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentState = convertToRaw(a);

    let markup = draftToHtml(rawContentState);
    markup = markup.replace(/<p><\/p>/gi, "<br/>");

    let b;

    if (props?.itemOne) {
        b = EditorState.createWithContent(convertFromRaw(JSON.parse(props?.itemOne))).getCurrentContent()
    }
    else {
        b = EditorState.createEmpty().getCurrentContent()
    }

    const rawContentStateB = convertToRaw(b);

    let markupB = draftToHtml(rawContentStateB);
    markupB = markupB.replace(/<p><\/p>/gi, "<br/>");


    return {
        "text": markup,
        "itemOne": markupB
    }
}
