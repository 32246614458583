import React, {useEffect, useRef, useState} from "react";
import {Grid} from "@mui/material";
import InputText from "../../../../../components/Admin/Inputs/InputText";
import Header from "./Header";
import {useNavigate, useParams} from "react-router-dom";
import clone from "../../../../../utils/clone";
import {apiSaveIncident, apiGetIncident} from "../../../../../services/Admin/obra";
import {apiDeleteIncident} from "../../../../../services/Admin/obraDelete";
import MsgError from "../../../../../components/MsgError";
import MsgSuccess from "../../../../../components/MsgSuccess";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../../components/Admin/Inputs/InputButtonLoading";
import InputButton from "../../../../../components/Admin/Inputs/InputButton";
import ModalConfirm from "../../../../../components/Admin/ModalConfirm";
import EditorText from "../../../../../components/EditorText";
import TitlePage from "../../../../../components/Admin/TitlePage";

export default function TabIncidents() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(false)
    const myRef = useRef(null)
    const [openModal, setOpenModal] = React.useState(false)
    const [pendingIndexRemove, setPendingIndexRemove] = React.useState(null)

    const handleOpenModal = (key) => {
        setOpenModal(true)
        setPendingIndexRemove(key)
    }

    useEffect(() => {
        getIncident()
    }, [state.langSelect])

    const getIncident = () => {
        setLoadingGetInfo(true)

        apiGetIncident({id}, state?.langSelect).then(resp => {
            let incidentsNew = resp.data.incidents?.map(doc => {
                return {
                    ...doc
                }
            })

            setState({...state, incidents: incidentsNew})
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data.msg)
        })
    }

    const handleSubmit = index => async () => {
        stateUpdateSimple(index, true, [], [])

        let error = validationSchema(state)

        if (error.length !== 0) {
            stateUpdateSimple(index, false, error, [])
            return
        }

        let incident = clone(state.incidents[index])
        incident.langSelect = state.langSelect

        await apiSaveIncident(incident).then(resp => {
            state.incidents[index].id = resp.data?.id
            setState({...state})
            stateUpdateSimple(index, false, [], resp.data?.msg)
        }).catch(err => {
            stateUpdateSimple(index, false, err.response?.data?.msg, [])
        })
    }

    const stateUpdateSimple = (index, loading, msgError, msgSuccess) => {
        state.incidents[index].loading = loading
        state.incidents[index].msgError = msgError
        state.incidents[index].msgSuccess = msgSuccess

        setState({...state})
    }

    const stateDeleteSimple = (index, loadingDelete, msgError, msgSuccess) => {
        state.incidents[index].loadingDelete = loadingDelete
        state.incidents[index].msgError = msgError
        state.incidents[index].msgSuccess = msgSuccess

        setState({...state})
    }

    const addIncident = () => {
        let model = state.model
        model.artworkId = id
        setState({...state, incidents: [...state.incidents, clone(model)]})
        myRef.current.scrollIntoView()
    }

    const handleRemove = () => {
        stateDeleteSimple(pendingIndexRemove, true, [], [])
        apiDeleteIncident({
            id: state.incidents[pendingIndexRemove].id
        }).then(resp => {
            window.location.reload();
            stateDeleteSimple(pendingIndexRemove, false, [], resp.data?.msg)
            setPendingIndexRemove(null)
        }).catch(err => {
            stateDeleteSimple(pendingIndexRemove, false, err.response?.data?.msg, [])
            setPendingIndexRemove(null)
        })
    }

    return (
        <>
            <Header
                state={state}
                setState={setState}
                id={id}
                tabType={1}
                msgError={msgError}
                msgSuccess={msgSuccess}
            >
                <InputButton
                    style={{margin: "5px 10px 0px 0px", width: 200}}
                    onClick={addIncident}
                    text=" + Afegir incident"
                />
            </Header>

            <LoadingSpinner show={loadingGetInfo}/>

            <div style={{display: loadingGetInfo ? 'none' : ''}}>

                {state.incidents.map((element, key) => (
                    <Grid container spacing={3} style={{padding: '0px'}} key={key}>

                        {key === 0 ? (
                            <Grid item xs={12} md={12} lg={12} style={{paddingTop: 0}}>
                                <TitlePage title={`Incident ${key + 1}`} />
                            </Grid>
                        ) : (
                            <Grid item xs={12} md={12} lg={12}>
                                <TitlePage title={`Incident ${key + 1}`} borderTop={true} />
                            </Grid>
                        )}

                        <Grid item xs={12} md={12} lg={12}>
                            <p style={{
                                fontSize: '11px',
                                fontFamily: 'AdminFontMedium',
                                color: '#778',
                                marginTop: '0px',
                                paddingLeft: '15px'
                            }}>Any i lloc incident</p>
                            <EditorText
                                style={{height: '80px'}}
                                content={state.incidents[key]?.yearSite}
                                setContent={(newContent) => {
                                    setState((prevState) => {
                                        const incidents = [...prevState.incidents];
                                        incidents[key] = {
                                            ...incidents[key],
                                            yearSite: newContent
                                        }
                                        return {...prevState, incidents};
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <p style={{
                                fontSize: '11px',
                                fontFamily: 'AdminFontMedium',
                                color: '#778',
                                marginTop: '0px',
                                paddingLeft: '15px'
                            }}>Text incident</p>
                            <EditorText
                                style={{height: '132px'}}
                                content={state.incidents[key]?.text}
                                setContent={(newContent) => {
                                    setState((prevState) => {
                                        const incidents = [...prevState.incidents];
                                        incidents[key] = {
                                            ...incidents[key],
                                            text: newContent
                                        };
                                        return {...prevState, incidents};
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <div style={{display: 'block'}}>
                                <MsgSuccess msg={state.incidents[key].msgSuccess}/>
                                <MsgError msg={state.incidents[key].msgError}/>
                            </div>

                            <InputButtonLoading
                                onClick={handleSubmit(key)}
                                loading={state.incidents[key].loading}
                                label={state.incidents[key].id ? 'Modificar' : 'Crear'}
                                position="left"
                                style={{marginRight: '15px'}}
                            />

                            {state.incidents[key].id && (
                                <InputButtonLoading
                                    onClick={() => handleOpenModal(key)}
                                    loading={state.incidents[key].loadingDelete}
                                    label="Eliminar"
                                    position="left"
                                    variant="outline"
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div style={{height: 5}}></div>
                        </Grid>

                    </Grid>
                ))}
                <ModalConfirm
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    handleAction={handleRemove}
                    ext="Eliminar el bloc d'incidents?"
                />
            </div>
            <div ref={myRef}></div>
        </>
    );
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        incidents: [],
        model: {
            artworkId: null,
            id: null,
            loading: false,
            loadingDelete: false,
            yearSite: "",
            text: "",
            msgSuccess: [],
            msgError: []
        },
    }
}

function validationSchema(schema) {

    let error = []

    return error
}
