import React from "react";
import {CircularProgress, Box} from "@mui/material";

export default function LoadingSpinner(props) {

    let showIn = props.show || false
    return (
        <>
            {showIn && (
                <div style={{
                    display: 'flex',
                    position: 'fixed',
                    zIndex: 9999,
                    top: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw',
                    left: 0
                }}>
                    <CircularProgress sx={{ color: '#F00214' }} size={50}/>
                </div>
            )}

        </>
    )
}
