import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TitlePage from "../../../../../../../components/Admin/TitlePage";
import InputButtonLoading from "../../../../../../../components/Admin/Inputs/InputButtonLoading";
import { apiSaveBlockText } from "../../../../../../../services/Admin/page";
import EditorTextWeb from "../../../../../../../components/EditorTextWeb";
import InputText from "../../../../../../../components/Admin/Inputs/InputText";
import InputAdornment from '@mui/material/InputAdornment';

import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FaTiktok } from "react-icons/fa";
import ReactDOMServer from "react-dom/server";
import FullTextToHtml from "./FullTextToHtml";
import {clone} from "lodash";
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function Footer(props) {

    const { state, setState, setReloadPreview, setMsgError, setMsgSuccess } = props
    const [loadingSave, setLoadingSave] = useState(false)

    const handleSubmit = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)

        state.blockText.htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.text} />);
        state.blockText.btnRedName_htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.btnRedName} />);
        state.blockText.btnRedLink_htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.btnRedLink} />);
        state.blockText.btnWhiteLink_htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.btnWhiteLink} />);
        state.blockText.itemOne_htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.itemOne} />);
        state.blockText.itemTwo_htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.itemTwo} />);
        state.blockText.itemFive_htmlCode = ReactDOMServer.renderToStaticMarkup(<FullTextToHtml text={state.blockText.itemFive} />);

        apiSaveBlockText(state).then(resp => {
            handleRefresh()
            setMsgSuccess(resp.data?.msg)
            setLoadingSave(false)
        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)
            handleRefresh()
        })
        
    }

    const handleChangeBlockText = (e) => {
        state.blockText[e.target.name] = e.target.value
        setState({ ...state })
    }

    const handleRefresh = () => {
        setReloadPreview(Math.random())
    }

    return (
        <>
            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title={state.block.webBlockName} borderTop={false} borderBottom={false} />
            </Grid>
            
            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title="Col·lumna 1" borderTop={false} borderBottom={true} />
            </Grid>

                <Grid item md={12}>
                    <InputText
                        label={"Títol"}
                        name="backgroundImageName"
                        disabled={loadingSave}
                        value={state?.blockText?.backgroundImageName}
                        onChange={handleChangeBlockText}
                    />
                </Grid>

                <Grid item md={12}>
                    <EditorTextWeb
                        style={{ height: 125 }}
                        options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']}
                        content={state.blockText.text}
                        setContent={(e) => {
                            state.blockText.text = e
                            setState({ ...state })
                        }}
                    />
                </Grid>

           

            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title="Col·lumna 2" borderTop={false} borderBottom={true} />
            </Grid>

                <Grid item md={12}>
                    <InputText
                        label={"Títol"}
                        name="backgroundImage"
                        disabled={loadingSave}
                        value={state?.blockText?.backgroundImage}
                        onChange={handleChangeBlockText}
                    />
                </Grid>

                <Grid item md={6}>
                    <EditorTextWeb
                        style={{ height: 125 }}
                        options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']}
                        content={state.blockText.btnRedName}
                        setContent={(e) => {
                            state.blockText.btnRedName = e
                            setState({ ...state })
                        }}
                    />
                </Grid>
                <Grid item md={6}>
                    <EditorTextWeb
                        style={{ height: 125 }}
                        options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']}
                        content={state.blockText.btnRedLink}
                        setContent={(e) => {
                            state.blockText.btnRedLink = e
                            setState({ ...state })
                        }}
                    />
                </Grid>

            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title="Col·lumna 3" borderTop={false} borderBottom={true} />
            </Grid>

                <Grid item md={12}>
                    <InputText
                        label={"Títol"}
                        name="btnWhiteName"
                        disabled={loadingSave}
                        value={state?.blockText?.btnWhiteName}
                        onChange={handleChangeBlockText}
                    />
                </Grid>

                <Grid item md={12}>
                    <EditorTextWeb
                        style={{ height: 125 }}
                        options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']}
                        content={state.blockText.btnWhiteLink}
                        setContent={(e) => {
                            state.blockText.btnWhiteLink = e
                            setState({ ...state })
                        }}
                    />
                </Grid>

            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title="Col·lumna 4" borderTop={false} borderBottom={true} />
            </Grid>

                <Grid item md={12}>
                    <InputText
                        label={"Títol"}
                        name="itemOneName"
                        disabled={loadingSave}
                        value={state?.blockText?.itemOneName}
                        onChange={handleChangeBlockText}
                    />
                </Grid>

                <Grid item md={12}>
                    <EditorTextWeb
                        style={{ height: 125 }}
                        options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']}
                        content={state.blockText.itemOne}
                        setContent={(e) => {
                            state.blockText.itemOne = e
                            setState({ ...state })
                        }}
                    />
                </Grid>

            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title="Col·lumna 5" borderTop={false} borderBottom={true} />
            </Grid>
            
                <Grid item md={12}>
                    <InputText
                        label={"Títol"}
                        name="itemTwoName"
                        disabled={loadingSave}
                        value={state?.blockText?.itemTwoName}
                        onChange={handleChangeBlockText}
                    />
                </Grid>

                <Grid item md={12}>
                    <EditorTextWeb
                        style={{ height: 125 }}
                        options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']}
                        content={state.blockText.itemTwo}
                        setContent={(e) => {
                            state.blockText.itemTwo = e
                            setState({ ...state })
                        }}
                    />
                </Grid>

                <Grid item md={12}>
                    <InputText
                        label={"Títol RRSS"}
                        name="itemThreeName"
                        disabled={loadingSave}
                        value={state?.blockText?.itemThreeName}
                        onChange={handleChangeBlockText}
                    />
                </Grid>

                <Grid item md={6}>
                    <InputText
                        label={"Enllaç Instagram"}
                        name="itemThree"
                        disabled={loadingSave}
                        value={state?.blockText?.itemThree}
                        onChange={handleChangeBlockText}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><InstagramIcon /></InputAdornment>,
                        }}
                        variant="filled"
                    />
                </Grid>

                <Grid item md={6}>
                    <InputText
                        label={"Enllaç Twitter"}
                        name="itemFourName"
                        disabled={loadingSave}
                        value={state?.blockText?.itemFourName}
                        onChange={handleChangeBlockText}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><TwitterIcon /></InputAdornment>,
                        }}
                        variant="filled"
                    />
                </Grid>

                <Grid item md={6}>
                    <InputText
                        label={"Enllaç Youtube"}
                        name="itemFour"
                        disabled={loadingSave}
                        value={state?.blockText?.itemFour}
                        onChange={handleChangeBlockText}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><YouTubeIcon /></InputAdornment>,
                        }}
                        variant="filled"
                    />
                </Grid>

                <Grid item md={6}>
                    <InputText
                        label={"Enllaç Ticktok"}
                        name="itemSeven"
                        disabled={loadingSave}
                        value={state?.blockText?.itemSeven}
                        onChange={handleChangeBlockText}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><FaTiktok /></InputAdornment>,
                        }}
                        variant="filled"
                    />
                </Grid>

                <Grid item md={6}>
                    <InputText
                        label={"Enllaç LinkedIn"}
                        name="itemSix"
                        disabled={loadingSave}
                        value={state?.blockText?.itemSix}
                        onChange={handleChangeBlockText}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><LinkedInIcon /></InputAdornment>,
                        }}
                        variant="filled"
                    />
                </Grid>

                <Grid item md={12}>
                    <EditorTextWeb
                        style={{ height: 125 }}
                        options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']}
                        content={state.blockText.itemFive}
                        setContent={(e) => {
                            state.blockText.itemFive = e
                            setState({ ...state })
                        }}
                    />
                </Grid>


            <Grid item md={12}>
                <InputButtonLoading
                    onClick={handleSubmit}
                    loading={loadingSave}
                    label="Modificar"
                    position="left"
                    style={{ float: 'right' }}
                />
            </Grid>
        </>
    )
}

function initialValues(props) {
    let langSelect = localStorage.getItem('langSelect')

    return {
        langSelect: langSelect ? langSelect : 'ca',
        backgroundImage: "",

    }
}

