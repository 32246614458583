import React, {useEffect, useRef, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

/**
 * Muestra un botón para subir ficheros
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **onChange** *function*
 * - **required** *bool*
 * - **multiple** *bool*
 * - **loading** *bool*
 *
 * ## RETURN
 * - **multiple == true**
 *
 * {target: {name: name, value: [{binary: binary, base64: base64}]}}
 * - **multiple == false**
 *
 * {target: {name: name, value: {binary: binary, base64: base64}}}
 */
export default function InputButton(props) {

    const {color, href, text, onClick, disabled, variant, position, style} = props
    const navigate = useNavigate()

    const colorIn = color ? color : "primary"
    const disabledIn = disabled ? disabled : false
    const variantIn = variant ? variant : 'contained'
    const positionIn = position ? position : 'right'

    const handleClick = () => {
        navigate(href)
    }

    return (
        <>
            {href && (
                <button
                    className={variantIn === 'contained' ? `btn-primary` : `btn-primary-outline`}
                    color={colorIn}
                    onClick={handleClick}
                    disabled={disabledIn}
                    style={{
                        float: positionIn,
                        ...style
                    }}
                >
                    {text}
                </button>
            )}

            {!href && (
                <button
                    className={variantIn === 'contained' ? `btn-primary` : `btn-primary-outline`}
                    color={colorIn}
                    onClick={onClick}
                    disabled={disabledIn}
                    style={{
                        float: positionIn,
                        ...style
                    }}
                >
                    {text}
                </button>
            )}
        </>
    )
}
