import React, { useRef, useState, useEffect } from "react";
import {convertFromRaw} from 'draft-js';
import {EditorState} from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export default function FullText({item}) {

    const [state, setState] = useState(initialValues(item))
    const [margin, setMargin] = useState('25px auto')

    useEffect(() => {
        responsiveMargin()
        window.addEventListener("resize", responsiveMargin);
        
        return () => {
            window.removeEventListener("resize", responsiveMargin);
        }
    }, [])
    
    const responsiveMargin = () => {
        if(state?.blockMargin){
            setMargin('0px auto')
        } else {
            if (window.innerWidth >= 768) {
                setMargin('50px auto')
            }else{
                setMargin('25px auto')
            }
        }
    };
      
    return(
        <div className="blocks-full-width">
            <div className="text-block" style={{margin: margin}}>
                <div className="text-container dynamic-html-text" dangerouslySetInnerHTML={{__html: state?.text}} />
                <div className="text-button text-center">
                    {state?.linkWhiteName && <div className="white-link-container"><a href={state?.linkWhite} id={state?.itemOne} className="white-link">{state?.linkWhiteName}</a></div>}
                    {state?.buttonModalName && <button id={state?.itemTwo}  className="white-button">{state?.buttonModalName}</button>}
                </div>
            </div>
        </div>
    )
}

function initialValues(props) {

    let a;
   
    if(props?.text)
    {
        a=EditorState.createWithContent(convertFromRaw(JSON.parse(props?.text))).getCurrentContent()
    }
    else
    {
        a=EditorState.createEmpty().getCurrentContent()
    }

    const rawContentState = convertToRaw(a);

    let markup = draftToHtml(rawContentState);

    markup=markup.replace(/<p><\/p>/gi, "<br/>");
    
    
    return {
        "text": markup,
        "linkWhiteName": props?.btnWhiteName,
        "linkWhite": props?.btnWhiteLink,
        "buttonModalName": props?.btnRedName,
        "buttonModalText": props?.btnRedLink,
        "blockMargin": props?.titleColor ?? false,
        'itemOne': props?.itemOne,
        'itemTwo': props?.itemTwo,
    }
}
