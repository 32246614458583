import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import DeslizaBajo from '../../../../assets/images/front/animaciones/stories/Desliza-bajo.gif'
import DeslizaLado from '../../../../assets/images/front/animaciones/stories/Desliza-lado.gif'
import InstructionsAnimationVertical from './instructionsAnimationVertical'
import InstructionsAnimationHorizontal from './instructionsAnimationHorizontal'

export default  function Instructions() {

    const {t} = useTranslation()
    const [showInstructions, setShowInstructions] = useState(false) // State para mostrar u ocultar la pantalla

    useEffect(() => {
        // Comprobamos si se ha mostrado esta pantalla
        const scannerInfo = localStorage.getItem('story-info')
        if (scannerInfo !== 'no') {
            const timerIn = setTimeout(() => {
                setShowInstructions(true)
            }, 3000)
            return () => clearTimeout(timerIn)
        }
    }, [])

    useEffect(() => {
        // Corrección del bug de 100vh en móvil
        handleResize()
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    // Corrección del bug de 100vh en móvil
    const handleResize = () => {
        var elementoMapa = document.querySelector('.stories-instructions-height');
        if(elementoMapa){
            elementoMapa.style.height = window.innerHeight + 'px';
        }
    };

    const handleClose = () => {
        // Guardamos en local storage si se ha mostrado esta pantalla
        localStorage.setItem('story-info', 'no')
        setShowInstructions(false)
    };

    return(
        <>
            {showInstructions && 
                <div className="stories-instructions-height">
                    <div className="stories-instructions">
                        <div className="instructions-container">
                            <div className="instructions-element">
                                <InstructionsAnimationVertical />
                                <p>{t('story.instructions.first_text')}</p>
                            </div>
                            <div className="instructions-element">
                                <InstructionsAnimationHorizontal />
                                <p>{t('story.instructions.second_text')}</p>
                            </div>
                        </div>
                        <div className="instructions-button-container">
                            <button type="button" onClick={handleClose}>{t('story.instructions.button_text')}</button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
