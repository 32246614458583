import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { RouteAdmin } from "../../../../routes/RouteAdmin";
import TitlePage from "../../../../components/Admin/TitlePage";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import InputSelect from '../../../../components/Admin/Inputs/InputSelect'
import InputText from "../../../../components/Admin/Inputs/InputText";
import { MdDeleteForever } from "react-icons/md";
import ButtonFile from "../../../../components/Admin/Inputs/ButtonFile";
import InputButtonLoading from "../../../../components/Admin/Inputs/InputButtonLoading";
import { useTranslation } from "react-i18next";
import ModalConfirm from "../../../../components/Admin/ModalConfirm";
import { apiGetDisplayScreen, apiSaveDisplayScreen, apiSaveFileDisplayScreen, apiDeleteFileDisplayScreen } from "../../../../services/Admin/display";
import InputCheckbox from "../../../../components/Admin/Inputs/InputCheckbox";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import { lang } from "../../../../utils/lang";
import LoadingSpinner from "../../../../components/LoadingSpinner";

export default function Rates() {

    let langSelectStorage = localStorage.getItem('langSelect')

    const [state, setState] = useState(initialValues())
    const [loadingSave, setLoadingSave] = useState(false)
    const [openModalFile, setOpenModalFile] = React.useState(false)
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(false)
    const [langSelect, setLangSelect] = useState(langSelectStorage ? langSelectStorage : 'ca')
    const [fileToDelete, setFileToDelete] = useState(null)
    const { t, i18n } = useTranslation()

    useEffect(() => {
        setLoadingGetInfo(true)
        getDisplayScreen()
    }, [state.langSelect])

    const getDisplayScreen = () => {

        setMsgError([])
        setMsgSuccess([])

        apiGetDisplayScreen( 3, state?.langSelect, i18n.resolvedLanguage).then(resp => {
            state.baseUrl = resp.data['baseUrl']
            setState(initialValues(resp.data[0]))
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
            setState(initialValues())

        })

    }

    const handleChangeVideoFile = (event) => {
        state[event.target.name] = event.target.value
        setState({ ...state })
    }

    const handleChangeMedia = (event) => {

        state[event.target.name] = event.target.value
        setState({ ...state })
    }


    const handleOpenModalFile = (fileType) => {
        setFileToDelete(fileType)
        setOpenModalFile(true)
    }

    const handleDeleteFile = () => {
        apiDeleteFileDisplayScreen({
            screenId: state.idScreen,
            langCode: state.langSelect,
            fileType: fileToDelete
        }).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setFileToDelete(null)
            window.location.reload();
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setFileToDelete(null)
        })
    }

    const handleSubmit = () => {

        setMsgError([])
        setMsgSuccess([])
        setLoadingSave(true)

        let fileMediaOneBynary = state?.fileMediaOne?.length > 0 ? state?.fileMediaOne[0].binary : null
    

        apiSaveDisplayScreen(state).then(resp => {

            handleSubmitFile(state.idScreen, fileMediaOneBynary)

        }).catch(err => {
            setMsgError(err?.response?.data?.msg)
            setLoadingSave(false)

        })

    }


    const handleSubmitFile = (idScreen, fileMediaOne) => {
        let f = new FormData()
        f.append(`langCode`, state.langSelect)
        f.append(`fileMediaOne[]`, fileMediaOne)
        f.append(`mediaOneType`, state.mediaOneType)
       
        apiSaveFileDisplayScreen(f, idScreen).then(resp => {
            setLoadingSave(false)
            setMsgSuccess(resp.data?.msg)
            window.location.reload();
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingSave(false)
        })
    }


    const handleChange = (e) => {
        state[e.target.name] = e.target.value
        setState({ ...state })
    }



    return (
        <>
            <Grid item md={12} style={{ paddingTop: '0px' }}>
                <TitlePage title="Edit Pantalla Tarifes" borderTop={false} borderBottom={false} />
            </Grid>
            <HeaderPage
                title="Tornar"
                urlBack={RouteAdmin.DISPLAY_MANAGEMENT.path}
            >
                <div style={{ float: 'right' }}>
                    <InputSelect
                        label="Idioma"
                        options={lang}
                        name="langSelect"
                        value={langSelect}
                        selectText="Selecciona un idioma"
                        style={{ width: 100 }}
                        onChange={(e) => {
                            setLangSelect(e.target.value)
                            state.langSelect = e.target.value
                            localStorage.setItem('langSelect', e.target.value)
                        }}
                    />
                </div>
            </HeaderPage>

            <LoadingSpinner show={loadingGetInfo} />

            <MsgError msg={msgError} />
            <MsgSuccess msg={msgSuccess} />

            <Grid container spacing={2} style={{ display: loadingGetInfo ? 'none' : '' }}>

                <Grid item md={12} style={{ marginTop: '5px' }}>Preus</Grid>


               
                <Grid item md={3}>
                    <InputCheckbox
                        labelLeft="Media: imatge"
                        label=" video"
                        name="mediaOneType"
                        checked={state?.mediaOneType || false}
                        onChange={(e) => {
                            state.mediaOneType = e.target.checked
                            state.fileMediaOne = []
                            state.urlFileMediaOne = ""
                            setState({ ...state })
                        }}
                        disabled={loadingSave}
                    />
                </Grid>
                <Grid item md={9}/>
               
                <Grid item md={6}>
                    {((state?.mediaOneType && (state?.fileMediaOne.length > 0 || state?.urlFileMediaOne.length > 0)) &&
                        <div style={{ textAlign: 'center', width: 400, marginLeft: 'auto', marginRight: 'auto' }}>
                            <video controls style={{ width: '100%', objectFit: 'cover' }}>
                                <source src={
                                    state.fileMediaOne?.[0]?.base64 ?? state.urlFileMediaOne
                                } ></source>
                            </video>
                        </div>
                    )}
                    {((!state?.mediaOneType && (state?.fileMediaOne.length > 0 || state?.urlFileMediaOne.length > 0)) &&
                        <div style={{ textAlign: 'center', width: 300, marginLeft: 'auto', marginRight: 'auto' }}>
                            <img alt="" src={
                                state?.fileMediaOne.length > 0 ? state.fileMediaOne?.[0]?.base64 :
                                    state.urlFileMediaOne
                            } style={{ width: '100%', objectFit: 'cover' }} />

                        </div>
                    )}
                </Grid>

                <Grid item md={6}/>
                <Grid item md={6}>
                    {state?.mediaOneType && (
                        <ButtonFile
                            label="+ Video"
                            required
                            name="fileMediaOne"
                            typeButton="secondary"
                            multiple={false}
                            onChange={handleChangeMedia}
                            accept="video/mp4,video/mkv,video/x-m4v,video/*"
                        />
                    )}
                    {!state?.mediaOneType && (
                        <ButtonFile
                            label="+ Imatge"
                            required
                            name="fileMediaOne"
                            typeButton="secondary"
                            multiple={false}
                            onChange={handleChangeMedia}
                            accept="image/png, image/jpg, image/jpeg, image/gif, image/*"
                        />
                    )}
                </Grid>

                <Grid item md={2}>
                    {(state?.fileMediaOne.length > 0 || state?.urlFileMediaOne.length > 0) &&
                        <InputButtonLoading
                            onClick={() => handleOpenModalFile('fileMediaOne')}
                            loading={false}
                            label={(
                                <><MdDeleteForever fontSize="inherit" style={{ width: '20px', height: '20px', marginBottom: '-3px' }} /> Eliminar media</>
                            )}
                            position="left"
                        />
                    }
                </Grid>

                <Grid item md={12}>
                    <InputButtonLoading
                        onClick={handleSubmit}
                        loading={loadingSave}
                        label="Modificar"
                        position="left"
                        style={{ float: 'right' }}
                    />
                </Grid>

                <ModalConfirm openModal={openModalFile} setOpenModal={setOpenModalFile} handleAction={handleDeleteFile} text="Eliminar el fitxer?" /> 

            </Grid>

        </>
    )
}

function initialValues(props) {

    let langSelect = localStorage.getItem('langSelect')

    return {
        idDisplay: 3,
        idScreen: 3,
        langSelect: langSelect ? langSelect : 'ca',
        mediaOneType: props?.mediaOneType === undefined ? false : props.mediaOneType,
        fileMediaOne: props?.fileMediaOne || [],
        urlFileMediaOne: props?.urlFileMediaOne || "",
        qrMediaOne: props?.qrMediaOne === undefined ? false : props.qrMediaOne,
        isActiveMediaOne: props?.isActiveMediaOne === undefined ? true : props.isActiveMediaOne,
    }
}

