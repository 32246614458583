import React, { useRef, useState, useEffect } from "react";
import {useTranslation} from "react-i18next";

export default function InsultBlock(props) {

    const {pluralTitle, hideTitle, content} = props
    const {t} = useTranslation()

    const [title, setTitle] = useState("")
    const hideHeaderIn = hideTitle === false ? false : true

    useEffect(() => {
        if(pluralTitle){
            setTitle(t('story.plural.insult'))
        }else{
            setTitle(t('story.insult'))
        }
    }, [title]);
    
    return(
        <>
            {content.text && 
                <div className="documentation-block" style={{maxWidth: '100%', marginTop: hideHeaderIn ? '0px' : '-4px'}}>
                    <div style={{paddingBottom: '4px', paddingTop: '8px', borderTop: hideHeaderIn ? '1px solid #FE7A84' : 'none'}}>
                        {hideHeaderIn && (<h2 style={{fontFamily: 'SohneBreitHalbfett', fontSize: '15px', lineHeight: '18px', color: '#E30613', margin: '0px', marginBottom: '12px'}}>{title}</h2>)}
                        <div style={{marginBottom: '24px'}}>
                            <h3 style={{fontFamily: 'QueensCondensedBoldIt', fontSize: '20px', lineHeight: '22px', color: '#141414', margin: '0px', marginBottom: '8px'}}>{content.author}</h3>
                            <div style={{fontFamily: 'SohneBuch', fontSize: '16px', lineHeight: '21px', color: '#141414', margin: '0px'}} dangerouslySetInnerHTML={{ __html: content.text }} className="text-block-html" />
                            <div style={{fontFamily: 'SohneBuch', fontSize: '12px', lineHeight: '14px', color: '#141414', margin: '0px'}} dangerouslySetInnerHTML={{ __html: content.source }} className="text-block-html" />
                            <p style={{fontFamily: 'SohneBuch', fontSize: '12px', lineHeight: '14px', color: '#141414', margin: '0px'}}>{content.date}</p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
