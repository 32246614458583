import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH_EXTENSION_ADMIN}`;

export function apiLogMail(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/log-mail/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}`, config)
}

export function apiLogReport(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/log-report/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}`, config)
}

export function apiLogCronjob(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/log-cronjob/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}`, config)
}

export function apiLogScanner(props, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/log-scanner/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}`, config)
}

export function apiLogScannerId(id, lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/log-scanner/${id}`, config)
}

export function apiSaveLog(lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/log-scanner/save`, config)
}

export function apiRemoveLog(lang = "ca") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.get(`${BASE_PATH}/${lang}/${baseUrl}/log-scanner/remove`, config)
}
