import {VscAdd, VscChromeClose, VscChromeMinimize} from "react-icons/vsc";
import TableCell from "@mui/material/TableCell";
import React from "react";

export default function InputNumber(props) {

    const {
        row,
        element,
        keyElement,
        keyRow,
        rowsIn,
        setRowsIn,
        handleChangeCell,
        colorOffset
    } = props

    // Actualizamos el valor de la celda segun la posición del array
    const handleChangeInput = (value, key, field, row) => {
        // Actualizamos el valor de la celda segun la posición del array
        rowsIn[key][field] = value
        setRowsIn(rowsIn)

        // Enviamos el valor en el array de origen
        handleChangeCell(value, row)
    }


    return (
        <TableCell
            component="td"
            scope="row"
            key={keyElement}
            size="small"
            style={{padding: '8px'}}
        >
            <input
                id={row.id}
                style={{
                    textAlign: 'left',
                    fontSize: 14,
                    color: colorOffset,
                    width: '90%',
                    padding: "5px 10px"
                }}
                onChange={(e) => handleChangeInput(e.target.value, keyRow, element.field, row)}
                value={row[element.field]}
            />
        </TableCell>
    )
}


