import React, { useRef, useState, useEffect } from "react";
import KeyboardArrowDownIcon from './Icons/KeyboardArrowDownIcon';
import CloseIcon from './Icons/CloseIcon';

export default function SlideCover(props) {

    const {
        featuredImage,
        backgroundImage,
        colorText,
        textScroll,
        colorTextScroll,
        artistName,
        elRef,
        handleClick,
        segundoBloqueEmpty,
        handleClickToScroll,
        index,
        handleButtonClickStop,
        colorTextArtistDinamic
    } = props

    const [heightText, setHeightText] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        if(ref && ref.current){
            setHeightText(ref.current.clientHeight)
        }
    })

    // const elRef = useRef();
    const [elHeight, setElHeight] = useState()

    useEffect(() => {
        setElHeight(elRef.current.clientHeight)
    })

    const style = {
        width: '100%', height: window.innerHeight, position: 'relative'
    }

    return(
        <div className="outstanding-image-block second-story-block primer-bloque" style={style} onClick={handleClick}>
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
            </div>
            <div style={{width: '100%', height: '100%', position: 'relative'}}>
                <div style={{
                    position: 'absolute',
                    top: `calc(50px + ${elHeight}px)`,
                    right: '0px',
                    width: '100%',
                    left: '0px',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    bottom: '0',
                    height: `calc(100% - (${heightText}px + (${elHeight}px + 50px)))`
                }}>
                    {featuredImage &&
                        <img src={featuredImage} style={{ objectFit: 'contain' }} />
                    }
                </div>
            </div>
            {!segundoBloqueEmpty && 
                <div ref={ref} className="deslizar-text" onClick={(event) => {handleClickToScroll(index);handleButtonClickStop(event);}} style={{
                    fontFamily: 'SohneBuch',
                    color: colorTextScroll,
                    width: 'calc(100% - 48px)',
                    textAlign: 'center',
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    margin: 'auto'
                }}>
                    <p style={{margin: '0px', whiteSpace: 'pre'}}>{textScroll}</p>
                    <KeyboardArrowDownIcon style={{margin: 'auto'}} className="keyboard-arrow-down-icon icon key-board-arrow-animation" color={colorTextScroll} />
                </div>
            }
        </div>
    )
}
